import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8660e468"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "sub-module-change-history-entry" };
const _hoisted_2 = { class: "date-tag" };
const _hoisted_3 = { class: "content" };
const _hoisted_4 = { class: "user" };
const _hoisted_5 = { class: "label" };
const _hoisted_6 = { class: "dark-blue" };
const _hoisted_7 = { class: "changes-container" };
const _hoisted_8 = { class: "column scale-content-elements" };
const _hoisted_9 = { class: "changes-header dark-blue" };
const _hoisted_10 = { class: "data" };
const _hoisted_11 = { class: "column scale-content-elements" };
const _hoisted_12 = { class: "changes-header dark-blue" };
const _hoisted_13 = { class: "data" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_CollapsibleLabel = _resolveComponent("CollapsibleLabel");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.entry.date), 1),
        _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.entry.user), 1),
            _createElementVNode("p", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.translate("changed")) + " ", 1),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.subModuleNameLabel), 1)
            ]),
            _createVNode(_component_CollapsibleLabel, {
                label: _ctx.translate('see-changes'),
                expandedLabel: _ctx.translate('hide-changes')
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("p", _hoisted_9, [
                                _createVNode(_component_Icon, { name: "topic" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.translate("old-info-header")) + ": ", 1)
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.subModuleDisplayViewComponent.name), {
                                    model: _ctx.entry.old
                                }, null, 8, ["model"]))
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("p", _hoisted_12, [
                                _createVNode(_component_Icon, { name: "topic" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.translate("new-info-header")) + ": ", 1)
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.subModuleDisplayViewComponent.name), {
                                    model: _ctx.entry.new
                                }, null, 8, ["model"]))
                            ])
                        ])
                    ])
                ]),
                _: 1
            }, 8, ["label", "expandedLabel"])
        ])
    ]));
}
