import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppButton = _resolveComponent("AppButton");
    return (_openBlock(), _createBlock(_component_AppButton, {
        disabled: _ctx.disabled,
        loading: _ctx.isLoading,
        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.saveEntityAndEmit()))
    }, {
        default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
    }, 8, ["disabled", "loading"]));
}
