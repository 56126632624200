var _a, _b;
import { __decorate, __metadata, __param } from "tslib";
import _ from "lodash";
import { service } from "@/di/decorators/service.decorator";
import { ModuleService } from "@/app/modular-content/services/module.service";
import { clone } from "@/app/shared/functions/clone";
import { ChangeDetectionServiceToken, DomainIdToken, ModuleIdToken, SubModuleConfigurationServiceToken, } from "@/app/modular-content/token/modular-content.token";
import { ModuleConfigurationService } from "@/app/modular-content/services/module-configuration.service";
import { inject } from "@/di/decorators/inject.decorator";
import { getChanges } from "@/app/shared/functions/get-changes";
let GenericChangeDetectionService = class GenericChangeDetectionService {
    moduleService;
    subModuleConfigurationService;
    moduleId;
    domainId;
    constructor(moduleService, subModuleConfigurationService, moduleId, domainId) {
        this.moduleService = moduleService;
        this.subModuleConfigurationService = subModuleConfigurationService;
        this.moduleId = moduleId;
        this.domainId = domainId;
    }
    hasChanges() {
        return !_.isEmpty(this.getChanges());
    }
    getChanges() {
        const current = clone(this.moduleService.getData());
        if (!current || _.isEmpty(current)) {
            return {};
        }
        const subModuleValues = this.moduleService
            .getSubModuleServices()
            .filter((it) => this.subModuleConfigurationService.isEnabled(it.getMetadata().id))
            .map((it) => it.getValue());
        return getChanges(current, subModuleValues);
    }
    discardChanges() {
        this.moduleService.getSubModuleServices().forEach((it) => it.resetValue());
    }
    getDomainId() {
        return this.domainId;
    }
    getModuleId() {
        return this.moduleId;
    }
};
GenericChangeDetectionService = __decorate([
    service({ token: ChangeDetectionServiceToken }),
    __param(1, inject(SubModuleConfigurationServiceToken)),
    __param(2, inject(ModuleIdToken)),
    __param(3, inject(DomainIdToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof ModuleService !== "undefined" && ModuleService) === "function" ? _a : Object, typeof (_b = typeof ModuleConfigurationService !== "undefined" && ModuleConfigurationService) === "function" ? _b : Object, String, String])
], GenericChangeDetectionService);
export { GenericChangeDetectionService };
