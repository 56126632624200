import { toStringToken } from "@/di/types/token";
import { inject as vueInject, provide as vueProvide } from "vue";
import { createInstanceManager } from "@/di/instance-manager/instance-manager";
import { reactiveInstanceSetup } from "@/di/setups/reactive-instance-setup";
import { reactivePropertiesSetup } from "@/di/setups/reactive-properties-setup";
import { returnWithSetup } from "@/di/setups/return-with-setup";
import { resolve, resolveAll } from "@/di/resolve";
import _ from "lodash";
import { InjectionContainerToken } from "@/di/tokens";
const globalInstanceManager = createInstanceManager([reactivePropertiesSetup, reactiveInstanceSetup, returnWithSetup]);
export const vueProviderContainer = (instanceManager = globalInstanceManager) => {
    const withWarningsTurnedOff = (fn) => {
        const warn = console.warn;
        console.warn = () => {
            /**/
        };
        const result = fn();
        console.warn = warn;
        return result;
    };
    const getCurrentValues = (token) => {
        try {
            return withWarningsTurnedOff(() => inject(token) ?? []);
        }
        catch (e) {
            return [];
        }
    };
    const inject = (token) => {
        return vueInject(toStringToken(token));
    };
    const provide = (token, value) => {
        const currentValues = getCurrentValues(token);
        const newValues = _.isArray(value) ? value : [value];
        const values = [...currentValues, ...newValues];
        vueProvide(toStringToken(token), values);
    };
    return {
        inject,
        provide,
        ...instanceManager,
    };
};
export const createAppProviderContainer = (app, instanceManager = globalInstanceManager) => {
    const internalProviders = {};
    const provide = (token, value) => {
        app.provide(toStringToken(token), value);
        internalProviders[toStringToken(token)] = value;
    };
    const inject = (token) => {
        return internalProviders[toStringToken(token)];
    };
    return {
        provide,
        inject,
        ...instanceManager,
    };
};
export const createInjectionContainer = (providerContainer, context, from) => {
    const container = {
        ...providerContainer,
        resolve(token) {
            return resolve(token, providerContainer, context, from);
        },
        resolveAll(token) {
            return resolveAll(token, providerContainer, context);
        },
    };
    container.provide(InjectionContainerToken, [{ token: InjectionContainerToken, useValue: container }]);
    return container;
};
