import { createModule } from "@/di/module";
import EntityEditModalComponent from "@/app/modular-content/components/entity-edit-modal.component.vue";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { EditEntityModalIdToken } from "@/app/modular-content/token/modular-content.token";
export const EditModalModule = (options) => {
    const modalId = "EditEntity" + options.id;
    const component = options.components?.editModal ?? EntityEditModalComponent;
    return createModule({
        imports: [
            createModule({
                imports: [ModalModule(modalId, suffixComponentName(component, options.id))],
                providers: [{ token: EditEntityModalIdToken, useValue: modalId }],
            }),
        ],
    });
};
