import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0238fc8c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-vacuum-test-confirmation" };
const _hoisted_2 = {
    key: 0,
    class: "column-description mb-0"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-6 column-header" };
const _hoisted_6 = { class: "col-6 column-description" };
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "col-6 column-header" };
const _hoisted_9 = { class: "col-6 column-description" };
const _hoisted_10 = { class: "row" };
const _hoisted_11 = { class: "col-6 column-header" };
const _hoisted_12 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("vacuum-test-confirmation")), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.vacuumTestConfirmation, "required", "not-required")), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("vacuum-not-older-than")), 1),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getSingularOrPluralInfo(_ctx.model?.vacuumTestConfirmationNotOlderThan, "temporal.hours.label-single", "temporal.hours.label")), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate("vacuum-validity")), 1),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.getSingularOrPluralInfo(_ctx.model?.vacuumTestConfirmationValidity, "temporal.days.label-single", "temporal.days.label")), 1)
                ])
            ]))
    ]));
}
