import _ from "lodash";
const isEqualObject = (a, b) => {
    const keysA = _.keys(a).sort();
    const keysB = _.keys(b).sort();
    if (!isEqualArray(keysA, keysB)) {
        return false;
    }
    return !_.map(keysA, (key) => {
        return isEqualDeep(a[key], b[key]);
    }).some((it) => it === false);
};
const isEqualArray = (a, b) => {
    if (a.length !== b.length) {
        return false;
    }
    return !_.map(a, (value, index) => {
        return isEqualDeep(value, b[index]);
    }).some((it) => it === false);
};
const isEqualValue = (a, b) => {
    /**
     * WORKAROUND:
     * Sometimes empty string is saved and sometimes null is saved for "empty string" on entities.
     * To work around this behaviour, we treat null and empty string as equal during change detection.
     */
    if ((a === "" || a === null) && (b === null || b === "")) {
        return true;
    }
    return _.isEqual(a, b);
};
export const isEqualDeep = (a, b) => {
    if (_.isObject(a) && _.isObject(b)) {
        return isEqualObject(a, b);
    }
    if (_.isArray(a) && _.isArray(b)) {
        return isEqualArray(a, b);
    }
    return isEqualValue(a, b);
};
