export const createContext = () => {
    const modules = new Map();
    const components = new Map();
    const links = new Map();
    const register = (module) => {
        modules.set(module.getId(), module);
        module.components?.forEach((it) => {
            const name = it.name;
            if (!name) {
                return;
            }
            components.set(name, module.getId());
        });
        if (module.root) {
            linkDependencyTree(module);
        }
    };
    const linkDependencyTree = (current) => {
        const imports = current.imports ?? [];
        imports.forEach((it) => {
            links.set(it.getId(), current.getId());
            linkDependencyTree(it);
        });
    };
    const ancestors = (from, result = []) => {
        const parent = links.get(from);
        const ancestorsList = [...result, from];
        if (!parent) {
            return ancestorsList;
        }
        return ancestors(parent, ancestorsList);
    };
    const distance = (from, to) => {
        if (from === to) {
            return 0;
        }
        const fromAncestors = ancestors(from);
        const toAncestors = ancestors(to);
        const match = fromAncestors.find((it) => toAncestors.includes(it));
        if (!match) {
            return 999;
        }
        return toAncestors.indexOf(match) + fromAncestors.indexOf(match);
    };
    const getModuleForComponent = (component) => {
        const name = component.type.name ?? "";
        const moduleId = components.get(name);
        if (moduleId) {
            return moduleId;
        }
        if (!component.parent) {
            return undefined;
        }
        return getModuleForComponent(component.parent);
    };
    return {
        getModuleForComponent,
        register,
        distance,
    };
};
