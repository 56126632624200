import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6a7c7686"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "d-flex" };
const _hoisted_2 = { ref: "entity" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ExtendableColumnsIcon = _resolveComponent("ExtendableColumnsIcon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            ref: "expandable",
            class: _normalizeClass(["text-wrap expanded", { hidden: !_ctx.isExpanded }]),
            style: _normalizeStyle({ maxHeight: !_ctx.isExpanded ? _ctx.lineHeight : '' })
        }, [
            _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "entity", {}, undefined, true)
            ], 512)
        ], 6),
        (_ctx.isOverflowed)
            ? (_openBlock(), _createBlock(_component_ExtendableColumnsIcon, {
                key: 0,
                onClick: _withModifiers(_ctx.toggleExpansion, ["stop"]),
                isOpen: _ctx.isExpanded
            }, null, 8, ["onClick", "isOpen"]))
            : _createCommentVNode("", true)
    ]));
}
