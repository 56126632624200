import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["data-qa-value"];
const _hoisted_2 = ["data-qa-value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Dropdown = _resolveComponent("Dropdown");
    return (_openBlock(), _createBlock(_component_Dropdown, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        name: _ctx.name,
        options: _ctx.translatedOptions(),
        optionValue: _ctx.optionValue,
        optionLabel: _ctx.optionLabel,
        placeholder: _ctx.placeholder,
        filter: _ctx.isFilterAvailable,
        filterPlaceholder: _ctx.translate('filter-placeholder'),
        disabled: _ctx.disabled,
        optionDisabled: _ctx.optionDisabled,
        panelClass: _ctx.panelClass,
        loading: _ctx.loading,
        showClear: !_ctx.required,
        filterFields: _ctx.filterBy,
        emptyFilterMessage: _ctx.translate('phrases.no-results-found'),
        emptyMessage: _ctx.translate('phrases.no-results-found'),
        "dropdown-icon": "pi pi-chevron-down",
        "clear-icon": "pi pi-times",
        "filter-icon": "pi pi-search",
        "loading-icon": "pi pi-spinner",
        autoFilterFocus: _ctx.isFilterAvailable,
        optionGroupChildren: _ctx.optionGroupChildren,
        onFilter: _cache[1] || (_cache[1] = (val) => (_ctx.filterValue = val?.value)),
        onBeforeShow: _cache[2] || (_cache[2] = () => _ctx.$emit('skipFirstTouch', true)),
        onBeforeHide: _cache[3] || (_cache[3] = () => _ctx.$emit('skipFirstTouch', false))
    }, {
        option: _withCtx(({ option }) => [
            _createElementVNode("div", {
                "data-qa-id": "option",
                "data-qa-value": option.id
            }, _toDisplayString(_ctx.getOption(option)), 9, _hoisted_1)
        ]),
        value: _withCtx(({ value, placeholder }) => [
            _createElementVNode("div", {
                "data-qa-id": "value",
                "data-qa-value": value
            }, _toDisplayString(_ctx.getValue(value, placeholder) || _ctx.persistedValue), 9, _hoisted_2)
        ]),
        _: 1
    }, 8, ["modelValue", "name", "options", "optionValue", "optionLabel", "placeholder", "filter", "filterPlaceholder", "disabled", "optionDisabled", "panelClass", "loading", "showClear", "filterFields", "emptyFilterMessage", "emptyMessage", "autoFilterFocus", "optionGroupChildren"]));
}
