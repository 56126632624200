import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-02b6b15b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-connections" };
const _hoisted_2 = {
    key: 0,
    class: "column-description mb-0"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = {
    key: 1,
    class: "column-description"
};
const _hoisted_6 = { class: "row" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = { class: "col-6 column-description" };
const _hoisted_9 = { key: 0 };
const _hoisted_10 = { key: 1 };
const _hoisted_11 = { class: "row" };
const _hoisted_12 = { class: "col-6 column-header" };
const _hoisted_13 = { class: "col-6 column-description" };
const _hoisted_14 = { key: 0 };
const _hoisted_15 = { key: 1 };
const _hoisted_16 = { class: "row" };
const _hoisted_17 = { class: "col-6 column-header" };
const _hoisted_18 = { class: "col-6 column-description" };
const _hoisted_19 = { class: "row" };
const _hoisted_20 = { class: "col-6 column-header" };
const _hoisted_21 = { class: "col-6 column-description" };
const _hoisted_22 = { key: 0 };
const _hoisted_23 = { key: 1 };
const _hoisted_24 = { class: "row" };
const _hoisted_25 = { class: "col-6 column-header" };
const _hoisted_26 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_27 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_28 = { class: "row" };
const _hoisted_29 = { class: "col-6 column-header" };
const _hoisted_30 = { class: "col-6 column-description" };
const _hoisted_31 = { class: "row" };
const _hoisted_32 = { class: "col-6 column-header" };
const _hoisted_33 = { class: "col-6 column-description" };
const _hoisted_34 = { class: "row" };
const _hoisted_35 = { class: "col-6 column-header" };
const _hoisted_36 = { class: "col-6 column-description" };
const _hoisted_37 = { key: 0 };
const _hoisted_38 = { key: 1 };
const _hoisted_39 = { class: "row" };
const _hoisted_40 = { class: "col-6 column-header" };
const _hoisted_41 = { class: "col-6 column-description" };
const _hoisted_42 = { class: "row" };
const _hoisted_43 = { class: "col-6 column-header" };
const _hoisted_44 = { class: "col-6 column-description" };
const _hoisted_45 = { class: "row" };
const _hoisted_46 = { class: "col-6 column-header" };
const _hoisted_47 = { class: "col-6 column-description" };
const _hoisted_48 = { class: "row" };
const _hoisted_49 = { class: "col-6 column-header" };
const _hoisted_50 = { class: "col-6 column-description" };
const _hoisted_51 = { key: 0 };
const _hoisted_52 = { key: 1 };
const _hoisted_53 = { class: "row" };
const _hoisted_54 = { class: "col-6 column-header" };
const _hoisted_55 = { class: "col-6 column-description" };
const _hoisted_56 = { class: "row" };
const _hoisted_57 = { class: "col-6 column-header" };
const _hoisted_58 = { class: "col-6 column-description" };
const _hoisted_59 = { key: 0 };
const _hoisted_60 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TruckWidget = _resolveComponent("TruckWidget");
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.model.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["col-6 column-header", { 'col-12': _ctx.hasTruckConnections }])
                    }, _toDisplayString(_ctx.translate("truck-connections-title")), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(["col-6", { 'col-12': _ctx.hasTruckConnections }])
                    }, [
                        (_ctx.model.truckConnections.length)
                            ? (_openBlock(), _createBlock(_component_TruckWidget, {
                                key: 0,
                                "truck-connections": _ctx.model.truckConnections,
                                "display-view": true
                            }, null, 8, ["truck-connections"]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_5, "-"))
                    ], 2)
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("clearly-labelled-connections-title")), 1),
                    _createElementVNode("div", _hoisted_8, [
                        (_ctx.model.clearlyLabelledConnections !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.model.clearlyLabelledConnections ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_10, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate("point-locked-connection-title")), 1),
                    _createElementVNode("div", _hoisted_13, [
                        (_ctx.model.pointLockedConnection !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.model.pointLockedConnection ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_15, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translate("connection-sealing-is-done-by-whom-title")), 1),
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getValue(_ctx.model.connectionSealingIsDoneByWhom, "ConnectionSealingIsDoneByWhom")), 1)
                ]),
                _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.translate("coupling-title")), 1),
                    _createElementVNode("div", _hoisted_21, [
                        (_ctx.model.coupling !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.model.coupling ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_23, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.translate("coupling-type-label")), 1),
                    (_ctx.model?.couplingTypes?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.couplingTypes, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "CouplingType")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_27, "-"))
                ]),
                _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.translate("coupling-size-label")), 1),
                    _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.getValue(_ctx.model.couplingSize, "CouplingSize")), 1)
                ]),
                _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.translate("configuration")), 1),
                    _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.getValue(_ctx.model.configuration, "ConnectionConfiguration")), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.couplingAdditionalInformation
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("phrases.additional-information")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.translate("flange-title")), 1),
                    _createElementVNode("div", _hoisted_36, [
                        (_ctx.model.flange !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_ctx.model.flange ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_38, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.translate("flange-type-label")), 1),
                    _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.getValue(_ctx.model.flangeType, "FlangeType")), 1)
                ]),
                _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.translate("flange-size-label")), 1),
                    _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.getValue(_ctx.model.flangeSize, "FlangeSize")), 1)
                ]),
                _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.translate("minimum-stud-bolt-length-label")), 1),
                    _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.model.minimumStudBoltLength || "-"), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.flangeAdditionalInformation
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("phrases.additional-information")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_48, [
                    _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.translate("heating-system-connection-title")), 1),
                    _createElementVNode("div", _hoisted_50, [
                        (_ctx.model.heating !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_51, _toDisplayString(_ctx.model.heating ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_52, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_53, [
                    _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.translate("heating-system-connection-type-label")), 1),
                    _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.getValue(_ctx.model.heatingType, "ConnectionHeatingType")), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.heatingAdditionalInformation
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("phrases.additional-information")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_56, [
                    _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.translate("adaptors-title")), 1),
                    _createElementVNode("div", _hoisted_58, [
                        (_ctx.model.adaptors !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_59, _toDisplayString(_ctx.model.adaptors ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_60, "-"))
                    ])
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.adaptorsAdditionalInformation
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("phrases.additional-information")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"])
            ]))
    ]));
}
