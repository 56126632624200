var _a, _b;
import { __decorate, __metadata, __param } from "tslib";
import _ from "lodash";
import { service } from "@/di/decorators/service.decorator";
import { BackendErrorResolverToken } from "@/app/validation/tokens/backend-error-resolver.token";
import { TranslationService } from "@/app/translation/services/translation.service";
import { DomainService } from "@/app/modular-content/services/domain.service";
import { paramCase } from "change-case";
import { injectAll } from "@/di/decorators/inject-all.decorator";
import { RouterService } from "@/app/shared/services/router.service";
import { RouteMetaDomainId } from "@/app/modular-content/modular-content.constants";
let ConflictErrorResolver = class ConflictErrorResolver {
    translationService;
    routerService;
    domainServices;
    path = "conflict";
    errorCode = "V1010";
    constructor(translationService, routerService, domainServices) {
        this.translationService = translationService;
        this.routerService = routerService;
        this.domainServices = domainServices;
    }
    resolve(error) {
        if (error.message !== this.errorCode || !error.field) {
            return error;
        }
        return {
            ...error,
            message: `validation.messages.${this.path}`,
            args: {
                field: this.translateField(_.kebabCase(error.field)),
            },
        };
    }
    canResolve(error) {
        return error?.message === this.errorCode;
    }
    translateField(field) {
        const translationPath = `global.form-fields.${field}.label`;
        let translatedField = this.translationService.translate(translationPath, this.getParams());
        if (translatedField === translationPath) {
            translatedField = field;
        }
        return translatedField;
    }
    getCurrentDomainId() {
        const routerDomainId = this.routerService.getMeta(RouteMetaDomainId);
        return this.domainServices.find((it) => it.getDomainId() === routerDomainId)?.getDomainId();
    }
    getParams() {
        const domainId = this.getCurrentDomainId();
        return domainId
            ? {
                domain: `domains.${paramCase(domainId)}`,
            }
            : undefined;
    }
};
ConflictErrorResolver = __decorate([
    service({ token: BackendErrorResolverToken }),
    __param(2, injectAll(DomainService)),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object, typeof (_b = typeof RouterService !== "undefined" && RouterService) === "function" ? _b : Object, Array])
], ConflictErrorResolver);
export { ConflictErrorResolver };
