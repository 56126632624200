export function convertTo24HourFormat(input) {
    const timeRegex24 = /^([01]\d|2[0-3]):([0-5]\d)$/; // 24-hour format
    const timeRegex12 = /^(\d{1,2}):([0-5]\d)\s*(AM|PM)$/i; // 12-hour format with AM/PM
    // Check for 24-hour time format (HH:mm)
    const is24HourFormat = timeRegex24.test(input);
    if (is24HourFormat) {
        return input;
    }
    // Check for 12-hour time format (h:mm AM/PM)
    const match = input.match(timeRegex12);
    if (match) {
        const [_, hour, minute, period] = match; // eslint-disable-line @typescript-eslint/no-unused-vars
        let hourNumber = parseInt(hour, 10);
        if (period.toUpperCase() === "PM" && hourNumber < 12) {
            hourNumber += 12; // Convert PM to 24-hour format
        }
        else if (period.toUpperCase() === "AM" && hourNumber === 12) {
            hourNumber = 0; // Handle 12:xx AM as 00:xx
        }
        // Ensure two-digit hour format in the case of 00:xx
        return `${String(hourNumber).padStart(2, "0")}:${minute}`;
    }
    // If none of the formats match, return null (invalid input)
    return null;
}
