import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2eb8df3a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "connect-button" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_OverlayPanel = _resolveComponent("OverlayPanel");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppButton, {
            class: "p-button-lg p-button-secondary",
            onClick: _ctx.toggleDropdown
        }, {
            default: _withCtx(() => [
                (!_ctx.isGenerating)
                    ? (_openBlock(), _createBlock(_component_Icon, {
                        key: 0,
                        name: "electrical_services"
                    }))
                    : _createCommentVNode("", true),
                (_ctx.isGenerating)
                    ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                        key: 1,
                        center: ""
                    }))
                    : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.translate("title")) + " ", 1),
                _createVNode(_component_Icon, {
                    name: "arrow_drop_down",
                    variant: "rounded"
                })
            ]),
            _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_OverlayPanel, {
            ref: "overlayPanel",
            onClick: _ctx.toggleDropdown
        }, {
            default: _withCtx(() => [
                _createVNode(_component_MenuItem, {
                    class: "gap-2",
                    icon: "electrical_services",
                    label: _ctx.translate('create'),
                    onClick: _ctx.generateMethod
                }, null, 8, ["label", "onClick"]),
                _createVNode(_component_MenuItem, {
                    class: "gap-2",
                    icon: "electrical_services",
                    label: _ctx.connectOrUpdateEntityTranslation,
                    onClick: _ctx.openConnectEntityModal
                }, null, 8, ["label", "onClick"])
            ]),
            _: 1
        }, 8, ["onClick"])
    ]));
}
