import { FilterMatchMode } from "primevue/api";
import { onMounted, ref } from "vue";
export const useTableFilters = (dataTableFilter = {}) => {
    const f = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ...dataTableFilter,
    };
    const filters = ref(f);
    const defaultFilters = ref(f);
    onMounted(() => {
        filters.value.global.value = null;
    });
    return {
        filters,
        defaultFilters,
    };
};
