import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { LeaveEditGuardService } from "@/app/modular-content/services/leave-edit-guard.service";
import { ManageCompanyNavigationItemToken } from "@/app/domains/public-data/companies/company-overview/tokens/manage-company-navigation-item.token";
import DisplayCompanyAccountComponent from "@/app/domains/public-data/companies/company-overview/company-account/components/display-company-account.component.vue";
import EditCompanyAccountComponent from "@/app/domains/public-data/companies/company-overview/company-account/components/edit-company-account.component.vue";
import { CompanyChangeDetectionService } from "@/app/domains/public-data/companies/company-overview/company-account/services/company-change-detection.service";
import { CompanyDeleteModalId, LogoUploadModalId } from "@/app/domains/public-data/companies/companies.constants";
import { ModalModule } from "@/app/modals/modules/modal.module";
import LogoUploadModalComponent from "@/app/domains/public-data/companies/company-overview/company-account/components/logo-upload/logo-upload-modal.component.vue";
import CompanyDeteleModalComponent from "@/app/domains/public-data/companies/company-overview/company-account/components/company-delete/company-delete-modal.component.vue";
import { LogoUploadService } from "@/app/domains/public-data/companies/company-overview/company-account/services/logo-upload.service";
const navigationItem = {
    label: "domains.companies.manage-company.manage-account.menu-item",
    route: "account",
    icon: "",
};
export const CompanyAccountModule = createModule({
    components: [
        DisplayCompanyAccountComponent,
        EditCompanyAccountComponent,
        LogoUploadModalComponent,
        CompanyDeteleModalComponent,
    ],
    imports: [
        ModalModule(LogoUploadModalId, LogoUploadModalComponent),
        ModalModule(CompanyDeleteModalId, CompanyDeteleModalComponent),
    ],
    providers: [
        { token: ManageCompanyNavigationItemToken, useValue: navigationItem },
        { token: TranslationPrefixToken, useValue: "domains.companies.manage-company.manage-account" },
        CompanyChangeDetectionService,
        LeaveEditGuardService,
        LogoUploadService,
    ],
});
