export const subModuleTokensFactory = (domainId, moduleId = "Generic", submoduleId = "Generic") => {
    return {
        service: `${domainId}${moduleId}${submoduleId}SubModuleService`,
        dataProvider: `${domainId}${moduleId}${submoduleId}SubModuleDataProvider`,
        displayComponent: `${domainId}${moduleId}${submoduleId}SubModuleDisplayComponent`,
        displayViewComponent: `${domainId}${moduleId}${submoduleId}SubModuleDisplayViewComponent`,
        editComponent: `${domainId}${moduleId}${submoduleId}SubModuleEditComponent`,
        editGuards: `${domainId}${moduleId}${submoduleId}SubModuleEditGuards`,
        meta: `${domainId}${moduleId}${submoduleId}SubModuleMeta`,
    };
};
