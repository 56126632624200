import { ProductDomainId } from "@/app/domains/products/products.constants";
import { SiteDomainId } from "@/app/domains/sites/sites.constants";
import { UnloadingPointDomainId } from "@/app/domains/unloading-points/unloading-points.constants";
import { LoadingPointDomainId } from "@/app/domains/loading-points/loading-points.constants";
export const SummaryDomainId = "Summary";
export const LoadingSummaryProductsDomainId = "LoadingProductsSummary";
export const UnloadingSummaryProductsDomainId = "UnloadingProductsSummary";
export const VerificationRequestsSummaryDomainId = "VerificationRequestsSummary";
export const ArchivedLoadingSummaryDomainId = "ArchivedLoadingSummary";
export const ArchivedUnloadingSummaryDomainId = "ArchivedUnloadingSummary";
export const SummaryConfirmationSuccessModalId = "SummaryConfirmationSuccessModal";
export const SummaryFeedbackFormModalId = "SummaryFeedbackFormModal";
export const FeedbackConfirmationSuccessModalId = "FeedbackConfirmationSuccessModal";
export const SummaryLoadingProductsRoute = "loading-products-summary-page";
export const SummaryUnloadingProductsRoute = "unloading-products-summary-page";
export const SummaryVerificationRouteName = "verification-requests-summary-page";
export const SummaryDomainBaseUrlEnum = {
    [VerificationRequestsSummaryDomainId]: "requirementprofiles/verificationView/:verificationRequestsId",
    [LoadingSummaryProductsDomainId]: "loadingpoints/:loadingPointId/products/:loadingProductsId/summary",
    [UnloadingSummaryProductsDomainId]: "unloadingpoints/:unloadingPointId/products/:unloadingProductsId/summary",
    [ArchivedLoadingSummaryDomainId]: "lanes/:lanesId/loadingPoints/archivedSummary",
    [ArchivedUnloadingSummaryDomainId]: "lanes/:lanesId/unloadingPoints/archivedSummary",
};
export const SummaryDomainTranslationPrefix = {
    [VerificationRequestsSummaryDomainId]: "verification-summary",
    [LoadingSummaryProductsDomainId]: "loading",
    [UnloadingSummaryProductsDomainId]: "unloading",
    [ArchivedLoadingSummaryDomainId]: "archived-loading",
    [ArchivedUnloadingSummaryDomainId]: "archived-unloading",
};
const Domain = {
    [VerificationRequestsSummaryDomainId]: UnloadingPointDomainId,
    [LoadingSummaryProductsDomainId]: LoadingPointDomainId,
    [UnloadingSummaryProductsDomainId]: UnloadingPointDomainId,
    [ArchivedLoadingSummaryDomainId]: LoadingPointDomainId,
    [ArchivedUnloadingSummaryDomainId]: UnloadingPointDomainId,
};
export const getMenuItems = (domainId) => [
    {
        entityId: "site",
        domainId: SiteDomainId,
        route: "summary-site",
        flagName: "siteChecked",
    },
    {
        entityId: "unloadingPoint",
        domainId: Domain[domainId],
        route: "summary-ulp",
        flagName: "unloadingPointChecked",
    },
    {
        entityId: "product",
        domainId: ProductDomainId,
        route: "summary-product",
        flagName: "productChecked",
    },
];
