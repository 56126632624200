export const DomainIdToken = "DomainId";
export const ModuleIdToken = "ModuleId";
export const SubModuleIdToken = "SubModuleId";
export const EntityTableFilterServiceToken = "EntityTableFilterService";
export const EntityListsMetaToken = "EntityListMeta";
export const ParentDomainIdToken = "ParentDomainId";
export const ParentDomainServiceToken = "ParentDomainService";
export const DomainMetadataToken = "DomainMetadata";
export const ModuleMetadataToken = "ModuleMetadata";
export const SubModuleMetadataToken = "SubModuleMetadata";
export const CopyEntityModalIdToken = "CopyEntityModalId";
export const CopyModuleModalIdToken = "CopyModuleModalId";
export const CreateEntityModalIdToken = "CreateEntityModalId";
export const EditEntityModalIdToken = "EditEntityModalId";
export const EntityTableComponentToken = "EntityTableComponent";
export const EntityPageComponentToken = "EntityPageComponent";
export const EntityCopyModalComponentToken = "EntityCopyModalComponent";
export const EntityEditFormComponentToken = "EntityEditFormComponent";
export const ModuleCopyModalComponentToken = "ModuleCopyModalComponent";
export const ModuleServicesToken = "ModuleServices";
export const SubModuleServicesToken = "SubModuleServices";
export const AllSubModulesMetaDataToken = "AllSubModulesMetadata";
export const SubModuleConfigurationServicesToken = "SubModuleConfigurationServices";
export const SubModuleConfigurationModalIdToken = "SubModuleConfigurationModalId";
export const SubModuleConfigurationServiceToken = "SubModuleConfigurationService";
export const ModuleConfigurationServiceToken = "ModuleConfigurationServiceToken";
export const UrlFactoryToken = "UrlFactory";
export const ChangeDetectionServiceToken = "ChangeDetectionService";
export const ConnectEntityModalIdToken = "ConnectEntityModalId";
