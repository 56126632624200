import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-56efea96"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "assigned-lanes-warning" };
const _hoisted_2 = { class: "wrapper" };
const _hoisted_3 = { class: "header" };
const _hoisted_4 = { class: "description" };
const _hoisted_5 = { class: "items" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Icon, {
            class: "icon-warning",
            name: "error"
        }),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("add-to-tender.warning.header")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("add-to-tender.warning.description")), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.assignedItems), 1)
        ])
    ]));
}
