import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors,
            class: "col-7"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { required: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("form-fields.reference-number.label")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_InputText, {
                            name: "referenceNumber",
                            "min-length": "3",
                            "max-length": "30",
                            required: "",
                            placeholder: _ctx.translate('form-fields.reference-number.placeholder')
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_FormError, { name: "referenceNumber" })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
