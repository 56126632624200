import _ from "lodash";
import { createModule } from "@/di/module";
import { moduleTokensFactory } from "@/app/modular-content/factories/module-tokens.factory";
import { subModuleTokensFactory } from "@/app/modular-content/factories/sub-module-tokens.factory";
import { ModuleService } from "@/app/modular-content/services/module.service";
import { SubModuleService } from "@/app/modular-content/services/sub-module.service";
import { PartialDataProviderService } from "@/app/shared/services/partial-data-provider.service";
import { DomainIdToken, ModuleIdToken, SubModuleIdToken, SubModuleMetadataToken, } from "@/app/modular-content/token/modular-content.token";
export const SubModuleModule = (options) => {
    const moduleSpecific = moduleTokensFactory(options.domainId, options.moduleId);
    const subModuleSpecific = subModuleTokensFactory(options.domainId, options.moduleId, options.id);
    const byModule = subModuleTokensFactory(options.domainId, options.moduleId);
    const byEntity = subModuleTokensFactory(options.domainId);
    const meta = {
        id: options.id,
        moduleId: options.moduleId,
        domainId: options.domainId,
        icon: options.icon,
        iconVariant: options.iconVariant,
        displayTitle: options.displayTitle ?? "",
        editTitle: options.editTitle ?? "",
        hideDisplayTitle: options.hideDisplayTitle ?? false,
        publicInformation: options.publicInformation ?? false,
        configurationTranslationPrefix: options.configurationTranslationPrefix ?? "",
        showChangeHistory: options.showChangeHistory ?? false,
    };
    return createModule({
        providers: [
            { token: ModuleService, useToken: moduleSpecific.service },
            { token: subModuleSpecific.dataProvider, useClass: PartialDataProviderService },
            { token: subModuleSpecific.editComponent, useValue: options.components.edit },
            { token: subModuleSpecific.meta, useValue: meta },
            { token: subModuleSpecific.displayComponent, useValue: options.components.display },
            { token: subModuleSpecific.displayViewComponent, useValue: options.components.displayView },
            { token: subModuleSpecific.service, useClass: SubModuleService },
            { token: subModuleSpecific.editGuards, useValue: options.editGuards },
            ..._.map(byModule, (token, key) => {
                return { token, useToken: _.get(subModuleSpecific, key) };
            }),
            ..._.map(byEntity, (token, key) => {
                return { token, useToken: _.get(subModuleSpecific, key) };
            }),
            { token: DomainIdToken, useValue: options.domainId },
            { token: ModuleIdToken, useValue: options.moduleId },
            { token: SubModuleIdToken, useValue: options.id },
            { token: SubModuleMetadataToken, useToken: subModuleSpecific.meta },
        ],
    });
};
