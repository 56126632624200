import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AutoComplete = _resolveComponent("AutoComplete");
    const _component_FormError = _resolveComponent("FormError");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        class: "create-assigned-product-master-form",
        initial: _ctx.initial
    }, {
        default: _withCtx(() => [
            (_ctx.activeCompany)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { disabled: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.company.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputText, {
                                value: _ctx.activeCompany.name,
                                disabled: ""
                            }, null, 8, ["value"])
                        ]),
                        _: 1
                    }),
                    (_ctx.activeCompany.managedByExternalCompany)
                        ? (_openBlock(), _createBlock(_component_FormField, { key: 0 }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { disabled: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.business-partner-of.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_InputText, {
                                    value: _ctx.activeCompany.companyGroupName || _ctx.activeCompany.managedByName,
                                    disabled: ""
                                }, null, 8, ["value"])
                            ]),
                            _: 1
                        }))
                        : _createCommentVNode("", true)
                ]))
                : _createCommentVNode("", true),
            (_ctx.unloadingLoadingPoint)
                ? (_openBlock(), _createBlock(_component_FormField, { key: 1 }, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { disabled: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.name.${_ctx.parentDomainTranslationPrefix("label")}`)), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_InputText, {
                            name: "unloadingLoadingPointId",
                            type: "hidden",
                            required: ""
                        }),
                        _createVNode(_component_InputText, {
                            value: _ctx.unloadingLoadingPoint.name,
                            disabled: ""
                        }, null, 8, ["value"])
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true),
            _createVNode(_component_FormField, null, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, { required: "" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix("label", true)}`)), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_AutoComplete, {
                        name: "name",
                        suggestions: _ctx.assignedProductMasters,
                        suggestionsGroupTitle: _ctx.translate('already-existing-assigned-product-masters'),
                        placeholder: _ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix('placeholder', true)}`),
                        minLength: 3,
                        maxLength: 300,
                        required: ""
                    }, null, 8, ["suggestions", "suggestionsGroupTitle", "placeholder"]),
                    _createVNode(_component_FormError, { name: "name" })
                ]),
                _: 1
            }),
            _createVNode(_component_FormField, null, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, { required: "" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("form-fields.assigned-product-master-type.label")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_Dropdown, {
                        name: "productType",
                        options: _ctx.productTypes,
                        optionValue: "id",
                        optionLabel: "name",
                        dictionaryId: "ProductType",
                        placeholder: _ctx.translate('form-fields.assigned-product-master-type.placeholder'),
                        loading: _ctx.productTypesAreLoading,
                        required: ""
                    }, null, 8, ["options", "placeholder", "loading"]),
                    _createVNode(_component_FormError, { name: "productType" })
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["initial"]));
}
