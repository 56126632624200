import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TechnicalRequirementsModuleId } from "@/app/modules/technical-requirements/technical-requirements.module";
import DisplayConnections from "@/app/sub-modules/connections/components/display-connections.vue";
import DisplayConnectionsView from "@/app/sub-modules/connections/components/display-connections-view.vue";
import EditConnections from "@/app/sub-modules/connections/components/edit-connections.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
export const ConnectionsSubModule = (domainId) => {
    const id = "connections";
    const moduleId = TechnicalRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplayConnections, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayConnectionsView, ...componentSuffixes);
    const editComponent = suffixComponentName(EditConnections, ...componentSuffixes);
    return createModule({
        components: [displayComponent, displayViewComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "cable",
                showChangeHistory: true,
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.connections",
            },
        ],
    });
};
