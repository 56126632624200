import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c8268098"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "links" };
const _hoisted_2 = { class: "resend-link" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormField = _resolveComponent("FormField");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_Button = _resolveComponent("Button");
    const _component_LinkButton = _resolveComponent("LinkButton");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        showCloseIcon: false,
        header: _ctx.translate('users.components.update-user-modal.header'),
        id: "verify-user-modal"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_AppForm, {
                modelValue: _ctx.verifyUserModel,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.verifyUserModel) = $event)),
                errors: _ctx.errors,
                onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event))
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { disabled: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.email-address.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputText, {
                                disabled: "",
                                placeholder: _ctx.user.email
                            }, null, 8, ["placeholder"])
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.verification-code.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputText, {
                                name: "verificationCode",
                                placeholder: _ctx.translate('form-fields.verification-code.placeholder'),
                                required: "",
                                "max-length": "255"
                            }, null, 8, ["placeholder"]),
                            _createVNode(_component_FormError, { name: "verificationCode" })
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue", "errors"]),
            _createVNode(_component_Button, {
                onClick: _ctx.verifyUser,
                loading: _ctx.isResending,
                disabled: !_ctx.isValid
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.verify-user")), 1)
                ]),
                _: 1
            }, 8, ["onClick", "loading", "disabled"]),
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.translate("users.components.verify-user-modal.cant-find-verification-code")), 1),
                    _createVNode(_component_LinkButton, { onClick: _ctx.resendLinkMethod }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("users.components.verify-user-modal.resend-link")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"])
                ]),
                _createVNode(_component_LinkButton, {
                    class: "change-account-link",
                    onClick: _ctx.logout
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("users.components.verify-user-modal.change-account")), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"])
            ])
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
