import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
let NullEntityTableFilterService = class NullEntityTableFilterService {
    createEqualsFilter() {
        return [{}];
    }
};
NullEntityTableFilterService = __decorate([
    service()
], NullEntityTableFilterService);
export { NullEntityTableFilterService };
