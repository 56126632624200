import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b7fa1fe2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-postal-address" };
const _hoisted_2 = { class: "col-3" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAddress = _resolveComponent("DisplayAddress");
    const _component_DisplayLocation = _resolveComponent("DisplayLocation");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_DisplayAddress, { address: _ctx.model }, null, 8, ["address"])
        ]),
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_DisplayLocation, {
                class: "p-button-map",
                location: _ctx.model.location
            }, null, 8, ["location"])
        ])
    ], 64));
}
