import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_DropdownMenuOverlay = _resolveComponent("DropdownMenuOverlay");
    return (_openBlock(), _createBlock(_component_DropdownMenuOverlay, {
        class: "roles-dropdown",
        overlayClass: "roles-overlay"
    }, {
        button: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getSelectedValue()) + " ", 1),
            _createVNode(_component_Icon, {
                class: "chevron",
                name: "chevron_left"
            })
        ]),
        items: _withCtx(({ hideOverlay }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role) => {
                return (_openBlock(), _createBlock(_component_MenuItem, {
                    key: role.id,
                    onClick: ($event) => (_ctx.select(role.id, { hideOverlay })),
                    icon: role.id === _ctx.selected ? 'check' : 'none',
                    label: _ctx.dictionaryTranslate('Roles', role)
                }, null, 8, ["onClick", "icon", "label"]));
            }), 128))
        ]),
        _: 1
    }));
}
