import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { NotEqual } from "@/app/modular-content/token/filter-operator.constants";
let PublicSitesFilterService = class PublicSitesFilterService {
    createNotEqualsFilter() {
        return [
            {
                propertyName: "SiteType",
                propertyValue: "TenantChemicalPark",
                operatorValue: NotEqual,
            },
            {
                propertyName: "SiteType",
                propertyValue: "TenantExternalWarehouse",
                operatorValue: NotEqual,
            },
            {
                propertyName: "SiteType",
                propertyValue: "TenantExternalTankfarm",
                operatorValue: NotEqual,
            },
        ];
    }
};
PublicSitesFilterService = __decorate([
    service()
], PublicSitesFilterService);
export { PublicSitesFilterService };
