import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a889bffa"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "card-container" };
const _hoisted_2 = {
    key: 2,
    class: "collapsed-card card-1"
};
const _hoisted_3 = {
    key: 3,
    class: "collapsed-card card-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobalNotification = _resolveComponent("GlobalNotification");
    const _component_VerificationNotification = _resolveComponent("VerificationNotification");
    const _component_Icon = _resolveComponent("Icon");
    return (_ctx.notificationList.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["notifications-group", { collapsed: _ctx.collapsed }])
        }, [
            _createElementVNode("div", _hoisted_1, [
                (_ctx.type === _ctx.NotificationGroup.Global)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.stackNotificationList, (notification, idx) => {
                        return (_openBlock(), _createBlock(_component_GlobalNotification, {
                            key: idx,
                            notification: notification
                        }, null, 8, ["notification"]));
                    }), 128))
                    : (_ctx.type === _ctx.NotificationGroup.Verification)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.stackNotificationList, (notification, idx) => {
                            return (_openBlock(), _createBlock(_component_VerificationNotification, {
                                key: idx,
                                notification: notification
                            }, null, 8, ["notification"]));
                        }), 128))
                        : _createCommentVNode("", true),
                (_ctx.collapsed && _ctx.notificationList.length > 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                    : _createCommentVNode("", true),
                (_ctx.collapsed && _ctx.notificationList.length > 2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                    : _createCommentVNode("", true)
            ]),
            (_ctx.notificationList.length > 1)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "toggle-button",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.collapsed = !_ctx.collapsed))
                }, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.toggleButtonLabel), 1),
                    _createVNode(_component_Icon, {
                        class: "toggle-button-icon",
                        name: "chevron_right"
                    })
                ]))
                : _createCommentVNode("", true)
        ], 2))
        : _createCommentVNode("", true);
}
