import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6cc6170e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-physical-check-in" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 bold-text" };
const _hoisted_6 = { class: "row location-row" };
const _hoisted_7 = { class: "col" };
const _hoisted_8 = { class: "row info-row" };
const _hoisted_9 = { class: "col-6 column-header" };
const _hoisted_10 = { class: "col-6 bold-text" };
const _hoisted_11 = { class: "row info-row" };
const _hoisted_12 = { class: "col-6 column-header" };
const _hoisted_13 = { class: "col-6" };
const _hoisted_14 = { key: 0 };
const _hoisted_15 = {
    key: 1,
    class: "bold-text"
};
const _hoisted_16 = { class: "row info-row" };
const _hoisted_17 = { class: "col-6 column-header" };
const _hoisted_18 = { class: "col-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAddress = _resolveComponent("DisplayAddress");
    const _component_DisplayLocation = _resolveComponent("DisplayLocation");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    const _component_OpeningHoursTable = _resolveComponent("OpeningHoursTable");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.translate("physical-check-in-title")), 1),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("first-reception")), 1),
                _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_DisplayAddress, {
                        address: _ctx.data.postalAddress,
                        short: ""
                    }, null, 8, ["address"])
                ])
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_DisplayLocation, {
                        location: _ctx.data.location
                    }, null, 8, ["location"])
                ])
            ]),
            _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("language-title")), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.languages()), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate("driver-identity")), 1),
                _createElementVNode("div", _hoisted_13, [
                    (_ctx.identities() && _ctx.identities().length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.identities(), (identity) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: identity,
                                    class: "chip"
                                }, _toDisplayString(identity), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_15, "-"))
                ])
            ]),
            _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translate("opening-hours")), 1),
                _createElementVNode("div", _hoisted_18, [
                    (_ctx.noOpeningHoursDefined)
                        ? (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 0 }, {
                            default: _withCtx(() => [
                                _createTextVNode(" - ")
                            ]),
                            _: 1
                        }))
                        : (_openBlock(), _createBlock(_component_OpeningHoursTable, {
                            key: 1,
                            "opening-hours": _ctx.data?.openingHours
                        }, null, 8, ["opening-hours"]))
                ])
            ])
        ])
    ]));
}
