import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-54f09b74"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-site-navigation" };
const _hoisted_2 = { class: "section-name" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate("title")), 1),
        _createVNode(_component_DisplayAttachments, {
            attachments: _ctx.model,
            title: _ctx.translate('navigation-apps')
        }, null, 8, ["attachments", "title"])
    ]));
}
