var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let DiacriticalValidator = class DiacriticalValidator {
    translationService;
    name = "diacritical";
    constructor(translationService) {
        this.translationService = translationService;
    }
    message() {
        return this.translationService.translate("validation.messages.diacritical");
    }
    validate(value) {
        value = value.replace(/\s+/g, "");
        const normalized = value.normalize("NFKD").replace(/[^\w]/g, "");
        return normalized === value;
    }
};
DiacriticalValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], DiacriticalValidator);
export { DiacriticalValidator };
