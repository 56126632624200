import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AutoComplete = _resolveComponent("AutoComplete");
    const _component_FormError = _resolveComponent("FormError");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            initial: _ctx.initial,
            class: "create-product-form"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { disabled: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("form-fields.company.label")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_InputText, {
                            disabled: "",
                            placeholder: _ctx.company?.name
                        }, null, 8, ["placeholder"])
                    ]),
                    _: 1
                }),
                (_ctx.company?.managedByExternalCompany)
                    ? (_openBlock(), _createBlock(_component_FormField, { key: 0 }, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { disabled: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.business-partner-of.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputText, {
                                disabled: "",
                                placeholder: _ctx.company?.companyGroupName ?? _ctx.company?.managedByName
                            }, null, 8, ["placeholder"])
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { required: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix("label", true)}`)), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_AutoComplete, {
                            name: "name",
                            placeholder: _ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix('placeholder', true)}`),
                            required: "",
                            "min-length": "3",
                            "max-length": "300",
                            suggestions: _ctx.companyProducts,
                            suggestionsGroupTitle: _ctx.groupTitle
                        }, null, 8, ["placeholder", "suggestions", "suggestionsGroupTitle"]),
                        _createVNode(_component_FormError, { name: "name" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { required: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.typeOfProductTranslation.label), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_Dropdown, {
                            name: "productType",
                            options: _ctx.productTypeOptions,
                            optionValue: "id",
                            optionLabel: "name",
                            dictionaryId: "ProductType",
                            placeholder: _ctx.typeOfProductTranslation.placeholder,
                            required: ""
                        }, null, 8, ["options", "placeholder"]),
                        _createVNode(_component_FormError, { name: "productType" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, {
                            required: !_ctx.isClustersList
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("form-fields.reference-number.label")), 1)
                            ]),
                            _: 1
                        }, 8, ["required"]),
                        _createVNode(_component_InputText, {
                            placeholder: _ctx.translate('form-fields.reference-number.placeholder'),
                            name: "referenceNumber",
                            id: "reference-number-input-field",
                            "min-length": "3",
                            "max-length": "30",
                            required: !_ctx.isClustersList
                        }, null, 8, ["placeholder", "required"]),
                        _createVNode(_component_FormError, { name: "referenceNumber" })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["initial"]));
}
