import { createModule } from "@/di/module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import EditGeneralSafetyRulesComponent from "@/app/sub-modules/general-safety-rules/components/edit-general-safety-rules.component.vue";
import DisplayGeneralSafetyRulesComponent from "@/app/sub-modules/general-safety-rules/components/display-general-safety-rules.component.vue";
import { SafetyAndSecurityModuleId } from "@/app/modules/safety-and-security/safety-and-security.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
export const GeneralSafetyRulesSubModule = (domainId) => {
    const id = "generalSafetyRules";
    const moduleId = SafetyAndSecurityModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditGeneralSafetyRulesComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayGeneralSafetyRulesComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "health_and_safety",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.general-safety-rules" }],
    });
};
