import { PreloadingRestrictionsDomainId } from "@/app/domains/preloading-restrictions/preloading-restrictions.constants";
import { CompanyProductClustersListId, CompanyProductsInboundListId, CompanyProductsListId, CompanyProductsOutboundListId, ProductDomainId, ProductDomainServiceToken, QuickCreateLaneModalId, } from "@/app/domains/products/products.constants";
import ProductsTableComponent from "@/app/domains/products/components/products-table.component.vue";
import ProductContextMenuHeaderComponent from "@/app/domains/products/components/product-context-menu-header.component.vue";
import CreateProductFormComponent from "@/app/domains/products/components/create-product-form.component.vue";
import { SelectedCompanyFilterService } from "@/app/domains/public-data/companies/services/selected-company-filter.service";
import { PreloadingRestrictionsModule } from "@/app/domains/preloading-restrictions/preloading-restrictions.module";
import CopyProductModalComponent from "@/app/domains/products/components/copy-product-modal.component.vue";
import { CopyProductService } from "@/app/domains/products/services/copy-product.service";
import QuickCreateLaneModalComponent from "@/app/domains/products/components/quick-create-lane-modal.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { RouteMetaListId } from "@/app/modular-content/modular-content.constants";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { GeneralInformationModule } from "@/app/modules/general-information/general-information.module";
import { CleaningRequirementsModule } from "@/app/modules/cleaning-requirements/cleaning-requirements.module";
import { ProductsSafetyModule } from "@/app/modules/products-safety/products-safety.module";
import { DgClassificationSubModule } from "@/app/sub-modules/dg-classification/dg-classification.module";
import { ProductParametersSubModule } from "@/app/sub-modules/product-parameters/product-parameters.module";
import { GeneralCleaningInfoSubModule } from "@/app/sub-modules/general-cleaning-info/general-cleaning-info.module";
import { EuropeanCleaningDocumentSubModule } from "@/app/sub-modules/european-cleaning-document/european-cleaning-document.module";
import { PreloadConfirmationSubModule } from "@/app/sub-modules/preload-confirmation/preload-confirmation.module";
import { VacuumTestConfirmationSubModule } from "@/app/sub-modules/vacuum-test-confirmation/vacuum-test-confirmation.module";
import { RequiredEquipmentStateSubModule } from "@/app/sub-modules/required-equipment-state/required-equipment-state.module";
import { CleaningOperatorsSubModule } from "@/app/sub-modules/cleaning-operators/cleaning-operators.module";
import { ProductBasicInformationSubModule } from "@/app/sub-modules/basic-information/product/product-basic-information.module";
import { ProductGroupAssignmentSubModule } from "@/app/sub-modules/product-group-assignment/product-group-assignment.module";
import { ProductAdditionalInformationSubModule } from "@/app/sub-modules/additional-information/product/product-additional-information.module";
import { PRLRestrictionsSettingsSubModule } from "@/app/sub-modules/prl-restrictions-settings/prl-restrictions-settings.module";
import { SafetyDataSheetSubModule } from "@/app/sub-modules/safety-data-sheet/safety-data-sheet.module";
import { SpecialProductRequirementsSubModule } from "@/app/sub-modules/special-product-requirements/special-product-requirements.module";
import { ProductClustersModule } from "@/app/domains/product-clusters/product-clusters.module";
import { CompanyRole } from "@/app/shared/types/access.type";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
const ListIdToBaseUrlMapping = {
    [CompanyProductsListId]: "products",
    [CompanyProductsOutboundListId]: "loadingpoints/products",
    [CompanyProductsInboundListId]: "unloadingpoints/products",
    [CompanyProductClustersListId]: "productclusters",
};
export const ProductsModule = createModule({
    components: [
        ProductContextMenuHeaderComponent,
        ProductsTableComponent,
        CreateProductFormComponent,
        CopyProductModalComponent,
        QuickCreateLaneModalComponent,
    ],
    imports: [
        DomainModule({
            id: ProductDomainId,
            icon: "science",
            detailsIcon: "schema",
            baseUrl: (routerService) => {
                const listId = routerService?.getMeta(RouteMetaListId) ?? "";
                return ListIdToBaseUrlMapping[listId] ?? ListIdToBaseUrlMapping[CompanyProductsListId];
            },
            createRoles: [CompanyRole.ProductManager],
            lists: [
                {
                    id: CompanyProductsListId,
                    default: true,
                    filter: SelectedCompanyFilterService,
                    copyAvailable: true,
                },
                {
                    id: CompanyProductsOutboundListId,
                    filter: SelectedCompanyFilterService,
                    createRoles: [],
                },
                {
                    id: CompanyProductsInboundListId,
                    filter: SelectedCompanyFilterService,
                    createRoles: [],
                },
                {
                    id: CompanyProductClustersListId,
                    filter: SelectedCompanyFilterService,
                    hideForBusinessPartners: true,
                },
            ],
            domainTranslationPrefix: (routerService) => {
                const listId = routerService?.getMeta(RouteMetaListId) ?? "";
                return listId === CompanyProductClustersListId ? "clusters" : "products";
            },
            forBusinessPartner: true,
            copyTooltip: "domains.products.copy-tooltip",
            components: {
                contextMenuHeader: ProductContextMenuHeaderComponent,
                table: ProductsTableComponent,
                createForm: CreateProductFormComponent,
                copyModal: CopyProductModalComponent,
            },
        }),
        GeneralInformationModule(ProductDomainId),
        ProductBasicInformationSubModule(ProductDomainId),
        ProductParametersSubModule(ProductDomainId),
        ProductGroupAssignmentSubModule(ProductDomainId),
        ProductAdditionalInformationSubModule(ProductDomainId),
        //
        ProductsSafetyModule(ProductDomainId),
        PRLRestrictionsSettingsSubModule(ProductDomainId),
        DgClassificationSubModule(ProductDomainId),
        SpecialProductRequirementsSubModule(ProductDomainId),
        SafetyDataSheetSubModule(ProductDomainId),
        //
        CleaningRequirementsModule(ProductDomainId, { copyAvailable: true }),
        GeneralCleaningInfoSubModule(ProductDomainId),
        EuropeanCleaningDocumentSubModule(ProductDomainId),
        PreloadConfirmationSubModule(ProductDomainId),
        VacuumTestConfirmationSubModule(ProductDomainId),
        RequiredEquipmentStateSubModule(ProductDomainId),
        CleaningOperatorsSubModule(ProductDomainId),
        //
        createModule({ imports: [ProductClustersModule] }),
        createModule({
            imports: [
                PreloadingRestrictionsModule(ProductDomainId, PreloadingRestrictionsDomainId, {
                    readOnly: ((entity) => !!entity?.masterForPrlRestrictionsId),
                    originDomain: PreloadingRestrictionsDomainId,
                    originId: ((entity) => entity?.masterForPrlRestrictionsId),
                    originIdGoToBtnLabel: "go-to-preloading-restrictions-master-product",
                }),
            ],
        }),
        ModalModule(QuickCreateLaneModalId, QuickCreateLaneModalComponent),
    ],
    providers: [
        CopyProductService,
        { token: ProductDomainServiceToken, useToken: domainTokensFactory(ProductDomainId).service },
        { token: TranslationPrefixToken, useValue: "domains.products" },
    ],
});
