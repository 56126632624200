import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-76545911"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "policy-tag" };
const _hoisted_2 = {
    key: 0,
    class: "policy-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Icon, {
            name: "new_releases",
            variant: "outlined",
            class: "policy-icon"
        }),
        (_ctx.name)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.name), 1))
            : _createCommentVNode("", true)
    ]));
}
