import { ConnectEntityModalIdToken } from "@/app/modular-content/token/modular-content.token";
export const domainTokensFactory = (domainId = "Generic", prefix = "") => {
    const tokenPrefix = `${prefix}${domainId}`;
    return {
        meta: `${tokenPrefix}DomainMeta`,
        pageComponent: `${tokenPrefix}DomainPageComponent`,
        tableComponent: domainTableComponentTokenFactory(domainId, prefix),
        createFormComponent: domainCreateFormComponentTokenFactory(domainId, prefix),
        editFormComponent: `${tokenPrefix}DomainEditFormComponent`,
        copyFormComponent: `${tokenPrefix}DomainCopyFormComponent`,
        restApiClient: `${tokenPrefix}DomainRestApiClient`,
        service: `${tokenPrefix}DomainService`,
        configurationService: `${tokenPrefix}DomainConfigurationService`,
        listsMeta: `${tokenPrefix}DomainListsMeta`,
        contextMenu: `${tokenPrefix}DomainContextMenu`,
        search: `${tokenPrefix}SearchService`,
        route: `${tokenPrefix}RouteService`,
        connect: `${tokenPrefix}ConnectService`,
        connectModal: `${tokenPrefix}${ConnectEntityModalIdToken}`,
    };
};
export const domainTableComponentTokenFactory = (domainId, prefix) => {
    return `${prefix}${domainId}DomainTableComponent`;
};
export const domainCreateFormComponentTokenFactory = (domainId, prefix) => {
    return `${prefix}${domainId}DomainCreateFormComponent`;
};
