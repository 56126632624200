import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormField = _resolveComponent("FormField");
    const _component_FormError = _resolveComponent("FormError");
    const _component_DropdownOptionsReload = _resolveComponent("DropdownOptionsReload");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_ctx.isLoading && !_ctx.loadOnMountCalled)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            initial: _ctx.initial,
            class: "create-unloading-point-form"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { disabled: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("form-fields.site-name.label")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_InputText, {
                            placeholder: _ctx.site?.name,
                            disabled: ""
                        }, null, 8, ["placeholder"])
                    ]),
                    _: 1
                }),
                (_ctx.company?.managedByExternalCompany)
                    ? (_openBlock(), _createBlock(_component_FormField, { key: 0 }, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { disabled: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.business-partner-of.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputText, {
                                placeholder: _ctx.company?.companyGroupName ?? _ctx.company?.managedByName,
                                disabled: ""
                            }, null, 8, ["placeholder"])
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { required: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix("label")}`)), 1)
                            ]),
                            _: 1
                        }),
                        _withDirectives(_createVNode(_component_InputText, {
                            name: "name",
                            placeholder: _ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix('placeholder')}`),
                            required: ""
                        }, null, 8, ["placeholder"]), [
                            [_directive_default_validation]
                        ]),
                        _createVNode(_component_FormError, { name: "name" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { required: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.reference-number.${_ctx.domainTranslationPrefix("label")}`)), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_InputText, {
                            name: "referenceNumber",
                            placeholder: _ctx.translate(`form-fields.reference-number.${_ctx.domainTranslationPrefix('placeholder')}`),
                            "min-length": "1",
                            "max-length": "30",
                            required: ""
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_FormError, { name: "referenceNumber" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("form-fields.building.label")), 1)
                            ]),
                            _: 1
                        }),
                        (_ctx.isError)
                            ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                key: 0,
                                onClick: _ctx.loadBuildings
                            }, null, 8, ["onClick"]))
                            : (_openBlock(), _createBlock(_component_Dropdown, {
                                key: 1,
                                loading: _ctx.isLoading,
                                name: "buildingId",
                                options: _ctx.buildings,
                                optionValue: "id",
                                optionLabel: "name",
                                additionalLabel: "referenceNumber",
                                filterFields: ['referenceNumber'],
                                placeholder: _ctx.translate('form-fields.building.placeholder'),
                                id: "building-dropdown"
                            }, null, 8, ["loading", "options", "placeholder"])),
                        _createVNode(_component_FormError, { name: "buildingId" })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["initial"]));
}
