import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { InstructionsModuleId } from "@/app/modules/instructions/instructions.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditGeneralInstructionsComponent from "@/app/sub-modules/general-instructions/components/edit-general-instructions.component.vue";
import DisplayGeneralInstructionsComponent from "@/app/sub-modules/general-instructions/components/display-general-instructions.component.vue";
import DisplayGeneralInstructionsViewComponent from "@/app/sub-modules/general-instructions/components/display-general-instructions-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const GeneralInstructionsSubModule = (domainId) => {
    const id = "generalInstructions";
    const moduleId = InstructionsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditGeneralInstructionsComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayGeneralInstructionsComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayGeneralInstructionsViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "tips_and_updates",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.general-instructions",
            },
        ],
    });
};
