import _ from "lodash";
import { TokenMetadata } from "@/di/metadata/injection-metadata";
export const isClassToken = (it) => {
    return !_.isString(it);
};
export const isStringToken = (it) => {
    return _.isString(it);
};
export const toStringToken = (it) => {
    if (isStringToken(it)) {
        return it;
    }
    if (isClassToken(it)) {
        return TokenMetadata.get(it);
    }
    return it;
};
