import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8d71a504"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "products-table-cell-product-name" };
const _hoisted_2 = { class: "content-wrapper label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ExpandableTableCell = _resolveComponent("ExpandableTableCell");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ExpandableTableCell, {
                entityName: _ctx.product.name,
                entityId: _ctx.product.id,
                iconName: _ctx.icon
            }, null, 8, ["entityName", "entityId", "iconName"])
        ]),
        _renderSlot(_ctx.$slots, "badge", {}, undefined, true)
    ]));
}
