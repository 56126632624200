import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-58ec0901"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "p-2 d-flex justify-content-between" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_Icon = _resolveComponent("Icon");
    const _component_Calendar = _resolveComponent("Calendar");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        class: "time-range-modal",
        "modal-id": _ctx.modalId,
        showCloseIcon: false,
        "show-header": false
    }, {
        default: _withCtx(() => [
            _createVNode(_component_Calendar, {
                inputId: "range",
                modelValue: _ctx.dateRange,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.dateRange) = $event)),
                selectionMode: "range",
                dateFormat: "dd.mm.yy",
                manualInput: false,
                placeholder: _ctx.translate('add-time-range'),
                required: "",
                inline: "",
                onDateSelect: _ctx.save
            }, {
                footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_AppButton, {
                            class: "p-button p-button-secondary",
                            onClick: _ctx.close
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                            ]),
                            _: 1
                        }, 8, ["onClick"]),
                        _createElementVNode("div", null, [
                            (_ctx.isValidDateRange)
                                ? (_openBlock(), _createBlock(_component_AppButton, {
                                    key: 0,
                                    class: "p-button p-button-secondary enabled me-4",
                                    disabled: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_Icon, { name: "date_range" }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.getDateRangeLabelFormat(_ctx.dateRange)), 1)
                                    ]),
                                    _: 1
                                }))
                                : _createCommentVNode("", true),
                            _createVNode(_component_AppButton, {
                                class: "p-button p-button-secondary",
                                onClick: _ctx.clear
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_Icon, { name: "restart_alt" }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.translate("reset-calendar")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"])
                        ])
                    ])
                ]),
                _: 1
            }, 8, ["modelValue", "placeholder", "onDateSelect"])
        ]),
        _: 1
    }, 8, ["modal-id"]));
}
