var _a, _b, _c;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { inject } from "@/di/decorators/inject.decorator";
import { RouterService } from "@/app/shared/services/router.service";
import { DomainMetadataToken } from "@/app/modular-content/token/modular-content.token";
import { DomainMetadata } from "@/app/modular-content/type/domain-metadata.type";
let ConnectService = class ConnectService {
    domainMetadata;
    httpService;
    routerService;
    baseUrlCallback;
    constructor(domainMetadata, httpService, routerService) {
        this.domainMetadata = domainMetadata;
        this.httpService = httpService;
        this.routerService = routerService;
        this.baseUrlCallback = domainMetadata.baseUrlCallback;
    }
    async generateConnectingId(id) {
        return await this.httpService.post(`${this.getBaseUrl()}/${id}/connectingId`, {});
    }
    async connectEntity(id, connectingId) {
        return await this.httpService.post(`${this.getBaseUrl()}/${id}/connect`, { connectingId });
    }
    async disconnectEntity(id) {
        return await this.httpService.post(`${this.getBaseUrl()}/${id}/disconnect`, {});
    }
    async updateConnection(id) {
        return await this.httpService.post(`${this.getBaseUrl()}/${id}/updateConnection`, {});
    }
    getBaseUrl() {
        return this.baseUrlCallback(this.routerService);
    }
};
ConnectService = __decorate([
    service(),
    __param(0, inject(DomainMetadataToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof DomainMetadata !== "undefined" && DomainMetadata) === "function" ? _a : Object, typeof (_b = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _b : Object, typeof (_c = typeof RouterService !== "undefined" && RouterService) === "function" ? _c : Object])
], ConnectService);
export { ConnectService };
