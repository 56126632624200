import { createModule } from "@/di/module";
import { contextMenuTokenFactory } from "@/app/shared/factories/context-menu-token.factory";
import { contextMenuItemTokenFactory } from "@/app/shared/factories/context-menu-item-token.factory";
import { ContextMenuOptionsToken } from "@/app/shared/tokens/context-menu-options.token";
export const ContextMenuModule = (options) => {
    const menuItemToken = contextMenuItemTokenFactory(options.id);
    const module = createModule({
        providers: [
            { token: contextMenuTokenFactory(options.id), useValue: options },
            { token: ContextMenuOptionsToken, useToken: contextMenuTokenFactory(options.id) },
        ],
    });
    const provideMenuItem = (menuItem) => {
        return {
            token: menuItemToken,
            useValue: menuItem,
        };
    };
    return {
        ...module,
        provideMenuItem,
    };
};
