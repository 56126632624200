import { createModule } from "@/di/module";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { CreateUserModalId, UpdateUserModalId } from "@/app/users/users.constants";
import CreateUserModalComponent from "@/app/users/components/create-user-modal.component.vue";
import UpdateUserModalComponent from "@/app/users/components/update-user-modal.component.vue";
import { UserService } from "@/app/users/services/user.service";
import { AppInitServiceToken } from "@/app/shared/tokens/app-init-service.token";
import { TokenUserService } from "@/app/users/services/token-user.service";
export const UsersModule = createModule({
    providers: [TokenUserService, UserService, { token: AppInitServiceToken, useToken: UserService }],
    imports: [
        ModalModule(CreateUserModalId, CreateUserModalComponent),
        ModalModule(UpdateUserModalId, UpdateUserModalComponent),
    ],
});
