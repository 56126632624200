import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
let CompanyFilterService = class CompanyFilterService {
    createEqualsFilter(value) {
        return [
            {
                propertyName: "CompanyId",
                propertyValue: value ?? "-",
            },
        ];
    }
};
CompanyFilterService = __decorate([
    service()
], CompanyFilterService);
export { CompanyFilterService };
