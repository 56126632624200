var _a, _b, _c, _d;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { InjectionScope } from "@/di/types/scope";
import { inject } from "@/di/decorators/inject.decorator";
import { DomainIdToken, EntityTableFilterServiceToken } from "@/app/modular-content/token/modular-content.token";
import { SearchService } from "@/app/modular-content/services/search.service";
import { UserStorageService } from "@/app/shared/services/user-storage.service";
import { EntityTableFilterService } from "@/app/modular-content/type/entity-table-filter-service.type";
import { RouterService } from "@/app/shared/services/router.service";
import _ from "lodash";
import { MatchModeType } from "@/app/modular-content/type/table-filter.type";
let EntityTableSearchDelegateService = class EntityTableSearchDelegateService {
    filterService;
    domainId;
    userStorageService;
    searchService;
    routerService;
    constructor(filterService, domainId, userStorageService, searchService, routerService) {
        this.filterService = filterService;
        this.domainId = domainId;
        this.userStorageService = userStorageService;
        this.searchService = searchService;
        this.routerService = routerService;
    }
    getListId() {
        return this.routerService.getCurrentRoute().meta.listId;
    }
    setColumns(columns) {
        this.userStorageService.set(`${this.domainId}.${this.getListId()}.columns`, columns);
    }
    getColumns() {
        return this.userStorageService.get(`${this.domainId}.${this.getListId()}.columns`);
    }
    getRows() {
        return this.searchService.getData();
    }
    loadPage(dto, headers) {
        dto.searchText = dto.searchText ? `${dto.searchText.trim()}` : "";
        dto.includeTotalResultCount = true;
        dto.skip = dto.skip ?? 0;
        const filters = dto.tableFilters
            ? _.concat(this.getComponentFilters(dto.tableFilters), this.getFilters())
            : this.getFilters();
        delete dto.tableFilters;
        return this.searchService.loadPage(dto, filters ?? this.getFilters(), headers);
    }
    getFilters() {
        const createEqualsFilter = this.filterService.createEqualsFilter?.() ?? [];
        const createAnyEqualsFilter = this.filterService.createAnyEqualsFilter?.() ?? [];
        const createNotEqualFilters = this.filterService.createNotEqualsFilter?.() ?? [];
        const createConditionalEqualFilter = this.filterService.createConditionalEqualFilter?.() ?? [];
        return _.compact(_.concat([...createEqualsFilter], [...createAnyEqualsFilter], [...createNotEqualFilters], [...createConditionalEqualFilter]));
    }
    getComponentFilters(componentFilters) {
        const equalFilters = [];
        const anyEqualFilters = [];
        if (componentFilters.length) {
            componentFilters.forEach((element) => {
                switch (element.matchMode) {
                    case MatchModeType.Equals:
                        equalFilters.push(this.createEqualFilter(element.propertyName, element.value, element.multipleValue));
                        break;
                    case MatchModeType.Contains:
                        anyEqualFilters.push(this.createAnyEqualFilter(element.propertyGroupName, element.value, element.propertyName, element.multipleValue));
                        break;
                    default:
                }
            });
        }
        return _.compact(_.flatten(_.concat([...equalFilters], [...anyEqualFilters])));
    }
    createEqualFilter(propertyName, value, multipleValue) {
        const equalFilters = [];
        if (value) {
            value.forEach((propertyValue) => {
                equalFilters.push({
                    propertyName,
                    propertyValue,
                    multipleValue,
                });
            });
        }
        return equalFilters;
    }
    createAnyEqualFilter(propertyGroupName, propertyValues, propertyName, multipleValue) {
        const anyEqualFilter = [];
        if (propertyValues?.length) {
            anyEqualFilter.push({
                propertyGroupName,
                propertyValues,
                propertyName,
                multipleValue,
            });
        }
        return anyEqualFilter;
    }
    resetData() {
        this.searchService.resetData();
    }
    setData(newData) {
        this.searchService.setData(newData);
    }
    addItem(item) {
        this.searchService.addItem(item);
    }
    deleteItem(item) {
        this.searchService.deleteItem(item);
    }
    editItem(item) {
        this.searchService.editItem(item);
    }
    editItems(items) {
        items.forEach((item) => this.searchService.editItem(item));
    }
};
EntityTableSearchDelegateService = __decorate([
    service({ scope: InjectionScope.Token }),
    __param(0, inject(EntityTableFilterServiceToken)),
    __param(1, inject(DomainIdToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof EntityTableFilterService !== "undefined" && EntityTableFilterService) === "function" ? _a : Object, String, typeof (_b = typeof UserStorageService !== "undefined" && UserStorageService) === "function" ? _b : Object, typeof (_c = typeof SearchService !== "undefined" && SearchService) === "function" ? _c : Object, typeof (_d = typeof RouterService !== "undefined" && RouterService) === "function" ? _d : Object])
], EntityTableSearchDelegateService);
export { EntityTableSearchDelegateService };
