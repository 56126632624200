import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fced12f8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "user-initials is-invited"
};
const _hoisted_2 = {
    key: 1,
    class: "user-initials"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.isInvited)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : (_ctx.initials?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.initials), 1))
            : _createCommentVNode("", true);
}
