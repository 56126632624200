var _a, _b, _c, _d, _e;
import { __decorate, __metadata } from "tslib";
import _ from "lodash";
import { service } from "@/di/decorators/service.decorator";
import { FormFieldState } from "@/app/form/types/form-field-state.enum";
import { reactive } from "@/di/decorators/computed.decorator";
import { InjectionScope } from "@/di/types/scope";
import { Subject } from "rxjs";
import { ValidationService } from "@/app/validation/services/validation.service";
let FormService = class FormService {
    validationService;
    initialValue = undefined;
    data = {};
    fieldErrors = {};
    globalErrors = [];
    fieldValidators = {};
    fieldStates = {};
    change = new Subject();
    constructor(validationService) {
        this.validationService = validationService;
    }
    getFieldState(field) {
        return this.fieldStates[field] ?? FormFieldState.Pristine;
    }
    setFieldState(field, state) {
        const currentState = this.getFieldState(field);
        if (state === FormFieldState.Pristine) {
            return;
        }
        if (currentState === FormFieldState.Dirty) {
            return;
        }
        if (state === FormFieldState.Dirty) {
            this.fieldStates[field] = state;
            return;
        }
        this.fieldStates[field] = state;
    }
    resetFieldState(field) {
        this.fieldStates[field] = FormFieldState.Pristine;
    }
    getFieldValue(field) {
        return _.get(this.data, field);
    }
    setFieldValue(field, value) {
        _.set(this.data, field, value);
        this.emitUpdateEvent();
        if (_.isEqual(_.get(this.initialValue, field), value)) {
            return;
        }
        this.setFieldState(field, FormFieldState.Dirty);
    }
    setFieldErrors(field, value) {
        this.fieldErrors[field] = value;
        this.globalErrors.splice(0, this.globalErrors.length);
        this.emitUpdateEvent();
    }
    getFieldErrors(field) {
        const byField = _.flatMap(_.pickBy(this.fieldErrors, (value, key) => key.startsWith(field)), (errors) => errors) ?? [];
        return [...byField, ...this.globalErrors].filter((it) => it.field === field);
    }
    addFieldError(field, error) {
        this.fieldErrors[field] = this.fieldErrors[field] ?? [];
        this.fieldErrors[field].push(error);
        this.setFieldState(field, FormFieldState.Dirty);
    }
    setGlobalErrors(errors) {
        this.globalErrors.splice(0, this.globalErrors.length);
        errors.forEach((it) => this.globalErrors.push(it));
    }
    addGlobalError(error) {
        this.globalErrors.push(error);
    }
    getValue() {
        return this.data;
    }
    reset() {
        if (!this.initialValue) {
            return;
        }
        _.keys(this.initialValue).forEach((key) => {
            this.data[key] = _.cloneDeep(_.get(this.initialValue, key));
        });
        _.keys(this.fieldStates).forEach((key) => {
            this.fieldStates[key] = FormFieldState.Pristine;
        });
        this.clearErrors();
        this.emitUpdateEvent();
        this.validate();
    }
    hasChanges() {
        return !_.isEqual(this.data, this.initialValue);
    }
    emitUpdateEvent() {
        this.change.next(this.data);
    }
    setInitialValue(value) {
        this.initialValue = value;
        this.reset();
    }
    onChange() {
        return this.change;
    }
    clearErrors = () => {
        this.globalErrors.splice(0, this.globalErrors.length);
        _.keys(this.fieldErrors).forEach((key) => {
            this.fieldErrors[key] = [];
        });
    };
    mapErrors(errorsToMap) {
        for (const [toAdd, values] of Object.entries(errorsToMap)) {
            values.forEach((value) => {
                const path = Object.keys(this.fieldErrors).find((path) => path.endsWith(value)) ?? "";
                const mapField = path.includes(toAdd) ? path.split(toAdd)[0] + toAdd : toAdd;
                if (path in this.fieldErrors) {
                    const fieldError = this.fieldErrors[path];
                    fieldError[0].field = mapField;
                    this.fieldErrors[mapField] = fieldError;
                    this.setFieldState(mapField, FormFieldState.Dirty);
                    delete this.fieldErrors[path];
                }
            });
        }
        this.emitUpdateEvent();
    }
    isValid = (skipChanges) => {
        if (!skipChanges && !this.hasChanges()) {
            return false;
        }
        let count = 0;
        _.forEach(this.fieldErrors, (errors) => {
            count += errors?.length ?? 0;
        });
        count += this.globalErrors.length;
        return count === 0;
    };
    setFieldValidators(field, validators) {
        this.fieldValidators[field] = validators;
    }
    createValidationSchema() {
        const schema = {};
        _.keys(this.fieldValidators).forEach((key) => {
            _.set(schema, key, toSchemaValidators(this.fieldValidators[key]));
        });
        return schema;
    }
    createValidationMessage(result) {
        const fieldValidator = (this.fieldValidators[result.path] ?? []).find((it) => {
            return it.validator?.name === result.validator;
        });
        if (!fieldValidator) {
            return "Invalid input";
        }
        return fieldValidator.validator.message(fieldValidator.arg);
    }
    applyValidationResults(validationResults) {
        validationResults
            .filter((it) => !it.valid)
            .forEach((it) => {
            this.fieldErrors[it.path] = [
                ...(this.fieldErrors[it.path] ?? []),
                {
                    field: it.path,
                    message: this.createValidationMessage(it),
                    cause: it.validator,
                },
            ];
        });
    }
    validate() {
        const results = this.validationService.validate(this.data, this.createValidationSchema());
        this.clearErrors();
        this.applyValidationResults(results);
    }
    validateField(field) {
        const results = this.validationService
            .validate(this.data, this.createValidationSchema())
            .filter((it) => it.path === field);
        this.fieldErrors[field] = [];
        this.applyValidationResults(results);
    }
};
__decorate([
    reactive(),
    __metadata("design:type", Object)
], FormService.prototype, "initialValue", void 0);
__decorate([
    reactive(),
    __metadata("design:type", typeof (_b = typeof Record !== "undefined" && Record) === "function" ? _b : Object)
], FormService.prototype, "data", void 0);
__decorate([
    reactive(),
    __metadata("design:type", typeof (_c = typeof Record !== "undefined" && Record) === "function" ? _c : Object)
], FormService.prototype, "fieldErrors", void 0);
__decorate([
    reactive(),
    __metadata("design:type", Array)
], FormService.prototype, "globalErrors", void 0);
__decorate([
    reactive(),
    __metadata("design:type", typeof (_d = typeof Record !== "undefined" && Record) === "function" ? _d : Object)
], FormService.prototype, "fieldValidators", void 0);
__decorate([
    reactive(),
    __metadata("design:type", typeof (_e = typeof Record !== "undefined" && Record) === "function" ? _e : Object)
], FormService.prototype, "fieldStates", void 0);
FormService = __decorate([
    service({ scope: InjectionScope.Token }),
    __metadata("design:paramtypes", [typeof (_a = typeof ValidationService !== "undefined" && ValidationService) === "function" ? _a : Object])
], FormService);
export { FormService };
const toSchemaValidators = (validators) => {
    return _.mapValues(_.keyBy(validators, (it) => it.validator.name), (it) => it.arg);
};
