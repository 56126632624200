import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = { class: "loading-pressure col-7" };
const _hoisted_3 = { class: "col-5" };
const _hoisted_4 = { class: "pressure-label-to" };
const _hoisted_5 = { class: "col-5" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors,
            class: "edit-loading-related-parameters"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, { class: "col-7" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createElementVNode("span", {
                                    innerHTML: _ctx.translateLabelWithUnit('form-fields.loading-rate.label', 'form-fields.loading-rate.unit')
                                }, null, 8, _hoisted_1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_InputNumber, {
                            name: "loadingRate",
                            placeholder: _ctx.translate('form-fields.loading-rate.placeholder'),
                            id: "loading-rate",
                            mode: "decimal",
                            min: 0,
                            maxIntegerDigits: "10",
                            maxFractionDigits: 4
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_FormError, { name: "loadingRate" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-7" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translateLabelWithUnit("form-fields.minimum-loading-time.label", "form-fields.minimum-loading-time.unit")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_InputNumber, {
                            name: "minLoadingTime",
                            placeholder: _ctx.translate('form-fields.minimum-loading-time.placeholder'),
                            id: "minimum-loading-time",
                            mode: "decimal",
                            min: 0,
                            maxIntegerDigits: "2",
                            maxFractionDigits: 2
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_FormError, { name: "minLoadingTime" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translateLabelWithUnit("form-fields.loading-pressure.label", "form-fields.loading-pressure.unit")), 1)
                            ]),
                            _: 1
                        }),
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_InputNumber, {
                                    name: "minLoadingPressure",
                                    placeholder: _ctx.translate('form-fields.loading-pressure.placeholder.min'),
                                    id: "minimum-loading-pressure",
                                    mode: "decimal",
                                    maxFractionDigits: 2
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "minLoadingPressure" })
                            ]),
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate("form-fields.loading-pressure.to")), 1),
                            _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_InputNumber, {
                                    name: "maxLoadingPressure",
                                    placeholder: _ctx.translate('form-fields.loading-pressure.placeholder.max'),
                                    id: "maximum-loading-pressure",
                                    mode: "decimal",
                                    maxFractionDigits: 2
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "maxLoadingPressure" })
                            ])
                        ])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
