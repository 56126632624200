import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "display-hose" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-value" };
const _hoisted_5 = { class: "row" };
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6 column-value" };
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col-6 column-header" };
const _hoisted_10 = { class: "col-6 column-value" };
const _hoisted_11 = { class: "row" };
const _hoisted_12 = { class: "col-6 column-header" };
const _hoisted_13 = { class: "col-6 column-value" };
const _hoisted_14 = { class: "row" };
const _hoisted_15 = { class: "col-6 column-header" };
const _hoisted_16 = { class: "col-6 column-value" };
const _hoisted_17 = { class: "row" };
const _hoisted_18 = { class: "col-6 column-header" };
const _hoisted_19 = { class: "col-6 column-value" };
const _hoisted_20 = { class: "row" };
const _hoisted_21 = { class: "col-6 column-header" };
const _hoisted_22 = { class: "col-6 column-value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("form-fields.provided")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getHoseToBeProvidedByCarrierValue(_ctx.model?.hoseToBeProvidedByCarrier)), 1)
        ]),
        (_ctx.model?.hoseToBeProvidedByCarrier)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("form-fields.testing-frequency")), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getDictionaryTranslationValue(_ctx.model?.testingFrequency, "HoseTestingFrequency")), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("form-fields.length")), 1),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getDictionaryTranslationValue(_ctx.model?.length, "HoseLength")), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate("form-fields.diameter.label")), 1),
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.getSingularOrPluralInfo(_ctx.model?.diameter, "form-fields.inch", "form-fields.inches")), 1)
                ]),
                _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.translate("form-fields.characteristics.label")), 1),
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.getCharacteristics()), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.additionalInformation
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.additional-requirements")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.translate(`form-fields.${_ctx.domainTranslationPrefix("lifting")}`)), 1),
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.hoseLifting), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.emptyingTheHoseEnsuredByFreeText,
                    freeText: ""
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.emptying")), 1)
                    ]),
                    description: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getDictionaryTranslationValue(_ctx.model?.emptyingTheHoseEnsuredBy, "EmptyingTheHoseEnsuredBy")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.translate(`form-fields.${_ctx.domainTranslationPrefix("sieving")}`)), 1),
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.sieving), 1)
                ])
            ], 64))
            : _createCommentVNode("", true)
    ]));
}
