import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
let ProductTypeFilterService = class ProductTypeFilterService {
    createEqualsFilter(value) {
        return [
            {
                propertyName: "ProductType",
                propertyValue: value ?? "-",
            },
        ];
    }
};
ProductTypeFilterService = __decorate([
    service()
], ProductTypeFilterService);
export { ProductTypeFilterService };
