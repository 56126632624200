import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8df95c5e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-opening-hours" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    const _component_OpeningHoursTable = _resolveComponent("OpeningHoursTable");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noOpeningHoursDefined)
            ? (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 0 }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.emptyOpeningHours), 1)
                ]),
                _: 1
            }))
            : (_openBlock(), _createBlock(_component_OpeningHoursTable, {
                key: 1,
                "opening-hours": _ctx.model
            }, null, 8, ["opening-hours"]))
    ]));
}
