import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_Button = _resolveComponent("Button");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        showCloseIcon: true,
        header: _ctx.translate('users.components.create-user-modal.header'),
        id: "create-user-modal"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_AppForm, {
                modelValue: _ctx.user,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.user) = $event)),
                errors: _ctx.errors,
                onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event))
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.email-address.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputText, {
                                name: "email",
                                placeholder: _ctx.translate('form-fields.email-address.placeholder'),
                                required: "",
                                email: ""
                            }, null, 8, ["placeholder"]),
                            _createVNode(_component_FormError, { name: "email" })
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue", "errors"]),
            _createVNode(_component_Button, {
                onClick: _ctx.createUser,
                loading: _ctx.isCreating,
                disabled: !_ctx.isValid
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.create")), 1)
                ]),
                _: 1
            }, 8, ["onClick", "loading", "disabled"])
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
