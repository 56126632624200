import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_EditAccessRestrictionsShared = _resolveComponent("EditAccessRestrictionsShared");
    const _component_AttachmentsInput = _resolveComponent("AttachmentsInput");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors
        }, {
            default: _withCtx(() => [
                _createElementVNode("h5", null, _toDisplayString(_ctx.translate("size-access-restriction")), 1),
                _createVNode(_component_EditAccessRestrictionsShared, {
                    initial: _ctx.initial,
                    modelValue: _ctx.accessRestrictionModel,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.accessRestrictionModel) = $event))
                }, null, 8, ["initial", "modelValue"]),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_AttachmentsInput, {
                            name: "additionalRestrictions",
                            title: _ctx.translate('form-fields.additional-restrictions.label')
                        }, null, 8, ["title"]),
                        _createVNode(_component_FormError, {
                            name: `additionalRestrictions[${_ctx.$utils.findErrorIndex(_ctx.errors)}].values`
                        }, null, 8, ["name"])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
