import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-63295372"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-address" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = {
    key: 0,
    class: "street-address"
};
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { key: 2 };
const _hoisted_6 = {
    key: 1,
    class: "col-12"
};
const _hoisted_7 = { class: "col-6 label" };
const _hoisted_8 = { class: "col-6 value" };
const _hoisted_9 = { class: "col-6 label" };
const _hoisted_10 = { class: "col-6 value" };
const _hoisted_11 = { class: "col-6 label" };
const _hoisted_12 = { class: "col-6 value" };
const _hoisted_13 = { class: "col-6 label" };
const _hoisted_14 = { class: "col-6 value" };
const _hoisted_15 = { class: "col-6 label" };
const _hoisted_16 = { class: "col-6 value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.short)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.isAddressEmpty)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode("-")
                    ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.formatted)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                (_ctx.address?.streetAddress || _ctx.address?.addressAddition)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.address?.streetAddress) + " " + _toDisplayString(_ctx.address?.addressAddition), 1))
                                    : _createCommentVNode("", true),
                                (_ctx.address?.postalCode || _ctx.address?.addressLocality)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.address?.postalCode) + " " + _toDisplayString(_ctx.address?.addressLocality), 1))
                                    : _createCommentVNode("", true),
                                (_ctx.address?.countryCode)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.getCountryName()), 1))
                                    : _createCommentVNode("", true)
                            ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_ctx.shortAddress), 1)
                            ], 64))
                    ], 64))
            ]))
            : (_openBlock(), _createElementBlock("table", _hoisted_6, [
                _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.translate("form-fields.street-address.label")), 1),
                    _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.address?.streetAddress ?? "-"), 1)
                ]),
                _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.translate("form-fields.address-addition.label")), 1),
                    _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.address?.addressAddition ?? "-"), 1)
                ]),
                _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.translate("form-fields.postal-code.label")), 1),
                    _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.address?.postalCode ?? "-"), 1)
                ]),
                _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.translate("form-fields.address-locality.label")), 1),
                    _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.address?.addressLocality ?? "-"), 1)
                ]),
                _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.translate("form-fields.country.label")), 1),
                    _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.getCountryName()), 1)
                ])
            ]))
    ]));
}
