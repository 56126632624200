import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { NotEqual } from "@/app/modular-content/token/filter-operator.constants";
let ManagedByExternalCompanyFilterService = class ManagedByExternalCompanyFilterService {
    createNotEqualsFilter() {
        return [
            {
                propertyName: "ManagedByExternalCompany",
                propertyValue: true,
                operatorValue: NotEqual,
            },
        ];
    }
};
ManagedByExternalCompanyFilterService = __decorate([
    service()
], ManagedByExternalCompanyFilterService);
export { ManagedByExternalCompanyFilterService };
