import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1bfe04ae"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        header: _ctx.translate('header'),
        showCloseIcon: false,
        "modal-id": _ctx.modalId,
        class: "close-tender-modal"
    }, {
        footer: _withCtx(() => [
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        class: "p-button-danger",
                        onClick: _ctx.confirm
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("close-button")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"])
                ]),
                _: 1
            })
        ]),
        default: _withCtx(() => [
            _createElementVNode("p", {
                innerHTML: _ctx.translate('message', { tenderId: _ctx.model.id, tenderName: _ctx.model.name })
            }, null, 8, _hoisted_1)
        ]),
        _: 1
    }, 8, ["header", "modal-id"]));
}
