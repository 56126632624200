import _ from "lodash";
export const isClassProvider = (it) => {
    return _.has(it, "useClass") && _.has(it, "token");
};
export const isValueProvider = (it) => {
    return _.has(it, "useValue") && _.has(it, "token");
};
export const isFactoryProvider = (it) => {
    return _.has(it, "useFactory") && _.has(it, "token");
};
export const isTokenProvider = (it) => {
    return _.has(it, "useToken") && _.has(it, "token");
};
export const isLiteralClassProvider = (it) => {
    return !_.has(it, "token");
};
export const toClassProvider = (it) => {
    return {
        token: it,
        useClass: it,
    };
};
