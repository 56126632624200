import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d3469c8c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "cookie-banner"
};
const _hoisted_2 = { class: "cookie-content" };
const _hoisted_3 = { class: "button-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppButton = _resolveComponent("AppButton");
    return (_ctx.isCookieOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("info")), 1),
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_AppButton, {
                    class: "p-button-tertiary",
                    onClick: _ctx.openCookieSettings
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("cookie-settings")), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_AppButton, {
                    class: "p-button-secondary",
                    onClick: _ctx.acceptMandatory
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("accept-mandatory-cookies")), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_AppButton, { onClick: _ctx.accept }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("buttons.accept-all")), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"])
            ])
        ]))
        : _createCommentVNode("", true);
}
