import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2cc39184"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "attachments-input" };
const _hoisted_2 = { class: "title globe-icon-wrapper" };
const _hoisted_3 = { class: "header col-3" };
const _hoisted_4 = { class: "header col-6" };
const _hoisted_5 = { class: "header col-2 justify-content-center" };
const _hoisted_6 = { class: "column col-3" };
const _hoisted_7 = { class: "name bold" };
const _hoisted_8 = { class: "column col-6 col-description" };
const _hoisted_9 = { class: "description" };
const _hoisted_10 = { class: "column col-2 col-url justify-content-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_Icon = _resolveComponent("Icon");
    const _component_EditableListInput = _resolveComponent("EditableListInput");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1),
            _createVNode(_component_GlobeIcon)
        ]),
        _createVNode(_component_EditableListInput, {
            modelValue: _ctx.attachmentsLocalized,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.attachmentsLocalized) = $event)),
            add: _ctx.add,
            edit: _ctx.edit,
            remove: _ctx.remove,
            addBtn: _ctx.translate('buttons.add'),
            emptyMsg: _ctx.emptyStateMsg || _ctx.translate('empty')
        }, {
            header: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("columns.title")), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("columns.description")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("columns.url")), 1)
            ]),
            row: _withCtx((attachment) => [
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(attachment.title), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(attachment.description), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                    (attachment.url)
                        ? (_openBlock(), _createBlock(_component_Icon, {
                            key: 0,
                            class: "url",
                            name: "link"
                        }))
                        : _createCommentVNode("", true),
                    (!attachment.url)
                        ? (_openBlock(), _createBlock(_component_Icon, {
                            key: 1,
                            class: "url no-url",
                            name: "link_off"
                        }))
                        : _createCommentVNode("", true)
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "add", "edit", "remove", "addBtn", "emptyMsg"])
    ]));
}
