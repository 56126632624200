import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-23da8c7d"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "title" };
const _hoisted_2 = {
    key: 0,
    class: "buttons"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Skeleton = _resolveComponent("Skeleton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([{ 'is-loading': _ctx.isLoading }, "page-header"])
    }, [
        _createElementVNode("div", _hoisted_1, [
            (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_Skeleton, {
                        width: "75px",
                        height: "22px"
                    }),
                    _createVNode(_component_Skeleton, {
                        width: "200px",
                        height: "44px"
                    }),
                    (_ctx.withSubtitle)
                        ? (_openBlock(), _createBlock(_component_Skeleton, {
                            key: 0,
                            width: "100px",
                            height: "16px"
                        }))
                        : _createCommentVNode("", true)
                ], 64))
                : _renderSlot(_ctx.$slots, "title", { key: 1 }, undefined, true)
        ]),
        (_ctx.$slots.buttons)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_ButtonGroup, null, {
                    default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "buttons", {}, undefined, true)
                    ]),
                    _: 3
                })
            ]))
            : _createCommentVNode("", true)
    ], 2));
}
