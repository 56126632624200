import { ModuleModule } from "@/app/modular-content/modules/module.module";
import { createModule } from "@/di/module";
export const InstallationsModuleId = "installations";
export const InstallationsModule = (domainId) => {
    return createModule({
        imports: [
            ModuleModule({
                id: InstallationsModuleId,
                domainId,
            }),
        ],
    });
};
