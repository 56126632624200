import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a967779c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "id" };
const _hoisted_2 = { "data-qa-id": "id" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.$props.withPrefix !== false)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.translate("id")) + ": ", 1)
            ], 64))
            : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
    ]));
}
