import _ from "lodash";
const cloneValue = (value) => {
    return value;
};
const cloneObject = (obj) => {
    const result = {};
    _.keys(obj).forEach((key) => {
        _.set(result, key, clone(_.get(obj, key)));
    });
    return result;
};
const cloneArray = (arr) => {
    return [...arr.map((it) => clone(it))];
};
export const clone = (value) => {
    if (_.isUndefined(value)) {
        return undefined;
    }
    if (_.isArray(value)) {
        return cloneArray(value);
    }
    if (_.isObject(value)) {
        return cloneObject(value);
    }
    return cloneValue(value);
};
