import { createModule } from "@/di/module";
import { RoutesModule } from "@/app/shared/modules/routes.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { CompanyOverviewRoutes } from "@/app/domains/public-data/companies/company-overview/company-overview.routes";
import { ManageMembersModule } from "@/app/domains/public-data/companies/company-overview/company-members/manage-members.module";
import { CompanySettingsModule } from "@/app/domains/public-data/companies/company-overview/company-settings/company-settings.module";
import { CompanyAccountModule } from "@/app/domains/public-data/companies/company-overview/company-account/company-account.module";
import CompanyOverviewComponent from "@/app/domains/public-data/companies/company-overview/company-overview.component.vue";
export const CompanyOverviewModule = createModule({
    components: [CompanyOverviewComponent],
    imports: [
        RoutesModule({ routes: CompanyOverviewRoutes }),
        CompanyAccountModule,
        ManageMembersModule,
        CompanySettingsModule,
    ],
    providers: [{ token: TranslationPrefixToken, useValue: "domains.companies.manage-company" }],
});
