import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["data-qa-value"];
const _hoisted_2 = ["data-qa-value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_MultiSelect = _resolveComponent("MultiSelect");
    return (_openBlock(), _createBlock(_component_MultiSelect, {
        class: "multiselect",
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        onChange: _ctx.change,
        name: _ctx.name,
        options: _ctx.translatedOptions(),
        optionValue: _ctx.optionValue,
        optionLabel: _ctx.optionLabel,
        placeholder: _ctx.placeholder,
        display: _ctx.display,
        filter: _ctx.isFilterAvailable,
        filterPlaceholder: _ctx.translate('filter-placeholder'),
        disabled: _ctx.disabled,
        panelClass: _ctx.panelClass,
        filterFields: _ctx.filterBy,
        "dropdown-icon": "pi pi-chevron-down",
        "filter-icon": "pi pi-search",
        "remove-token-icon": "pi pi-times-circle",
        autoFilterFocus: _ctx.isFilterAvailable
    }, {
        option: _withCtx(({ option }) => [
            _createElementVNode("div", {
                "data-qa-id": "option",
                "data-qa-value": option.id
            }, _toDisplayString(_ctx.getOption(option)), 9, _hoisted_1)
        ]),
        chip: _withCtx(({ value }) => [
            _createElementVNode("div", {
                "data-qa-id": "value",
                "data-qa-value": value
            }, _toDisplayString(_ctx.getValue(value)), 9, _hoisted_2)
        ]),
        _: 1
    }, 8, ["modelValue", "onChange", "name", "options", "optionValue", "optionLabel", "placeholder", "display", "filter", "filterPlaceholder", "disabled", "panelClass", "filterFields", "autoFilterFocus"]));
}
