import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "display-general-safety-rules" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-6 label" };
const _hoisted_5 = { class: "col-6 value" };
const _hoisted_6 = { class: "row" };
const _hoisted_7 = { class: "col-6 label" };
const _hoisted_8 = { class: "col-6 value" };
const _hoisted_9 = { class: "row" };
const _hoisted_10 = { class: "col-6 label" };
const _hoisted_11 = { class: "col-6 value" };
const _hoisted_12 = { class: "row" };
const _hoisted_13 = { class: "col-6 label" };
const _hoisted_14 = { class: "col-6 value" };
const _hoisted_15 = { class: "row" };
const _hoisted_16 = { class: "col-6 label" };
const _hoisted_17 = { class: "col-6 value" };
const _hoisted_18 = { class: "row" };
const _hoisted_19 = { class: "col-6 label" };
const _hoisted_20 = ["innerHTML"];
const _hoisted_21 = { class: "col-6 value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("form-fields.is-video-monitored.label")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.videoMonitoredValue), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("form-fields.additional-person.label")), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.additionalPersonValue), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("form-fields.prohibited.label")), 1),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getMultipleValues(_ctx.model?.prohibitedOnSite, "ProhibitedOnSite")), 1)
            ]),
            _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("form-fields.smoking.label")), 1),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.getValue(_ctx.model?.smoking, "Smoking")), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate("form-fields.language.label")), 1),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.getMultipleValues(_ctx.model?.minimumLanguageRequirement, "Languages")), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.translate("form-fields.bbs-guidelines.label")), 1),
                    _createElementVNode("div", {
                        class: "small",
                        innerHTML: _ctx.translate('form-fields.bbs-guidelines.label-small')
                    }, null, 8, _hoisted_20)
                ]),
                _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.bbsGuidelinesValue), 1)
            ]),
            _createVNode(_component_DisplayAttachments, {
                attachments: _ctx.model.otherSafetyRules,
                title: _ctx.translate('form-fields.other.label'),
                "empty-state-msg": "-"
            }, null, 8, ["attachments", "title"])
        ])
    ]));
}
