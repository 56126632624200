import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ErrorMessage = _resolveComponent("ErrorMessage");
    return (!_ctx.pristine)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.$slots.default)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.errors[0])
                        ? (_openBlock(), _createBlock(_component_ErrorMessage, { key: 0 }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(_ctx.errors[0]?.message, _ctx.errors[0]?.args)), 1)
                            ]),
                            _: 1
                        }))
                        : _createCommentVNode("", true)
                ], 64))
                : _createCommentVNode("", true),
            (_ctx.$slots.default)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.errors.length > 0)
                        ? (_openBlock(), _createBlock(_component_ErrorMessage, { key: 0 }, {
                            default: _withCtx(() => [
                                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                            ]),
                            _: 3
                        }))
                        : _createCommentVNode("", true)
                ], 64))
                : _createCommentVNode("", true)
        ], 64))
        : _createCommentVNode("", true);
}
