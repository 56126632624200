export const LoadingPointAssignedProductsDomainId = "LoadingProducts";
export const UnloadingPointAssignedProductsDomainId = "UnloadingProducts";
export const AssignedEntitiesServiceToken = "AssignedEntitiesService";
export const AssignedProductDomainServiceToken = "AssignedProductDomainService";
export const AssignedProductMasterSearchServiceToken = "AssignedProductMasterSearchService";
export const AssignedProductSearchServiceToken = "AssignedProductSearchService";
export const AssignProductMasterModalIdToken = "AssignProductMasterModalId";
export const UnloadingLoadingPointFilterServiceToken = "UnloadingLoadingPointFilterService";
export const AssignedProductsListId = "all";
export const AssignProductMasterModalId = "AssignProductMaster";
