import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_ctx.isCompanyBulkProduct())
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "prl-badge-link",
            "data-qa-id": "prl-badge",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.navigateToPRL(_ctx.productId, _ctx.siteId, _ctx.loadingPointId, _ctx.unloadingPointId)), ["stop"]))
        }, [
            _createTextVNode(_toDisplayString(_ctx.translate("prl")), 1)
        ])), [
            [
                _directive_tooltip,
                _ctx.translate('modular-content.components.entity-lists.buttons.go-to-prl'),
                void 0,
                { bottom: true }
            ]
        ])
        : _createCommentVNode("", true);
}
