import { defineStore } from "pinia";
const initialState = {
    isMaintenance: false,
};
export const useMaintenanceStore = defineStore({
    id: "maintenance",
    state: () => initialState,
    actions: {
        setMaintenanceState(value) {
            this.isMaintenance = value;
        },
    },
});
