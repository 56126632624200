var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let CasNumberValidator = class CasNumberValidator {
    translationService;
    name = "CasNumber";
    regex = new RegExp(/^\d{2,7}-\d{2}-\d$/);
    constructor(translationService) {
        this.translationService = translationService;
    }
    message() {
        return this.translationService.translate("validation.messages.cas-number");
    }
    validate(value) {
        function isValidCasNumber(casNumber) {
            const parts = casNumber.split("-");
            if (parts.length !== 3) {
                return false;
            }
            const digits = parts[0] + parts[1];
            const checkDigit = parseInt(parts[2]);
            let sum = 0;
            for (let i = 0; i < digits.length; i++) {
                sum += parseInt(digits[digits.length - 1 - i]) * (i + 1);
            }
            return sum % 10 === checkDigit;
        }
        return this.regex.test(value) && isValidCasNumber(value);
    }
};
CasNumberValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], CasNumberValidator);
export { CasNumberValidator };
