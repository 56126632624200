import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fcf930b0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-weighing" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-description" };
const _hoisted_5 = {
    key: 0,
    class: "row"
};
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6 column-description" };
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col-6 column-header" };
const _hoisted_10 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("truck-weighing")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.truckWeighingInfo), 1)
        ]),
        (_ctx.model?.truckWeighing)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("weighing-to-be-executed")), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.weighingToBeExecutedInfo()), 1)
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("weighing-bridge-on-site")), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.weighingBridgeOnSiteInfo), 1)
        ])
    ]));
}
