import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-83571078"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "content",
    ref: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["collapsible-label", { collapsed: _ctx.collapsed }])
    }, [
        _createElementVNode("div", {
            class: "label",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.collapsed = !_ctx.collapsed))
        }, [
            _createVNode(_component_Icon, {
                name: "chevron_right",
                class: "chevron-icon"
            }),
            _createElementVNode("p", null, _toDisplayString(_ctx.labelText), 1)
        ]),
        _createElementVNode("div", {
            class: "content-container",
            style: _normalizeStyle({ maxHeight: _ctx.collapsed ? 0 : `${_ctx.contentHeight}px` })
        }, [
            _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 512)
        ], 4)
    ], 2));
}
