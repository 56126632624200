var _a;
import { __decorate, __metadata } from "tslib";
import { InjectionScope } from "@/di/types/scope";
import { reactive } from "@/di/decorators/computed.decorator";
import { service } from "@/di/decorators/service.decorator";
import { DeepPartial } from "@/types/deep-partial.type";
let PartialDataProviderService = class PartialDataProviderService {
    data = {};
    setData(data) {
        this.data = data;
    }
    getData() {
        return this.data;
    }
    resetData() {
        this.data = {};
    }
};
__decorate([
    reactive(),
    __metadata("design:type", typeof (_a = typeof DeepPartial !== "undefined" && DeepPartial) === "function" ? _a : Object)
], PartialDataProviderService.prototype, "data", void 0);
PartialDataProviderService = __decorate([
    service({ scope: InjectionScope.Token })
], PartialDataProviderService);
export { PartialDataProviderService };
