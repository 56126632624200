import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-67dcc4ec"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "title-button" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_PageTitle = _resolveComponent("PageTitle");
    return (_openBlock(), _createElementBlock("button", _hoisted_1, [
        _createVNode(_component_Icon, { name: "arrow_back" }),
        _createVNode(_component_PageTitle, null, {
            default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
        })
    ]));
}
