export const InfrastructuresNearbyInfo = [
    {
        translateKey: "petrol-label",
        nameValue: "petrolStation",
        iconName: "local_gas_station",
    },
    {
        translateKey: "lng-label",
        nameValue: "lngStation",
        iconName: "local_gas_station",
    },
    {
        translateKey: "e-charging-label",
        nameValue: "eChargingStation",
        iconName: "ev_station",
    },
    {
        translateKey: "air-filling-label",
        nameValue: "airFillingStation",
        iconName: "air",
    },
    {
        translateKey: "food-takeaway-label",
        nameValue: "foodTakeaway",
        iconName: "fastfood",
    },
    {
        translateKey: "supermarket-label",
        nameValue: "supermarket",
        iconName: "local_grocery_store",
    },
    {
        translateKey: "safety-equipment-store-label",
        nameValue: "safetyEquipmentStore",
        iconName: "health_and_safety",
    },
    {
        translateKey: "kiosk-label",
        nameValue: "kiosk",
        iconName: "store",
    },
    {
        translateKey: "pharmacy-label",
        nameValue: "pharmacy",
        iconName: "local_pharmacy",
    },
    {
        translateKey: "sanitary-facilities-label",
        nameValue: "sanitaryFacilities",
        iconName: "wash",
    },
];
