import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-30c82a97"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "verifier-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_AutoCompleteDropdown = _resolveComponent("AutoCompleteDropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createBlock(_component_Modal, {
        showCloseIcon: true,
        "modal-id": _ctx.modalId,
        header: _ctx.translate('requirement-profile-form.edit-header')
    }, {
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
                    initial: _ctx.initial,
                    errors: _ctx.errors,
                    class: "edit-requirement-profile-modal",
                    onIsValid: _cache[2] || (_cache[2] = ($event) => (_ctx.isValid = $event))
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: "",
                                    required: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("requirement-profile-form.company-from")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_InputText, {
                                    disabled: "",
                                    value: `${_ctx.initial.companyFromName} / ${_ctx.initial.companyFromReferenceNumber ?? '-'}`,
                                    class: "input-disabled"
                                }, null, 8, ["value"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, { class: "outbound-product" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: "",
                                    required: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("requirement-profile-form.outbound-product.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Dropdown, {
                                    class: "product-dropdown outbound",
                                    disabled: "",
                                    placeholder: `${_ctx.initial.outboundProductName} / ${_ctx.initial.outboundProductReferenceNumber ?? '-'}`,
                                    id: "outbound-product-dropdown"
                                }, null, 8, ["placeholder"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: "",
                                    required: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("requirement-profile-form.company-to.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Dropdown, {
                                    disabled: "",
                                    placeholder: `${_ctx.initial.companyToName} / ${_ctx.initial.companyToReferenceNumber ?? '-'}`,
                                    id: "company-to-dropdown"
                                }, null, 8, ["placeholder"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: "",
                                    required: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("requirement-profile-form.site-to.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Dropdown, {
                                    disabled: "",
                                    placeholder: `${_ctx.initial.siteToName} / ${_ctx.initial.siteToReferenceNumber ?? '-'}`,
                                    id: "site-to-dropdown"
                                }, null, 8, ["placeholder"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: "",
                                    required: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("requirement-profile-form.unloading-point.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Dropdown, {
                                    disabled: "",
                                    placeholder: `${_ctx.initial.unloadingPointName} / ${_ctx.initial.unloadingPointReferenceNumber ?? '-'}`,
                                    id: "unloading-point-dropdown"
                                }, null, 8, ["placeholder"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: "",
                                    required: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("requirement-profile-form.inbound-product.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Dropdown, {
                                    class: "product-dropdown inbound",
                                    disabled: "",
                                    placeholder: `${_ctx.initial.inboundProductName} / ${_ctx.initial.inboundProductReferenceNumber ?? '-'}`,
                                    id: "unloading-point-dropdown"
                                }, null, 8, ["placeholder"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("requirement-profile-form.requires-customer-verification")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_RadioButton, {
                                    modelValue: _ctx.model.isRequiredVerification,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.isRequiredVerification) = $event)),
                                    truthyLabel: "phrases.required",
                                    falsyLabel: "phrases.not-required"
                                }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                        }),
                        (_ctx.requiresVerification)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_InputLabel, { required: _ctx.isRequestedStatusVerification }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("requirement-profile-form.verifier-email.label")), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["required"]),
                                        _withDirectives(_createVNode(_component_AutoCompleteDropdown, {
                                            required: _ctx.isRequestedStatusVerification,
                                            name: "verifierEmail",
                                            suggestions: _ctx.verifiersSuggestions,
                                            placeholder: _ctx.translate('requirement-profile-form.verifier-email.placeholder'),
                                            email: ""
                                        }, null, 8, ["required", "suggestions", "placeholder"]), [
                                            [_directive_default_validation]
                                        ]),
                                        _createVNode(_component_FormError, { name: "verifierEmail" })
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_InputLabel, null, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("requirement-profile-form.status-verification")), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_Dropdown, {
                                            required: "",
                                            options: _ctx.statusVerificationOptions,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            dictionaryId: "RequirementProfileStatusVerification",
                                            name: "statusVerification",
                                            disabledOptionIds: ['InProgress']
                                        }, null, 8, ["options"]),
                                        _createVNode(_component_FormError, { name: "statusVerification" })
                                    ]),
                                    _: 1
                                })
                            ]))
                            : _createCommentVNode("", true),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.additional-information.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _withDirectives(_createVNode(_component_InputText, {
                                    name: "additionalInformation",
                                    placeholder: _ctx.translate('form-fields.additional-information.placeholder')
                                }, null, 8, ["placeholder"]), [
                                    [_directive_default_validation]
                                ]),
                                _createVNode(_component_FormError, { name: "additionalInformation" })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["modelValue", "initial", "errors"])),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.close())),
                        id: "cancel-button"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_AppButton, {
                        loading: _ctx.isUpdating,
                        onClick: _ctx.updateEntity,
                        disabled: !_ctx.isValid
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.save")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "onClick", "disabled"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
