import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-15b6b4b8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-compressor" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-description" };
const _hoisted_5 = {
    key: 0,
    class: "row"
};
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("compressor-to-be-provided-by-carrier")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.compressorProvidedByCarrier), 1)
        ]),
        (_ctx.model.compressorToBeProvidedByCarrier)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("additional-compressor-requirements")), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.additionalCompressorRequirementsInfo()), 1)
            ]))
            : _createCommentVNode("", true)
    ]));
}
