var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { CompanyService } from "@/app/domains/public-data/companies/services/company.service";
let SelectedCompanyFromOrToFilterService = class SelectedCompanyFromOrToFilterService {
    companyService;
    constructor(companyService) {
        this.companyService = companyService;
    }
    createConditionalEqualFilter() {
        const selectedCompanyId = this.companyService.getSelectedCompany()?.id;
        return [
            {
                propertyNames: ["CompanyFromId", "CompanyToId"],
                propertyValues: [selectedCompanyId, selectedCompanyId],
            },
        ];
    }
};
SelectedCompanyFromOrToFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof CompanyService !== "undefined" && CompanyService) === "function" ? _a : Object])
], SelectedCompanyFromOrToFilterService);
export { SelectedCompanyFromOrToFilterService };
