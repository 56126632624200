import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        class: "edit-assigned-product-master-basic-information-view",
        initial: _ctx.initial,
        errors: _ctx.errors,
        onContext: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('context', $event)))
    }, {
        default: _withCtx(() => [
            _createVNode(_component_FormField, { class: "col-xl-7" }, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, { required: "" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix("label", true)}`)), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_InputText, {
                        name: "name",
                        placeholder: _ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix('placeholder', true)}`),
                        minLength: 3,
                        maxLength: 300,
                        required: ""
                    }, null, 8, ["placeholder"]),
                    _createVNode(_component_FormError, { name: "name" })
                ]),
                _: 1
            }),
            _createVNode(_component_FormField, { class: "col-xl-7" }, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, { disabled: "" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("form-fields.assigned-product-master-type.label")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_InputText, {
                        value: _ctx.translateProductType(),
                        disabled: ""
                    }, null, 8, ["value"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modelValue", "initial", "errors"]));
}
