import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-092b7617"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-site-incident-management" };
const _hoisted_2 = { class: "title" };
const _hoisted_3 = { class: "container" };
const _hoisted_4 = { class: "title" };
const _hoisted_5 = { class: "checkbox-container mt-1" };
const _hoisted_6 = { class: "container" };
const _hoisted_7 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createBlock(_component_AppForm, {
                key: 1,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.model) = $event)),
                initial: _ctx.initial
            }, {
                default: _withCtx(() => [
                    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate("emergency-system")), 1),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_RadioButton, {
                                modelValue: _ctx.model.emergencySystem,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.emergencySystem) = $event)),
                                dictionary: _ctx.dictionaries.emergencySystem,
                                dictionaryId: "EmergencySystem"
                            }, null, 8, ["modelValue", "dictionary"])
                        ]),
                        _: 1
                    }),
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.translate("availability-of-trained-emergency-response")), 1),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_RadioButton, {
                                    modelValue: _ctx.model.availabilityOfTrainedEmergencyReposponseTeamOnSite,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.availabilityOfTrainedEmergencyReposponseTeamOnSite) = $event)),
                                    dictionary: _ctx.dictionaries.availabilityOfTrainedEmergencyResponseTeamOnSite,
                                    dictionaryId: "AvailabilityOfTrainedEmergencyResponseTeamOnSite"
                                }, null, 8, ["modelValue", "dictionary"])
                            ]),
                            _: 1
                        }),
                        _createElementVNode("div", _hoisted_5, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkBoxes, (checkBox) => {
                                return (_openBlock(), _createBlock(_component_FormField, {
                                    key: checkBox.id,
                                    class: "checkbox-item"
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_AppCheckbox, {
                                            modelValue: checkBox.value,
                                            "onUpdate:modelValue": [($event) => ((checkBox.value) = $event), ($event) => (_ctx.updateCheckBox($event, checkBox.id))],
                                            disabled: !_ctx.isEmergencyAvailable
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_InputLabel, {
                                                    class: "checkbox-label",
                                                    disabled: !_ctx.isEmergencyAvailable
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.dictionaryTranslate("AvailabilityOfTrainedEmergencyResponseTeamOnSiteOption", checkBox)), 1)
                                                    ]),
                                                    _: 2
                                                }, 1032, ["disabled"])
                                            ]),
                                            _: 2
                                        }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"])
                                    ]),
                                    _: 2
                                }, 1024));
                            }), 128))
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.translate("reporting-system")), 1),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_RadioButton, {
                                    modelValue: _ctx.model.nearMissIncidentReportingSystem,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model.nearMissIncidentReportingSystem) = $event)),
                                    truthyLabel: "phrases.available",
                                    falsyLabel: "phrases.not-available"
                                }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                        })
                    ])
                ]),
                _: 1
            }, 8, ["modelValue", "initial"]))
    ]));
}
