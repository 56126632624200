import { createModule } from "@/di/module";
import { domainCreateFormComponentTokenFactory, domainTableComponentTokenFactory, domainTokensFactory, } from "@/app/modular-content/factories/domain-tokens.factory";
import { EntityCopyModalComponentToken, EntityEditFormComponentToken, EntityPageComponentToken, } from "@/app/modular-content/token/modular-content.token";
export const DomainComponentsModule = (options) => {
    const domainTokens = domainTokensFactory(options.id);
    const providers = [
        { token: domainTokens.pageComponent, useValue: options.components.mainPage },
        { token: domainTokens.createFormComponent, useValue: options.components.createForm },
        { token: domainTokens.editFormComponent, useValue: options.components.editForm },
        { token: domainTokens.copyFormComponent, useValue: options.components.copyModal },
        ...getTableProviders(options),
        ...getCreateFormProviders(options),
        { token: EntityPageComponentToken, useToken: domainTokens.pageComponent },
        { token: EntityEditFormComponentToken, useToken: domainTokens.editFormComponent },
        { token: EntityCopyModalComponentToken, useToken: domainTokens.copyFormComponent },
    ];
    return createModule({
        providers,
    });
};
const getTableProviders = (options) => {
    return (options.lists?.map((it) => {
        const token = domainTableComponentTokenFactory(options.id, it.id);
        return {
            token,
            useValue: it.component || options.components.table,
        };
    }) ?? []);
};
const getCreateFormProviders = (options) => {
    return (options.lists?.map((it) => {
        const token = domainCreateFormComponentTokenFactory(options.id, it.id);
        return {
            token,
            useValue: it.component || options.components.createForm,
        };
    }) ?? []);
};
