import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7a61a4b1"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_SearchDropdown = _resolveComponent("SearchDropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_DropdownOptionsReload = _resolveComponent("DropdownOptionsReload");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        header: _ctx.translate('header'),
        "modal-id": _ctx.modalId,
        class: "copy-preloading-restrictions-modal"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_AppForm, {
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event))
            }, {
                default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.translate("description")), 1),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("company-label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_SearchDropdown, {
                                name: "companyFromId",
                                optionValue: "id",
                                optionLabel: "name",
                                additionalLabel: "referenceNumber",
                                filterFields: ['referenceNumber'],
                                placeholder: _ctx.translate('company-placeholder'),
                                searchService: _ctx.companiesSearchService,
                                searchSelect: ['id', 'name', 'referenceNumber'],
                                searchFields: ['name', 'referenceNumber'],
                                searchMode: "all",
                                searchInclude: "OnlyMine",
                                searchFilter: "CompanyType ne 'LogisticsServiceProvider'",
                                required: ""
                            }, null, 8, ["placeholder", "searchService"]),
                            _createVNode(_component_FormError, { name: "companyFromId" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("product-label")), 1)
                                ]),
                                _: 1
                            }),
                            (_ctx.companyProductsLoad.isError.value)
                                ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                    key: 0,
                                    onClick: _ctx.companyProductsLoad.loadMethod
                                }, null, 8, ["onClick"]))
                                : (_openBlock(), _createBlock(_component_Dropdown, {
                                    key: 1,
                                    name: "productFromId",
                                    disabled: !_ctx.selectedCompanyId || _ctx.companyProductsLoad.isLoading.value,
                                    loading: _ctx.companyProductsLoad.isLoading.value,
                                    options: _ctx.companyProducts,
                                    optionValue: "id",
                                    optionLabel: "name",
                                    additionalLabel: "referenceNumber",
                                    filterFields: ['referenceNumber'],
                                    placeholder: _ctx.translate('product-placeholder'),
                                    required: ""
                                }, null, 8, ["disabled", "loading", "options", "placeholder"])),
                            _createVNode(_component_FormError, { name: "productFromId" })
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        loading: _ctx.isCopying,
                        onClick: _ctx.copyMethod,
                        disabled: !_ctx.model.productFromId || !_ctx.isValid
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("submit-button")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "onClick", "disabled"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["header", "modal-id"]));
}
