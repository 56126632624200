import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _directive_tooltip = _resolveDirective("tooltip");
    return _withDirectives((_openBlock(), _createBlock(_component_Icon, {
        class: _normalizeClass([_ctx.size]),
        name: "language"
    }, null, 8, ["class"])), [
        [
            _directive_tooltip,
            _ctx.translate('tooltip'),
            void 0,
            { bottom: true }
        ]
    ]);
}
