import RequirementProfileContextMenuHeaderComponent from "@/app/domains/requirement-profiles/components/requirement-profiles-context-menu-header.component.vue";
import RequirementProfileListComponent from "@/app/domains/requirement-profiles/components/requirement-profiles-list.component.vue";
import CreateRequirementProfileModalComponent from "@/app/domains/requirement-profiles/components/create-requirement-profile-modal.component.vue";
import EditRequirementProfileModalComponent from "@/app/domains/requirement-profiles/components/edit-requirement-profile-modal.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { RequirementProfileDomainId } from "@/app/domains/requirement-profiles/requirement-profile.constants";
import { RequirementProfileService } from "@/app/domains/requirement-profiles/services/requirement-profile.service";
import { SelectedCompanyFromFilterService } from "@/app/domains/public-data/companies/services/selected-company-from-filter.service";
import DropdownOptionsReloadComponent from "@/app/shared/components/dropdown-options-reload.component.vue";
import { CompanyRole } from "@/app/shared/types/access.type";
import { FeedbackModalId } from "@/app/domains/requirement-profiles/requirement-profile.constants";
import FeedbackModal from "@/app/domains/requirement-profiles/components/feedback-modal.vue";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { RequestVerificationService } from "@/app/domains/requirement-profiles/services/request-verification.service";
import RequestVerificationButtonComponent from "@/app/domains/requirement-profiles/components/request-verification-button.component.vue";
import { RequirementProfileFeedbackService } from "@/app/domains/requirement-profiles/services/requirement-profile-feedback.service";
import { RequirementProfileBookmarksListId, CompanyListId, RequirementProfilesUrl, RequirementProfileBookmarksUrl, } from "@/app/domains/requirement-profiles/requirement-profile.constants";
import { RouteMetaListId } from "@/app/modular-content/modular-content.constants";
export const RequirementProfileModule = createModule({
    components: [
        RequirementProfileContextMenuHeaderComponent,
        RequirementProfileListComponent,
        CreateRequirementProfileModalComponent,
        EditRequirementProfileModalComponent,
        DropdownOptionsReloadComponent,
        RequestVerificationButtonComponent,
        FeedbackModal,
    ],
    imports: [
        DomainModule({
            id: RequirementProfileDomainId,
            icon: "task",
            detailsIcon: "task_rounded",
            baseUrl: (routerService) => {
                return routerService?.getMeta(RouteMetaListId) === RequirementProfileBookmarksListId
                    ? RequirementProfileBookmarksUrl
                    : RequirementProfilesUrl;
            },
            createRoles: [CompanyRole.RequirementManager],
            forBusinessPartner: true,
            lists: [
                {
                    default: true,
                    id: CompanyListId,
                    filter: SelectedCompanyFromFilterService,
                },
                {
                    id: RequirementProfileBookmarksListId,
                    filter: SelectedCompanyFromFilterService,
                    createRoles: [],
                },
            ],
            domainTranslationPrefix: "requirement-profiles",
            components: {
                contextMenuHeader: RequirementProfileContextMenuHeaderComponent,
                table: RequirementProfileListComponent,
                createModal: CreateRequirementProfileModalComponent,
                editModal: EditRequirementProfileModalComponent,
            },
        }),
        ModalModule(FeedbackModalId, FeedbackModal),
    ],
    providers: [
        RequirementProfileService,
        SelectedCompanyFromFilterService,
        RequestVerificationService,
        RequirementProfileFeedbackService,
        {
            token: TranslationPrefixToken,
            useValue: "domains.requirement-profiles",
        },
    ],
});
