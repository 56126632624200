import { createModule } from "@/di/module";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { ConnectService } from "@/app/modular-content/services/connect.service";
export const DomainConnectServiceModule = (options) => {
    const tokens = domainTokensFactory(options.id);
    return createModule({
        providers: [
            { token: tokens.connect, useClass: ConnectService },
            { token: ConnectService, useToken: tokens.connect },
        ],
    });
};
