import router from "@/router";
export const MatomoOptions = {
    host: process.env.VUE_APP_MATOMO_BASE_URL,
    siteId: process.env.VUE_APP_MATOMO_SITE_ID,
    trackerFileName: process.env.VUE_APP_MATOMO_TRACKER_FILE_NAME,
    router: router,
    enableLinkTracking: true,
    requireConsent: true,
    requireCookieConsent: true,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: [],
    crossOrigin: undefined,
};
