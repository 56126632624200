var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let FormatValidator = class FormatValidator {
    translationService;
    name = "format";
    constructor(translationService) {
        this.translationService = translationService;
    }
    message(format) {
        return this.translationService.translate("validation.messages.format", { format: `${format}` });
    }
    validate(value, format) {
        return !value || !!value.match(format);
    }
};
FormatValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], FormatValidator);
export { FormatValidator };
