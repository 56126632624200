import { UnregisteredTokenError } from "@/di/errors/unregistered-token.error";
import { getConstructorParametersMetadata } from "@/di/metadata/constructor-parameters-metadata";
import { ParameterMetadataMissingError } from "@/di/errors/parameter-metadata-missing.error";
import { toStringToken } from "@/di/types/token";
import { isClassProvider, isFactoryProvider, isTokenProvider, isValueProvider, } from "@/di/types/provider";
import { InjectionScope } from "@/di/types/scope";
import { ScopeMetadata } from "@/di/metadata/injection-metadata";
import _ from "lodash";
import { ModuleIdMetaKey } from "@/di/tokens";
const instanceIdentifier = (token, constructor, provider) => {
    const scope = ScopeMetadata.get(constructor);
    if (scope === InjectionScope.Global) {
        return constructor.name;
    }
    if (scope === InjectionScope.Module) {
        return provider.meta?.[ModuleIdMetaKey] + constructor.name;
    }
    if (scope === InjectionScope.Token) {
        return token + constructor.name;
    }
    return constructor.name;
};
export const resolveFactoryProvider = (token, provider, container, context) => {
    return provider.useFactory((token) => {
        return resolve(token, container, context);
    }, (token) => {
        return resolveAll(token, container, context);
    });
};
const resolveClassProvider = (token, provider, container, context, meta = {}) => {
    const constructor = provider.useClass;
    const identifier = instanceIdentifier(token, constructor, provider);
    const currentModule = meta[ModuleIdMetaKey];
    if (container.getInstance(identifier)) {
        return container.getInstance(identifier);
    }
    const params = getConstructorParametersMetadata(constructor);
    const resolved = params.map((it, index) => {
        if (it.token === undefined) {
            throw new ParameterMetadataMissingError(constructor, index, it);
        }
        return it.multi
            ? resolveAll(it.token, container, context)
            : resolve(it.token, container, context, currentModule);
    });
    const instance = new constructor(...resolved);
    return container.setInstance(identifier, instance);
};
const resolveTokenProvider = (token, provider, container, context) => {
    return resolve(toStringToken(provider.useToken), container, context);
};
const resolveValueProvider = (token, provider) => {
    return provider.useValue;
};
const resolveProvider = (token, provider, container, context) => {
    const stringToken = toStringToken(token);
    if (provider === undefined) {
        throw new UnregisteredTokenError(stringToken);
    }
    const meta = provider.meta;
    if (isFactoryProvider(provider)) {
        return resolveFactoryProvider(token, provider, container, context);
    }
    if (isClassProvider(provider)) {
        return resolveClassProvider(token, provider, container, context, meta);
    }
    if (isTokenProvider(provider)) {
        return resolveTokenProvider(token, provider, container, context);
    }
    if (isValueProvider(provider)) {
        return resolveValueProvider(token, provider);
    }
    return provider;
};
const getModuleId = (provider) => {
    return (provider.meta ?? {})[ModuleIdMetaKey];
};
const bestProvider = (providers, context, from) => {
    const moduleIds = _.uniq(_.compact(providers.map(getModuleId)));
    if (providers.length === 1 || !from || moduleIds.length === 1) {
        return providers[0];
    }
    const sortedProviders = providers
        .filter((it) => {
        return getModuleId(it);
    })
        .map((it) => ({
        ...it,
        distance: context.distance(from, getModuleId(it) ?? ""),
    }))
        .sort((a, b) => a.distance - b.distance);
    return sortedProviders[0];
};
export const resolve = (token, container, context, from) => {
    const stringToken = toStringToken(token);
    const providers = (container.inject(stringToken) ?? []).reverse();
    const provider = bestProvider(providers, context, from);
    return resolveProvider(token, provider, container, context);
};
export const resolveAll = (token, container, context) => {
    const stringToken = toStringToken(token);
    return (container.inject(stringToken) ?? []).map((provider) => resolveProvider(token, provider, container, context));
};
