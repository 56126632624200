var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import axios from "axios";
import { DeleteRestrictionErrorCode } from "@/app/core/types/delete-restriction.type";
const excludedCodes = ["A1020", DeleteRestrictionErrorCode];
const listOfErrors = [
    {
        code: "A1009",
        message: () => "core.services.api-error-interceptor.invalid-email",
    },
    {
        code: "A1041",
        message: () => "core.services.api-error-interceptor.no-proper-role",
    },
    {
        code: "A1051",
        message: () => "core.services.api-error-interceptor.existing-requirement-profile",
    },
    {
        code: "A1072",
        message: () => "core.services.api-error-interceptor.existing-lane",
    },
    {
        code: "A1053",
        message: () => "core.services.api-error-interceptor.no-permission-to-add-new-user",
    },
    {
        code: "A1064",
        message: () => "core.services.api-error-interceptor.public-api-consumer",
    },
    {
        code: "A1069",
        message: () => "core.services.api-error-interceptor.product-assigned-to-master-product",
    },
    {
        code: "A1026",
        message: () => "core.services.api-error-interceptor.lane-company-permission",
    },
    {
        code: "A1030",
        message: () => "core.services.api-error-interceptor.last-company-admin",
    },
    {
        code: "A1076",
        message: () => "core.services.api-error-interceptor.lane-only-bp-selected",
    },
    {
        code: "V1003",
        message: () => "core.services.api-error-interceptor.empty-field",
    },
    {
        code: "V1006",
        message: () => "core.services.api-error-interceptor.max-characters",
    },
    {
        code: "V1005",
        message: () => "core.services.api-error-interceptor.at-least-characters",
    },
    {
        code: "V1010",
        message: () => "core.services.api-error-interceptor.conflict",
    },
    {
        code: "V1012",
        predicate: (e) => e.paths.some((path) => ["gates", "drivingLanes", "laneNumber"].every((s) => path.includes(s))),
        message: () => "sub-modules.gates.errors.duplication.driving-lane",
    },
    {
        code: "V1012",
        predicate: (e) => e.paths.some((path) => ["gates", "name"].every((s) => path.includes(s))),
        message: () => "sub-modules.gates.errors.duplication.gate-name",
    },
    {
        code: "V1012",
        predicate: (e) => e.paths.some((path) => ["checkIns", "name"].every((s) => path.includes(s))),
        message: () => "sub-modules.check-in.errors.duplication.name",
    },
    {
        code: "V1012",
        predicate: (e) => e.paths.some((path) => ["weighingBridges", "name"].every((s) => path.includes(s))),
        message: () => "sub-modules.weighing-bridges.errors.duplication.name",
    },
    {
        code: "V1015",
        message: () => "core.services.api-error-interceptor.max-number",
    },
    {
        code: "V1017",
        message: () => "core.services.api-error-interceptor.exact-characters",
    },
    {
        code: "A1082",
        message: () => "modular-content.components.connect-modal.toast-invalid-code",
    },
    {
        code: "V1025",
        message: () => "core.services.api-error-interceptor.missing-main-lang",
    },
    {
        code: "A1118",
        message: () => "domains.assigned-products.services.remove-product-from-product-master.toast-message.product-not-assigned-error",
    },
];
const errorMessages = {
    getMessages(error) {
        const es = listOfErrors.filter((o) => o.code === error.errorCode);
        const messages = [];
        es.forEach((e) => {
            if (typeof e.predicate === "function") {
                if (e.predicate(error)) {
                    messages.push(e.message());
                }
            }
            else {
                messages.push(e.message());
            }
        });
        if (messages.length === 0) {
            const { errorDescription } = error;
            if (errorDescription && errorDescription.length > 0) {
                messages.push(errorDescription);
            }
        }
        return messages;
    },
};
let ApiErrorInterceptorService = class ApiErrorInterceptorService {
    errorHandler;
    constructor(errorHandler) {
        this.errorHandler = errorHandler;
    }
    onResponseError(config, error) {
        if (!axios.isAxiosError(error)) {
            return error;
        }
        const errors = error.response?.data.errors;
        const errorToastMessages = [];
        const errorCodesToSuppress = config.errorCodesToSuppress;
        if (errors) {
            errors.forEach((error) => {
                const suppress = errorCodesToSuppress?.includes(error.errorCode);
                if (!suppress) {
                    errorToastMessages.push(...errorMessages.getMessages(error).map((message) => ({
                        severity: ToastSeverity.Error,
                        message,
                        condition: error.condition,
                    })));
                }
            });
        }
        const noExcludedErrors = !errors?.filter((it) => excludedCodes.includes(it.errorCode))?.length;
        if (errorToastMessages.length === 0 && noExcludedErrors) {
            errorToastMessages.push({
                severity: ToastSeverity.Error,
                message: "core.services.api-error-interceptor.toast-message",
            });
        }
        errorToastMessages.forEach((error) => this.errorHandler.showToast(error));
        return error;
    }
};
ApiErrorInterceptorService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof ToastService !== "undefined" && ToastService) === "function" ? _a : Object])
], ApiErrorInterceptorService);
export { ApiErrorInterceptorService };
