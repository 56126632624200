import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Calendar = _resolveComponent("Calendar");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        header: _ctx.translate('assign-partner.label'),
        class: "assign-partners-modal"
    }, {
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    class: "create-lane-form",
                    initial: _ctx.model,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
                    errors: _ctx.errors
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("assign-partner.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_AppMultiSelect, {
                                    name: "assignedPartners",
                                    options: _ctx.partnerCompanies,
                                    optionValue: "id",
                                    optionLabel: "name",
                                    display: "chip",
                                    placeholder: _ctx.translate('assign-partner.placeholder')
                                }, null, 8, ["options", "placeholder"]),
                                _createVNode(_component_FormError, { name: "assignedPartners" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("assign-partner.validity.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Calendar, {
                                    inputId: "range",
                                    modelValue: _ctx.assignPartnerValidRange,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.assignPartnerValidRange) = $event)),
                                    name: "assignmentValidFrom",
                                    selectionMode: "range",
                                    dateFormat: "dd.mm.yy",
                                    manualInput: false,
                                    placeholder: _ctx.translate('assign-partner.validity.placeholder'),
                                    showIcon: true
                                }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["initial", "modelValue", "errors"])),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        onClick: _ctx.save,
                        loading: _ctx.isSaving
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.confirm")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick", "loading"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
