export const RequirementProfileDomainId = "RequirementProfiles";
export const FeedbackModalId = "FeedbackModal";
export const RequirementProfileBookmarksListId = "bookmarks";
export const CompanyListId = "company";
export const RequirementProfilesUrl = "requirementprofiles";
export const RequirementProfileBookmarksUrl = "requirementprofiles/bookmarks";
export const EditRequirementProfileModalId = "EditEntityRequirementProfiles";
export const RequirementProfilesCompanyRouteName = "requirement-profiles/company";
export const verificationStatusStyles = {
    NotRequired: "gray",
    NotStarted: "blue",
    Requested: "yellow",
    Info: "red",
    Confirmed: "green",
    ManuallyDone: "green",
    InProgress: "gray",
};
