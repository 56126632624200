var _a;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { inject } from "@/di/decorators/inject.decorator";
import { DomainIdToken } from "@/app/modular-content/token/modular-content.token";
import { camelCase } from "change-case";
import { createRouteId } from "@/app/modular-content/functions/create-route-id";
import _ from "lodash";
import { RouterService } from "@/app/shared/services/router.service";
import { useListIdStore } from "@/app/modular-content/stores/list-id.state";
let DomainRouteService = class DomainRouteService {
    domainId;
    routerService;
    entityIdRouteParameter;
    listIdStore;
    constructor(domainId, routerService) {
        this.domainId = domainId;
        this.routerService = routerService;
        this.entityIdRouteParameter = `${camelCase(this.domainId)}Id`;
        this.listIdStore = useListIdStore();
    }
    createRoute(name, subId) {
        const routeDomainId = createRouteId(this.domainId);
        const routeSubId = subId ? createRouteId(subId) : undefined;
        return _.compact([`${routeDomainId}-${name}`, routeSubId]).join("/");
    }
    isDomainRoute(route) {
        return route.meta.domainId === this.domainId;
    }
    getDetailsRoute(moduleId) {
        return this.createRoute("details", moduleId);
    }
    getEditRoute(moduleId) {
        return this.createRoute("edit", moduleId);
    }
    getListRoute(listId) {
        return this.createRoute("list", listId);
    }
    async navigateToDetails(id, moduleId, referrer, params) {
        this.routerService
            .navigateTo(this.getDetailsRoute(moduleId), {
            ...params,
            [this.entityIdRouteParameter]: id,
        })
            .then(() => {
            if (referrer) {
                this.listIdStore.setListId(this.domainId, referrer.listId, referrer.domainId);
            }
        });
    }
    getCurrentEntityId() {
        return this.routerService.getParam(this.entityIdRouteParameter);
    }
};
DomainRouteService = __decorate([
    service(),
    __param(0, inject(DomainIdToken)),
    __metadata("design:paramtypes", [String, typeof (_a = typeof RouterService !== "undefined" && RouterService) === "function" ? _a : Object])
], DomainRouteService);
export { DomainRouteService };
