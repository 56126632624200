var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { AuthService } from "@/app/auth/services/auth.service";
import { HttpRequestInterceptorToken } from "@/app/http/tokens/http-request-interceptor.token";
let AuthTokenInterceptorService = class AuthTokenInterceptorService {
    authService;
    constructor(authService) {
        this.authService = authService;
    }
    interceptRequest(request, next) {
        const token = this.authService.getToken();
        if (!token) {
            return next(request);
        }
        request.headers = {
            ...request.headers,
            Authorization: `Bearer ${token}`,
        };
        return next(request);
    }
};
AuthTokenInterceptorService = __decorate([
    service({ token: HttpRequestInterceptorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof AuthService !== "undefined" && AuthService) === "function" ? _a : Object])
], AuthTokenInterceptorService);
export { AuthTokenInterceptorService };
