var _a, _b;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { useEntityStore } from "@/app/modular-content/stores/entity.state";
import { merge } from "@/app/shared/functions/merge";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
let RequirementProfileService = class RequirementProfileService {
    httpService;
    toastService;
    store = useEntityStore();
    constructor(httpService, toastService) {
        this.httpService = httpService;
        this.toastService = toastService;
    }
    async setStatusVerification(dto) {
        await this.httpService.post("requirementprofiles/changeStatus", dto);
    }
    async getInboundProductId(dto) {
        return await this.httpService.post("requirementprofiles/takeInboundProductName", dto);
    }
    async deleteRequirementProfile(requirementProfileId) {
        await this.httpService.delete(`requirementprofiles/${requirementProfileId}`);
    }
    async update(id, dto) {
        const callback = () => this.updateRequirementProfile(id, dto);
        const entity = await this.store.load(callback);
        this.store.byId[id] = entity;
        return entity;
    }
    async addBookmarks(requirementProfileIds) {
        await this.httpService.post("requirementprofiles/bookmarks", {
            ids: requirementProfileIds,
        });
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: "domains.requirement-profiles.bookmark-requirement-profile.toast-message.add",
        });
    }
    async removeBookmark(requirementProfileId) {
        await this.httpService.delete(`requirementprofiles/${requirementProfileId}/bookmark`);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: "domains.requirement-profiles.bookmark-requirement-profile.toast-message.remove",
        });
    }
    async updateRequirementProfile(id, dto) {
        const current = await this.httpService.get(`requirementprofiles/${id}`);
        const updated = merge(current, dto);
        return this.replace(id, updated);
    }
    async replace(id, entity) {
        const updated = await this.httpService.put(`requirementprofiles/${id}`, entity);
        const updatedMerge = merge(entity, updated);
        return updated ? updatedMerge : entity;
    }
};
RequirementProfileService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object])
], RequirementProfileService);
export { RequirementProfileService };
