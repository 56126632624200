import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot, Fragment as _Fragment } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _mergeProps({
            class: ["checkbox", {
                    checked: _ctx.checked,
                    disabled: _ctx.disabled,
                    'type-toggle': _ctx.type === 'toggle',
                    'type-checkbox': _ctx.type === 'checkbox',
                }],
            tabindex: "1"
        }, _ctx.$attrs, {
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.toggle && _ctx.toggle(...args))),
            onKeyup: [
                _cache[1] || (_cache[1] = _withKeys(
                //@ts-ignore
                (...args) => (_ctx.toggle && _ctx.toggle(...args)), ["space"])),
                _cache[2] || (_cache[2] = _withKeys(
                //@ts-ignore
                (...args) => (_ctx.toggle && _ctx.toggle(...args)), ["enter"]))
            ]
        }), [
            (_ctx.checked && _ctx.type === 'checkbox')
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    name: "check"
                }))
                : _createCommentVNode("", true)
        ], 16),
        (_ctx.label)
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                onClick: _cache[3] || (_cache[3] =
                    //@ts-ignore
                    (...args) => (_ctx.toggle && _ctx.toggle(...args))),
                class: _normalizeClass({ disabled: _ctx.disabled })
            }, _toDisplayString(_ctx.label), 3))
            : _createCommentVNode("", true),
        (_ctx.$slots.default)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "label",
                onClick: _cache[4] || (_cache[4] =
                    //@ts-ignore
                    (...args) => (_ctx.toggle && _ctx.toggle(...args)))
            }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]))
            : _createCommentVNode("", true)
    ], 64));
}
