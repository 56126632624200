import { createModule } from "@/di/module";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { DomainMetadataToken } from "@/app/modular-content/token/modular-content.token";
import { toCallback } from "@/app/modular-content/modules/functions/transform-to-callback.function";
export const DomainMetadataModule = (options) => {
    const specific = domainTokensFactory(options.id);
    const generic = domainTokensFactory();
    const baseUrlCallback = toCallback(options.baseUrl);
    const createBtnLabelCallback = toCallback(options.createBtnLabel);
    const readOnly = toCallback(options.readOnly);
    const meta = {
        id: options.id,
        parent: options.parent,
        baseUrlCallback,
        keepSearchUrlParams: options.keepSearchUrlParams ?? false,
        readOnly,
        listIds: options.lists?.map((it) => it.id) ?? [],
        createBtnLabelCallback,
        domainTranslationPrefix: options.domainTranslationPrefix,
        createRoles: options.createRoles,
        originDomain: options.originDomain,
        originId: options.originId,
        originIdGoToBtnLabel: options.originIdGoToBtnLabel,
        copyAvailable: !!options.components?.copyModal,
        copyTooltip: options.copyTooltip,
        crossDomainDetailsCreateModal: {
            domainId: options.crossDomainDetailsCreateModal?.domainId,
            modalListId: options.crossDomainDetailsCreateModal?.modalListId,
        },
        standAloneDomain: options.standAloneDomain ?? false,
        loady4Tender: options.loady4Tender ?? false,
        hideSideNavigation: options.hideSideNavigation ?? false,
        hideMenuItem: options.hideMenuItem ?? false,
        connectingDisabled: options.connectingDisabled ?? false,
    };
    return createModule({
        providers: [
            { token: specific.meta, useValue: meta },
            { token: generic.meta, useToken: specific.meta },
            { token: DomainMetadataToken, useToken: specific.meta },
        ],
    });
};
