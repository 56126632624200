import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    return (_ctx.hasLocation)
        ? (_openBlock(), _createBlock(_component_AppButton, {
            key: 0,
            class: "p-button p-button-secondary",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.seeOnMap(_ctx.location)))
        }, {
            default: _withCtx(() => [
                _createVNode(_component_Icon, {
                    name: "map",
                    variant: "rounded"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.translate("phrases.see-on-map")), 1)
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
