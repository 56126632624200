var _a, _b;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { CompanyIdHeader } from "@/app/http/constants/custom-headers";
let AssignPartnersService = class AssignPartnersService {
    httpService;
    toastService;
    constructor(httpService, toastService) {
        this.httpService = httpService;
        this.toastService = toastService;
    }
    async assignPartners(payload, companyId) {
        const updated = await this.httpService.post("lanes/assignPartnerToLane", payload, {
            headers: { [CompanyIdHeader]: companyId },
        });
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: "domains.lanes.assign-partner.toast-message",
        });
        return updated;
    }
};
AssignPartnersService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object])
], AssignPartnersService);
export { AssignPartnersService };
