import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5ffb968c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-working-on-top" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row section" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 column-description" };
const _hoisted_6 = { class: "row section" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = { class: "col-6 column-description" };
const _hoisted_9 = { class: "row section" };
const _hoisted_10 = { class: "col-6 column-header" };
const _hoisted_11 = { class: "col-6 column-description" };
const _hoisted_12 = { class: "row section" };
const _hoisted_13 = { class: "col-6 column-header" };
const _hoisted_14 = { class: "col-6 column-description" };
const _hoisted_15 = { class: "row section" };
const _hoisted_16 = { class: "col-6 column-header" };
const _hoisted_17 = { class: "col-6 column-description" };
const _hoisted_18 = { class: "row section" };
const _hoisted_19 = { class: "col-6 column-header" };
const _hoisted_20 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("available-clearance"))), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.clearanceHeightInfo), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("height-difference"))), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.heightDifferenceInfo), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("driver-to-work-on-top")), 1),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.driverToWorkOnTopInfo), 1)
            ]),
            _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("access-to-top")), 1),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.getValue(_ctx.model?.accessToTop, "AccessToTop")), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate("work-on-top")), 1),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.getValue(_ctx.model?.workToTop, "WorkOnTop")), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.translate("gantry-height")), 1),
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.getValue(_ctx.model?.gantryHeight, "GantryHeight")), 1)
            ])
        ])
    ]));
}
