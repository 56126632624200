var _a, _b, _c;
import { __decorate, __metadata, __param } from "tslib";
import { firstValueFrom } from "rxjs";
import { service } from "@/di/decorators/service.decorator";
import { RouteGuardToken } from "@/app/shared/tokens/route-guard.token";
import { modal } from "@/app/shared/decorators/inject-modal.decorator";
import { CancelEditModalId } from "@/app/core/core.constants";
import { ModalService } from "@/app/modals/services/modal.service";
import { inject } from "@/di/decorators/inject.decorator";
import { ChangeDetectionServiceToken } from "@/app/modular-content/token/modular-content.token";
import { ChangeDetectionService } from "@/app/modular-content/interfaces/change-detection.service";
import { RouterService } from "@/app/shared/services/router.service";
let LeaveEditGuardService = class LeaveEditGuardService {
    cancelModal;
    changeDetectionService;
    routerService;
    constructor(cancelModal, changeDetectionService, routerService) {
        this.cancelModal = cancelModal;
        this.changeDetectionService = changeDetectionService;
        this.routerService = routerService;
    }
    async canActivate(from) {
        const isLeavingFromEdit = this.isEditRoute(from);
        const leavingDomainId = from.meta.domainId;
        const leavingModuleId = from.meta.moduleId;
        const serviceDomainId = this.changeDetectionService.getDomainId();
        const serviceModuleId = this.changeDetectionService.getModuleId();
        if (!isLeavingFromEdit ||
            (leavingModuleId && leavingModuleId !== serviceModuleId) ||
            (leavingDomainId && leavingDomainId !== serviceDomainId)) {
            return true;
        }
        if (!this.changeDetectionService.hasChanges()) {
            return true;
        }
        this.cancelModal.setVisible(true);
        const result = await firstValueFrom(this.cancelModal.onResultChange());
        if (result.confirmed) {
            this.changeDetectionService.discardChanges();
        }
        return result.confirmed;
    }
    isEditRoute(route) {
        return !!route.name && !!String(route.name).match(/.+-edit.*/);
    }
};
LeaveEditGuardService = __decorate([
    service({ token: RouteGuardToken }),
    __param(0, modal(CancelEditModalId)),
    __param(1, inject(ChangeDetectionServiceToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof ModalService !== "undefined" && ModalService) === "function" ? _a : Object, typeof (_b = typeof ChangeDetectionService !== "undefined" && ChangeDetectionService) === "function" ? _b : Object, typeof (_c = typeof RouterService !== "undefined" && RouterService) === "function" ? _c : Object])
], LeaveEditGuardService);
export { LeaveEditGuardService };
