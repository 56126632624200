import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
let CookieService = class CookieService {
    visible = false;
    setVisible(visible) {
        this.visible = visible;
    }
    isVisible() {
        return this.visible;
    }
};
CookieService = __decorate([
    service()
], CookieService);
export { CookieService };
