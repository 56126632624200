var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let MaxValidator = class MaxValidator {
    translationService;
    name = "max";
    arg = Number;
    constructor(translationService) {
        this.translationService = translationService;
    }
    message(max) {
        return this.translationService.translate("validation.messages.max", { max: max + 1 });
    }
    validate(value, max) {
        if (!value || value.toString() === "-") {
            return true;
        }
        return value <= max;
    }
};
MaxValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], MaxValidator);
export { MaxValidator };
