import { onMounted, ref, shallowRef } from "vue";
import { useInstance } from "@/app/shared/composables/use-instance.composable";
import _ from "lodash";
import { resolve } from "@/di/composables/resolve";
import { BackendValidationErrorsService } from "@/app/validation/services/backend-validation-errors.service";
/*
 * When the new load method isn't called on mount, it was to be
 * extracted from the composable in the component where it is
 * used and called from the template. The load method in the
 * component is still required to be used inside the new method,
 * but it *cannot* be used in the template.
 * Even when replacing the load method in the component instance,
 * the template will still reference the original method from the
 * component.
 *
 * Example:
 * ```
 *  <template>
 *    <Button :loading="isLoading" @click="loadMethod">...</Button>
 *  </template>
 *
 *  <script>
 *  setup() {
 *    const { loadMethod, isLoading } = useLoadMethod("load", false)
 *
 *    return {
 *      loadMethod,
 *      isLoading
 *    }
 *  }
 *  methods: {
 *    load() {
 *       // normal loading/creating/saving logic
 *    }
 *  }
 * </script>
 * ```
 */
export const useLoadMethod = (method = "load", callOnMount = true) => {
    const loadOnMountCalled = ref(!callOnMount);
    const isLoading = ref(false);
    const isError = ref(false);
    const errorResponse = shallowRef();
    const backendErrorsService = callOnMount ? undefined : resolve(BackendValidationErrorsService);
    let instance;
    let originalLoadMethod = () => {
        return;
    };
    const newLoadMethod = async () => {
        isLoading.value = true;
        isError.value = false;
        errorResponse.value = undefined;
        try {
            await originalLoadMethod();
        }
        catch (e) {
            isError.value = true;
            isLoading.value = false;
            errorResponse.value = e;
            backendErrorsService?.addAxiosError(e);
            throw e;
        }
        isLoading.value = false;
    };
    onMounted(async () => {
        instance = useInstance();
        originalLoadMethod = _.get(instance, `ctx.${method}`);
        if (callOnMount) {
            await newLoadMethod();
            loadOnMountCalled.value = true;
        }
    });
    const reset = () => {
        if (!instance) {
            return;
        }
        isLoading.value = false;
        isError.value = false;
    };
    return {
        loadMethod: newLoadMethod,
        reset,
        loadOnMountCalled,
        isLoading,
        isError,
        errorResponse,
    };
};
