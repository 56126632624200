import { createInjectionContainer, vueProviderContainer } from "@/di/container";
import { globalModuleContext } from "@/di/module";
import { getCurrentInstance } from "vue";
const useModuleId = (context) => {
    const instance = getCurrentInstance();
    if (!context || !instance) {
        return;
    }
    return context.getModuleForComponent(instance);
};
export const resolve = (token, container = vueProviderContainer(), context = globalModuleContext) => {
    return createInjectionContainer(container, context, useModuleId(context)).resolve(token);
};
export const tryResolve = (token, container = vueProviderContainer(), context = globalModuleContext) => {
    try {
        return resolve(token, container, context);
    }
    catch (e) {
        return undefined;
    }
};
export const resolveAll = (token, container = vueProviderContainer(), context = globalModuleContext) => {
    return createInjectionContainer(container, context, useModuleId(context)).resolveAll(token);
};
