import AssignedLanesListComponent from "@/app/domains/assigned-lanes/components/assigned-lanes-list.component.vue";
import AssignedLanesContextMenuHeaderComponent from "@/app/domains/assigned-lanes/components/assigned-lanes-context-menu-header.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import PlatformLinkModalComponent from "@/app/shared/components/platform-link/components/platform-link-modal.component.vue";
import DropdownOptionsReloadComponent from "@/app/shared/components/dropdown-options-reload.component.vue";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { PlatformLinkService } from "@/app/shared/components/platform-link/platform-link.service";
import { AssignedLanesDomainId } from "@/app/domains/assigned-lanes/assigned-lanes.constants";
import { AssignedLaneFilterService } from "@/app/domains/assigned-lanes/services/assigned-lane-filter.service";
import { EntityLinkService } from "@/app/shared/services/view-entity-links.service";
import { PlatformLinkModalId } from "@/app/shared/components/platform-link/platform-link.constants";
export const AssignedLanesModule = createModule({
    components: [AssignedLanesListComponent, DropdownOptionsReloadComponent, PlatformLinkModalComponent],
    imports: [
        DomainModule({
            id: AssignedLanesDomainId,
            icon: "person_add_alt_1",
            detailsIcon: "person_add_alt_1",
            forLogisticsServiceProvider: true,
            readOnly: true,
            lists: [
                {
                    id: "company",
                    default: true,
                    filter: AssignedLaneFilterService,
                },
            ],
            domainTranslationPrefix: "assigned-lanes",
            baseUrl: "lanes",
            components: {
                contextMenuHeader: AssignedLanesContextMenuHeaderComponent,
                table: AssignedLanesListComponent,
            },
        }),
        ModalModule(PlatformLinkModalId, PlatformLinkModalComponent),
    ],
    providers: [
        AssignedLaneFilterService,
        PlatformLinkService,
        EntityLinkService,
        {
            token: TranslationPrefixToken,
            useValue: "domains.assigned-lanes",
        },
    ],
});
