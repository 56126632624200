var _a;
import { __decorate, __metadata, __param } from "tslib";
import { Router, } from "vue-router";
import { inject } from "@/di/decorators/inject.decorator";
import { service } from "@/di/decorators/service.decorator";
import { returnWith } from "@/app/shared/functions/return-with";
import { RouterToken } from "@/app/shared/tokens/router.token";
let RouterService = class RouterService {
    router;
    constructor(router) {
        this.router = router;
    }
    getCurrentRoute() {
        return this.router.currentRoute.value;
    }
    async navigateTo(name, params, replace = false) {
        if (this.router.hasRoute(name)) {
            await this.router.push({ name, params: params, replace });
            return;
        }
        await this.router.push({ path: name, replace });
    }
    getRoute(options) {
        return this.router.resolve(options);
    }
    getNamedRoute(name) {
        return this.router.getRoutes().find((it) => it.name === name);
    }
    getRouteDetailsRecordByMetaData(domainId, moduleId) {
        return this.router.getRoutes().find((it) => {
            const isDetails = it.name?.toString().indexOf("details") !== -1;
            const isEdit = it.name?.toString().indexOf("edit") !== -1;
            return it.meta.domainId === domainId && it.meta.moduleId === moduleId && (isDetails || !isEdit);
        });
    }
    isRouteActive(name) {
        const currentRoute = this.getCurrentRoute();
        const currentRouteName = currentRoute.name;
        if (currentRouteName === undefined) {
            return false;
        }
        if (currentRouteName === name) {
            return true;
        }
        if (currentRouteName.includes("/") && currentRouteName.startsWith(name)) {
            return true;
        }
        const record = this.getNamedRoute(name) ?? returnWith(false);
        if (record.redirect) {
            const redirectRecord = record.redirect;
            return this.isRouteActive(redirectRecord.name);
        }
        return false;
    }
    isEditRoute() {
        const route = this.getCurrentRoute() ?? returnWith();
        const path = route?.path;
        return !!path && !!String(path).match(/.+edit.*/);
    }
    getParams() {
        const route = this.getCurrentRoute() ?? returnWith();
        return route.params;
    }
    getParam(key) {
        const route = this.getCurrentRoute() ?? returnWith();
        return route.params[key];
    }
    getMeta(key) {
        const route = this.getCurrentRoute() ?? returnWith();
        return route.meta[key];
    }
};
RouterService = __decorate([
    service(),
    __param(0, inject(RouterToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof Router !== "undefined" && Router) === "function" ? _a : Object])
], RouterService);
export { RouterService };
