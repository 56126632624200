export const supportedLanguages = ["en", "de", "pl", "es", "it", "fr", "ro"];
export const defaultLanguage = "en";
export const LanguageOriginalNames = {
    es: "Español",
    en: "English",
    de: "Deutsch",
    pl: "Polski",
    it: "Italiano",
    fr: "Français",
    ro: "Română",
};
