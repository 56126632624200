import { createModule } from "@/di/module";
import ManageMembersComponent from "@/app/domains/public-data/companies/company-overview/company-members/manage-members.component.vue";
import { RoutesModule } from "@/app/shared/modules/routes.module";
import { ManageMembersRoutes } from "./manage-members.routes";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import InviteMembersModalComponent from "@/app/domains/public-data/companies/company-overview/company-members/components/invite-members-modal.component.vue";
import LeaveCompanyModalComponent from "@/app/domains/public-data/companies/company-overview/company-members/components/leave-company-modal.component.vue";
import CannotLeaveCompanyModalComponent from "@/app/domains/public-data/companies/company-overview/company-members/components/cannot-leave-company-modal.component.vue";
import RemoveUserModalComponent from "@/app/domains/public-data/companies/company-overview/company-members/components/remove-user-modal.component.vue";
import PermissionRightsModalComponent from "@/app/domains/public-data/companies/company-overview/company-members/components/permission-rights-modal.component.vue";
import ManageMembersTableComponent from "@/app/domains/public-data/companies/company-overview/company-members/components/manage-members-table.component.vue";
import { ManageCompanyNavigationItemToken } from "@/app/domains/public-data/companies/company-overview/tokens/manage-company-navigation-item.token";
import { ManageMembersService } from "@/app/domains/public-data/companies/company-overview/company-members/services/manage-members.service";
import { InviteMembersModalId, LeaveCompanyModalId, CannotLeaveCompanyModalId, RemoveUserModalId, PermissionRightsModalId, } from "@/app/domains/public-data/companies/company-overview/company-members/manage-members.constants";
import { ModalModule } from "@/app/modals/modules/modal.module";
const navigationItem = {
    label: "domains.companies.manage-company.manage-members.menu-item",
    externalCompanylabel: "domains.companies.manage-company.manage-members.external-company-menu-item",
    route: "users",
    icon: "",
};
export const ManageMembersModule = createModule({
    components: [
        ManageMembersComponent,
        InviteMembersModalComponent,
        ManageMembersTableComponent,
        LeaveCompanyModalComponent,
        CannotLeaveCompanyModalComponent,
        RemoveUserModalComponent,
        PermissionRightsModalComponent,
    ],
    imports: [
        RoutesModule({
            routes: ManageMembersRoutes,
            parent: "manage-company-root",
        }),
        ModalModule(InviteMembersModalId, InviteMembersModalComponent),
        ModalModule(LeaveCompanyModalId, LeaveCompanyModalComponent),
        ModalModule(CannotLeaveCompanyModalId, CannotLeaveCompanyModalComponent),
        ModalModule(RemoveUserModalId, RemoveUserModalComponent),
        ModalModule(PermissionRightsModalId, PermissionRightsModalComponent),
    ],
    providers: [
        ManageMembersService,
        { token: ManageCompanyNavigationItemToken, useValue: navigationItem },
        { token: TranslationPrefixToken, useValue: "domains.companies.manage-company.manage-members" },
    ],
});
