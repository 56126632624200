import { defineStore } from "pinia";
const initialState = {
    originDomain: undefined,
    originId: undefined,
};
export const useEntityDetailsStore = defineStore({
    id: "entity-details",
    state: () => initialState,
    actions: {
        reset() {
            this.originDomain = undefined;
            this.originId = undefined;
        },
    },
});
