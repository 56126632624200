var _a;
import { __decorate, __metadata } from "tslib";
import jwt_decode from "jwt-decode";
import { service } from "@/di/decorators/service.decorator";
import { AuthService } from "@/app/auth/services/auth.service";
import { CurrentUserServiceToken } from "@/app/users/tokens/current-user-service.token";
let TokenUserService = class TokenUserService {
    authService;
    constructor(authService) {
        this.authService = authService;
    }
    getCurrentUserId() {
        if (this.authService.isLocalModeEnabled()) {
            return this.authService.getLocalUserId();
        }
        const token = this.authService.getToken();
        const data = jwt_decode(token);
        return data.sub;
    }
};
TokenUserService = __decorate([
    service({ token: CurrentUserServiceToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof AuthService !== "undefined" && AuthService) === "function" ? _a : Object])
], TokenUserService);
export { TokenUserService };
