import { createModule } from "@/di/module";
import SummaryComponent from "@/app/domains/summary/summary.component.vue";
import SiteSummaryComponent from "@/app/domains/summary/components/site-summary.component.vue";
import ULPSummaryComponent from "@/app/domains/summary/components/ulp-summary.component.vue";
import ProductSummaryComponent from "@/app/domains/summary/components/product-summary.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { paramCase } from "change-case";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { FeedbackConfirmationSuccessModalId, SummaryConfirmationSuccessModalId, SummaryFeedbackFormModalId, ArchivedLoadingSummaryDomainId, ArchivedUnloadingSummaryDomainId, } from "@/app/domains/summary/summary.constants";
import SummaryFeedbackFormModalComponent from "@/app/domains/summary/components/summary-feedback-form-modal.component.vue";
import SummaryConfirmationSuccessModalComponent from "@/app/domains/summary/components/summary-confirmation-success-modal.component.vue";
import FeedbackConfirmationSuccessModalComponent from "@/app/domains/summary/components/feedback-confirmation-success-modal.component.vue";
import { SummaryVerificationService } from "@/app/domains/summary/services/summary-verification.service";
import { RoutesModule } from "@/app/shared/modules/routes.module";
import { getSummaryRoutes } from "@/app/domains/summary/summary.routes";
import { SummaryDomainBaseUrlEnum, SummaryDomainTranslationPrefix } from "@/app/domains/summary/summary.constants";
export const SummaryModule = (parentDomainId, id, options) => {
    const componentSuffixes = [parentDomainId, id];
    const domainIdParam = `${paramCase(id)}-page`;
    const summaryRoutes = getSummaryRoutes(id, parentDomainId);
    const mainPageComponent = suffixComponentName(SummaryComponent, ...componentSuffixes);
    const siteSummaryComponent = suffixComponentName(SiteSummaryComponent, ...componentSuffixes);
    const ulpSummaryComponent = suffixComponentName(ULPSummaryComponent, ...componentSuffixes);
    const productSummaryComponent = suffixComponentName(ProductSummaryComponent, ...componentSuffixes);
    return createModule({
        components: [
            SummaryFeedbackFormModalComponent,
            SummaryConfirmationSuccessModalComponent,
            FeedbackConfirmationSuccessModalComponent,
            mainPageComponent,
            siteSummaryComponent,
            ulpSummaryComponent,
            productSummaryComponent,
        ],
        imports: [
            DomainModule({
                parent: parentDomainId,
                id: id,
                icon: "science",
                detailsIcon: "science",
                public: options?.public,
                domainTranslationPrefix: () => {
                    return SummaryDomainTranslationPrefix[id] ?? "";
                },
                forBusinessPartner: true,
                hideSideNavigation: id === ArchivedLoadingSummaryDomainId || id === ArchivedUnloadingSummaryDomainId,
                baseUrl: () => {
                    return SummaryDomainBaseUrlEnum[id];
                },
                components: {
                    mainPage: mainPageComponent,
                },
            }),
            RoutesModule({
                routes: summaryRoutes,
                parent: domainIdParam,
            }),
            ModalModule(SummaryConfirmationSuccessModalId, SummaryConfirmationSuccessModalComponent),
            ModalModule(SummaryFeedbackFormModalId, SummaryFeedbackFormModalComponent),
            ModalModule(FeedbackConfirmationSuccessModalId, FeedbackConfirmationSuccessModalComponent),
        ],
        providers: [SummaryVerificationService, { token: TranslationPrefixToken, useValue: "domains.summary" }],
    });
};
