import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Calendar = _resolveComponent("Calendar");
    return (_openBlock(), _createBlock(_component_Calendar, {
        class: "calendar",
        inputId: _ctx.inputId,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        name: _ctx.name,
        selectionMode: _ctx.selectionMode,
        dateFormat: _ctx.dateFormat,
        placeholder: _ctx.placeholder,
        showIcon: _ctx.showIcon,
        manualInput: _ctx.manualInput,
        disabled: _ctx.disabled
    }, {
        footer: _withCtx(() => [
            _renderSlot(_ctx.$slots, "footer")
        ]),
        _: 3
    }, 8, ["inputId", "modelValue", "name", "selectionMode", "dateFormat", "placeholder", "showIcon", "manualInput", "disabled"]));
}
