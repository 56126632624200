import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import EditParkingSpaceBasicComponent from "@/app/sub-modules/parking-space-basic/components/edit-parking-space-basic.component.vue";
import DisplayParkingSpaceBasicComponent from "@/app/sub-modules/parking-space-basic/components/display-parking-space-basic.component.vue";
import { FacilitiesModuleId } from "@/app/modules/facilities/facilities.module";
export const ParkingSpaceBasicSubModule = (domainId) => {
    const id = "parkingSpace";
    const moduleId = FacilitiesModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditParkingSpaceBasicComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayParkingSpaceBasicComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "local_parking",
                editTitle: "sub-modules.parking-space.name-edit",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.parking-space",
            },
        ],
    });
};
