import { createApp } from "vue";
import App from "./app/app.component.vue";
import router from "./router";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import { AppModule } from "@/app/app.module";
import ToastService from "primevue/toastservice";
import { disableWindowScroll } from "@/window";
import VueCookies from "vue-cookies";
import { Roles } from "@/app/shared/constants/access.constants";
import { Permissions } from "@/app/shared/constants/permissions.constants";
import VueMatomo from "vue-matomo";
import { MatomoOptions } from "@/app/analytics/analytics.constants";
import mitt from "mitt";
import UtilFunctions from "@/app/shared/utils";
import "./assets/styles/prime/_theme.scss";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "./assets/styles/styles.scss";
import "material-icons/iconfont/material-icons.css";
import LocalizedField from "@/app/shared/components/fields-display/components/localized-field-display.component.vue";
const emitter = mitt();
const app = createApp(App);
app.component("LocalizedField", LocalizedField);
app.use(PrimeVue, { ripple: true });
app.use(createPinia());
app.use(AppModule);
app.use(router);
app.use(ToastService);
app.use(VueCookies);
app.use(VueMatomo, MatomoOptions);
app.mount("#app");
app.config.globalProperties.$roles = Roles;
app.config.globalProperties.$permissions = Permissions;
app.config.globalProperties.$utils = UtilFunctions;
app.config.globalProperties.$emitter = emitter;
if (process.env.VUE_APP_MODE === "production") {
    app.config.warnHandler = () => null;
}
disableWindowScroll();
