import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-51133770"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "overlay"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
        _createVNode(_Transition, null, {
            default: _withCtx(() => [
                (_ctx.anyModalVisible)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }),
        _createElementVNode("div", {
            class: _normalizeClass(["modals", { visible: _ctx.anyModalVisible }])
        }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.componentNames, (name, id) => {
                return (_openBlock(), _createBlock(_Transition, { key: name }, {
                    default: _withCtx(() => [
                        (_ctx.visibleById[id])
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(name), { key: 0 }))
                            : _createCommentVNode("", true)
                    ]),
                    _: 2
                }, 1024));
            }), 128))
        ], 2)
    ]));
}
