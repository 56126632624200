var _a, _b, _c, _d;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { inject } from "@/di/decorators/inject.decorator";
import { UrlFactoryToken } from "@/app/modular-content/token/modular-content.token";
import { SourceURLFactory } from "@/app/modular-content/type/url-factory.type";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { TranslationService } from "@/app/translation/services/translation.service";
let CopyModuleService = class CopyModuleService {
    httpService;
    toastService;
    translationService;
    urlFactory;
    constructor(httpService, toastService, translationService, urlFactory) {
        this.httpService = httpService;
        this.toastService = toastService;
        this.translationService = translationService;
        this.urlFactory = urlFactory;
    }
    async copyModule(sourceId) {
        await this.httpService.put(this.urlFactory.createUrl(sourceId), {});
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate("modular-content.components.copy-module.toast-message.success"),
        });
    }
};
CopyModuleService = __decorate([
    service(),
    __param(3, inject(UrlFactoryToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, typeof (_d = typeof SourceURLFactory !== "undefined" && SourceURLFactory) === "function" ? _d : Object])
], CopyModuleService);
export { CopyModuleService };
