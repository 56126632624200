import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        header: _ctx.translate(`${_ctx.domainTranslationPrefix('header', true)}`),
        "modal-id": _ctx.modalId,
        class: "entity-edit-modal"
    }, {
        default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.editFormComponentName))),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        class: "p-button",
                        loading: _ctx.isUpdating,
                        onClick: _ctx.updateEntity
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.save")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["header", "modal-id"]));
}
