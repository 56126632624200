import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            class: "edit-processes"
        }, {
            default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.translate("message")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkBoxes, (checkBox) => {
                    return (_openBlock(), _createBlock(_component_FormField, {
                        key: checkBox.id
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppCheckbox, {
                                name: "processes",
                                "model-value": checkBox.isChecked,
                                "onUpdate:modelValue": ($event) => (_ctx.toggleCheckBox(checkBox))
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.dictionaryTranslate("Processes", checkBox)), 1)
                                ]),
                                _: 2
                            }, 1032, ["model-value", "onUpdate:modelValue"])
                        ]),
                        _: 2
                    }, 1024));
                }), 128))
            ]),
            _: 1
        }, 8, ["modelValue", "initial"]));
}
