import { createModule } from "@/di/module";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { createRouteId } from "@/app/modular-content/functions/create-route-id";
import { ContextMenuModule } from "@/app/shared/modules/context-menu.module";
import { translationDomainPrefix } from "@/app/modular-content/modules/functions/translation-domain-prefix.function";
import { contextMenuItemTokenFactory } from "@/app/shared/factories/context-menu-item-token.factory";
export const DomainContextMenuModule = (options) => {
    const tokens = domainTokensFactory(options.id);
    const routeDomainId = createRouteId(options.id);
    const contextMenuItem = {
        label: "menu-items.details",
        icon: options.detailsIcon,
        route: `${routeDomainId}-details`,
        condition: options.parentCondition,
        conditionOrigin: options.parent,
        disabled: !!options.detailsDisabled,
        public: true,
    };
    return createModule({
        imports: [
            ContextMenuModule({
                id: tokens.contextMenu,
                listKey: options.id,
                header: options.components.contextMenuHeader,
                backLink: {
                    label: `${translationDomainPrefix(options)}.back-link`,
                    route: `${routeDomainId}-list`,
                },
            }),
        ],
        providers: [{ token: contextMenuItemTokenFactory(tokens.contextMenu), useValue: contextMenuItem }],
    });
};
