import { resolve } from "@/di/composables/resolve";
import { RouterService } from "@/app/shared/services/router.service";
import { createModalIdFactory } from "@/app/modular-content/factories/create-modal-id.factory";
import { RouteMetaDomainId, RouteMetaListId } from "@/app/modular-content/modular-content.constants";
import { useModal } from "@/app/modals/composables/use-modal.composable";
import { RolesService } from "@/app/shared/services/roles.service";
import { CompaniesDomainId, CreateCompanyNoticeModalId } from "@/app/domains/public-data/companies/companies.constants";
import { CompanyRole } from "@/app/shared/types/access.type";
import { BusinessPartnersListId } from "@/app/domains/business-partners/business-partners.constants";
export const useCreateModal = (domainId, listId) => {
    const routerService = resolve(RouterService);
    const metaListId = routerService.getMeta(RouteMetaListId);
    const metaDomainId = routerService.getMeta(RouteMetaDomainId);
    const currentDomainId = domainId ?? metaDomainId;
    const currentListId = listId ?? metaListId;
    if (currentDomainId === undefined) {
        throw new Error(`Cannot resolve domain create-modal without domain id.`);
    }
    // workaround to switch modals in company list based on role
    const rolesService = resolve(RolesService);
    const role = rolesService.getRoleForActiveCompany();
    const allowedRoles = [CompanyRole.CompanyAdmin, CompanyRole.RequirementManager];
    const switchModalBasedOnRole = () => {
        if (currentDomainId === CompaniesDomainId && role !== CompanyRole.LoadyAdmin) {
            if (currentListId === BusinessPartnersListId && allowedRoles.includes(role)) {
                return;
            }
            return CreateCompanyNoticeModalId;
        }
    };
    const modalId = createModalIdFactory(currentDomainId, currentListId);
    return {
        modalId: switchModalBasedOnRole() ?? modalId,
        modalService: useModal(switchModalBasedOnRole() ?? modalId),
    };
};
