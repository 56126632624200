import { createModule } from "@/di/module";
import { UsersModule } from "@/app/users/users.module";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { SideNavigationMenuItemToken } from "@/app/core/tokens/side-navigation-menu-item.token";
import DashboardComponent from "@/app/dashboard/dashboard.component.vue";
import UserGreetingComponent from "@/app/dashboard/components/user-greeting.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { NotificationsService } from "@/app/dashboard/services/notifications.service";
import CreateNotificationModal from "@/app/dashboard/components/notifications/create-notification-modal.component.vue";
import { CreateNotificationModalId } from "@/app/dashboard/constants/notification.constants";
import { DashboardRouteName } from "@/app/dashboard/constants/dashboard.constants";
const sideMenuItem = {
    icon: "dashboard",
    label: "menu-items.dashboard",
    route: DashboardRouteName,
    sideNavTier: 1,
    forLogisticsServiceProvider: true,
    public: true,
};
export const DashboardModule = createModule({
    components: [DashboardComponent, UserGreetingComponent, CreateNotificationModal],
    imports: [UsersModule, ModalModule(CreateNotificationModalId, CreateNotificationModal)],
    providers: [
        NotificationsService,
        { token: SideNavigationMenuItemToken, useValue: sideMenuItem },
        { token: TranslationPrefixToken, useValue: "dashboard" },
    ],
});
