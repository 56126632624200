import { isNaN } from "lodash";
export const tryParseGoogleLocation = (s) => {
    const coords = s
        .split(",")
        .map((it) => parseFloat(it.trim()))
        .filter((it) => !isNaN(it));
    if (coords.length !== 2) {
        return undefined;
    }
    return coords;
};
