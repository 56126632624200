import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_NotificationCard = _resolveComponent("NotificationCard");
    return (_openBlock(), _createBlock(_component_NotificationCard, { notification: _ctx.notification }, {
        header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate(_ctx.header)), 1)
        ]),
        description: _withCtx(() => [
            _createElementVNode("span", {
                innerHTML: _ctx.translate(_ctx.description, {
                    userName: _ctx.notification.userName,
                    userEmail: _ctx.notification.userEmail,
                    unloadingPointName: _ctx.notification.unloadingPointName,
                    inboundProductName: _ctx.notification.inboundProductName,
                    siteToName: _ctx.notification.siteToName,
                    requirementProfileId: _ctx.notification.requirementProfileId,
                })
            }, null, 8, _hoisted_1)
        ]),
        footer: _withCtx(() => [
            _createVNode(_component_AppButton, {
                class: "button",
                onClick: _ctx.navigateTo
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("button")) + " ", 1),
                    _createVNode(_component_Icon, {
                        class: "icon-button",
                        name: "arrow_forward"
                    })
                ]),
                _: 1
            }, 8, ["onClick"])
        ]),
        _: 1
    }, 8, ["notification"]));
}
