var _a;
import { __decorate, __metadata, __param } from "tslib";
import { inject } from "@/di/decorators/inject.decorator";
import { InjectionScope } from "@/di/types/scope";
import { resolve } from "@/di/composables/resolve";
import { service } from "@/di/decorators/service.decorator";
import { DomainIdToken, ModuleIdToken, SubModuleIdToken } from "@/app/modular-content/token/modular-content.token";
import { subModuleTokensFactory } from "@/app/modular-content/factories/sub-module-tokens.factory";
import { InjectorService } from "@/app/shared/services/injector.service";
import { RouterService } from "@/app/shared/services/router.service";
import { RouteMetaDomainId, RouteMetaModuleId } from "@/app/modular-content/modular-content.constants";
let SubModuleService = class SubModuleService {
    domainId;
    moduleId;
    subModuleId;
    injector;
    dataProvider;
    metadata;
    editComponent;
    editGuards;
    displayComponent;
    displayViewComponent;
    constructor(domainId, moduleId, subModuleId, injector) {
        this.domainId = domainId;
        this.moduleId = moduleId;
        this.subModuleId = subModuleId;
        this.injector = injector;
        const tokens = subModuleTokensFactory(domainId, moduleId, subModuleId);
        this.dataProvider = injector.resolve(tokens.dataProvider);
        this.metadata = injector.resolve(tokens.meta);
        this.editComponent = injector.resolve(tokens.editComponent);
        this.displayComponent = injector.resolve(tokens.displayComponent);
        this.displayViewComponent = injector.resolve(tokens.displayViewComponent);
        this.editGuards = injector.resolve(tokens.editGuards);
    }
    getValue() {
        return this.dataProvider.getData();
    }
    setValue(value) {
        this.dataProvider.setData(value);
    }
    resetValue() {
        this.dataProvider.resetData();
    }
    getDisplayComponent() {
        return this.displayComponent;
    }
    getDisplayViewComponent() {
        return this.displayViewComponent;
    }
    getEditComponent() {
        return this.editComponent;
    }
    getMetadata() {
        return this.metadata;
    }
    getDomainId() {
        return this.domainId;
    }
    getModuleId() {
        return this.moduleId;
    }
    getSubModuleId() {
        return this.subModuleId;
    }
    getEditGuards() {
        return this.editGuards ?? [];
    }
    static resolveFromCurrentRouteById(id) {
        const routerService = resolve(RouterService);
        const domainId = routerService.getMeta(RouteMetaDomainId);
        const moduleId = routerService.getMeta(RouteMetaModuleId);
        const tokens = subModuleTokensFactory(domainId, moduleId, id);
        return resolve(tokens.service);
    }
};
SubModuleService = __decorate([
    service({ scope: InjectionScope.Token }),
    __param(0, inject(DomainIdToken)),
    __param(1, inject(ModuleIdToken)),
    __param(2, inject(SubModuleIdToken)),
    __metadata("design:paramtypes", [String, String, String, typeof (_a = typeof InjectorService !== "undefined" && InjectorService) === "function" ? _a : Object])
], SubModuleService);
export { SubModuleService };
