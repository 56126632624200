import { createModule } from "@/di/module";
import { RoutesToken } from "@/app/shared/tokens/routes.token";
import _ from "lodash";
export const RoutesModule = (options) => {
    const routes = options.routes.map((it) => {
        _.set(it, "parent", options.parent);
        return it;
    });
    return createModule({
        providers: [{ token: RoutesToken, useValue: routes }],
    });
};
