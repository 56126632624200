import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-412ea2bf"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-slot-booking" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 bold-text" };
const _hoisted_5 = {
    key: 0,
    class: "row info-row"
};
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6" };
const _hoisted_8 = {
    key: 0,
    class: "chip-wrapper"
};
const _hoisted_9 = {
    key: 1,
    class: "bold-text"
};
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { class: "section" };
const _hoisted_12 = { class: "row" };
const _hoisted_13 = { class: "col-6 column-header" };
const _hoisted_14 = { class: "col-6 bold-text" };
const _hoisted_15 = { class: "row info-row" };
const _hoisted_16 = { class: "col-6 column-header" };
const _hoisted_17 = { class: "col-6" };
const _hoisted_18 = ["href"];
const _hoisted_19 = {
    key: 1,
    class: "bold-text"
};
const _hoisted_20 = { class: "row info-row" };
const _hoisted_21 = { class: "col-6 column-header" };
const _hoisted_22 = { class: "col-6" };
const _hoisted_23 = ["href"];
const _hoisted_24 = {
    key: 1,
    class: "bold-text"
};
const _hoisted_25 = { class: "section" };
const _hoisted_26 = { class: "section" };
const _hoisted_27 = { class: "row" };
const _hoisted_28 = { class: "col-6 column-header" };
const _hoisted_29 = { class: "col-6 cutoffotime-element" };
const _hoisted_30 = { class: "bold-text" };
const _hoisted_31 = {
    key: 0,
    class: "description"
};
const _hoisted_32 = {
    key: 0,
    class: "pe-1"
};
const _hoisted_33 = { key: 1 };
const _hoisted_34 = { class: "row info-row" };
const _hoisted_35 = { class: "col-6 column-header" };
const _hoisted_36 = { class: "col-6 bold-text" };
const _hoisted_37 = { key: 0 };
const _hoisted_38 = { key: 1 };
const _hoisted_39 = { class: "section" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("slot-booking-requirement")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getValue(_ctx.model?.required, "SlotBookingRequired")), 1)
        ]),
        (_ctx.isBookingRequired)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("slot-booking-by")), 1),
                _createElementVNode("div", _hoisted_7, [
                    (_ctx.model.slotBookingBy.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingBy(), (book) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: book,
                                    class: "chip"
                                }, _toDisplayString(book), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_9, "-"))
                ]),
                (_ctx.isBookingRequired)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("section", _hoisted_11, [
                            _createElementVNode("h5", null, _toDisplayString(_ctx.translate("slot-booking-procedure")), 1),
                            _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("form-fields.telephone.label")), 1),
                                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.model?.procedure.phoneNumber || "-"), 1)
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate("booking-by-mail")), 1),
                                _createElementVNode("div", _hoisted_17, [
                                    (_ctx.model?.procedure.email)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            class: "url",
                                            href: `mailto:${_ctx.model?.procedure.email}`
                                        }, _toDisplayString(_ctx.model?.procedure.email), 9, _hoisted_18))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_19, "-"))
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.translate("form-fields.url.label")), 1),
                                _createElementVNode("div", _hoisted_22, [
                                    (_ctx.model?.procedure.onlineBooking)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            href: _ctx.model?.procedure.onlineBooking,
                                            target: "_blank",
                                            class: "url"
                                        }, [
                                            _createTextVNode(_toDisplayString(_ctx.translate("components.display-slot-booking.slot-booking-tool")) + " ", 1),
                                            _createVNode(_component_Icon, { name: "launch" })
                                        ], 8, _hoisted_23))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_24, "-"))
                                ])
                            ])
                        ]),
                        _createElementVNode("section", _hoisted_25, [
                            _createVNode(_component_DisplayAttachments, {
                                attachments: _ctx.model.instructions,
                                title: _ctx.translate('slot-booking-instructions')
                            }, null, 8, ["attachments", "title"])
                        ]),
                        _createElementVNode("section", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, [
                                _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.translate("slot-booking-cutoff-time")), 1),
                                _createElementVNode("div", _hoisted_29, [
                                    _createElementVNode("div", _hoisted_30, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.getValue(_ctx.model?.cutoffTime?.type, _ctx.cutOfTimeDictionaryId)), 1)
                                    ]),
                                    (_ctx.model.cutoffTime.days || _ctx.model.cutoffTime.time)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_31, [
                                            (_ctx.model.cutoffTime.days)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.getSingularOrPluralInfo(_ctx.model?.cutoffTime?.days, "days.label-single", "days.label")), 1))
                                                : _createCommentVNode("", true),
                                            (_ctx.isBookingRequired && _ctx.model.cutoffTime.time)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.translate("slot-booking-until")) + " " + _toDisplayString(_ctx.model.cutoffTime.time), 1))
                                                : _createCommentVNode("", true)
                                        ]))
                                        : _createCommentVNode("", true)
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_34, [
                                _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.translate("new-slot-booking-required")), 1),
                                _createElementVNode("div", _hoisted_36, [
                                    (_ctx.model?.rebookingTime?.hours || _ctx.model?.rebookingTime?.minutes)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_ctx.model.rebookingTime.hours ?? 0) + " " + _toDisplayString(_ctx.translate("slot-booking-hours")) + " " + _toDisplayString(_ctx.model.rebookingTime.minutes ?? 0) + " " + _toDisplayString(_ctx.translate("slot-booking-minutes")), 1))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_38, "-"))
                                ])
                            ])
                        ]),
                        _createElementVNode("section", _hoisted_39, [
                            _createVNode(_component_DisplayAttachments, {
                                attachments: _ctx.model.instructionsForMissedSlots,
                                title: _ctx.translate('components.display-slot-booking.slot-booking-missed-slot')
                            }, null, 8, ["attachments", "title"])
                        ])
                    ]))
                    : _createCommentVNode("", true)
            ]))
            : _createCommentVNode("", true)
    ]));
}
