import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1d77f90d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "error-state"
};
const _hoisted_2 = {
    key: 0,
    class: "empty-state"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_NoPermissionsInfo = _resolveComponent("NoPermissionsInfo");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([{ isLoading: _ctx.isLoading, isError: _ctx.isError, empty: _ctx.empty, 'flex-grow-1': _ctx.empty }, "table"])
    }, [
        (!_ctx.isLoading && _ctx.isError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "error", {}, () => [
                    (_ctx.isForbidden(_ctx.error))
                        ? (_openBlock(), _createBlock(_component_NoPermissionsInfo, { key: 0 }))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("h3", null, _toDisplayString(_ctx.contextTranslate("error.header")), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.contextTranslate("error.message")), 1),
                            _createVNode(_component_AppButton, {
                                class: "p-button-secondary p-button-lg",
                                onClick: _ctx.reload
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_Icon, { name: "refresh" }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.contextTranslate("buttons.retry")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"])
                        ], 64))
                ], true)
            ]))
            : _createCommentVNode("", true),
        (!_ctx.isError)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _renderSlot(_ctx.$slots, "table", { data: _ctx.data }, undefined, true),
                (!_ctx.isLoading && _ctx.empty)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _renderSlot(_ctx.$slots, "empty", {}, () => [
                            _createElementVNode("h3", null, _toDisplayString(_ctx.contextTranslate("empty.header")), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.contextTranslate("empty.message")), 1)
                        ], true)
                    ]))
                    : _createCommentVNode("", true)
            ], 64))
            : _createCommentVNode("", true)
    ], 2));
}
