import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-78915086"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-equipment-packed" };
const _hoisted_2 = {
    key: 0,
    class: "chips-wrapper"
};
const _hoisted_3 = {
    key: 1,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.equipments && _ctx.equipments.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipments, (equipment) => {
                    return (_openBlock(), _createElementBlock("span", {
                        key: equipment,
                        class: "chip"
                    }, _toDisplayString(equipment), 1));
                }), 128))
            ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.translate("no-equipment")), 1))
    ]));
}
