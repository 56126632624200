import _ from "lodash";
import { camelCase } from "change-case";
export const parseRedirectHash = () => {
    const hash = location.hash.replace("#", "");
    const search = new URLSearchParams(hash);
    const record = {};
    search.forEach((value, key) => {
        record[key] = value;
    });
    return _.mapKeys(record, (value, key) => {
        return camelCase(key);
    });
};
export const redirectToSignIn = () => {
    const url = createAuthorizeUrl();
    window.location.replace(url);
};
export const redirectToSignOut = () => {
    const url = createLogoutUrl();
    window.location.replace(url);
};
const createAuthorizeUrl = () => {
    const url = new URL(process.env.VUE_APP_AUTH_BASE_URL + "/" + process.env.VUE_APP_AUTH_TOKEN_ENDPOINT);
    url.searchParams.append("p", process.env.VUE_APP_AUTH_FLOW_DEFAULT);
    url.searchParams.append("client_id", process.env.VUE_APP_AUTH_CLIENT_ID);
    url.searchParams.append("redirect_uri", window.location.origin);
    url.searchParams.append("nonce", "null");
    url.searchParams.append("scope", "openid");
    url.searchParams.append("response_type", "id_token");
    url.searchParams.append("prompt", "login");
    return url.toString();
};
const createLogoutUrl = () => {
    // DOCS: https://docs.microsoft.com/en-us/azure/active-directory-b2c/openid-connect#send-a-sign-out-request
    const url = new URL(process.env.VUE_APP_AUTH_BASE_URL +
        "/" +
        process.env.VUE_APP_AUTH_FLOW_SIGN_IN +
        "/" +
        process.env.VUE_APP_AUTH_LOGOUT_ENDPOINT);
    url.searchParams.append("client_id", process.env.VUE_APP_AUTH_CLIENT_ID);
    url.searchParams.append("post_logout_redirect_uri", createAuthorizeUrl());
    return url.toString();
};
