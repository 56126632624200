import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { Subject } from "rxjs";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
let ToastService = class ToastService {
    messages = new Subject();
    showSuccessToast(message) {
        this.showToast({ ...message, severity: ToastSeverity.Success });
    }
    showToast(message) {
        this.messages.next(message);
    }
    onToastMessage() {
        return this.messages;
    }
};
ToastService = __decorate([
    service()
], ToastService);
export { ToastService };
