import { defineStore } from "pinia";
const initialState = {
    isTrackingPossible: false,
};
export const useAnalyticsStore = defineStore({
    id: "analytics",
    state: () => initialState,
    actions: {
        setTrackingPossible(value) {
            this.isTrackingPossible = value;
        },
    },
});
