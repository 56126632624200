var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
import validator from "validator";
let MaxFractionDigitsValidator = class MaxFractionDigitsValidator {
    translationService;
    name = "max-fraction-digits";
    arg = Number;
    constructor(translationService) {
        this.translationService = translationService;
    }
    message(max) {
        return this.translationService.translate("validation.messages.max-fraction-digits", { max: max + 1 });
    }
    validate(value, max) {
        if (!value || value.toString() === "-") {
            return true;
        }
        return validator.isDecimal(value.toString(), { decimal_digits: `0,${max}` });
    }
};
MaxFractionDigitsValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], MaxFractionDigitsValidator);
export { MaxFractionDigitsValidator };
