import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-096ada26"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "text-button" };
const _hoisted_2 = { class: "label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("button", _hoisted_1, [
        (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                name: _ctx.icon
            }, null, 8, ["name"]))
            : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
    ]));
}
