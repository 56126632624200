import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-624b3752"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_Button = _resolveComponent("Button");
    return (_openBlock(), _createBlock(_component_Button, {
        class: _normalizeClass(["button", { loading: _ctx.loading }])
    }, {
        default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : _createCommentVNode("", true)
        ]),
        _: 3
    }, 8, ["class"]));
}
