import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { NotEqual } from "@/app/modular-content/token/filter-operator.constants";
let ProductFilterService = class ProductFilterService {
    createNotEqualsFilter(value) {
        return [
            {
                propertyName: "Id",
                propertyValue: value,
                operatorValue: NotEqual,
            },
        ];
    }
};
ProductFilterService = __decorate([
    service()
], ProductFilterService);
export { ProductFilterService };
