import { formatDate } from "@/app/shared/utils/date-time.utils";
export const getStringValue = (value) => {
    if (!value || value === "") {
        return undefined;
    }
    return value;
};
export const getDateValue = (value, format) => {
    if (!value || value === "") {
        return undefined;
    }
    return formatDate(value, format);
};
export const hasValue = (value) => {
    return !(value === undefined || value === null || value.trim() === "" || value === "-");
};
