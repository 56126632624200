import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, renderList as _renderList, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "free-text"
};
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "lanes-title" };
const _hoisted_4 = { class: "bold" };
const _hoisted_5 = {
    key: 2,
    class: "d-flex gap-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_LocalizedFieldTag = _resolveComponent("LocalizedFieldTag");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.alignmentClass)
    }, [
        _createElementVNode("div", {
            class: _normalizeClass(["column-header gap-3", { 'col-6': !_ctx.gates }])
        }, [
            _renderSlot(_ctx.$slots, "header"),
            _createVNode(_component_GlobeIcon, { size: "md" })
        ], 2),
        _createElementVNode("div", {
            class: _normalizeClass(["column-description", { 'col-6': !_ctx.gates }])
        }, [
            (_ctx.freeText)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _renderSlot(_ctx.$slots, "description"),
                    (_ctx.hasLocalizedFreeText)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createTextVNode(_toDisplayString(_ctx.localizedValue) + " ", 1),
                            (_ctx.showFallbackTag)
                                ? (_openBlock(), _createBlock(_component_LocalizedFieldTag, { key: 0 }))
                                : _createCommentVNode("", true)
                        ]))
                        : _createCommentVNode("", true)
                ], 64))
                : (_ctx.gates)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.drivingLanes.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drivingLanes, (lane) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        class: "lanes",
                                        key: lane.laneNumber
                                    }, [
                                        _createElementVNode("div", _hoisted_3, [
                                            _createElementVNode("span", _hoisted_4, _toDisplayString(lane.laneNumber) + ":", 1),
                                            (_ctx.showLanesFallbackTag)
                                                ? (_openBlock(), _createBlock(_component_LocalizedFieldTag, { key: 0 }))
                                                : _createCommentVNode("", true)
                                        ]),
                                        _createElementVNode("span", null, _toDisplayString(lane.lanePurpose), 1)
                                    ]));
                                }), 128))
                            ]))
                            : (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 1 }, {
                                default: _withCtx(() => [
                                    _createTextVNode("-")
                                ]),
                                _: 1
                            }))
                    ], 64))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createTextVNode(_toDisplayString(_ctx.localizedValue) + " ", 1),
                        (_ctx.showFallbackTag)
                            ? (_openBlock(), _createBlock(_component_LocalizedFieldTag, { key: 0 }))
                            : _createCommentVNode("", true)
                    ]))
        ], 2)
    ], 2));
}
