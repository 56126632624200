var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let EcNumberValidator = class EcNumberValidator {
    translationService;
    name = "EcNumber";
    regex = new RegExp(/^\d{3}-\d{3}-\d$/);
    constructor(translationService) {
        this.translationService = translationService;
    }
    message() {
        return this.translationService.translate("validation.messages.ec-number");
    }
    validate(value) {
        const isValidEcNumber = (value) => {
            const parts = value.split("-");
            if (parts.length !== 3) {
                return false;
            }
            const digits = parts[0] + parts[1];
            const checkDigit = parseInt(parts[2]);
            const sum = Array.from(digits).reduce((acc, d, i) => acc + parseInt(d) * (i + 1), 0);
            return sum % 11 === checkDigit;
        };
        return this.regex.test(value) && isValidEcNumber(value);
    }
};
EcNumberValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], EcNumberValidator);
export { EcNumberValidator };
