import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InlineSvg = _resolveComponent("InlineSvg");
    return (_openBlock(), _createElementBlock("span", {
        class: _normalizeClass(["icon", { svg: _ctx.isSvg, [_ctx.variant]: true }])
    }, [
        (!_ctx.isSvg)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.name), 1)
            ], 64))
            : _createCommentVNode("", true),
        (_ctx.isSvg)
            ? (_openBlock(), _createBlock(_component_InlineSvg, {
                key: 1,
                href: _ctx.svgHref
            }, null, 8, ["href"]))
            : _createCommentVNode("", true)
    ], 2));
}
