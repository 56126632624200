import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-30adf9bc"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "truck-title" };
const _hoisted_2 = { class: "svg-wrapper" };
const _hoisted_3 = { class: "checkboxes-wrapper" };
const _hoisted_4 = { class: "checkbox" };
const _hoisted_5 = { class: "truck-title" };
const _hoisted_6 = { class: "svg-wrapper" };
const _hoisted_7 = { class: "checkboxes-wrapper" };
const _hoisted_8 = { class: "checkbox" };
const _hoisted_9 = { class: "checkbox" };
const _hoisted_10 = { class: "checkbox" };
const _hoisted_11 = { class: "truck-title" };
const _hoisted_12 = { class: "svg-wrapper" };
const _hoisted_13 = { class: "checkboxes-wrapper" };
const _hoisted_14 = { class: "checkbox" };
const _hoisted_15 = { class: "truck-title" };
const _hoisted_16 = { class: "svg-wrapper" };
const _hoisted_17 = { class: "checkboxes-wrapper" };
const _hoisted_18 = { class: "checkbox" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TruckTop = _resolveComponent("TruckTop");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_FormField = _resolveComponent("FormField");
    const _component_TruckRear = _resolveComponent("TruckRear");
    const _component_TruckLeft = _resolveComponent("TruckLeft");
    const _component_TruckRight = _resolveComponent("TruckRight");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass({ 'truck-widget': true, 'display-view': _ctx.displayView, 'empty-field-error': _ctx.error })
    }, [
        (_ctx.isTopViewIncluded)
            ? (_openBlock(), _createBlock(_component_FormField, {
                key: 0,
                class: "truck-box"
            }, {
                default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.translate("truck-top-view")), 1),
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_TruckTop, { "truck-connections": _ctx.truckArray }, null, 8, ["truck-connections"])
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_AppCheckbox, {
                                "model-value": _ctx.truckArray.includes('Top'),
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.updateCheckbox($event, 'Top')))
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.translate("a-top-label")), 1)
                                ]),
                                _: 1
                            }, 8, ["model-value"])
                        ])
                    ])
                ]),
                _: 1
            }))
            : _createCommentVNode("", true),
        (_ctx.isRearViewIncluded)
            ? (_openBlock(), _createBlock(_component_FormField, {
                key: 1,
                class: "truck-box"
            }, {
                default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_5, _toDisplayString(_ctx.translate("truck-rear-view")), 1),
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_TruckRear, { "truck-connections": _ctx.truckArray }, null, 8, ["truck-connections"])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_AppCheckbox, {
                                "model-value": _ctx.truckArray.includes('LeftSide'),
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (_ctx.updateCheckbox($event, 'LeftSide')))
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.translate("b-left-side-label")), 1)
                                ]),
                                _: 1
                            }, 8, ["model-value"])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_AppCheckbox, {
                                "model-value": _ctx.truckArray.includes('Middle'),
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => (_ctx.updateCheckbox($event, 'Middle')))
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.translate("c-middle-label")), 1)
                                ]),
                                _: 1
                            }, 8, ["model-value"])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_AppCheckbox, {
                                "model-value": _ctx.truckArray.includes('RightSide'),
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => (_ctx.updateCheckbox($event, 'RightSide')))
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.translate("d-right-side-label")), 1)
                                ]),
                                _: 1
                            }, 8, ["model-value"])
                        ])
                    ])
                ]),
                _: 1
            }))
            : _createCommentVNode("", true),
        (_ctx.isSideLeftViewIncluded)
            ? (_openBlock(), _createBlock(_component_FormField, {
                key: 2,
                class: "truck-box"
            }, {
                default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_11, _toDisplayString(_ctx.translate("truck-side-view-left")), 1),
                    _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_TruckLeft, { "truck-connections": _ctx.truckArray }, null, 8, ["truck-connections"])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_AppCheckbox, {
                                "model-value": _ctx.truckArray.includes('SideLeft'),
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => (_ctx.updateCheckbox($event, 'SideLeft')))
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.translate("e-side-left-label")), 1)
                                ]),
                                _: 1
                            }, 8, ["model-value"])
                        ])
                    ])
                ]),
                _: 1
            }))
            : _createCommentVNode("", true),
        (_ctx.isSideRightViewIncluded)
            ? (_openBlock(), _createBlock(_component_FormField, {
                key: 3,
                class: "truck-box"
            }, {
                default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_15, _toDisplayString(_ctx.translate("truck-side-view-right")), 1),
                    _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_TruckRight, { "truck-connections": _ctx.truckArray }, null, 8, ["truck-connections"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                            _createVNode(_component_AppCheckbox, {
                                "model-value": _ctx.truckArray.includes('SideRight'),
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => (_ctx.updateCheckbox($event, 'SideRight')))
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.translate("f-side-right-label")), 1)
                                ]),
                                _: 1
                            }, 8, ["model-value"])
                        ])
                    ])
                ]),
                _: 1
            }))
            : _createCommentVNode("", true)
    ], 2));
}
