var _a, _b;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { CompanyIdHeader } from "@/app/http/constants/custom-headers";
let LogoUploadService = class LogoUploadService {
    httpService;
    toastService;
    constructor(httpService, toastService) {
        this.httpService = httpService;
        this.toastService = toastService;
    }
    async setCompanyLogo({ companyId, file }) {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("name", file.name);
        await this.httpService.post(`companies/${companyId}/companyLogo`, formData, {
            headers: {
                [CompanyIdHeader]: companyId,
                "Content-Type": "multipart/form-data",
            },
        });
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: "domains.companies.services.company.logo-upload.toast-message",
        });
    }
};
LogoUploadService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object])
], LogoUploadService);
export { LogoUploadService };
