import _ from "lodash";
const errorName = "ReturnsWithError";
export class ReturnWithError extends Error {
    value;
    constructor(value) {
        super(`Can not use returnWith outside of a service!`);
        this.value = value;
        this.name = errorName;
    }
}
export const isReturnWithError = (val) => {
    return _.has(val, "name") && _.get(val, "name") === errorName;
};
