import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-12e66236"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "context-menu-header" };
const _hoisted_2 = {
    key: 1,
    class: "label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    const _component_Skeleton = _resolveComponent("Skeleton");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Thumbnail, { icon: _ctx.icon }, null, 8, ["icon"]),
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
            : (_ctx.label)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
                : _createCommentVNode("", true)
    ]));
}
