export const CreateNotificationModalId = "CreateNotificationModal";
export const NotificationType = {
    Default: "Default",
    Information: "Information",
    Successful: "Successful",
    Error: "Error",
    Warning: "Warning",
};
export const NotificationGroup = {
    Verification: "RequestVerification",
    Global: "Global",
};
export const NotificationIcons = [
    {
        iconName: "",
        type: "Default",
    },
    {
        iconName: "info",
        type: "Information",
    },
    {
        iconName: "check_circle",
        type: "Successful",
    },
    {
        iconName: "error",
        type: "Error",
    },
    {
        iconName: "error",
        type: "Warning",
    },
];
