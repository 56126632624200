import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-667b00b9"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "col-name" };
const _hoisted_2 = { class: "name" };
const _hoisted_3 = { class: "label" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
const _hoisted_6 = {
    key: 1,
    class: "col-role"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_UserInitials = _resolveComponent("UserInitials");
    const _component_id = _resolveComponent("id");
    const _component_Column = _resolveComponent("Column");
    const _component_Icon = _resolveComponent("Icon");
    const _component_RolesDropdown = _resolveComponent("RolesDropdown");
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_TableRowContextMenu = _resolveComponent("TableRowContextMenu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.delegate.loadPage,
        isLoading: _ctx.isLoading,
        isError: _ctx.isError,
        error: _ctx.error,
        contextTranslate: _ctx.translate,
        class: "manage-members-table"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                value: slotProps.data.results,
                rowHover: false,
                totalRecords: slotProps.data.count,
                tableFilters: _ctx.filters,
                filters: _ctx.filters,
                "onUpdate:filters": _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                loading: _ctx.isLoading,
                globalFilterFields: ['email', 'firstName', 'lastName', 'roleId'],
                removableSort: ""
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_Column, {
                        field: "id",
                        header: _ctx.translate('columns.name'),
                        sortable: "",
                        style: { "min-width": "18rem" }
                    }, {
                        body: _withCtx((slotProps) => [
                            _createElementVNode("div", _hoisted_1, [
                                _createVNode(_component_UserInitials, {
                                    user: slotProps.data,
                                    isInvited: !_ctx.isMemberRegistered(slotProps.data)
                                }, null, 8, ["user", "isInvited"]),
                                _createElementVNode("div", _hoisted_2, [
                                    _createElementVNode("div", _hoisted_3, [
                                        (!_ctx.isMemberRegistered(slotProps.data))
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.translate("not-registred-user")), 1))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(slotProps.data.firstName) + " " + _toDisplayString(slotProps.data.lastName), 1))
                                    ]),
                                    _createVNode(_component_id, { "with-prefix": false }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(slotProps.data.email), 1)
                                        ]),
                                        _: 2
                                    }, 1024)
                                ])
                            ])
                        ]),
                        _: 2
                    }, 1032, ["header"]),
                    _createVNode(_component_Column, {
                        field: "roleId",
                        style: { "min-width": "10rem" }
                    }, {
                        header: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("columns.role")) + " ", 1),
                            _createVNode(_component_Icon, {
                                name: "info",
                                class: "info-icon",
                                variant: "outlined",
                                onClick: _ctx.openPermissionRightsModal
                            }, null, 8, ["onClick"])
                        ]),
                        body: _withCtx(({ data }) => [
                            (_ctx.roleCheck(_ctx.$permissions.isAdmin, _ctx.companyId) && !_ctx.company?.managedByExternalCompany)
                                ? (_openBlock(), _createBlock(_component_RolesDropdown, {
                                    key: 0,
                                    roles: _ctx.roles,
                                    selected: data.roleId,
                                    onUpdate: ($event) => (_ctx.$emit('setRole', $event, data))
                                }, null, 8, ["roles", "selected", "onUpdate"]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.getSelectedValue(data.roleId)), 1))
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_Column, {
                        class: "not-resizable-column",
                        style: { "min-width": "6rem" }
                    }, {
                        body: _withCtx((slotProps) => [
                            (_ctx.isContextMenuAvailable(slotProps.data.email))
                                ? (_openBlock(), _createBlock(_component_TableRowContextMenu, {
                                    key: 0,
                                    data: slotProps.data,
                                    contextTranslate: _ctx.translate,
                                    ref: "dropdownMenuOverlay",
                                    notEditable: ""
                                }, {
                                    items: _withCtx((contextMenuSlotProps) => [
                                        (_ctx.isCurrentUser(slotProps.data.email))
                                            ? (_openBlock(), _createBlock(_component_MenuItem, {
                                                key: 0,
                                                label: _ctx.translate('components.leave-company'),
                                                onClick: () => _ctx.onLeaveCompany(slotProps.data, contextMenuSlotProps)
                                            }, null, 8, ["label", "onClick"]))
                                            : (_ctx.roleCheck(_ctx.$permissions.isAdmin, _ctx.companyId))
                                                ? (_openBlock(), _createBlock(_component_MenuItem, {
                                                    key: 1,
                                                    label: _ctx.translate('components.remove-user'),
                                                    onClick: () => _ctx.onRemoveUser(slotProps.data, contextMenuSlotProps)
                                                }, null, 8, ["label", "onClick"]))
                                                : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                }, 1032, ["data", "contextTranslate"]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 2
                    }, 1024)
                ]),
                _: 2
            }, 1032, ["value", "totalRecords", "tableFilters", "filters", "loading"])
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
