import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d0309b14"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-infrastructures-nearby" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 col-xl-4" };
const _hoisted_4 = { class: "title" };
const _hoisted_5 = { class: "col-6 col-xl-3" };
const _hoisted_6 = { class: "title" };
const _hoisted_7 = { class: "col-6 col-xl-4" };
const _hoisted_8 = { class: "col-6 col-xl-3 input-wrapper" };
const _hoisted_9 = { class: "units" };
const _hoisted_10 = { class: "col-6 col-xl-3 offset-6 offset-xl-4" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.translate("components.edit-infrastructures-nearby.infrastructure-titles")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("h6", _hoisted_6, _toDisplayString(_ctx.translate("components.edit-infrastructures-nearby.distance-titles")), 1)
                        ])
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infrastructureConfig, (it) => {
                        return (_openBlock(), _createBlock(_component_FormField, {
                            class: "row align-items-center",
                            key: it.translateKey
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_7, [
                                    _createVNode(_component_InputLabel, { class: "label-text" }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate(it.translateKey)), 1)
                                        ]),
                                        _: 2
                                    }, 1024)
                                ]),
                                _createElementVNode("div", _hoisted_8, [
                                    _createVNode(_component_InputNumber, {
                                        name: it.nameValue,
                                        min: 0,
                                        mode: "decimal",
                                        maxFractionDigits: 2,
                                        placeholder: _ctx.translate('components.edit-infrastructures-nearby.distance-placeholder')
                                    }, null, 8, ["name", "placeholder"]),
                                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.translate("measurement.kilometers")), 1)
                                ]),
                                _createElementVNode("div", _hoisted_10, [
                                    _createVNode(_component_FormError, {
                                        name: it.nameValue
                                    }, null, 8, ["name"])
                                ])
                            ]),
                            _: 2
                        }, 1024));
                    }), 128))
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
