import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-256b8c40"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "certified-cargo-securing-system-input" };
const _hoisted_2 = { class: "globe-icon-wrapper-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_LocalizedInput = _resolveComponent("LocalizedInput");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial,
        errors: _ctx.errors
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h6", null, _toDisplayString(_ctx.translate("certified-cargo-securing-system")), 1),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("certified-cargo-securing-system")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_RadioButton, {
                            name: "isRequired",
                            dictionary: _ctx.certifiedCargoSecuringSystemRequired,
                            dictionaryId: "CertifiedCargoSecuringSystemRequired"
                        }, null, 8, ["dictionary"])
                    ]),
                    _: 1
                }),
                (_ctx.model.isRequired === 'RequiredOnlyInCaseOf')
                    ? (_openBlock(), _createBlock(_component_FormField, {
                        key: 0,
                        class: "col-7"
                    }, {
                        default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_InputText, {
                                name: "requiredOnlyInCaseOf",
                                placeholder: _ctx.translate('required-only-in-case-of-placeholder')
                            }, null, 8, ["placeholder"]), [
                                [_directive_default_validation]
                            ]),
                            _createVNode(_component_FormError, { name: "requiredOnlyInCaseOf" })
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("system-to-be-provided-by")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_RadioButton, {
                            name: "providedBy",
                            dictionary: _ctx.loadSecuringMaterialProvidedBy,
                            dictionaryId: "LoadSecuringMaterialProvidedBy"
                        }, null, 8, ["dictionary"])
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-7" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("system-specification")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_AppMultiSelect, {
                            name: "systemSpecification",
                            options: _ctx.systemSpecification,
                            optionValue: "id",
                            optionLabel: "name",
                            dictionaryId: "SystemSpecification",
                            display: "chip",
                            placeholder: _ctx.translate('enter-system-specification')
                        }, null, 8, ["options", "placeholder"])
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("phrases.additional-information")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_GlobeIcon, { size: "sm" })
                        ]),
                        _createVNode(_component_LocalizedInput, {
                            name: "additionalInformation",
                            placeholder: _ctx.translate('phrases.additional-information')
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_FormError, { name: "cargoSecuringMaterial.certifiedCargoSecuringSystem.additionalInformation" })
                    ]),
                    _: 1
                })
            ])
        ]),
        _: 1
    }, 8, ["modelValue", "initial", "errors"]));
}
