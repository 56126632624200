import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import DisplayWeighingBridgesComponent from "@/app/sub-modules/weighing-bridges/components/display-weighing-bridges.component.vue";
import EditWeighingBridgesComponent from "@/app/sub-modules/weighing-bridges/components/edit-weighing-bridges.component.vue";
import { FacilitiesModuleId } from "@/app/modules/facilities/facilities.module";
export const WeighingBridgesSubModule = (domainId) => {
    const id = "weighingBridges";
    const moduleId = FacilitiesModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditWeighingBridgesComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayWeighingBridgesComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "svg/weighing-bridges",
                editTitle: "sub-modules.weighing-bridges.name-edit",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.weighing-bridges" }],
    });
};
