import { reactive } from "vue";
import _ from "lodash";
import { ReactivePropertiesMetadata } from "@/di/metadata/vue-metadata";
export const reactivePropertiesSetup = (target) => {
    const instance = target;
    const props = ReactivePropertiesMetadata.get(target);
    if (props.length === 0) {
        return target;
    }
    props.forEach((prop) => {
        let reactiveProp = reactive(_.get(target, prop));
        instance.__defineGetter__(prop, () => reactiveProp);
        instance.__defineSetter__(prop, (v) => (reactiveProp = reactive(v)));
    });
    return target;
};
