import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "d-flex flex-column" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "d-flex flex-column" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ProductsTableCellProductName = _resolveComponent("ProductsTableCellProductName");
    const _component_Column = _resolveComponent("Column");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    return (_openBlock(), _createBlock(_component_AppTable, {
        class: "assigned-product-masters-table",
        delegate: _ctx.delegate,
        contextTranslate: _ctx.translate,
        isLoading: _ctx.isLoading,
        isError: _ctx.isDataError,
        error: _ctx.dataError,
        onReload: _ctx.loadDataMethod
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                ref: "table",
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onFiltersUpdate
                ],
                visibleColumns: _ctx.visibleColumns,
                "onUpdate:visibleColumns": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.visibleColumns) = $event)),
                    _ctx.delegate.setColumns
                ],
                value: slotProps.data.results,
                columnsToggleDefault: _ctx.columnsToggleDefault,
                tableFilters: _ctx.filters,
                filterDisplay: "row",
                totalRecords: slotProps.data.count,
                first: _ctx.params.first,
                lazy: true,
                loading: _ctx.isLoading,
                onPage: _cache[2] || (_cache[2] = ($event) => (_ctx.onPageChange($event))),
                onRowClick: _ctx.onRowClick
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_Column, {
                        field: "name",
                        header: _ctx.translate('columns.name'),
                        style: { "min-width": "16rem" }
                    }, {
                        body: _withCtx((slotProps) => [
                            _createVNode(_component_ProductsTableCellProductName, {
                                product: slotProps.data
                            }, null, 8, ["product"])
                        ]),
                        _: 2
                    }, 1032, ["header"]),
                    (_ctx.isColumnVisible('id'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            field: "id",
                            header: _ctx.translateColumnHeader('id'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx((slotProps) => [
                                _createTextVNode(_toDisplayString(slotProps.data.id ?? "-"), 1)
                            ]),
                            _: 2
                        }, 1032, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('productType'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 1,
                            field: "productType",
                            header: _ctx.translateColumnHeader('productType'),
                            filterMatchMode: "equals",
                            showFilterMenu: false,
                            showClearButton: false,
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(_ctx.translateProductType(data.productType)), 1)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    options: _ctx.productTypes,
                                    optionLabel: "name",
                                    optionValue: "id",
                                    dictionaryId: "ProductType",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.assigned-product-master-type'),
                                    onChange: filterCallback
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "onChange"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('entityCreationDate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 2,
                            field: "entityCreationDate",
                            header: _ctx.translateColumnHeader('entityCreationDate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_1, [
                                    (data.createdTime && data.createdBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.createdTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.translateUser(data.createdBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_3, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('updatedTime'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 3,
                            field: "updatedTime",
                            header: _ctx.translateColumnHeader('updatedTime'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_4, [
                                    (data.updatedTime && data.updatedBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.updatedTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.translateUser(data.updatedBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true)
                ]),
                _: 2
            }, 1032, ["filters", "visibleColumns", "value", "columnsToggleDefault", "tableFilters", "totalRecords", "first", "loading", "onUpdate:visibleColumns", "onUpdate:filters", "onRowClick"])
        ]),
        _: 1
    }, 8, ["delegate", "contextTranslate", "isLoading", "isError", "error", "onReload"]));
}
