import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-80e45c5a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-product-group-assignment" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_5 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_6 = { class: "row" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_9 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_10 = { class: "row" };
const _hoisted_11 = { class: "col-6 column-header" };
const _hoisted_12 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_13 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_14 = { class: "row" };
const _hoisted_15 = { class: "col-6 column-header" };
const _hoisted_16 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_17 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_18 = { class: "row" };
const _hoisted_19 = { class: "col-6 column-header" };
const _hoisted_20 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_21 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_22 = { class: "row" };
const _hoisted_23 = { class: "col-6 column-header" };
const _hoisted_24 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_25 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_26 = { class: "row" };
const _hoisted_27 = { class: "col-6 column-header" };
const _hoisted_28 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_29 = {
    key: 1,
    class: "col-6 column-value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("ec-numbers")), 1),
            (_ctx.model?.ecNumbers?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.ecNumbers, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(tag), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, "-"))
        ]),
        _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("cas-numbers")), 1),
            (_ctx.model?.casNumbers?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.casNumbers, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(tag), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
        ]),
        _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate("chemical-classification")), 1),
            (_ctx.model?.chemicalAttributes?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.chemicalAttributes, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(_ctx.getValue(tag, "ChemicalAttribute")), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, "-"))
        ]),
        _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.translate("physical-chemical-classification")), 1),
            (_ctx.model?.physicalChemicalAttributes?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.physicalChemicalAttributes, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(_ctx.getValue(tag, "PhysicalChemicalAttribute")), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_17, "-"))
        ]),
        _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.translate("toxicological-classification")), 1),
            (_ctx.model?.toxicologicalAttributes?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.toxicologicalAttributes, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(_ctx.getValue(tag, "ToxicologicalAttribute")), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_21, "-"))
        ]),
        _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.translate("application-and-or-origin")), 1),
            (_ctx.model?.applicationAttributes?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.applicationAttributes, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(_ctx.getValue(tag, "ProductApplication")), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_25, "-"))
        ]),
        _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.translate("other-attributes")), 1),
            (_ctx.model?.otherAttributes?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.otherAttributes, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(_ctx.getValue(tag, "OtherAttribute")), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_29, "-"))
        ])
    ]));
}
