import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpRequestInterceptorToken } from "@/app/http/tokens/http-request-interceptor.token";
let ApiSubscriptionKeyInterceptorService = class ApiSubscriptionKeyInterceptorService {
    interceptRequest(request, next) {
        request.headers = {
            ...request.headers,
            "Ocp-Apim-Subscription-Key": process.env.VUE_APP_API_SUBSCRIPTION_KEY,
        };
        return next(request);
    }
};
ApiSubscriptionKeyInterceptorService = __decorate([
    service({ token: HttpRequestInterceptorToken })
], ApiSubscriptionKeyInterceptorService);
export { ApiSubscriptionKeyInterceptorService };
