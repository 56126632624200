var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { InjectionScope } from "@/di/types/scope";
import { DomainMetadata } from "@/app/modular-content/type/domain-metadata.type";
import { returnWith } from "@/app/shared/functions/return-with";
import { inject } from "@/di/decorators/inject.decorator";
import { DomainIdToken, DomainMetadataToken } from "@/app/modular-content/token/modular-content.token";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { DomainRouteService } from "@/app/modular-content/services/domain-route.service";
import { RestClientService } from "@/app/http/services/rest-client.service";
import { TranslationService } from "@/app/translation/services/translation.service";
import { useEntityStore } from "@/app/modular-content/stores/entity.state";
import { RouterService } from "@/app/shared/services/router.service";
let DomainService = class DomainService {
    domainId;
    metadata;
    toastService;
    domainRouteService;
    translationService;
    api;
    routerService;
    store = useEntityStore();
    constructor(domainId, metadata, toastService, domainRouteService, translationService, api, routerService) {
        this.domainId = domainId;
        this.metadata = metadata;
        this.toastService = toastService;
        this.domainRouteService = domainRouteService;
        this.translationService = translationService;
        this.api = api;
        this.routerService = routerService;
    }
    getDomainId() {
        return this.domainId;
    }
    getMetadata() {
        return this.metadata;
    }
    getBaseUrl() {
        return this.getMetadata().baseUrlCallback(this.routerService);
    }
    async create(dto) {
        const callback = () => this.api.create(dto);
        const entity = (await this.store.load(callback));
        this.store.byId[entity.id] = entity;
        const translationPrefix = typeof this.metadata.domainTranslationPrefix === "function"
            ? this.metadata.domainTranslationPrefix(this.routerService)
            : this.metadata.domainTranslationPrefix; //
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate(`modular-content.services.domain.create.${translationPrefix}-toast-message`),
        });
        return entity;
    }
    getData() {
        return this.store.data;
    }
    async loadCurrent() {
        const id = this.getCurrentId() ?? returnWith();
        await this.loadById(id);
    }
    getCurrent() {
        const id = this.getCurrentId() ?? returnWith();
        return this.getById(id);
    }
    getCurrentId() {
        return this.domainRouteService.getCurrentEntityId();
    }
    getById(id) {
        return this.store.getEntityById(id);
    }
    async update(id, dto) {
        const callback = () => this.api.update(id, dto);
        const entity = await this.store.load(callback);
        this.store.byId[id] = entity;
        return entity;
    }
    async loadData() {
        const callback = () => this.api.getData();
        const data = await this.store.load(callback);
        this.store.data = data;
    }
    async loadById(id) {
        const callback = () => this.api.get(id);
        const entity = await this.store.load(callback);
        this.store.byId[id] = entity;
    }
    async deleteById(id) {
        await this.api.delete(id);
    }
};
DomainService = __decorate([
    service({ scope: InjectionScope.Token }),
    __param(0, inject(DomainIdToken)),
    __param(1, inject(DomainMetadataToken)),
    __metadata("design:paramtypes", [String, typeof (_a = typeof DomainMetadata !== "undefined" && DomainMetadata) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object, typeof (_c = typeof DomainRouteService !== "undefined" && DomainRouteService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof RestClientService !== "undefined" && RestClientService) === "function" ? _e : Object, typeof (_f = typeof RouterService !== "undefined" && RouterService) === "function" ? _f : Object])
], DomainService);
export { DomainService };
