import { createModule } from "@/di/module";
import { GatesModuleId } from "@/app/modules/gates/gates.module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import DisplayGatesComponent from "@/app/sub-modules/gates/components/display-gates.component.vue";
import EditGatesComponent from "@/app/sub-modules/gates/components/edit-gates.component.vue";
import EditDrivingLaneModalComponent from "@/app/sub-modules/gates/components/driving-lane-widget/driving-lane-modal.component.vue";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { DrivingLaneModalId } from "@/app/sub-modules/gates/components/driving-lane-widget/driving-lane.constants";
export const GatesSubModule = (domainId) => {
    const id = "gates";
    const moduleId = GatesModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditGatesComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayGatesComponent, ...componentSuffixes);
    const modalComponent = suffixComponentName(EditDrivingLaneModalComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, modalComponent],
        imports: [
            ModalModule(DrivingLaneModalId, EditDrivingLaneModalComponent),
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "svg/gates",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.gates" }],
    });
};
