var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { DomainService } from "@/app/modular-content/services/domain.service";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { TranslationService } from "@/app/translation/services/translation.service";
let AssignedProductService = class AssignedProductService {
    httpService;
    domainService;
    toastService;
    translationService;
    constructor(httpService, domainService, toastService, translationService) {
        this.httpService = httpService;
        this.domainService = domainService;
        this.toastService = toastService;
        this.translationService = translationService;
    }
    async assignProductsToProductMaster(products, productMasterId) {
        const payload = {
            productMasterId,
            productIds: this.getProductIds(products),
        };
        await this.httpService.post(`${this.getBaseUrl()}/assignProductMaster`, payload);
        this.toastService.showSuccessToast({
            message: this.translationService.translate("domains.assigned-products.services.assign-products-to-product-master.toast-message.success"),
        });
        return this.updateProducts(products, { productMasterId });
    }
    async removeProductFromProductMaster(product) {
        const payload = {
            productId: this.getProductId(product),
        };
        await this.httpService.post(`${this.getBaseUrl()}/removeProductMaster`, payload);
        this.toastService.showSuccessToast({
            message: this.translationService.translate("domains.assigned-products.services.remove-product-from-product-master.toast-message.success"),
        });
        return this.updateProduct(product, { productMasterId: undefined });
    }
    getProductIds(products) {
        return products.map((product) => this.getProductId(product));
    }
    getProductId(product) {
        return product.id;
    }
    updateProducts(products, updates) {
        return products.map((product) => this.updateProduct(product, updates));
    }
    updateProduct(product, updates) {
        return { ...product, ...updates };
    }
    getBaseUrl() {
        return this.domainService.getBaseUrl();
    }
};
AssignedProductService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof DomainService !== "undefined" && DomainService) === "function" ? _b : Object, typeof (_c = typeof ToastService !== "undefined" && ToastService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object])
], AssignedProductService);
export { AssignedProductService };
