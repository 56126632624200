import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-33f2860d"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "note" };
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        class: "assign-product-master-modal",
        modalId: _ctx.modalId,
        header: _ctx.translate('header')
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_Icon, {
                    class: "note-icon",
                    name: "info"
                }),
                _createElementVNode("p", {
                    class: "note-text",
                    innerHTML: _ctx.translate('note')
                }, null, 8, _hoisted_2)
            ]),
            _createVNode(_component_AppForm, {
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                initial: _ctx.initial,
                errors: _ctx.errors,
                onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event))
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("product-master.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_Dropdown, {
                                name: "productMasterId",
                                options: _ctx.productMasters,
                                optionLabel: "name",
                                optionValue: "id",
                                placeholder: _ctx.translate('product-master.placeholder'),
                                loading: _ctx.productMastersAreLoading,
                                required: ""
                            }, null, 8, ["options", "placeholder", "loading"]),
                            _createVNode(_component_FormError, { name: "productMasterId" })
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue", "initial", "errors"]),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        loading: _ctx.assignmentInProcess,
                        disabled: !_ctx.isValid,
                        onClick: _ctx.assignProductsToProductMasterMethod
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.confirm")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "disabled", "onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modalId", "header"]));
}
