import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4e019be6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "slat-boards-input element" };
const _hoisted_2 = { class: "mt-5" };
const _hoisted_3 = { class: "sub-element" };
const _hoisted_4 = { class: "globe-icon-wrapper-sm" };
const _hoisted_5 = { class: "sub-element" };
const _hoisted_6 = { class: "globe-icon-wrapper-sm" };
const _hoisted_7 = { class: "sub-element" };
const _hoisted_8 = { class: "globe-icon-wrapper-sm" };
const _hoisted_9 = { class: "sub-element" };
const _hoisted_10 = { class: "globe-icon-wrapper-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_FormField = _resolveComponent("FormField");
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_LocalizedInput = _resolveComponent("LocalizedInput");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial,
        errors: _ctx.errors
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.translate("slat-boards")), 1),
                _createVNode(_component_InputLabel, null, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("slat-boards-to-be-provided-by")), 1)
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_RadioButton, {
                            modelValue: _ctx.model.providedBy,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.providedBy) = $event)),
                            dictionary: _ctx.loadSecuringMaterialProvidedBy,
                            dictionaryId: "LoadSecuringMaterialProvidedBy"
                        }, null, 8, ["modelValue", "dictionary"])
                    ]),
                    _: 1
                }),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("tarpaulin-trailers")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_GlobeIcon, { size: "sm" })
                    ]),
                    _createVNode(_component_FormField, { class: "col-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_LocalizedInput, {
                                name: "tarpaulinTrailers",
                                placeholder: _ctx.translate('enter-tarpaulin-trailers')
                            }, null, 8, ["placeholder"]),
                            _createVNode(_component_FormError, { name: "cargoSecuringMaterial.slatBoards.tarpaulinTrailers" })
                        ]),
                        _: 1
                    })
                ]),
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("slat-boards-curtain-side-trailers")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_GlobeIcon, { size: "sm" })
                    ]),
                    _createVNode(_component_FormField, { class: "col-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_LocalizedInput, {
                                modelValue: _ctx.model.curtainSideTrailers,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.curtainSideTrailers) = $event)),
                                placeholder: _ctx.translate('enter-curtain-side-trailers')
                            }, null, 8, ["modelValue", "placeholder"]),
                            _createVNode(_component_FormError, { name: "cargoSecuringMaterial.slatBoards.curtainSideTrailers" })
                        ]),
                        _: 1
                    })
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("tarpaulin-superstructures")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_GlobeIcon, { size: "sm" })
                    ]),
                    _createVNode(_component_FormField, { class: "col-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_LocalizedInput, {
                                modelValue: _ctx.model.tarpaulinSuperstructures,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model.tarpaulinSuperstructures) = $event)),
                                placeholder: _ctx.translate('enter-tarpaulin-superstructures')
                            }, null, 8, ["modelValue", "placeholder"]),
                            _createVNode(_component_FormError, { name: "cargoSecuringMaterial.slatBoards.tarpaulinSuperstructures" })
                        ]),
                        _: 1
                    })
                ]),
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("box-truck")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_GlobeIcon, { size: "sm" })
                    ]),
                    _createVNode(_component_FormField, { class: "col-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_LocalizedInput, {
                                modelValue: _ctx.model.boxTruck,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.model.boxTruck) = $event)),
                                placeholder: _ctx.translate('box-truck-placeholder')
                            }, null, 8, ["modelValue", "placeholder"]),
                            _createVNode(_component_FormError, { name: "cargoSecuringMaterial.slatBoards.boxTruck" })
                        ]),
                        _: 1
                    })
                ])
            ])
        ]),
        _: 1
    }, 8, ["modelValue", "initial", "errors"]));
}
