var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { UserService } from "@/app/users/services/user.service";
import { RouteGuardToken } from "@/app/shared/tokens/route-guard.token";
let InactiveUserInterceptorService = class InactiveUserInterceptorService {
    userService;
    constructor(userService) {
        this.userService = userService;
    }
    async canActivate() {
        if (this.userService.isUserInactive()) {
            this.userService.showInactiveUserModal();
            return false;
        }
        else {
            return true;
        }
    }
};
InactiveUserInterceptorService = __decorate([
    service({ token: RouteGuardToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof UserService !== "undefined" && UserService) === "function" ? _a : Object])
], InactiveUserInterceptorService);
export { InactiveUserInterceptorService };
