import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { FacilitiesModuleId } from "@/app/modules/facilities/facilities.module";
import DisplayInfrastructuresNearby from "@/app/sub-modules/infrastructures-nearby/components/display-infrastructures-nearby.vue";
import EditInfrastructuresNearby from "@/app/sub-modules/infrastructures-nearby/components/edit-infrastructures-nearby.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
export const InfrastructuresNearbySubModule = (domainId) => {
    const id = "nearbyInfrastructures";
    const moduleId = FacilitiesModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplayInfrastructuresNearby, ...componentSuffixes);
    const editComponent = suffixComponentName(EditInfrastructuresNearby, ...componentSuffixes);
    return createModule({
        components: [displayComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "storefront",
                components: {
                    display: displayComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.nearby-infrastructures",
            },
        ],
    });
};
