import { __decorate, __metadata, __param } from "tslib";
import _ from "lodash";
import { service } from "@/di/decorators/service.decorator";
import { InjectionScope } from "@/di/types/scope";
import axios from "axios";
import { BackendErrorResolverToken } from "@/app/validation/tokens/backend-error-resolver.token";
import { injectAll } from "@/di/decorators/inject-all.decorator";
import { rootErrorPath } from "@/app/validation/constants";
let BackendValidationService = class BackendValidationService {
    errorResolvers;
    constructor(errorResolvers) {
        this.errorResolvers = errorResolvers;
    }
    createFormErrors(error) {
        if (!axios.isAxiosError(error) || !error.response?.data?.errors) {
            return [];
        }
        const formErrors = error.response.data.errors.flatMap((error) => {
            return error.paths?.map((path) => {
                const pathSegments = getPathSegments(path);
                const field = getField(pathSegments);
                const cleanedPath = cleanPath(pathSegments);
                return {
                    field,
                    path: cleanedPath,
                    condition: error.condition,
                    message: error.errorCode,
                };
            });
        });
        return resolveErrors(formErrors, this.errorResolvers);
    }
};
BackendValidationService = __decorate([
    service({ scope: InjectionScope.Global }),
    __param(0, injectAll(BackendErrorResolverToken)),
    __metadata("design:paramtypes", [Array])
], BackendValidationService);
export { BackendValidationService };
function getPathSegments(path) {
    return _.split(path, ".").slice(1);
}
function getField(pathSegments) {
    if (pathSegments.length > 2) {
        return pathSegments.slice(1).join(".");
    }
    else {
        return pathSegments[pathSegments.length - 1];
    }
}
function cleanPath(pathSegments) {
    let path = pathSegments.length >= 1 ? pathSegments[0] : rootErrorPath;
    if (/\[|\]/g.test(path)) {
        path = _.first(pathSegments) || path;
        path = removeBracketsFromSubmoduleId(path);
    }
    return path;
}
function removeBracketsFromSubmoduleId(path) {
    return path.includes(".")
        ? path.replace(/(.*?\[.*?\])\./, (match, group) => group.replace(/\[.*?\]/g, "") + ".")
        : path.replace(/\[.*?\]/g, "");
}
function resolveErrors(formErrors, errorResolvers) {
    return _.compact(formErrors.map((err) => {
        return errorResolvers.find((resolver) => resolver.canResolve(err))?.resolve(err);
    }));
}
