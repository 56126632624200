import { CompanyRole } from "@/app/shared/types/access.type";
export const Permissions = {
    belongsToCompany: [
        CompanyRole.Member,
        CompanyRole.ProductManager,
        CompanyRole.RequirementManager,
        CompanyRole.TenderManager,
        CompanyRole.DataManager,
        CompanyRole.CompanyAdmin,
        CompanyRole.LoadyAdmin,
    ],
    canCRUDEntity: [CompanyRole.DataManager, CompanyRole.CompanyAdmin, CompanyRole.LoadyAdmin],
    canManageTenders: [CompanyRole.TenderManager, CompanyRole.LoadyAdmin],
    isAdmin: [CompanyRole.CompanyAdmin, CompanyRole.LoadyAdmin],
    isLoadyAdmin: [CompanyRole.LoadyAdmin],
};
