import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b0b316e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "item" };
const _hoisted_2 = {
    key: 0,
    class: "status"
};
const _hoisted_3 = {
    key: 1,
    class: "item"
};
const _hoisted_4 = { class: "status" };
const _hoisted_5 = {
    class: "content",
    ref: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["panel", { collapsed: _ctx.collapsed }])
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
                class: "title",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.collapsed = !_ctx.collapsed))
            }, [
                _createVNode(_component_Icon, { name: "chevron_right" }),
                _createElementVNode("h4", null, _toDisplayString(_ctx.$props.title), 1)
            ]),
            (_ctx.alwaysEnabled)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.translate("always-enabled")), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.isChecked), 1),
                    _createVNode(_component_AppCheckbox, {
                        type: "toggle",
                        modelValue: _ctx.checked,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.checked) = $event))
                    }, null, 8, ["modelValue"])
                ]))
        ]),
        _createElementVNode("div", {
            class: "content-container",
            style: _normalizeStyle({ maxHeight: _ctx.collapsed ? 0 : `${_ctx.contentHeight}px` })
        }, [
            _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 512)
        ], 4)
    ], 2));
}
