import { defineStore } from "pinia";
import _ from "lodash";
const initialState = {
    allIds: [],
    byId: {},
    myCompanyIds: [],
    selectedId: undefined,
    selectedBusinessPartnerId: undefined,
};
export const useCompaniesStore = defineStore({
    id: "companies",
    state: () => initialState,
    actions: {
        setSelectedId(id) {
            this.selectedId = id;
            this.selectedBusinessPartnerId = undefined;
        },
        setSelectedBusinessPartnerId(id) {
            this.selectedBusinessPartnerId = id;
        },
        addCompany(company) {
            this.myCompanyIds.push(company.id);
            this.allIds.push(company.id);
            this.byId[company.id] = company;
        },
        setCompanies(companies) {
            this.myCompanyIds = companies.map((it) => it.id);
            this.allIds = companies.map((it) => it.id);
            this.byId = _.keyBy(companies, "id");
        },
        setCompany(id, company) {
            this.byId[id] = company;
            this.allIds = _.keys(this.byId);
        },
        removeCompanyById(id) {
            delete this.byId[id];
            this.allIds.splice(this.allIds.indexOf(id), 1);
            this.myCompanyIds.splice(this.myCompanyIds.indexOf(id), 1);
        },
    },
});
