import { ModuleModule } from "@/app/modular-content/modules/module.module";
import CopyModulesModal from "@/app/domains/assigned-products/components/copy-modules-modal.component.vue";
import { createModule } from "@/di/module";
export const SafetyAndSecurityModuleId = "safetyAndSecurity";
export const SafetyAndSecurityModule = (domainId, options) => {
    return createModule({
        imports: [
            ModuleModule({
                id: SafetyAndSecurityModuleId,
                domainId: domainId,
                navigationIcon: options?.navigationIcon,
                copyAvailable: options?.copyAvailable,
                copyTooltip: options?.copyTooltipTranslationKey,
                copyModuleModal: CopyModulesModal,
            }),
        ],
    });
};
