import TendersLanesListComponent from "@/app/domains/tenders-lanes/components/tenders-lanes-list.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import CreateLaneComponent from "@/app/domains/lanes/components/create-lane-modal.component.vue";
import PlatformLinkModalComponent from "@/app/shared/components/platform-link/components/platform-link-modal.component.vue";
import { TendersLanesDomainId, TendersLanesListId, ClosedTendersLanesListId, AddToTenderModalId, } from "@/app/domains/tenders-lanes/tenders-lanes.constants";
import { SelectedTendersLanesFilterService } from "@/app/domains/tenders-lanes/services/selected-tenders-lanes-filter.service";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { PlatformLinkService } from "@/app/shared/components/platform-link/platform-link.service";
import { PlatformLinkModalId } from "@/app/shared/components/platform-link/platform-link.constants";
import { TendersLanesService } from "@/app/domains/tenders-lanes/services/tenders-lanes.service";
import AddToTenderModal from "@/app/domains/tenders-lanes/components/add-to-tender-modal.component.vue";
import { Permissions } from "@/app/shared/constants/permissions.constants";
import { RouteMetaListId } from "@/app/modular-content/modular-content.constants";
export const TendersLanesModule = createModule({
    components: [CreateLaneComponent, TendersLanesListComponent],
    imports: [
        DomainModule({
            id: TendersLanesDomainId,
            icon: "repeat_on",
            detailsIcon: "repeat_on",
            loady4Tender: true,
            lists: [
                {
                    id: TendersLanesListId,
                    default: true,
                    filter: SelectedTendersLanesFilterService,
                    createRoles: Permissions.canManageTenders,
                },
                {
                    id: ClosedTendersLanesListId,
                    filter: SelectedTendersLanesFilterService,
                    createRoles: [],
                },
            ],
            domainTranslationPrefix: "tenders-lanes",
            baseUrl: (routerService) => {
                if (routerService?.getMeta(RouteMetaListId) === ClosedTendersLanesListId) {
                    return "tenders/archivedlanes";
                }
                return "tenders/lanes";
            },
            components: {
                createModal: CreateLaneComponent,
                table: TendersLanesListComponent,
            },
        }),
        ModalModule(PlatformLinkModalId, PlatformLinkModalComponent),
        ModalModule(AddToTenderModalId, AddToTenderModal),
    ],
    providers: [
        SelectedTendersLanesFilterService,
        PlatformLinkService,
        TendersLanesService,
        {
            token: TranslationPrefixToken,
            useValue: "domains.tenders-lanes",
        },
    ],
});
