import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4116b336"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-product-group-assignment-view" };
const _hoisted_2 = { class: "title" };
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = { class: "title" };
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = { class: "title" };
const _hoisted_7 = { class: "title" };
const _hoisted_8 = { class: "title" };
const _hoisted_9 = { class: "title" };
const _hoisted_10 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_AppChips = _resolveComponent("AppChips");
    const _component_FormError = _resolveComponent("FormError");
    const _component_ErrorMessage = _resolveComponent("ErrorMessage");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createBlock(_component_AppForm, {
                key: 1,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                initial: _ctx.initial,
                errors: _ctx.errors,
                onContext: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('context', $event)))
            }, {
                default: _withCtx(() => [
                    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate("ec-numbers")), 1),
                    _createVNode(_component_FormField, { class: "col-xl-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppChips, {
                                name: "ecNumbers",
                                placeholder: _ctx.translate('ec-numbers-placeholder'),
                                separator: [' ', '\n', ','],
                                onlyNumbersAndDash: true,
                                itemStyles: _ctx.itemStyles('ecNumbers').tokenErrors
                            }, null, 8, ["placeholder", "itemStyles"]),
                            _createVNode(_component_FormError, {
                                name: `ecNumbers[${_ctx.itemStyles('ecNumbers').errorIndex}]`
                            }, null, 8, ["name"]),
                            (_ctx.errorName('ecNumbers')?.length && _ctx.itemStyles('ecNumbers').errorIndex === null)
                                ? (_openBlock(), _createBlock(_component_ErrorMessage, { key: 0 }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("div", {
                                            innerHTML: _ctx.errorMessage('ecNumbers')
                                        }, null, 8, _hoisted_3)
                                    ]),
                                    _: 1
                                }))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }),
                    _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.translate("cas-numbers")), 1),
                    _createVNode(_component_FormField, { class: "col-xl-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppChips, {
                                name: "casNumbers",
                                placeholder: _ctx.translate('cas-numbers-placeholder'),
                                separator: [' ', '\n', ','],
                                onlyNumbersAndDash: true,
                                itemStyles: _ctx.itemStyles('casNumbers').tokenErrors
                            }, null, 8, ["placeholder", "itemStyles"]),
                            _createVNode(_component_FormError, {
                                name: `casNumbers[${_ctx.itemStyles('casNumbers').errorIndex}]`
                            }, null, 8, ["name"]),
                            (_ctx.errorName('casNumbers')?.length && _ctx.itemStyles('casNumbers').errorIndex === null)
                                ? (_openBlock(), _createBlock(_component_ErrorMessage, { key: 0 }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("div", {
                                            innerHTML: _ctx.errorMessage('casNumbers')
                                        }, null, 8, _hoisted_5)
                                    ]),
                                    _: 1
                                }))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }),
                    _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.translate("chemical-classification")), 1),
                    _createVNode(_component_FormField, { class: "col-xl-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppMultiSelect, {
                                name: "chemicalAttributes",
                                options: _ctx.dictionaries.chemicalAttribute,
                                optionValue: "id",
                                optionLabel: "name",
                                dictionaryId: "ChemicalAttribute",
                                display: "chip",
                                placeholder: _ctx.translate('chemical-classification-placeholder')
                            }, null, 8, ["options", "placeholder"])
                        ]),
                        _: 1
                    }),
                    _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.translate("physical-chemical-classification")), 1),
                    _createVNode(_component_FormField, { class: "col-xl-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppMultiSelect, {
                                name: "physicalChemicalAttributes",
                                options: _ctx.dictionaries.physicalChemicalAttribute,
                                optionValue: "id",
                                optionLabel: "name",
                                dictionaryId: "PhysicalChemicalAttribute",
                                display: "chip",
                                placeholder: _ctx.translate('physical-chemical-classification-placeholder')
                            }, null, 8, ["options", "placeholder"])
                        ]),
                        _: 1
                    }),
                    _createElementVNode("h5", _hoisted_8, _toDisplayString(_ctx.translate("toxicological-classification")), 1),
                    _createVNode(_component_FormField, { class: "col-xl-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppMultiSelect, {
                                name: "toxicologicalAttributes",
                                options: _ctx.dictionaries.toxicologicalAttribute,
                                optionValue: "id",
                                optionLabel: "name",
                                dictionaryId: "ToxicologicalAttribute",
                                display: "chip",
                                placeholder: _ctx.translate('toxicological-classification-placeholder')
                            }, null, 8, ["options", "placeholder"])
                        ]),
                        _: 1
                    }),
                    _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.translate("application-and-or-origin")), 1),
                    _createVNode(_component_FormField, { class: "col-xl-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppMultiSelect, {
                                name: "applicationAttributes",
                                options: _ctx.dictionaries.productApplication,
                                optionValue: "id",
                                optionLabel: "name",
                                dictionaryId: "ProductApplication",
                                display: "chip",
                                placeholder: _ctx.translate('application-and-or-origin-placeholder')
                            }, null, 8, ["options", "placeholder"])
                        ]),
                        _: 1
                    }),
                    _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.translate("other-attributes")), 1),
                    _createVNode(_component_FormField, { class: "col-xl-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppMultiSelect, {
                                name: "otherAttributes",
                                options: _ctx.dictionaries.otherAttribute,
                                optionValue: "id",
                                optionLabel: "name",
                                dictionaryId: "OtherAttribute",
                                display: "chip",
                                placeholder: _ctx.translate('other-attributes-placeholder')
                            }, null, 8, ["options", "placeholder"])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue", "initial", "errors"]))
    ]));
}
