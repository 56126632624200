import DOMPurify from "dompurify";
import { marked } from "marked";
export function renderMarkdown(source) {
    try {
        return DOMPurify.sanitize(marked.parse(source));
    }
    catch {
        return source;
    }
}
