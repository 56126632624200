import VerificationsListComponent from "@/app/domains/verification-requests/components/verification-requests-list.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { VerificationRequestsDomainId, VerificationRequestedListId, VerificationConfirmedListId, VerificationFeedbackDoneListId, } from "@/app/domains/verification-requests/verification-requests.constants";
import VerificationsContextMenuHeaderComponent from "@/app/domains/verification-requests/components/verification-requests-context-menu-header.component.vue";
import { SelectedUserFilterService } from "./services/selected-user-filter.service";
import { ConfirmedRequestFilterService } from "@/app/domains/verification-requests/services/confirmed-request-filter.service";
import { FeedbackDoneFilterService } from "@/app/domains/verification-requests/services/feedback-done-filter.service";
import { SummaryModule } from "@/app/domains/summary/summary.module";
import { SummaryDomainId } from "@/app/domains/summary/summary.constants";
export const VerificationRequestsModule = createModule({
    components: [VerificationsContextMenuHeaderComponent, VerificationsListComponent],
    imports: [
        DomainModule({
            id: VerificationRequestsDomainId,
            icon: "summarize",
            detailsIcon: "summarize",
            accessCondition(rolesService) {
                return rolesService?.isVerifier();
            },
            lists: [
                {
                    id: VerificationRequestedListId,
                    default: true,
                    filter: SelectedUserFilterService,
                },
                {
                    id: VerificationConfirmedListId,
                    filter: ConfirmedRequestFilterService,
                },
                {
                    id: VerificationFeedbackDoneListId,
                    filter: FeedbackDoneFilterService,
                },
            ],
            domainTranslationPrefix: "verification-requests",
            forBusinessPartner: true,
            baseUrl: "requirementprofiles/verificationView",
            readOnly: true,
            detailsDisabled: true,
            sideNavTier: 2,
            public: true,
            components: {
                contextMenuHeader: VerificationsContextMenuHeaderComponent,
                table: VerificationsListComponent,
            },
        }),
        SummaryModule(VerificationRequestsDomainId, VerificationRequestsDomainId + SummaryDomainId, { public: true }),
    ],
    providers: [
        SelectedUserFilterService,
        ConfirmedRequestFilterService,
        FeedbackDoneFilterService,
        {
            token: TranslationPrefixToken,
            useValue: "domains.verification-requests",
        },
    ],
});
