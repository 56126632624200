import { createModule } from "@/di/module";
import { createRouteId } from "@/app/modular-content/functions/create-route-id";
import { translationDomainPrefix } from "@/app/modular-content/modules/functions/translation-domain-prefix.function";
import { SideNavigationMenuItemToken } from "@/app/core/tokens/side-navigation-menu-item.token";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { contextMenuItemTokenFactory } from "@/app/shared/factories/context-menu-item-token.factory";
export const DomainMenuModule = (options) => {
    const routeDomainId = createRouteId(options.id);
    const route = options.lists?.length ? `${routeDomainId}-list` : `${routeDomainId}-page`;
    const menuItem = {
        label: `${translationDomainPrefix(options)}.menu-items.side-navigation`,
        icon: options.icon,
        route,
        accessCondition: options.accessCondition,
        condition: options.parentCondition,
        conditionOrigin: options.parent,
        sideNavTier: options.sideNavTier ?? 1,
        public: options.public ?? false,
        forBusinessPartner: options.forBusinessPartner ?? false,
        forLogisticsServiceProvider: options.forLogisticsServiceProvider ?? false,
        onlyForLogisticsServiceProvider: options.onlyForLogisticsServiceProvider ?? false,
        hideMenuItem: options.hideMenuItem ?? false,
        loady4Tender: options.loady4Tender ?? false,
    };
    if (!options.parent) {
        return createModule({
            providers: [{ token: SideNavigationMenuItemToken, useValue: menuItem }],
        });
    }
    const parentTokens = domainTokensFactory(options.parent);
    const token = contextMenuItemTokenFactory(parentTokens.contextMenu);
    return createModule({
        providers: [{ token, useValue: menuItem }],
    });
};
