import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-333ac8ed"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "info" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Button = _resolveComponent("Button");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        class: "lane-for-tender-modal",
        header: _ctx.translate('header')
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.translate("message")), 1),
            _createVNode(_component_ButtonGroup, { orientation: "horizontal" }, {
                default: _withCtx(() => [
                    _createVNode(_component_Button, {
                        class: "p-button-lg p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_Button, {
                        loading: _ctx.isLoading,
                        class: "p-button-lg",
                        onClick: _ctx.sendLane
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.confirm")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
