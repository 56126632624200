import _ from "lodash";
import { clone } from "@/app/shared/functions/clone";
import { merge } from "@/app/shared/functions/merge";
import { mergeReduce } from "@/app/shared/functions/merge-reducer";
import { isEqualDeep } from "@/app/shared/functions/is-equal-deep";
export const getChanges = (initial, current) => {
    const changed = merge(clone(initial), _.reduce(current, mergeReduce));
    return _.pickBy(changed, (value, key) => {
        const equal = isEqualDeep(value, initial[key]);
        return !equal;
    });
};
