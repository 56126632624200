export const PublicDataDomainId = "PublicData";
export const PublicDataRouteName = "public-data-list";
export const AllCompaniesListId = "all-companies";
export const AllSitesListId = "all-sites";
export const CompanyGroupsListId = "company-groups";
export const CompanyGroupsDomainPrefix = "company-groups";
export const PublicDataDomainPrefix = "public-data";
export const PublicDataRoute = "/public-data";
export const CompaniesUrl = "companies";
export const CompanyGroupsUrl = "companygroups";
export const SitesUrl = "sites/public";
