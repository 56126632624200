import LanesListComponent from "@/app/domains/lanes/components/lanes-list.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import LanesContextMenuHeaderComponent from "@/app/domains/lanes/components/lanes-context-menu-header.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import CreateLaneComponent from "@/app/domains/lanes/components/create-lane-modal.component.vue";
import EditLaneComponent from "@/app/domains/lanes/components/edit-lane-modal.component.vue";
import PlatformLinkModalComponent from "@/app/shared/components/platform-link/components/platform-link-modal.component.vue";
import Lane4TenderModalComponent from "@/app/shared/components/platform-link/components/lane-for-tender-modal.component.vue";
import { AssignPartnersModalId, LanesDomainId } from "@/app/domains/lanes/lanes.constants";
import { ArchivedLoadingSummaryDomainId, ArchivedUnloadingSummaryDomainId, } from "@/app/domains/summary/summary.constants";
import { SelectedCompanyFromOrToFilterService } from "@/app/domains/public-data/companies/services/selected-company-from-or-to-filter.service";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { PlatformLinkService } from "@/app/shared/components/platform-link/platform-link.service";
import { Lane4TenderModalId, PlatformLinkModalId } from "@/app/shared/components/platform-link/platform-link.constants";
import DropdownOptionsReloadComponent from "@/app/shared/components/dropdown-options-reload.component.vue";
import { AssignPartnersService } from "@/app/domains/lanes/assign-partners.service";
import AssignPartnersModal from "@/app/domains/lanes/components/assign-partners-modal.component.vue";
import { CompanyRole } from "@/app/shared/types/access.type";
import { SummaryModule } from "@/app/domains/summary/summary.module";
export const LanesModule = createModule({
    components: [
        LanesContextMenuHeaderComponent,
        CreateLaneComponent,
        EditLaneComponent,
        LanesListComponent,
        DropdownOptionsReloadComponent,
        PlatformLinkModalComponent,
        Lane4TenderModalComponent,
    ],
    imports: [
        DomainModule({
            id: LanesDomainId,
            icon: "repeat_on",
            detailsIcon: "repeat_on",
            createRoles: [CompanyRole.TenderManager],
            lists: [
                {
                    id: "company",
                    default: true,
                    filter: SelectedCompanyFromOrToFilterService,
                },
            ],
            domainTranslationPrefix: "lanes",
            baseUrl: "lanes",
            components: {
                contextMenuHeader: LanesContextMenuHeaderComponent,
                createModal: CreateLaneComponent,
                editModal: EditLaneComponent,
                table: LanesListComponent,
            },
        }),
        ModalModule(PlatformLinkModalId, PlatformLinkModalComponent),
        ModalModule(Lane4TenderModalId, Lane4TenderModalComponent),
        ModalModule(AssignPartnersModalId, AssignPartnersModal),
        SummaryModule(LanesDomainId, ArchivedLoadingSummaryDomainId),
        SummaryModule(LanesDomainId, ArchivedUnloadingSummaryDomainId),
    ],
    providers: [
        SelectedCompanyFromOrToFilterService,
        PlatformLinkService,
        AssignPartnersService,
        {
            token: TranslationPrefixToken,
            useValue: "domains.lanes",
        },
    ],
});
