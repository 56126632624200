import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import Edit from "@/app/sub-modules/hose/components/edit-hose.component.vue";
import Display from "@/app/sub-modules/hose/components/display-hose.component.vue";
import DisplayView from "@/app/sub-modules/hose/components/display-hose-view.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { TechnicalRequirementsModuleId } from "@/app/modules/technical-requirements/technical-requirements.module";
const moduleId = TechnicalRequirementsModuleId;
const id = "hose";
export const HoseSubModule = (domainId) => {
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(Edit, ...componentSuffixes);
    const displayComponent = suffixComponentName(Display, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayView, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "gesture",
                showChangeHistory: true,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.hose" }],
    });
};
