import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { BaseTranslationService } from "@/app/translation/services/base-translation.service";
let TranslationService = class TranslationService extends BaseTranslationService {
    getTranslationsPath(language) {
        return `/translations/LOADYFRONTEND.${language.toUpperCase()}.json`;
    }
};
TranslationService = __decorate([
    service()
], TranslationService);
export { TranslationService };
