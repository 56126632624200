export class ValidationError extends Error {
    validator;
    path;
    value;
    arg;
    constructor(validator, path, value, arg) {
        super(validator.message(arg));
        this.validator = validator;
        this.path = path;
        this.value = value;
        this.arg = arg;
        this.name = "ValidationError";
    }
}
