var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { AuthService } from "@/app/auth/services/auth.service";
import { CompanyIdHeader, UserIdHeader } from "@/app/http/constants/custom-headers";
import { HttpRequestInterceptorToken } from "@/app/http/tokens/http-request-interceptor.token";
let AuthLocalIdentityInterceptorService = class AuthLocalIdentityInterceptorService {
    authService;
    constructor(authService) {
        this.authService = authService;
    }
    interceptRequest(request, next) {
        if (this.authService.isLocalModeEnabled()) {
            request.headers = {
                ...request.headers,
                [CompanyIdHeader]: this.authService.getLocalCompanyId(),
                [UserIdHeader]: this.authService.getLocalUserId(),
            };
        }
        return next(request);
    }
};
AuthLocalIdentityInterceptorService = __decorate([
    service({ token: HttpRequestInterceptorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof AuthService !== "undefined" && AuthService) === "function" ? _a : Object])
], AuthLocalIdentityInterceptorService);
export { AuthLocalIdentityInterceptorService };
