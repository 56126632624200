var _a, _b, _c, _d;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { VerificationStatus } from "@/app/domains/requirement-profiles/enums/status-verification.enum";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { ProcessingItemsService } from "@/app/shared/services/processing-items.service";
import { RouterService } from "@/app/shared/services/router.service";
import { reactive } from "@/di/decorators/computed.decorator";
import { BaseUrlCallback } from "@/app/modular-content/modules/types/domain-module.types";
import { RestClientBaseUrlCallbackToken } from "@/app/http/tokens/rest-client-base-url-callback.token";
import { inject } from "@/di/decorators/inject.decorator";
let SummaryVerificationService = class SummaryVerificationService {
    httpClient;
    routerService;
    processingItemsService;
    baseUrlCallback;
    feedbacks = [];
    flags = {
        siteChecked: false,
        unloadingPointChecked: true,
        productChecked: false,
    };
    constructor(httpClient, routerService, processingItemsService, baseUrlCallback) {
        this.httpClient = httpClient;
        this.routerService = routerService;
        this.processingItemsService = processingItemsService;
        this.baseUrlCallback = baseUrlCallback;
    }
    getVerificationId() {
        return this.routerService.getParam("verificationRequestsId") ?? "";
    }
    getEntityId() {
        return this.routerService.getMeta("entityId") ?? "";
    }
    getFlags() {
        return this.flags;
    }
    getFeedbacksPerEntity() {
        return this.feedbacks.filter((it) => it.entityType === this.getEntityId());
    }
    getFeedbacks() {
        return this.feedbacks;
    }
    async getVerificationSummaryPerEntity() {
        return await this.httpClient.get(`requirementprofiles/verificationView/${this.getVerificationId()}/entities/${this.getEntityId()}`);
    }
    async getSummaryPerEntity() {
        return await this.httpClient.get(`${this.getBaseUrl()}/${this.getEntityId()}`);
    }
    async confirm() {
        await this.httpClient.post("requirementprofiles/changeStatus", {
            id: this.getVerificationId(),
            statusVerification: VerificationStatus.Confirmed,
        });
        this.processingItemsService.add({ id: this.getVerificationId(), statusVerification: VerificationStatus.Confirmed });
    }
    async sendInfo() {
        await this.httpClient.post("requirementprofiles/changeStatus", {
            id: this.getVerificationId(),
            statusVerification: VerificationStatus.Info,
        });
        this.processingItemsService.add({ id: this.getVerificationId(), statusVerification: VerificationStatus.Info });
    }
    async loadFeedbacks() {
        this.feedbacks = await this.httpClient.get(`requirementprofiles/${this.getVerificationId()}/prefeedbacks`);
    }
    getUpdatedFeedbackList(list, feedback) {
        const conditionCheck = (it) => it.entityType === feedback.entityType && it.sectionType === feedback.sectionType;
        const feedbackExists = list.find(conditionCheck);
        if (feedbackExists) {
            list = list.map((it) => (conditionCheck(it) ? feedback : it));
        }
        else {
            list.push(feedback);
        }
        return list;
    }
    async sendFeedback(feedback) {
        const verificationRequestId = this.getVerificationId();
        await this.httpClient.post(`requirementprofiles/${verificationRequestId}/prefeedbacks`, feedback);
        const newFeedback = {
            entityType: feedback.entityType,
            sectionType: feedback.sectionType,
            feedback: feedback.feedback,
        };
        this.feedbacks = this.getUpdatedFeedbackList(this.feedbacks, newFeedback);
    }
    async loadFlags() {
        this.flags = await this.httpClient.get(`requirementprofiles/${this.getVerificationId()}/flags`);
    }
    async setFlag(flags) {
        const newFlags = { ...this.flags, ...flags };
        await this.httpClient.post(`requirementprofiles/${this.getVerificationId()}/flags`, newFlags);
        this.flags = newFlags;
    }
    getBaseUrl() {
        return this.baseUrlCallback(this.routerService);
    }
};
__decorate([
    reactive(),
    __metadata("design:type", Array)
], SummaryVerificationService.prototype, "feedbacks", void 0);
SummaryVerificationService = __decorate([
    service(),
    __param(3, inject(RestClientBaseUrlCallbackToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof RouterService !== "undefined" && RouterService) === "function" ? _b : Object, typeof (_c = typeof ProcessingItemsService !== "undefined" && ProcessingItemsService) === "function" ? _c : Object, typeof (_d = typeof BaseUrlCallback !== "undefined" && BaseUrlCallback) === "function" ? _d : Object])
], SummaryVerificationService);
export { SummaryVerificationService };
