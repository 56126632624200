import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7e98297e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-sealing" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = {
    key: 0,
    class: "section"
};
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-6 column-header" };
const _hoisted_6 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_LocalizedField, {
                modelValue: _ctx.model.sealingOfVehicleInSomeCases,
                freeText: ""
            }, {
                header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("sealing-of-vehicle-connections")), 1)
                ]),
                description: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getValue(_ctx.model?.sealingOfVehicleConnections, "SealingOfVehicleConnections")), 1)
                ]),
                _: 1
            }, 8, ["modelValue"])
        ]),
        (_ctx.sealingRequired)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("sealing-executed-by")), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getValue(_ctx.model?.executedBy, "SealingIsDoneByWhom")), 1)
                ])
            ]))
            : _createCommentVNode("", true)
    ]));
}
