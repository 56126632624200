import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        class: _normalizeClass({
            'p-invalid': _ctx.hasError,
            invalid: _ctx.hasError,
            required: _ctx.isRequired,
            disabled: _ctx.disabled,
            touched: _ctx.touched,
            dirty: _ctx.dirty,
        }),
        disabled: _ctx.disabled,
        invalid: _ctx.hasError,
        errors: _ctx.errors,
        "data-qa-id": _ctx.name,
        onChange: _ctx.resetErrors,
        onFocusout: _ctx.markTouched,
        onSkipFirstTouch: _ctx.skipFirstTouch
    }, {
        default: _withCtx(() => [
            (_ctx.$slots.default)
                ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
                : _createCommentVNode("", true)
        ]),
        _: 3
    }, 40, ["modelValue", "class", "disabled", "invalid", "errors", "data-qa-id", "onChange", "onFocusout", "onSkipFirstTouch"]));
}
