import _ from "lodash";
const keyPrefix = "di:constructor-parameter";
const parameterTokenKey = (index) => `${keyPrefix}:${index}:token`;
const parameterMultiKey = (index) => `${keyPrefix}:${index}:multi`;
export const setConstructorParameterInjectionToken = (constructor, index, token, multi = false) => {
    Reflect.defineMetadata(parameterTokenKey(index), token, constructor);
    Reflect.defineMetadata(parameterMultiKey(index), multi, constructor);
};
const hasParameterMetadata = (constructor, index) => {
    return Reflect.hasMetadata(parameterTokenKey(index), constructor);
};
const isClass = (type) => {
    return _.isFunction(type) && _.has(type, "name");
};
const getConstructorParameterMetadata = (constructor, index, type) => {
    if (!hasParameterMetadata(constructor, index)) {
        if (isClass(type)) {
            return {
                token: type.name,
                multi: false,
            };
        }
        return undefined;
    }
    const token = Reflect.getMetadata(parameterTokenKey(index), constructor);
    const multi = Reflect.getMetadata(parameterMultiKey(index), constructor);
    return {
        token,
        multi,
    };
};
export const getConstructorParametersMetadata = (constructor) => {
    const parameters = Reflect.getMetadata("design:paramtypes", constructor) ?? [];
    return parameters.map((it, index) => {
        const knownMeta = getConstructorParameterMetadata(constructor, index, it) ?? {};
        return {
            type: it,
            ...knownMeta,
        };
    });
};
