import { pascalCase } from "change-case";
import EmptyComponent from "@/app/shared/components/empty.component.vue";
export const useComponent = (self, component = EmptyComponent) => {
    if (!self) {
        throw new Error("No component instance available");
    }
    const componentName = pascalCase(`${component.name}`);
    if (!self.$options.components) {
        self.$options.components = {};
    }
    self.$options.components[componentName] = component;
    return componentName;
};
