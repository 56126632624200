export var Weekday;
(function (Weekday) {
    Weekday["Monday"] = "monday";
    Weekday["Tuesday"] = "tuesday";
    Weekday["Wednesday"] = "wednesday";
    Weekday["Thursday"] = "thursday";
    Weekday["Friday"] = "friday";
    Weekday["Saturday"] = "saturday";
    Weekday["Sunday"] = "sunday";
})(Weekday || (Weekday = {}));
