import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_FormField = _resolveComponent("FormField");
    const _component_SearchDropdown = _resolveComponent("SearchDropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_DropdownOptionsReload = _resolveComponent("DropdownOptionsReload");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        header: _ctx.translate('header'),
        "modal-id": _ctx.modalId,
        class: "copy-modules-modal"
    }, {
        default: _withCtx(() => [
            (_ctx.loadMethod.isLoading.value)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                    initial: _ctx.initial,
                    onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event))
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("select-modules-label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_AppMultiSelect, {
                                    name: "modules",
                                    options: _ctx.availableModules,
                                    optionValue: "id",
                                    optionLabel: "name",
                                    display: "chip",
                                    placeholder: _ctx.translate('select-modules-placeholder'),
                                    required: ""
                                }, null, 8, ["options", "placeholder"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("global.phrases.select-company-label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_SearchDropdown, {
                                    name: "companyId",
                                    initial: _ctx.initialCompany,
                                    optionValue: "id",
                                    optionLabel: "name",
                                    additionalLabel: "referenceNumber",
                                    filterFields: ['referenceNumber'],
                                    placeholder: _ctx.translate('global.phrases.select-company-placeholder'),
                                    searchService: _ctx.companiesSearchService,
                                    searchSelect: ['id', 'name', 'referenceNumber'],
                                    searchFields: ['name', 'referenceNumber'],
                                    searchMode: "all",
                                    searchInclude: "OnlyMineOrOnlyBusinessPartners",
                                    required: ""
                                }, null, 8, ["initial", "placeholder", "searchService"]),
                                _createVNode(_component_FormError, { name: "companyId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("global.phrases.select-site-label")), 1)
                                    ]),
                                    _: 1
                                }),
                                (_ctx.companySitesLoad.isError.value)
                                    ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                        key: 0,
                                        onClick: _ctx.companySitesLoad.loadMethod
                                    }, null, 8, ["onClick"]))
                                    : (_openBlock(), _createBlock(_component_Dropdown, {
                                        key: 1,
                                        name: "siteId",
                                        disabled: !_ctx.selectedCompanyId || _ctx.companySitesLoad.isLoading.value,
                                        loading: _ctx.companySitesLoad.isLoading.value,
                                        options: _ctx.companySites,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        additionalLabel: "referenceNumber",
                                        filterFields: ['referenceNumber'],
                                        placeholder: _ctx.translate('global.phrases.select-site-label'),
                                        required: ""
                                    }, null, 8, ["disabled", "loading", "options", "placeholder"])),
                                _createVNode(_component_FormError, { name: "siteId" })
                            ]),
                            _: 1
                        }),
                        (_ctx.isInboundProduct)
                            ? (_openBlock(), _createBlock(_component_FormField, { key: 0 }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, { required: "" }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("global.phrases.select-unloading-point-label")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    (_ctx.siteUnloadingPointsLoad.isError.value)
                                        ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                            key: 0,
                                            onClick: _ctx.siteUnloadingPointsLoad.loadMethod
                                        }, null, 8, ["onClick"]))
                                        : (_openBlock(), _createBlock(_component_Dropdown, {
                                            key: 1,
                                            name: "unloadingPointId",
                                            disabled: !_ctx.selectedSiteId || _ctx.siteUnloadingPointsLoad.isLoading.value,
                                            loading: _ctx.siteUnloadingPointsLoad.isLoading.value,
                                            options: _ctx.siteUnloadingPoints,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            additionalLabel: "referenceNumber",
                                            filterFields: ['referenceNumber'],
                                            placeholder: _ctx.translate('global.phrases.select-unloading-point-label'),
                                            required: ""
                                        }, null, 8, ["disabled", "loading", "options", "placeholder"])),
                                    _createVNode(_component_FormError, { name: "unloadingPointId" })
                                ]),
                                _: 1
                            }))
                            : (_openBlock(), _createBlock(_component_FormField, { key: 1 }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, { required: "" }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("global.phrases.select-loading-point-label")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    (_ctx.siteLoadingPointsLoad.isError.value)
                                        ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                            key: 0,
                                            onClick: _ctx.siteLoadingPointsLoad.loadMethod
                                        }, null, 8, ["onClick"]))
                                        : (_openBlock(), _createBlock(_component_Dropdown, {
                                            key: 1,
                                            name: "loadingPointId",
                                            disabled: !_ctx.selectedSiteId || _ctx.siteLoadingPointsLoad.isLoading.value,
                                            loading: _ctx.siteLoadingPointsLoad.isLoading.value,
                                            options: _ctx.siteLoadingPoints,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            additionalLabel: "referenceNumber",
                                            filterFields: ['referenceNumber'],
                                            placeholder: _ctx.translate('global.phrases.select-loading-point-label'),
                                            required: ""
                                        }, null, 8, ["disabled", "loading", "options", "placeholder"])),
                                    _createVNode(_component_FormError, { name: "loadingPointId" })
                                ]),
                                _: 1
                            })),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate(`select-${_ctx.domainTranslationPrefix("product")}-label`)), 1)
                                    ]),
                                    _: 1
                                }),
                                (_ctx.productsLoadMethod.isError.value)
                                    ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                        key: 0,
                                        onClick: _ctx.productsLoadMethod.loadMethod
                                    }, null, 8, ["onClick"]))
                                    : (_openBlock(), _createBlock(_component_Dropdown, {
                                        key: 1,
                                        name: "productId",
                                        disabled: !_ctx.selectedUnLoadingPoint || _ctx.productsLoadMethod.isLoading.value,
                                        loading: _ctx.productsLoadMethod.isLoading.value,
                                        options: _ctx.products,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        additionalLabel: "referenceNumber",
                                        filterFields: ['referenceNumber'],
                                        placeholder: _ctx.translate('global.phrases.select-product-placeholder'),
                                        required: ""
                                    }, null, 8, ["disabled", "loading", "options", "placeholder"])),
                                _createVNode(_component_FormError, { name: "productId" })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["modelValue", "initial"])),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        loading: _ctx.copyLoad?.isLoading.value,
                        onClick: _ctx.copyLoad.loadMethod,
                        disabled: !_ctx.model.productId || !_ctx.model.modules?.length || !_ctx.isValid
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("global.buttons.copy")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "onClick", "disabled"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["header", "modal-id"]));
}
