var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { RouterService } from "@/app/shared/services/router.service";
import { createEntityIdRouteParam } from "@/app/modular-content/functions/create-entity-id-route-param.function";
import { UnloadingPointDomainId } from "@/app/domains/unloading-points/unloading-points.constants";
let SelectedULPFilterService = class SelectedULPFilterService {
    routerService;
    constructor(routerService) {
        this.routerService = routerService;
    }
    createEqualsFilter() {
        return [
            {
                propertyName: "UnloadingPointId",
                propertyValue: this.routerService.getParam(this.getIdRouteParam()),
            },
        ];
    }
    getIdRouteParam() {
        return createEntityIdRouteParam(UnloadingPointDomainId);
    }
};
SelectedULPFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof RouterService !== "undefined" && RouterService) === "function" ? _a : Object])
], SelectedULPFilterService);
export { SelectedULPFilterService };
