import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputUrl = _resolveComponent("InputUrl");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_SubmitButton = _resolveComponent("SubmitButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_Modal = _resolveComponent("Modal");
    const _directive_localized_validation = _resolveDirective("localized-validation");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        class: "attachment-modal",
        showCloseIcon: false,
        showLocalized: ""
    }, {
        default: _withCtx(() => [
            _createVNode(_component_AppForm, {
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                initial: _ctx.initial
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.title.label")), 1)
                                ]),
                                _: 1
                            }),
                            _withDirectives(_createVNode(_component_InputText, {
                                name: "title",
                                placeholder: _ctx.translate('form-fields.title.placeholder'),
                                required: ""
                            }, null, 8, ["placeholder"]), [
                                [_directive_localized_validation]
                            ]),
                            _createVNode(_component_FormError, { name: "title" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.description.label")), 1)
                                ]),
                                _: 1
                            }),
                            _withDirectives(_createVNode(_component_InputText, {
                                name: "description",
                                placeholder: _ctx.translate('form-fields.description.placeholder')
                            }, null, 8, ["placeholder"]), [
                                [_directive_localized_validation]
                            ]),
                            _createVNode(_component_FormError, { name: "description" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.url.label")), 1)
                                ]),
                                _: 1
                            }),
                            _withDirectives(_createVNode(_component_InputUrl, {
                                name: "url",
                                placeholder: _ctx.translate('form-fields.url.placeholder'),
                                "url-with-optional-schema": ""
                            }, null, 8, ["placeholder"]), [
                                [_directive_localized_validation]
                            ]),
                            _createVNode(_component_FormError, { name: "url" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_ButtonGroup, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppButton, {
                                class: "p-button-tertiary",
                                onClick: _ctx.cancel
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_SubmitButton, { submit: _ctx.submit }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.confirm")), 1)
                                ]),
                                _: 1
                            }, 8, ["submit"])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue", "initial"])
        ]),
        _: 1
    }, 8, ["modal-id"]));
}
