import "reflect-metadata";
import { InjectionScope } from "@/di/types/scope";
import { ScopeMetadata, TokenMetadata, TypeMetadata } from "@/di/metadata/injection-metadata";
import { InjectionType } from "@/di/types/type";
import { ReactiveMetadata } from "@/di/metadata/vue-metadata";
import { ReactiveType } from "@/di/types/reactive";
export function service(options = {}) {
    return function (constructor) {
        TypeMetadata.set(constructor, InjectionType.Class);
        TokenMetadata.set(constructor, options.token ?? constructor.name);
        ScopeMetadata.set(constructor, options.scope ?? InjectionScope.Module);
        ReactiveMetadata.set(constructor, options.reactive ?? ReactiveType.Shallow);
        return constructor;
    };
}
