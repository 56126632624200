import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7d2c6c96"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "description mb-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayVehicleAndPPEChips = _resolveComponent("DisplayVehicleAndPPEChips");
    return (_ctx.noRequirements)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.translate("no-special-ppe-requirements")), 1))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_DisplayVehicleAndPPEChips, {
                data: _ctx.model.minPPEToEnterSite,
                label: _ctx.translate('minimum-ppe-requirements.label'),
                anyChips: _ctx.model.minPPEToEnterSite?.length,
                editableDomain: ""
            }, null, 8, ["data", "label", "anyChips"]),
            _createVNode(_component_DisplayVehicleAndPPEChips, {
                data: _ctx.model.additionalPPEForDGGoods,
                label: _ctx.translate('additional-ppe-requirements.label'),
                anyChips: _ctx.model.additionalPPEForDGGoods?.length,
                editableDomain: ""
            }, null, 8, ["data", "label", "anyChips"]),
            _createVNode(_component_DisplayVehicleAndPPEChips, {
                data: _ctx.model.additionalULPSpecificPPErequirements,
                label: _ctx.translate('un-loading-point-specific-requirements.loading-label'),
                noChipsString: _ctx.translate('un-loading-point-specific-requirements.site-placeholder')
            }, null, 8, ["data", "label", "noChipsString"]),
            _createVNode(_component_DisplayVehicleAndPPEChips, {
                data: _ctx.model.additionalAssignedProductSpecificPPErequirements,
                label: _ctx.translate('additional-product-ppe-requirements.label'),
                noChipsString: _ctx.translate('additional-product-ppe-requirements.loading-placeholder')
            }, null, 8, ["data", "label", "noChipsString"])
        ], 64));
}
