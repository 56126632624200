var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let ExactLengthValidator = class ExactLengthValidator {
    translationService;
    name = "exact-length";
    arg = Number;
    constructor(translationService) {
        this.translationService = translationService;
    }
    message(length) {
        return this.translationService.translate("validation.messages.exact-length", { length });
    }
    validate(value, length) {
        return (!value ||
            (Math.sign(value) === -1 ? Math.abs(value).toString().length == length : value.toString().length == length));
    }
};
ExactLengthValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], ExactLengthValidator);
export { ExactLengthValidator };
