import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { useUserStore } from "@/app/users/stores/user.state";
import { VerificationStatus } from "@/app/domains/requirement-profiles/enums/status-verification.enum";
let SelectedUserFilterService = class SelectedUserFilterService {
    userStore;
    constructor() {
        this.userStore = useUserStore();
    }
    createEqualsFilter() {
        return [
            {
                propertyNames: ["StatusVerification", "StatusVerification"],
                propertyValues: [VerificationStatus.Requested, VerificationStatus.InProgress],
            },
            {
                propertyName: "VerifierId",
                propertyValue: this.userStore.user.id ?? "",
            },
        ];
    }
};
SelectedUserFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [])
], SelectedUserFilterService);
export { SelectedUserFilterService };
