import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-01b01cf3"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "select-business-partner-button" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "label word-break" };
const _hoisted_4 = { class: "managed-by word-break" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            class: _normalizeClass(["company-context", { 'company-active': _ctx.isManageCompanyActive }]),
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.goToCompany && _ctx.goToCompany(...args)))
        }, [
            _createVNode(_component_Thumbnail, {
                src: _ctx.selectedCompany?.logoPath,
                icon: "domain",
                size: "lg"
            }, null, 8, ["src"]),
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.selectedCompany?.name), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(`${_ctx.translate("domains.business-partners.components.side-navigation.managed-by")} ${_ctx.selectedCompany?.managedByName}`), 1)
            ])
        ], 2)
    ]));
}
