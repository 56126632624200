import { resolve } from "@/di/composables/resolve";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
export function useParentEntityLocations(domainService) {
    const locations = [];
    let parentDomainId = domainService.getMetadata().parent;
    while (parentDomainId) {
        const parentDomainService = resolve(domainTokensFactory(parentDomainId).service);
        const domainId = parentDomainService.getDomainId();
        const entityId = parentDomainService.getCurrentId();
        if (entityId) {
            locations.push([domainId, entityId]);
        }
        parentDomainId = parentDomainService.getMetadata().parent;
    }
    return {
        parentEntityLocations: locations,
    };
}
