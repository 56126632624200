import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { BackendErrorResolverToken } from "@/app/validation/tokens/backend-error-resolver.token";
let RequiredErrorResolver = class RequiredErrorResolver {
    errorCode = "V1003";
    path = "required";
    resolve(error) {
        if (error.message !== this.errorCode || !error.field) {
            return error;
        }
        return {
            ...error,
            cause: this.path,
            message: `validation.messages.${this.path}`,
        };
    }
    canResolve(error) {
        return error?.message === this.errorCode;
    }
};
RequiredErrorResolver = __decorate([
    service({ token: BackendErrorResolverToken })
], RequiredErrorResolver);
export { RequiredErrorResolver };
