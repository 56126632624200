var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
let RequirementProfileFeedbackService = class RequirementProfileFeedbackService {
    httpService;
    constructor(httpService) {
        this.httpService = httpService;
    }
    async getFeedbackFor(requirementProfileId) {
        return await this.httpService.get(`requirementprofiles/${requirementProfileId}/feedbacks`);
    }
};
RequirementProfileFeedbackService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object])
], RequirementProfileFeedbackService);
export { RequirementProfileFeedbackService };
