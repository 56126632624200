import { createModule } from "@/di/module";
import { createModalIdFactory } from "@/app/modular-content/factories/create-modal-id.factory";
import { CreateEntityModalIdToken } from "@/app/modular-content/token/modular-content.token";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EntityCreateModalComponent from "@/app/modular-content/components/entity-create-modal.component.vue";
export const CreateModalModule = (options, listId = "") => {
    const suffixes = [options.id, listId];
    const modalId = createModalIdFactory(options.id, listId);
    const component = getEntityCreateModalComponent(options, listId);
    const providers = listId === "" ? [{ token: CreateEntityModalIdToken, useValue: modalId }] : [];
    return createModule({
        imports: [ModalModule(modalId, suffixComponentName(component, ...suffixes))],
        providers,
    });
};
export const CreateModalModules = (options) => {
    const modules = options.lists?.map((it) => {
        return CreateModalModule(options, it.id);
    }) ?? [];
    modules.push(CreateModalModule(options));
    return modules;
};
const getEntityCreateModalComponent = (options, listId) => {
    const id = (listId === "" ? undefined : listId) ?? undefined;
    return (options.lists?.find((it) => it.id === id)?.createModal ??
        options.components?.createModal ??
        EntityCreateModalComponent);
};
