var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { TranslationService } from "@/app/translation/services/translation.service";
import { useUserStore } from "@/app/users/stores/user.state";
import { UpdateUserModalId, VerifyUserModalId } from "@/app/users/users.constants";
import { useModal } from "@/app/modals/composables/use-modal.composable";
let UserService = class UserService {
    httpClient;
    toastService;
    translationService;
    userStore;
    updateUserModal;
    verifyUserModal;
    constructor(httpClient, toastService, translationService) {
        this.httpClient = httpClient;
        this.toastService = toastService;
        this.translationService = translationService;
        this.userStore = useUserStore();
        this.updateUserModal = useModal(UpdateUserModalId);
        this.verifyUserModal = useModal(VerifyUserModalId);
    }
    async createUser(dto) {
        const user = await this.httpClient.post("members/createUser", dto);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate("users.create-user.toast-message.success"),
        });
        return user;
    }
    async updateUser(dto) {
        await this.httpClient.post("members/updateUserProfile", dto);
        this.userStore.updateUser(dto);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate("users.update-user.toast-message.success"),
        });
    }
    async verifyUser(verificationCode) {
        await this.httpClient.post(`members/${this.userStore.userId}/reactivate`, { verificationCode });
        this.userStore.setUserInactiveStatus(false);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate("users.update-user.toast-message.success"),
        });
    }
    async resendLink() {
        await this.httpClient.post(`members/${this.userStore.userId}/resendVerificationCode`, {});
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate("users.resend-verification-code.toast-message.success"),
        });
    }
    async getUser() {
        return await this.httpClient.get("members/profiles/my");
    }
    isUserInactive() {
        return this.userStore.user.isInactive;
    }
    setUserInactiveStatus(status) {
        this.userStore.setUserInactiveStatus(status);
    }
    showInactiveUserModal() {
        this.verifyUserModal.setVisible(true);
    }
    async onAppInit() {
        const user = await this.getUser();
        this.userStore.setUser(user);
        if (this.isUserInactive()) {
            this.showInactiveUserModal();
        }
        if (!user.firstName || !user.lastName) {
            this.updateUserModal.setVisible(true);
        }
    }
};
UserService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object])
], UserService);
export { UserService };
