import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_DisplayProductBasicInformationView = _resolveComponent("DisplayProductBasicInformationView");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_DisplayProductBasicInformationView, {
            key: 1,
            model: _ctx.data,
            submodule: _ctx.submodule
        }, null, 8, ["model", "submodule"]));
}
