import { createModule } from "@/di/module";
import { DictionaryTranslationService } from "@/app/translation/services/dictionary-translation.service";
import { TranslationService } from "@/app/translation/services/translation.service";
import { AppInitServiceToken } from "@/app/shared/tokens/app-init-service.token";
export const TranslationModule = createModule({
    providers: [
        TranslationService,
        DictionaryTranslationService,
        { token: AppInitServiceToken, useToken: TranslationService },
        { token: AppInitServiceToken, useToken: DictionaryTranslationService },
    ],
});
