import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Calendar = _resolveComponent("Calendar");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.currentModalId,
        header: _ctx.translate(_ctx.isEditMode ? 'edit-header' : 'create-header'),
        class: "tender-modal"
    }, {
        footer: _withCtx(() => [
            _createVNode(_component_ButtonGroup, { class: "buttons-group" }, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.currentModalService.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    (_ctx.isEditMode)
                        ? (_openBlock(), _createBlock(_component_AppButton, {
                            key: 0,
                            loading: _ctx.isUpdating,
                            onClick: _ctx.update
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("buttons.update")), 1)
                            ]),
                            _: 1
                        }, 8, ["loading", "onClick"]))
                        : (_openBlock(), _createBlock(_component_AppButton, {
                            key: 1,
                            loading: _ctx.isCreating,
                            onClick: _ctx.create,
                            disabled: !_ctx.isValid
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("buttons.create")), 1)
                            ]),
                            _: 1
                        }, 8, ["loading", "onClick", "disabled"]))
                ]),
                _: 1
            })
        ]),
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: "",
                    class: "m-5"
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    initial: _ctx.initial,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                    errors: _ctx.errors,
                    errorsToMap: {
                        tenderValidRange: ['start', 'end'],
                        contractValidRange: ['contractStart', 'contractEnd'],
                    },
                    onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event))
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("tender-name-label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_InputText, {
                                    name: "name",
                                    placeholder: _ctx.translate('tender-name-placeholder'),
                                    "min-length": "3",
                                    "max-length": "30",
                                    required: ""
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "name" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.reference-number.tender-label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_InputText, {
                                    name: "referenceNumber",
                                    placeholder: _ctx.translate('form-fields.reference-number.tender-placeholder'),
                                    "min-length": "3",
                                    "max-length": "30"
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "referenceNumber" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("tender-validity-label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Calendar, {
                                    inputId: "range",
                                    name: "tenderValidRange",
                                    selectionMode: "range",
                                    dateFormat: "dd.mm.yy",
                                    manualInput: false,
                                    placeholder: _ctx.translate('tender-validity-placeholder'),
                                    showIcon: true,
                                    required: ""
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "tenderValidRange" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("contract-validity-label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Calendar, {
                                    inputId: "range",
                                    name: "contractValidRange",
                                    selectionMode: "range",
                                    dateFormat: "dd.mm.yy",
                                    manualInput: false,
                                    placeholder: _ctx.translate('contract-validity-placeholder'),
                                    showIcon: true,
                                    required: ""
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "contractValidRange" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: !_ctx.isEditMode,
                                    required: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("tender-status-label")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["disabled"]),
                                (_ctx.isEditMode)
                                    ? (_openBlock(), _createBlock(_component_Dropdown, {
                                        key: 0,
                                        name: "status",
                                        options: _ctx.tenderStatusOptions,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        dictionaryId: "TenderStatus",
                                        placeholder: _ctx.translate('tender-status-placeholder'),
                                        required: ""
                                    }, null, 8, ["options", "placeholder"]))
                                    : (_openBlock(), _createBlock(_component_Dropdown, {
                                        key: 1,
                                        disabled: "",
                                        placeholder: _ctx.getValue('Preparing', _ctx.tenderStatusOptions, 'TenderStatus')
                                    }, null, 8, ["placeholder"]))
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["initial", "modelValue", "errors"]))
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
