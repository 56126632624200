import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-09a63042"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_ctx.src)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass(['thumbnail', 'thumbnail-' + _ctx.size]),
            src: _ctx.src
        }, null, 10, _hoisted_1))
        : (_ctx.icon)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(['thumbnail-placeholder', 'thumbnail-' + _ctx.size])
            }, [
                _createVNode(_component_Icon, { name: _ctx.icon }, null, 8, ["name"])
            ], 2))
            : _createCommentVNode("", true);
}
