import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3f049676"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-contact-data" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row section" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 column-description" };
const _hoisted_6 = { class: "row section" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = { class: "col-6" };
const _hoisted_9 = ["href"];
const _hoisted_10 = {
    key: 1,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("form-fields.telephone.label")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.phoneInfo), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("email")), 1),
                _createElementVNode("div", _hoisted_8, [
                    (_ctx.model?.email)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: `mailto:${_ctx.model.email}`,
                            target: "_blank",
                            class: "column-link-value"
                        }, _toDisplayString(_ctx.model.email), 9, _hoisted_9))
                        : (_openBlock(), _createElementBlock("span", _hoisted_10, "-"))
                ])
            ])
        ])
    ]));
}
