import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ca38fef8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "expandable-table-cell",
    "data-qa-id": "product-info-table-cell"
};
const _hoisted_2 = { class: "content-wrapper" };
const _hoisted_3 = {
    key: 0,
    class: "image-placeholder"
};
const _hoisted_4 = { class: "name" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    const _component_Expandable = _resolveComponent("Expandable");
    const _component_Id = _resolveComponent("Id");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.logoPath || _ctx.iconName)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_Thumbnail, {
                        icon: _ctx.iconName,
                        src: _ctx.logoPath,
                        size: "lg"
                    }, null, 8, ["icon", "src"])
                ]))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
                _withDirectives((_openBlock(), _createBlock(_component_Expandable, { key: _ctx.entityName }, {
                    entity: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.entityName) + " ", 1),
                        _renderSlot(_ctx.$slots, "entity", {}, undefined, true)
                    ]),
                    _: 3
                })), [
                    [
                        _directive_tooltip,
                        {
                            value: _ctx.entityName,
                        },
                        void 0,
                        { bottom: true }
                    ]
                ]),
                _createVNode(_component_Id, {
                    class: "id",
                    "with-prefix": false
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.entityId) + " ", 1),
                        _renderSlot(_ctx.$slots, "entityId", {}, undefined, true)
                    ]),
                    _: 3
                })
            ])
        ]),
        _renderSlot(_ctx.$slots, "badge", {}, undefined, true)
    ]));
}
