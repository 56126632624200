import { Subject, takeUntil } from "rxjs";
import { onMounted, onUnmounted } from "vue";
import { useInstance } from "@/app/shared/composables/use-instance.composable";
export const track = (observable, property) => {
    const unsubscribe = new Subject();
    onMounted(() => {
        const instance = useInstance();
        observable.pipe(takeUntil(unsubscribe)).subscribe((value) => {
            instance.data[property] = value;
        });
    });
    onUnmounted(() => {
        unsubscribe.next();
    });
};
