import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6385363e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-receiving-tank" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row section" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 column-description" };
const _hoisted_6 = { class: "row section" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = { class: "col-6 column-description" };
const _hoisted_9 = { class: "row section" };
const _hoisted_10 = { class: "col-6 column-header" };
const _hoisted_11 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("tank-size")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.tankSize), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("high-level-alarm-wth-tank")), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.highLevelAlarmWithTankInfo), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("termination-by-alarm"))), 1),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.terminationByAlarm), 1)
            ])
        ])
    ]));
}
