import { createModule } from "@/di/module";
import { ModuleModule } from "@/app/modular-content/modules/module.module";
import CopyModulesModal from "@/app/domains/assigned-products/components/copy-modules-modal.component.vue";
export const InstructionsModuleId = "instructions";
export const InstructionsModule = (entityId, options) => {
    return createModule({
        imports: [
            ModuleModule({
                id: InstructionsModuleId,
                domainId: entityId,
                navigationIcon: options?.navigationIcon,
                copyAvailable: options?.copyAvailable,
                copyTooltip: options?.copyTooltipTranslationKey,
                copyModuleModal: CopyModulesModal,
            }),
        ],
    });
};
