var _a;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { inject } from "@/di/decorators/inject.decorator";
import { RouterToken } from "@/app/shared/tokens/router.token";
import { Router } from "vue-router";
import { injectAll } from "@/di/decorators/inject-all.decorator";
import { RouteGuardToken } from "@/app/shared/tokens/route-guard.token";
import { AppInitServiceToken } from "@/app/shared/tokens/app-init-service.token";
let RouterConfigurationService = class RouterConfigurationService {
    router;
    guards;
    constructor(router, guards) {
        this.router = router;
        this.guards = guards;
        setupGuards(router, guards);
    }
};
RouterConfigurationService = __decorate([
    service({ token: AppInitServiceToken }),
    __param(0, inject(RouterToken)),
    __param(1, injectAll(RouteGuardToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof Router !== "undefined" && Router) === "function" ? _a : Object, Array])
], RouterConfigurationService);
export { RouterConfigurationService };
const setupGuards = (router, guards) => {
    router.beforeEach(async (to, from) => {
        for (const guard of guards) {
            if (!(await guard.canActivate(from, to))) {
                return false;
            }
        }
        return true;
    });
};
