import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_AssignedLanesWarning = _resolveComponent("AssignedLanesWarning");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        header: _ctx.translate('add-to-tender.label'),
        class: "add-to-tender-modal"
    }, {
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    initial: _ctx.initial,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                    errors: _ctx.errors,
                    onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event))
                }, {
                    default: _withCtx(() => [
                        (_ctx.alreadyAssignedIds.length)
                            ? (_openBlock(), _createBlock(_component_FormField, { key: 0 }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_AssignedLanesWarning, { ids: _ctx.alreadyAssignedIds }, null, 8, ["ids"])
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("add-to-tender.select")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Dropdown, {
                                    name: "tenderId",
                                    options: _ctx.tendersOptions,
                                    optionValue: "id",
                                    optionLabel: "label",
                                    placeholder: _ctx.translate('add-to-tender.select'),
                                    required: ""
                                }, null, 8, ["options", "placeholder"]),
                                _createVNode(_component_FormError, { name: "tenderId" })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["initial", "modelValue", "errors"])),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        onClick: _ctx.add,
                        loading: _ctx.isAdding,
                        disabled: !_ctx.isValid
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.assign")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick", "loading", "disabled"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
