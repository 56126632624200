import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-747c810c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "info" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Button = _resolveComponent("Button");
    const _component_SubmitButton = _resolveComponent("SubmitButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_Modal = _resolveComponent("Modal");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createBlock(_component_Modal, {
        showCloseIcon: true,
        "modal-id": _ctx.modalId,
        header: _ctx.translate('header'),
        id: "create-company-group-modal"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_AppForm, {
                modelValue: _ctx.companyGroup,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.companyGroup) = $event)),
                errors: _ctx.errors
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.company-group-name.label")), 1)
                                ]),
                                _: 1
                            }),
                            _withDirectives(_createVNode(_component_InputText, {
                                placeholder: _ctx.translate('form-fields.company-group-name.placeholder'),
                                name: "name",
                                id: "company-group-name-input-field",
                                required: ""
                            }, null, 8, ["placeholder"]), [
                                [_directive_default_validation]
                            ]),
                            _createVNode(_component_FormError, { name: "name" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.company-group-id.label")), 1)
                                ]),
                                _: 1
                            }),
                            _withDirectives(_createVNode(_component_InputText, {
                                placeholder: _ctx.translate('form-fields.company-group-id.placeholder'),
                                name: "id",
                                modelValue: _ctx.companyGroup.id,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.companyGroup.id) = $event)),
                                modelModifiers: { trim: true },
                                onInput: _cache[1] || (_cache[1] = () => (_ctx.companyGroup.id = _ctx.companyGroup.id?.toUpperCase())),
                                id: "company-group-id-input-field",
                                required: "",
                                "one-word": "",
                                alphanumeric: ""
                            }, null, 8, ["placeholder", "modelValue"]), [
                                [_directive_default_validation]
                            ]),
                            _createVNode(_component_FormError, { name: "id" })
                        ]),
                        _: 1
                    }),
                    _createElementVNode("div", null, [
                        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.translate("company-group-info")), 1)
                    ]),
                    _createVNode(_component_ButtonGroup, { orientation: "horizontal" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Button, {
                                class: "p-button-lg p-button-tertiary",
                                onClick: _ctx.close,
                                id: "cancel-button"
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_SubmitButton, {
                                loading: _ctx.isCreating,
                                submit: _ctx.create,
                                class: "p-button-lg",
                                id: "create-button"
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.create")), 1)
                                ]),
                                _: 1
                            }, 8, ["loading", "submit"])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue", "errors"])
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
