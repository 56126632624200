import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-32d2c19d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "required"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("label", {
        class: _normalizeClass(["input-label", { disabled: _ctx.disabled, pointer: _ctx.pointer }])
    }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
            : _createCommentVNode("", true)
    ], 2));
}
