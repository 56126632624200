import _ from "lodash";
import { vueProviderContainer } from "@/di/container";
const withWarningsTurnedOff = (fn) => {
    const warn = console.warn;
    console.warn = () => {
        /**/
    };
    const result = fn();
    console.warn = warn;
    return result;
};
const getCurrentValues = (token, inject) => {
    try {
        return withWarningsTurnedOff(() => inject(token) ?? []);
    }
    catch (e) {
        return [];
    }
};
export const provide = (token, value, container) => {
    container = container ?? vueProviderContainer();
    const currentValues = getCurrentValues(token, container.inject);
    const newValues = _.isArray(value) ? value : [value];
    const values = [...currentValues, ...newValues];
    container.provide(token, values);
};
