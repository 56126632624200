import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1f877ae9"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "access-restriction" };
const _hoisted_2 = { class: "restriction-container" };
const _hoisted_3 = { class: "title" };
const _hoisted_4 = { class: "value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InlineSvg = _resolveComponent("InlineSvg");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, _toDisplayString(_ctx.translate("max")), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.label), 1)
            ]),
            _createVNode(_component_InlineSvg, {
                class: "image",
                href: `/assets/images/access-restrictions/${_ctx.image}.svg`
            }, null, 8, ["href"])
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.value) + " " + _toDisplayString(_ctx.unit), 1)
    ]));
}
