var _a, _b, _c;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { DomainMetadataToken, ModuleIdToken } from "@/app/modular-content/token/modular-content.token";
import { DomainRouteService } from "@/app/modular-content/services/domain-route.service";
import { inject } from "@/di/decorators/inject.decorator";
import { DomainMetadata } from "@/app/modular-content/type/domain-metadata.type";
import { RouterService } from "@/app/shared/services/router.service";
import { camelCase } from "change-case";
import { resolve } from "@/di/composables/resolve";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
let ModuleUrlFactoryService = class ModuleUrlFactoryService {
    meta;
    moduleId;
    domainRouteService;
    routerService;
    constructor(meta, moduleId, domainRouteService, routerService) {
        this.meta = meta;
        this.moduleId = moduleId;
        this.domainRouteService = domainRouteService;
        this.routerService = routerService;
    }
    createUrl() {
        const baseUrl = this.createBaseUrl();
        const moduleId = this.getModuleId();
        return `${baseUrl}/${moduleId}`;
    }
    createBaseUrl() {
        const baseUrl = this.getDomainBaseUrl();
        const entityId = this.getEntityId();
        return `${baseUrl}/${entityId}`;
    }
    getDomainBaseUrl() {
        const domainId = this.routerService.getCurrentRoute()?.meta?.domainId;
        let currentDomainMetaData = undefined;
        try {
            /*
             * `DomainMetadataToken` sometimes injects parent domain metadata which causes request to
             * be sent to the wrong domain. To handle this, we try to resolve the metadata based on the
             * domain from the current route
             */
            currentDomainMetaData = resolve(domainTokensFactory(domainId).meta);
        }
        catch (e) {
            // hide error
        }
        return currentDomainMetaData?.baseUrlCallback() ?? this.meta.baseUrlCallback();
    }
    getEntityId() {
        const domainId = this.routerService.getCurrentRoute()?.meta?.domainId;
        return this.routerService.getParam(`${camelCase(domainId)}Id`) ?? this.domainRouteService.getCurrentEntityId();
    }
    getModuleId() {
        const moduleId = this.routerService.getCurrentRoute()?.meta?.moduleId;
        return moduleId ?? this.moduleId;
    }
};
ModuleUrlFactoryService = __decorate([
    service(),
    __param(0, inject(DomainMetadataToken)),
    __param(1, inject(ModuleIdToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof DomainMetadata !== "undefined" && DomainMetadata) === "function" ? _a : Object, String, typeof (_b = typeof DomainRouteService !== "undefined" && DomainRouteService) === "function" ? _b : Object, typeof (_c = typeof RouterService !== "undefined" && RouterService) === "function" ? _c : Object])
], ModuleUrlFactoryService);
export { ModuleUrlFactoryService };
