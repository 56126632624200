import { createModule } from "@/di/module";
import { ModuleModule } from "@/app/modular-content/modules/module.module";
import CopyModulesModal from "@/app/domains/assigned-products/components/copy-modules-modal.component.vue";
export const TechnicalRequirementsModuleId = "technicalRequirements";
export const TechnicalRequirementsModule = (entityId, options) => {
    return createModule({
        imports: [
            ModuleModule({
                id: TechnicalRequirementsModuleId,
                domainId: entityId,
                navigationIcon: options?.navigationIcon,
                copyAvailable: options?.copyAvailable,
                copyTooltip: options?.copyTooltipTranslationKey,
                copyModuleModal: CopyModulesModal,
            }),
        ],
    });
};
