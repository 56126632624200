import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "address-input" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-7" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-3" };
const _hoisted_6 = { class: "col-9" };
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "col-7" };
const _hoisted_9 = { class: "row" };
const _hoisted_10 = { class: "col-7" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, { required: _ctx.isRequired }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("form-fields.street-address.label")), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["required"]),
                                    _withDirectives(_createVNode(_component_InputText, {
                                        required: _ctx.isRequired,
                                        name: "streetAddress",
                                        placeholder: _ctx.translate('form-fields.street-address.placeholder')
                                    }, null, 8, ["required", "placeholder"]), [
                                        [_directive_default_validation]
                                    ]),
                                    _createVNode(_component_FormError, { name: "streetAddress" })
                                ]),
                                _: 1
                            }),
                            _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("div", _hoisted_5, [
                                    _createVNode(_component_FormField, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_InputLabel, { required: _ctx.isRequired }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.postal-code.label")), 1)
                                                ]),
                                                _: 1
                                            }, 8, ["required"]),
                                            _withDirectives(_createVNode(_component_InputText, {
                                                required: _ctx.isRequired,
                                                name: "postalCode",
                                                placeholder: _ctx.translate('form-fields.postal-code.placeholder')
                                            }, null, 8, ["required", "placeholder"]), [
                                                [_directive_default_validation]
                                            ]),
                                            _createVNode(_component_FormError, { name: "postalCode" })
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_6, [
                                    _createVNode(_component_FormField, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_InputLabel, { required: _ctx.isRequired }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.address-locality.label")), 1)
                                                ]),
                                                _: 1
                                            }, 8, ["required"]),
                                            _withDirectives(_createVNode(_component_InputText, {
                                                required: _ctx.isRequired,
                                                name: "addressLocality",
                                                placeholder: _ctx.translate('form-fields.address-locality.placeholder')
                                            }, null, 8, ["required", "placeholder"]), [
                                                [_directive_default_validation]
                                            ]),
                                            _createVNode(_component_FormError, { name: "addressLocality" })
                                        ]),
                                        _: 1
                                    })
                                ])
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("form-fields.address-addition.label")), 1)
                                        ]),
                                        _: 1
                                    }),
                                    _withDirectives(_createVNode(_component_InputText, {
                                        name: "addressAddition",
                                        placeholder: _ctx.translate('form-fields.address-addition.placeholder')
                                    }, null, 8, ["placeholder"]), [
                                        [_directive_default_validation]
                                    ]),
                                    _createVNode(_component_FormError, { name: "addressAddition" })
                                ]),
                                _: 1
                            })
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_InputLabel, { required: _ctx.isRequired }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("form-fields.country.placeholder")), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["required"]),
                                    _createVNode(_component_Dropdown, {
                                        name: "countryCode",
                                        options: _ctx.countriesOptions,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        dictionaryId: "Country",
                                        placeholder: _ctx.translate('form-fields.country.placeholder'),
                                        filterPlaceholder: _ctx.translate('form-fields.country.filter-placeholder'),
                                        required: _ctx.isRequired
                                    }, null, 8, ["options", "placeholder", "filterPlaceholder", "required"]),
                                    _createVNode(_component_FormError, { name: "countryCode" })
                                ]),
                                _: 1
                            })
                        ])
                    ])
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "initial"]));
}
