var _a, _b;
import { __decorate, __metadata } from "tslib";
import axios from "axios";
import { get } from "lodash";
import { reactive } from "@/di/decorators/computed.decorator";
import { service } from "@/di/decorators/service.decorator";
import { UserStorageService } from "@/app/shared/services/user-storage.service";
import { renderTemplate, isTemplate } from "@/app/translation/functions/render-template.function";
import { defaultLanguage, supportedLanguages } from "@/app/translation/translation.constants";
import fallback from "@/assets/i18n/en.yml";
const systemLanguage = navigator.language.slice(0, 2);
const initialLanguage = supportedLanguages.includes(systemLanguage) ? systemLanguage : defaultLanguage;
let BaseTranslationService = class BaseTranslationService {
    userStorageService;
    currentLanguage = initialLanguage;
    translations = new Map();
    constructor(userStorageService) {
        this.userStorageService = userStorageService;
    }
    async onAppInit() {
        await this.loadTranslations(this.getUserLanguage());
    }
    async loadTranslations(language) {
        if (this.shouldSkipLoadingTranslations()) {
            return;
        }
        if (this.translations.has(language)) {
            return this.setLanguage(language);
        }
        try {
            const response = await axios.get(this.getUrl(language));
            this.translations.set(language, response.data);
            this.setLanguage(language);
        }
        catch {
            //
        }
    }
    getLanguage() {
        return this.currentLanguage;
    }
    setLanguage(language) {
        this.currentLanguage = language;
        this.setUserLanguage(language);
    }
    getSupportedLanguages() {
        return supportedLanguages;
    }
    translate(key, params) {
        const translation = this.getTranslation(key);
        return isTemplate(translation) ? renderTemplate(translation, params) : translation;
    }
    getUrl(language) {
        return new URL(this.getTranslationsPath(language), process.env.VUE_APP_TRANSLATIONS_BASE_URL).toString();
    }
    getUserLanguage() {
        return this.userStorageService.get("language") || this.getLanguage();
    }
    setUserLanguage(language) {
        this.userStorageService.set("language", language);
    }
    shouldSkipLoadingTranslations() {
        return process.env.VUE_APP_LOAD_TRANSLATIONS === "false";
    }
    getTranslation(key) {
        return this.getTranslations()[key] || get(fallback, key) || key;
    }
    getTranslations() {
        return this.translations.get(this.currentLanguage) || {};
    }
};
__decorate([
    reactive(),
    __metadata("design:type", typeof (_b = typeof Map !== "undefined" && Map) === "function" ? _b : Object)
], BaseTranslationService.prototype, "translations", void 0);
BaseTranslationService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof UserStorageService !== "undefined" && UserStorageService) === "function" ? _a : Object])
], BaseTranslationService);
export { BaseTranslationService };
