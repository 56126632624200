import { ModuleModule } from "@/app/modular-content/modules/module.module";
import { createModule } from "@/di/module";
export const FacilitiesModuleId = "facilities";
export const FacilitiesModule = (domainId) => {
    return createModule({
        imports: [
            ModuleModule({
                id: FacilitiesModuleId,
                domainId,
            }),
        ],
    });
};
