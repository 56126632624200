import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AutoComplete = _resolveComponent("AutoComplete");
    return (_openBlock(), _createBlock(_component_AutoComplete, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        suggestions: _ctx.suggestedItems,
        onComplete: _cache[1] || (_cache[1] = (event) => _ctx.filterSuggestions(event)),
        dropdown: "",
        onItemSelect: _ctx.selectItem,
        scrollHeight: "200px"
    }, null, 8, ["modelValue", "suggestions", "onItemSelect"]));
}
