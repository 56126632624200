import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createBlock(_component_AppForm, {
        initial: _ctx.initial,
        class: "create-building-form"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_FormField, null, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, { required: "" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix("label", true)}`)), 1)
                        ]),
                        _: 1
                    }),
                    _withDirectives(_createVNode(_component_InputText, {
                        name: "name",
                        placeholder: _ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix('placeholder', true)}`),
                        required: ""
                    }, null, 8, ["placeholder"]), [
                        [_directive_default_validation]
                    ]),
                    _createVNode(_component_FormError, { name: "name" })
                ]),
                _: 1
            }),
            _createVNode(_component_FormField, null, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, { required: "" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.reference-number.${_ctx.domainTranslationPrefix("label", true)}`)), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_InputText, {
                        name: "referenceNumber",
                        placeholder: _ctx.translate(`form-fields.reference-number.${_ctx.domainTranslationPrefix('placeholder', true)}`),
                        "min-length": "3",
                        "max-length": "30",
                        required: ""
                    }, null, 8, ["placeholder"]),
                    _createVNode(_component_FormError, { name: "referenceNumber" })
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["initial"]));
}
