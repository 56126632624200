import DashboardComponent from "@/app/dashboard/dashboard.component.vue";
import NoPermissionComponent from "@/app/no-permission/no-permission.component.vue";
import { useRolesStore } from "@/app/shared/state/roles.state";
import { NoPermissionRouteName } from "@/app/no-permission/no-permission.constants";
import { DashboardRouteName } from "@/app/dashboard/constants/dashboard.constants";
export const AppRoutes = [
    {
        path: "/dashboard",
        name: DashboardRouteName,
        component: DashboardComponent,
    },
    {
        path: "/no-permission",
        name: NoPermissionRouteName,
        component: NoPermissionComponent,
        beforeEnter: () => {
            const rolesStore = useRolesStore();
            return rolesStore.isPublicApiConsumer ? true : DashboardRouteName;
        },
    },
];
