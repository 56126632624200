import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
let ProcessingItemsService = class ProcessingItemsService {
    items = {};
    add(item) {
        this.items[item.id] = item;
    }
    get(id) {
        const item = this.items[id];
        if (item) {
            delete this.items[id];
        }
        return item;
    }
};
ProcessingItemsService = __decorate([
    service()
], ProcessingItemsService);
export { ProcessingItemsService };
