export const InviteMembersModalId = "InviteMembers";
export const LeaveCompanyModalId = "LeaveCompany";
export const CannotLeaveCompanyModalId = "CannotLeaveCompany";
export const RemoveUserModalId = "RemoveUser";
export const PermissionRightsModalId = "PermissionRights";
export const RolesOrder = [
    "companyAdmin",
    "dataManager",
    "requirementManager",
    "productManager",
    "tenderManager",
    "member",
    "verifier",
];
