var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { UNAUTHORIZED } from "http-status";
import { HttpErrorInterceptorToken } from "@/app/http/tokens/http-error-interceptor.token";
import { SessionService } from "./session.service";
let UnauthorizedInterceptorService = class UnauthorizedInterceptorService {
    sessionService;
    constructor(sessionService) {
        this.sessionService = sessionService;
    }
    onResponseError(config, error) {
        if (error.response?.status !== UNAUTHORIZED) {
            return error;
        }
        this.sessionService.logout();
        return error;
    }
};
UnauthorizedInterceptorService = __decorate([
    service({ token: HttpErrorInterceptorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof SessionService !== "undefined" && SessionService) === "function" ? _a : Object])
], UnauthorizedInterceptorService);
export { UnauthorizedInterceptorService };
