var _a, _b, _c, _d, _e;
import { __decorate, __metadata } from "tslib";
import _ from "lodash";
import { service } from "@/di/decorators/service.decorator";
import { useCompaniesStore } from "@/app/domains/public-data/companies/state/companies.state";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { UserStorageService } from "@/app/shared/services/user-storage.service";
import { RestClientService } from "@/app/http/services/rest-client.service";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { Subject } from "rxjs";
import { SessionStorageService } from "@/app/shared/services/session-storage.service";
let CompanyService = class CompanyService {
    companyApi;
    httpService;
    userStorageService;
    sessionStorageService;
    toastService;
    companySubject = new Subject();
    companiesStore;
    constructor(companyApi, httpService, userStorageService, sessionStorageService, toastService) {
        this.companyApi = companyApi;
        this.httpService = httpService;
        this.userStorageService = userStorageService;
        this.sessionStorageService = sessionStorageService;
        this.toastService = toastService;
        this.companiesStore = useCompaniesStore();
    }
    async createCompany(dto) {
        const company = await this.companyApi.create(dto);
        this.companySubject.next(company);
        this.companiesStore.addCompany(company);
        return company;
    }
    onCreateCompanyChange() {
        return this.companySubject.asObservable();
    }
    async loadMyCompanies() {
        const companies = await this.fetchMyCompanies();
        this.companiesStore.setCompanies(companies);
    }
    async loadMyCompaniesExceptInGroup(groupId) {
        const companies = await this.fetchMyCompanies();
        // WORKAROUND: It takes too long for BE to remove user from all companies in the group
        const companiesNotInTheGroup = companies.filter((company) => company.companyGroupId !== groupId);
        this.companiesStore.setCompanies(companiesNotInTheGroup);
    }
    async fetchMyBusinessPartnerCompliantCompanies(dto) {
        return await this.searchCompanies({ ...dto, include: "OnlyBusinessPartnerCompliantCompanies" });
    }
    async loadCompanyById(id) {
        const company = await this.companyApi.get(id);
        this.companiesStore.setCompany(id, company);
    }
    async fetchMyCompanies(dto) {
        return await this.searchCompanies({ ...dto, include: "OnlyMine" });
    }
    async fetchMyBusinessPartners() {
        return await this.searchCompanies({ include: "OnlyBusinessPartners" });
    }
    async fetchAllCompanies() {
        // "All" means real companies related to the user and current company group
        // select is overwritten and returns limited properties
        return await this.searchCompanies({ include: "All" });
    }
    async fetchLogisticsServiceProviderCompanies() {
        return await this.searchCompanies({
            include: "All",
            filter: "CompanyType eq 'LogisticsServiceProvider'",
        });
    }
    async fetchAllRegularCompanies() {
        return await this.searchCompanies({
            include: "All",
            filter: "ManagedByExternalCompany ne true",
        });
    }
    async fetchCompaniesGroup(groupId) {
        return await this.searchCompanies({
            include: "All",
            filter: `CompanyGroupId eq '${groupId}'`,
        });
    }
    async updateCompany(id, dto) {
        const company = await this.companyApi.replace(id, dto);
        this.companiesStore.setCompany(id, company);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: "domains.companies.services.company.update-company.toast-message",
        });
    }
    selectFirstAvailableCompany() {
        const company = this.getFirstAvailableCompany();
        if (company) {
            this.selectCompany(company.id);
        }
    }
    selectCompanyById(id) {
        this.companiesStore.setSelectedId(id);
        this.sessionStorageService.set("companies.selectedId", id);
        this.sessionStorageService.clear("companies.selectedBusinessPartnerId");
        this.saveSelectedCompanyId(id);
    }
    saveSelectedCompanyId(id) {
        this.userStorageService.set("companies.selectedId", id);
        this.userStorageService.clear("companies.selectedBusinessPartnerId");
    }
    selectBusinessPartnerById(id) {
        this.companiesStore.setSelectedBusinessPartnerId(id);
        this.sessionStorageService.set("companies.selectedBusinessPartnerId", id);
        this.saveSelectedBusinessPartnerId(id);
    }
    saveSelectedBusinessPartnerId(id) {
        this.userStorageService.set("companies.selectedBusinessPartnerId", id);
    }
    async selectCompany(id) {
        const company = await this.getOrFetchCompanyById(id);
        if (!company) {
            return;
        }
        else if (company.managedByExternalCompany) {
            this.selectBusinessPartnerById(id);
        }
        else {
            this.selectCompanyById(id);
        }
    }
    async selectIfBusinessPartnerEntity(id) {
        if (id) {
            const company = await this.getOrFetchCompanyById(id);
            if (company?.managedByExternalCompany) {
                this.selectBusinessPartnerById(id);
            }
        }
    }
    getCompanyById(id) {
        const companies = this.getAllStoredCompanies();
        return companies[id];
    }
    async getOrFetchCompanyById(id) {
        if (!id) {
            return;
        }
        let company = this.getCompanyById(id);
        if (!company) {
            await this.loadCompanyById(id);
            company = this.getCompanyById(id);
        }
        return company;
    }
    getSelectedCompany() {
        const id = this.companiesStore.selectedId;
        if (!id) {
            return;
        }
        return this.getAllStoredCompanies()[id];
    }
    getSelectedBusinessPartner() {
        const id = this.companiesStore.selectedBusinessPartnerId;
        if (!id) {
            return;
        }
        return this.getAllStoredCompanies()[id];
    }
    getActiveCompany() {
        const id = this.companiesStore.selectedBusinessPartnerId || this.companiesStore.selectedId;
        if (!id) {
            return;
        }
        return this.getAllStoredCompanies()[id];
    }
    getParentCompanyId(bpcId) {
        return this.getAllStoredCompanies()[bpcId].managedById ?? "";
    }
    getMyCompanies() {
        const myCompanyIds = this.companiesStore.myCompanyIds;
        return this.sortCompaniesByName(_.values(this.getAllStoredCompanies())
            .filter((company) => !company.managedByExternalCompany)
            .filter((company) => myCompanyIds.includes(company.id)));
    }
    async deleteCompany(companyId) {
        await this.httpService.delete(`companies/${companyId}`);
        this.companiesStore.removeCompanyById(companyId);
    }
    searchCompanies(dto = {}) {
        return this.httpService.post("companies/search", dto).then((res) => res.results ?? []);
    }
    getAllStoredCompanies() {
        return this.companiesStore.byId;
    }
    getFirstAvailableCompany() {
        return this.getMyCompanies()[0];
    }
    sortCompaniesByName(companies) {
        return _.sortBy(companies, [(company) => company.name.toLowerCase()]);
    }
};
CompanyService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof RestClientService !== "undefined" && RestClientService) === "function" ? _a : Object, typeof (_b = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _b : Object, typeof (_c = typeof UserStorageService !== "undefined" && UserStorageService) === "function" ? _c : Object, typeof (_d = typeof SessionStorageService !== "undefined" && SessionStorageService) === "function" ? _d : Object, typeof (_e = typeof ToastService !== "undefined" && ToastService) === "function" ? _e : Object])
], CompanyService);
export { CompanyService };
