import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-07754147"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "hose-specification-input" };
const _hoisted_2 = { class: "title" };
const _hoisted_3 = { class: "hose-specification col-3" };
const _hoisted_4 = { class: "unit-wrapper" };
const _hoisted_5 = { class: "hose-specification col-3" };
const _hoisted_6 = { class: "unit-wrapper" };
const _hoisted_7 = { class: "hose-specification col-3" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate("hose-specification.title")), 1),
        _createVNode(_component_AppForm, {
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            class: "hose-specifications"
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_InputLabel, { class: "label" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("hose-specification.length")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_InputNumber, {
                                    class: "inputnumber",
                                    min: 0,
                                    mode: "decimal",
                                    maxIntegerDigits: 4,
                                    maxFractionDigits: 2,
                                    placeholder: _ctx.translate('hose-specification.length-placeholder'),
                                    name: "length"
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_InputLabel, { class: "label" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("measurement.meter")), 1)
                                    ]),
                                    _: 1
                                })
                            ]),
                            _createVNode(_component_FormError, { name: "length" })
                        ]),
                        _: 1
                    })
                ]),
                _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_InputLabel, { class: "label" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("hose-specification.diameter")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                                _createVNode(_component_InputNumber, {
                                    class: "inputnumber",
                                    min: 0,
                                    mode: "decimal",
                                    maxIntegerDigits: 4,
                                    maxFractionDigits: 2,
                                    placeholder: _ctx.translate('hose-specification.diameter-placeholder'),
                                    name: "diameter"
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_InputLabel, { class: "label" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("global.measurement.inch")), 1)
                                    ]),
                                    _: 1
                                })
                            ]),
                            _createVNode(_component_FormError, { name: "diameter" })
                        ]),
                        _: 1
                    })
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_InputLabel, { class: "label" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("hose-specification.elasticity")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputNumber, {
                                class: "inputnumber",
                                min: 0,
                                mode: "decimal",
                                maxIntegerDigits: 4,
                                maxFractionDigits: 2,
                                placeholder: _ctx.translate('hose-specification.elasticity-placeholder'),
                                name: "elasticity"
                            }, null, 8, ["placeholder"]),
                            _createVNode(_component_FormError, { name: "elasticity" })
                        ]),
                        _: 1
                    })
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "initial"])
    ]));
}
