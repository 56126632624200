import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputText = _resolveComponent("InputText");
    const _component_Textarea = _resolveComponent("Textarea");
    const _component_InputUrl = _resolveComponent("InputUrl");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_Button = _resolveComponent("Button");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_openBlock(), _createBlock(_component_Modal, {
        showCloseIcon: true,
        "modal-id": _ctx.modalId,
        header: _ctx.translate('create')
    }, {
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    modelValue: _ctx.webhook,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.webhook) = $event)),
                    errors: _ctx.errors,
                    onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event)),
                    class: "create-webhook-modal"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.webhook-trigger.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Dropdown, {
                                    name: "trigger",
                                    options: _ctx.webhookTrigger,
                                    optionValue: "id",
                                    optionLabel: "name",
                                    dictionaryId: "WebhookTrigger",
                                    placeholder: _ctx.translate('form-fields.webhook-trigger.placeholder'),
                                    required: ""
                                }, null, 8, ["options", "placeholder"]),
                                _createVNode(_component_FormError, { name: "trigger" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.webhook-name.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _withDirectives(_createVNode(_component_InputText, {
                                    placeholder: _ctx.translate('form-fields.webhook-name.placeholder'),
                                    name: "name",
                                    required: ""
                                }, null, 8, ["placeholder"]), [
                                    [_directive_default_validation]
                                ]),
                                _createVNode(_component_FormError, { name: "name" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.webhook-description.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _withDirectives(_createVNode(_component_Textarea, {
                                    name: "description",
                                    placeholder: _ctx.translate('form-fields.webhook-description.placeholder'),
                                    autoResize: true,
                                    rows: "2",
                                    cols: "30"
                                }, null, 8, ["placeholder"]), [
                                    [_directive_default_validation]
                                ]),
                                _createVNode(_component_FormError, { name: "description" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.webhook-url.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _withDirectives(_createVNode(_component_InputUrl, {
                                    name: "uri",
                                    placeholder: _ctx.translate('form-fields.webhook-url.placeholder'),
                                    url: "",
                                    required: ""
                                }, null, 8, ["placeholder"]), [
                                    [_directive_default_validation]
                                ]),
                                _createVNode(_component_FormError, { name: "uri" })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["modelValue", "errors"])),
            _createVNode(_component_ButtonGroup, { orientation: "horizontal" }, {
                default: _withCtx(() => [
                    _createVNode(_component_Button, {
                        class: "p-button-lg p-button-tertiary",
                        onClick: _ctx.close,
                        id: "cancel-button"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_Button, {
                        class: "p-button-lg",
                        loading: _ctx.isCreating,
                        disabled: !_ctx.isValid,
                        onClick: _ctx.create,
                        id: "create-button"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.create")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "disabled", "onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
