import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7c2d9025"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "container" };
const _hoisted_2 = { class: "roles" };
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
    class: "permissions",
    ref: "permissions"
};
const _hoisted_5 = { class: "permission-header" };
const _hoisted_6 = { class: "permission-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_Icon = _resolveComponent("Icon");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        showCloseIcon: true,
        "modal-id": _ctx.modalId,
        header: _ctx.translate('header')
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role) => {
                        return (_openBlock(), _createElementBlock("div", {
                            class: _normalizeClass(["role", { 'role-active': role.id === _ctx.selectedRole }]),
                            key: role.id,
                            onClick: ($event) => (_ctx.setRole(role.id))
                        }, _toDisplayString(_ctx.dictionaryTranslate("Roles", role)), 11, _hoisted_3));
                    }), 128))
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("permission-header")), 1),
                    _createElementVNode("div", _hoisted_6, [
                        (_ctx.isLoading)
                            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                                key: 0,
                                center: ""
                            }))
                            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.permissions.values, (value) => {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: value.dictionaryValueId,
                                    class: "permission-item"
                                }, [
                                    _createVNode(_component_Icon, { name: "check_circle" }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.dictionaryGroupTranslate(_ctx.permissions, value)), 1)
                                ]));
                            }), 128))
                    ])
                ], 512)
            ])
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
