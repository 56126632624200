import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-559bf9e8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-infrastructures-nearby" };
const _hoisted_2 = {
    key: 0,
    class: "row col-xl-9"
};
const _hoisted_3 = { class: "bold-label" };
const _hoisted_4 = {
    key: 1,
    class: "no-infrastructure-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.infrastructureConfig.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infrastructureConfig, (info) => {
                    return (_openBlock(), _createElementBlock("div", {
                        class: "col-6 info-wrapper",
                        key: info.translateKey
                    }, [
                        _createVNode(_component_Icon, {
                            class: "icon icon-styles",
                            name: info.iconName
                        }, null, 8, ["name"]),
                        _createElementVNode("span", null, [
                            _createElementVNode("b", _hoisted_3, _toDisplayString(_ctx.translate(info.translateKey)), 1),
                            _createTextVNode(" - " + _toDisplayString(_ctx.model[info.nameValue]) + " " + _toDisplayString(_ctx.translate("measurement.kilometers")), 1)
                        ])
                    ]));
                }), 128))
            ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.translate("components.display-infrastructures-nearby.no-infrastructures")), 1))
    ]));
}
