import { createModule } from "@/di/module";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { DomainService } from "@/app/modular-content/services/domain.service";
import { DomainRouteService } from "@/app/modular-content/services/domain-route.service";
export const DomainServiceModule = (options) => {
    const tokens = domainTokensFactory(options.id);
    return createModule({
        providers: [
            { token: tokens.service, useClass: DomainService },
            { token: DomainService, useToken: tokens.service },
            { token: tokens.route, useClass: DomainRouteService },
            { token: DomainRouteService, useToken: tokens.route },
        ],
    });
};
