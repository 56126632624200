import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ad18887a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "name" };
const _hoisted_2 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { key: 1 };
const _hoisted_6 = { class: "d-flex flex-column" };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = { key: 1 };
const _hoisted_9 = { class: "d-flex flex-column" };
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Column = _resolveComponent("Column");
    const _component_Badge = _resolveComponent("Badge");
    const _component_TableRowContextMenu = _resolveComponent("TableRowContextMenu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.loadMethod,
        isLoading: _ctx.isLoading,
        isError: _ctx.isError,
        error: _ctx.errorResponse,
        contextTranslate: _ctx.translate,
        class: "buildings-list"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                value: slotProps.data.results,
                onRowClick: _ctx.navigateTo,
                columnsToggleDefault: _ctx.columnsToggleDefault,
                totalRecords: slotProps.data.count,
                tableFilters: _ctx.filters,
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onUpdateFilters
                ],
                visibleColumns: _ctx.visibleColumns,
                "onUpdate:visibleColumns": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.visibleColumns) = $event)),
                    _ctx.delegate.setColumns
                ],
                lazy: true,
                ref: "buildingTable",
                loading: _ctx.isLoading,
                onPage: _cache[2] || (_cache[2] = ($event) => (_ctx.onPage($event))),
                first: _ctx.lazyParams.first
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_Column, {
                        field: "name",
                        header: _ctx.translate('columns.name'),
                        style: { "min-width": "10rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            _createElementVNode("div", _hoisted_1, _toDisplayString(data.name ?? "-"), 1)
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    (_ctx.isColumnVisible('referenceNumber'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            field: "referenceNumber",
                            header: _ctx.getColumnHeader('referenceNumber'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.referenceNumber?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(data.referenceNumber), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_3, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('loadingPointCount'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 1,
                            field: "loadingPointCount",
                            header: _ctx.getColumnHeader('loadingPointCount'),
                            dataType: "numeric",
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.loadingPointCount)
                                    ? (_openBlock(), _createBlock(_component_Badge, { key: 0 }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.loadingPointCount), 1)
                                        ]),
                                        _: 2
                                    }, 1024))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_4, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('unloadingPointCount'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 2,
                            field: "unloadingPointCount",
                            header: _ctx.getColumnHeader('unloadingPointCount'),
                            dataType: "numeric",
                            style: { "min-width": "8rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.unloadingPointCount)
                                    ? (_openBlock(), _createBlock(_component_Badge, { key: 0 }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.unloadingPointCount), 1)
                                        ]),
                                        _: 2
                                    }, 1024))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_5, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('entityCreationDate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 3,
                            field: "entityCreationDate",
                            header: _ctx.getColumnHeader('entityCreationDate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_6, [
                                    (data.createdTime && data.createdBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.createdTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.createdBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_8, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('updatedTime'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 4,
                            field: "updatedTime",
                            header: _ctx.getColumnHeader('updatedTime'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_9, [
                                    (data.updatedTime && data.updatedBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.updatedTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.updatedBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_11, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.roleCheck(_ctx.$permissions.canCRUDEntity, _ctx.entity?.companyId))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 5,
                            style: { "min-width": "6rem" },
                            class: "not-resizable-column"
                        }, {
                            body: _withCtx((slotProps) => [
                                _createVNode(_component_TableRowContextMenu, {
                                    "context-translate": _ctx.translate,
                                    data: slotProps.data,
                                    onDelete: _ctx.delegate.deleteItem
                                }, null, 8, ["context-translate", "data", "onDelete"])
                            ]),
                            _: 2
                        }, 1024))
                        : _createCommentVNode("", true)
                ]),
                _: 2
            }, 1032, ["value", "onRowClick", "columnsToggleDefault", "totalRecords", "tableFilters", "filters", "onUpdate:filters", "visibleColumns", "onUpdate:visibleColumns", "loading", "first"])
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
