import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_DropdownMenuOverlay = _resolveComponent("DropdownMenuOverlay");
    return (_openBlock(), _createBlock(_component_DropdownMenuOverlay, {
        class: "top-navigation-language-menu",
        overlayClass: "top-navigation-lang-dropdown"
    }, {
        button: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.selectedLabel(_ctx.selectedDataLanguage)), 1),
            _createVNode(_component_Icon, {
                name: "arrow_drop_down",
                variant: "rounded"
            })
        ]),
        items: _withCtx(({ hideOverlay }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataLanguages, (language) => {
                return (_openBlock(), _createBlock(_component_MenuItem, {
                    key: language.value,
                    label: language.label,
                    id: "top-navigation-language-menu-item",
                    onClick: ($event) => (_ctx.changeDataLangauge(language.value, { hideOverlay })),
                    icon: _ctx.isSelected(language.value) ? 'check' : 'none'
                }, null, 8, ["label", "onClick", "icon"]));
            }), 128))
        ]),
        _: 1
    }));
}
