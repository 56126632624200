import { resolve } from "@/di/composables/resolve";
import { DictionaryTranslationService } from "@/app/translation/services/dictionary-translation.service";
export const useDictionaryTranslate = () => {
    const dictionaryTranslationService = resolve(DictionaryTranslationService);
    return (dictionaryId, value = {}, param) => {
        const paramName = param ? param : "name";
        const translatedName = dictionaryTranslationService.translate(`${dictionaryId}.${value?.id}.${paramName}`) ?? value[paramName];
        return translatedName;
    };
};
export const useDictionariesTranslate = () => {
    const dictionaryTranslationService = resolve(DictionaryTranslationService);
    return (dictionaryIds, value = {}, param, additionalParam) => {
        const paramName = param ? param : "name";
        let translatedName = value[paramName];
        for (const dictionaryName of dictionaryIds) {
            const name = dictionaryTranslationService.translate(`${dictionaryName}.${value?.id}.${paramName}`);
            if (name.split(".").length === 1) {
                if (additionalParam) {
                    const additionalName = dictionaryTranslationService.translate(`${dictionaryName}.${value?.id}.${additionalParam}`);
                    translatedName = `${name} - ${additionalName}`;
                }
                else {
                    translatedName = name;
                }
                break;
            }
        }
        return translatedName;
    };
};
export const useDictionaryGroupTranslate = () => {
    const dictionaryTranslationService = resolve(DictionaryTranslationService);
    return (dictionaryGroup = {}, value = {}) => {
        const translatedName = dictionaryTranslationService.translate(`${dictionaryGroup?.dictionaryId}.${value?.dictionaryValueId}.name`);
        return translatedName;
    };
};
