import { defineStore } from "pinia";
const initialState = {
    token: undefined,
};
export const useAuthStore = defineStore({
    id: "auth",
    state: () => initialState,
    actions: {
        setToken(token) {
            this.token = token;
        },
        clearToken() {
            this.token = undefined;
        },
    },
});
