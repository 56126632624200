import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-713b6a00"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "input-info" };
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = { class: "link-label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_Icon = _resolveComponent("Icon");
    const _component_Button = _resolveComponent("Button");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createBlock(_component_Modal, {
        showCloseIcon: true,
        "modal-id": _ctx.modalId,
        class: "platform-link-modal"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_AppForm, {
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event))
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, { required: "" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.link-validity.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputNumber, {
                                name: "linkValidity",
                                mode: "integer",
                                min: 1,
                                max: 156,
                                placeholder: _ctx.translate('form-fields.link-validity.placeholder'),
                                disabled: _ctx.isLoading || _ctx.linkHasBeenGenerated,
                                required: ""
                            }, null, 8, ["placeholder", "disabled"]),
                            _createVNode(_component_FormError, { name: "linkValidity" })
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_Icon, {
                    name: "info",
                    variant: "outlined"
                }),
                _createElementVNode("span", {
                    innerHTML: _ctx.translate('form-fields.link-validity-info')
                }, null, 8, _hoisted_2)
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", {
                    onClick: _cache[2] || (_cache[2] =
                        //@ts-ignore
                        (...args) => (_ctx.generate && _ctx.generate(...args))),
                    class: _normalizeClass([{ disabled: !_ctx.isValid || _ctx.isLoading || _ctx.linkHasBeenGenerated }, "link-container"])
                }, [
                    _createElementVNode("div", null, [
                        _createVNode(_component_Icon, { name: "copy_all" })
                    ]),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("form-fields.generate-link")), 1)
                ], 2)
            ])), [
                [
                    _directive_tooltip,
                    {
                        value: _ctx.translate('form-fields.link-already-generated-tooltip'),
                        disabled: !_ctx.linkHasBeenGenerated,
                    },
                    void 0,
                    { left: true }
                ]
            ]),
            _createVNode(_component_ButtonGroup, { orientation: "horizontal" }, {
                default: _withCtx(() => [
                    _createVNode(_component_Button, {
                        class: "p-button-lg",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.close")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id"]));
}
