import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_VirtualScroller = _resolveComponent("VirtualScroller");
    return (_openBlock(), _createBlock(_component_VirtualScroller, {
        class: "virtual-scroller",
        items: _ctx.items,
        itemSize: _ctx.itemHeight,
        scrollHeight: _ctx.clientHeight + 'px'
    }, {
        item: _withCtx(({ item }) => [
            _renderSlot(_ctx.$slots, "item", { item: item })
        ]),
        _: 3
    }, 8, ["items", "itemSize", "scrollHeight"]));
}
