import { defineStore } from "pinia";
const initialState = {
    byId: {},
    isVerifier: false,
    isPublicApiConsumer: false,
};
export const useRolesStore = defineStore({
    id: "roles",
    state: () => initialState,
    getters: {
        hasPermission: (state) => !state.isPublicApiConsumer,
    },
    actions: {
        setRole(role) {
            this.byId[role.companyId] = role.roleId;
        },
        setPublicApiConsumer(value) {
            this.isPublicApiConsumer = value;
        },
        setIsVerifier(value) {
            this.isVerifier = value;
        },
    },
});
