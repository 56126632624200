import { reactive, shallowReactive } from "vue";
import { ReactiveMetadata } from "@/di/metadata/vue-metadata";
import { ReactiveType } from "@/di/types/reactive";
export const reactiveInstanceSetup = (target) => {
    const reactiveType = ReactiveMetadata.get(target);
    switch (reactiveType) {
        case ReactiveType.None:
            return target;
        case ReactiveType.Shallow:
            return shallowReactive(target);
        case ReactiveType.Deep:
            return reactive(target);
    }
};
