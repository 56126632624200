import _ from "lodash";
import { isReturnWithError } from "@/app/shared/errors/return-with.error";
const getMethods = (target) => Object.getOwnPropertyNames(target).filter((item) => typeof _.get(target, item) === "function");
const handleReturnWithError = (e) => {
    if (isReturnWithError(e)) {
        return e.value;
    }
    throw e;
};
export const returnWithSetup = (target) => {
    const methods = getMethods(Object.getPrototypeOf(target))
        .filter((it) => it !== "constructor")
        .map((it) => ({
        key: it,
        value: _.get(target, it),
    }));
    const keyedMethods = _.mapValues(_.keyBy(methods, "key"), ({ value }) => value);
    _.forEach(keyedMethods, (method, key) => {
        const original = _.get(target, key);
        _.set(target, key, (...args) => {
            try {
                const result = original.apply(target, args);
                if (result && result["then"] && result["catch"]) {
                    return result.catch((e) => {
                        handleReturnWithError(e);
                    });
                }
                return result;
            }
            catch (e) {
                return handleReturnWithError(e);
            }
        });
    });
    return target;
};
