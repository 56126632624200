var _a, _b;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { LocalStorageService } from "@/app/shared/services/local-storage.service";
import { CurrentUserService } from "@/app/users/types/current-user-service.type";
import { inject } from "@/di/decorators/inject.decorator";
import { CurrentUserServiceToken } from "@/app/users/tokens/current-user-service.token";
let UserStorageService = class UserStorageService {
    localStorageService;
    userService;
    constructor(localStorageService, userService) {
        this.localStorageService = localStorageService;
        this.userService = userService;
    }
    clear(path) {
        this.localStorageService.clear(`${this.prefix()}.${path}`);
    }
    get(path) {
        return this.localStorageService.get(`${this.prefix()}.${path}`);
    }
    set(path, value) {
        this.localStorageService.set(`${this.prefix()}.${path}`, value);
    }
    prefix() {
        return this.userService.getCurrentUserId();
    }
};
UserStorageService = __decorate([
    service(),
    __param(1, inject(CurrentUserServiceToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof LocalStorageService !== "undefined" && LocalStorageService) === "function" ? _a : Object, typeof (_b = typeof CurrentUserService !== "undefined" && CurrentUserService) === "function" ? _b : Object])
], UserStorageService);
export { UserStorageService };
