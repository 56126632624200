var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ChangeDetectionServiceToken } from "@/app/modular-content/token/modular-content.token";
import { CompanySettingsService } from "@/app/domains/public-data/companies/company-overview/company-settings/services/company-settings.service";
let CompanySettingsChangeDetectionService = class CompanySettingsChangeDetectionService {
    companySettingsService;
    constructor(companySettingsService) {
        this.companySettingsService = companySettingsService;
    }
    getChanges() {
        return this.companySettingsService.getChanges();
    }
    hasChanges() {
        return this.companySettingsService.hasChanges();
    }
    discardChanges() {
        this.companySettingsService.discard();
    }
    getDomainId() {
        return "";
    }
    getModuleId() {
        return "";
    }
};
CompanySettingsChangeDetectionService = __decorate([
    service({ token: ChangeDetectionServiceToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof CompanySettingsService !== "undefined" && CompanySettingsService) === "function" ? _a : Object])
], CompanySettingsChangeDetectionService);
export { CompanySettingsChangeDetectionService };
