var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { CompanyService } from "@/app/domains/public-data/companies/services/company.service";
import { RolesService } from "@/app/shared/services/roles.service";
import { SessionStorageService } from "@/app/shared/services/session-storage.service";
import { UserStorageService } from "@/app/shared/services/user-storage.service";
import { Permissions } from "@/app/shared/constants/permissions.constants";
let CompanyAppInitService = class CompanyAppInitService {
    userStorageService;
    sessionStorageService;
    companyService;
    rolesService;
    constructor(userStorageService, sessionStorageService, companyService, rolesService) {
        this.userStorageService = userStorageService;
        this.sessionStorageService = sessionStorageService;
        this.companyService = companyService;
        this.rolesService = rolesService;
    }
    async onAppInit() {
        await this.companyService.loadMyCompanies();
        if (this.companyService.getMyCompanies().length === 0) {
            return;
        }
        const sessionBusinessPartnerId = this.sessionStorageService.get("companies.selectedBusinessPartnerId");
        const sessionCompanyId = this.sessionStorageService.get("companies.selectedId");
        const localBusinessPartnerId = this.userStorageService.get("companies.selectedBusinessPartnerId");
        const localCompanyId = this.userStorageService.get("companies.selectedId");
        if (sessionBusinessPartnerId || sessionCompanyId) {
            await this.checkSelection(sessionCompanyId);
            await this.checkSelection(sessionBusinessPartnerId);
        }
        else if (localCompanyId || localBusinessPartnerId) {
            await this.checkSelection(localCompanyId);
            await this.checkSelection(localBusinessPartnerId);
        }
        if (this.companyService.getSelectedCompany()) {
            return;
        }
        this.companyService.selectFirstAvailableCompany();
    }
    async checkSelection(companyId) {
        if (companyId && (await this.roleBelongsToCompany(companyId))) {
            await this.companyService.selectCompany(companyId);
        }
    }
    async roleBelongsToCompany(companyId) {
        try {
            await this.rolesService.loadRole(companyId);
            return this.rolesService.hasRole(Permissions.belongsToCompany, companyId);
        }
        catch {
            return false;
        }
    }
};
CompanyAppInitService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof UserStorageService !== "undefined" && UserStorageService) === "function" ? _a : Object, typeof (_b = typeof SessionStorageService !== "undefined" && SessionStorageService) === "function" ? _b : Object, typeof (_c = typeof CompanyService !== "undefined" && CompanyService) === "function" ? _c : Object, typeof (_d = typeof RolesService !== "undefined" && RolesService) === "function" ? _d : Object])
], CompanyAppInitService);
export { CompanyAppInitService };
