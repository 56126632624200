export var CompanyRole;
(function (CompanyRole) {
    CompanyRole["Member"] = "member";
    CompanyRole["DataManager"] = "dataManager";
    CompanyRole["ProductManager"] = "productManager";
    CompanyRole["RequirementManager"] = "requirementManager";
    CompanyRole["TenderManager"] = "tenderManager";
    CompanyRole["CompanyAdmin"] = "companyAdmin";
    CompanyRole["LoadyAdmin"] = "loadyAdmin";
})(CompanyRole || (CompanyRole = {}));
