var _a, _b;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { CompanyService } from "@/app/domains/public-data/companies/services/company.service";
import { useRolesStore } from "@/app/shared/state/roles.state";
let RolesService = class RolesService {
    httpClient;
    companyService;
    rolesStore;
    constructor(httpClient, companyService) {
        this.httpClient = httpClient;
        this.companyService = companyService;
        this.rolesStore = useRolesStore();
    }
    async loadRole(companyId) {
        const { role, isVerifier, isPublicApiConsumer } = await this.httpClient.post(`members/role/my`, companyId
            ? {
                companyId,
            }
            : {});
        this.rolesStore.setIsVerifier(isVerifier);
        if (role?.roleId) {
            this.rolesStore.setRole(role);
        }
        this.rolesStore.setPublicApiConsumer(isPublicApiConsumer);
    }
    getRoleForActiveCompany() {
        const companyId = this.companyService.getActiveCompany()?.id;
        return this.rolesStore.byId[companyId];
    }
    hasRole(roleArr, companyId) {
        const id = companyId ?? this.companyService.getActiveCompany()?.id;
        const role = this.rolesStore.byId[id];
        if (!role) {
            this.loadRole(id);
        }
        return role ? roleArr.includes(role) : false;
    }
    isVerifier() {
        return this.rolesStore.isVerifier;
    }
};
RolesService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof CompanyService !== "undefined" && CompanyService) === "function" ? _b : Object])
], RolesService);
export { RolesService };
