var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let OneWordValidator = class OneWordValidator {
    translationService;
    name = "one-word";
    constructor(translationService) {
        this.translationService = translationService;
    }
    message() {
        return this.translationService.translate("validation.messages.one-word");
    }
    validate(value) {
        if (!value) {
            return true;
        }
        value = value.replace(/(^\s*)|(\s*$)/gi, "");
        value = value.replace(/[ ]{2,}/gi, " ");
        value = value.replace(/\n /, "\n");
        return value.split(" ").length <= 1;
    }
};
OneWordValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], OneWordValidator);
export { OneWordValidator };
