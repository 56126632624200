export var HeatingMedium;
(function (HeatingMedium) {
    HeatingMedium["Water"] = "Water";
    HeatingMedium["Steam"] = "Steam";
    HeatingMedium["Oil"] = "Oil";
    HeatingMedium["Glycol"] = "Glycol";
    HeatingMedium["Electric"] = "Electric";
})(HeatingMedium || (HeatingMedium = {}));
export var TempUnits;
(function (TempUnits) {
    TempUnits["Celsius"] = "Celsius";
    TempUnits["Farenheit"] = "Farenheit";
})(TempUnits || (TempUnits = {}));
export var HeatingOrCoolingDuringTransport;
(function (HeatingOrCoolingDuringTransport) {
    HeatingOrCoolingDuringTransport["NotRequired"] = "NotRequired";
    HeatingOrCoolingDuringTransport["Required"] = "Required";
    HeatingOrCoolingDuringTransport["NotAllowed"] = "NotAllowed";
})(HeatingOrCoolingDuringTransport || (HeatingOrCoolingDuringTransport = {}));
