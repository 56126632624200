import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputText = _resolveComponent("InputText");
    return (_openBlock(), _createBlock(_component_InputText, {
        id: _ctx.id,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        type: "text",
        name: _ctx.name,
        placeholder: _ctx.placeholder,
        maxLength: _ctx.maxOrSafeLength,
        disabled: _ctx.disabled,
        onInput: _ctx.onInput
    }, null, 8, ["id", "modelValue", "name", "placeholder", "maxLength", "disabled", "onInput"]));
}
