import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors,
            class: "edit-ulp-basic-information row"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, { class: "col-lg-7" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { required: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix("label", true)}`)), 1)
                            ]),
                            _: 1
                        }),
                        _withDirectives(_createVNode(_component_InputText, {
                            name: "name",
                            placeholder: _ctx.translate(`form-fields.name.${_ctx.domainTranslationPrefix('placeholder', true)}`),
                            required: ""
                        }, null, 8, ["placeholder"]), [
                            [_directive_default_validation]
                        ]),
                        _createVNode(_component_FormError, { name: "name" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-lg-7" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, { required: "" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(`form-fields.reference-number.${_ctx.domainTranslationPrefix("label", true)}`)), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_InputText, {
                            name: "referenceNumber",
                            placeholder: _ctx.translate(`form-fields.reference-number.${_ctx.domainTranslationPrefix('placeholder', true)}`),
                            "min-length": "1",
                            "max-length": "30",
                            required: ""
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_FormError, { name: "referenceNumber" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-lg-7" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("building.label")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_Dropdown, {
                            id: "building-dropdown",
                            name: "buildingId",
                            options: _ctx.buildings,
                            optionValue: "id",
                            optionLabel: "name",
                            placeholder: _ctx.translate('building.placeholder'),
                            loading: _ctx.isLoading,
                            additionalLabel: "referenceNumber",
                            filterFields: ['referenceNumber']
                        }, null, 8, ["options", "placeholder", "loading"]),
                        _createVNode(_component_FormError, { name: "buildingId" })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
