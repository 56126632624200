var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { reactive } from "@/di/decorators/computed.decorator";
import { useListIdStore } from "@/app/modular-content/stores/list-id.state";
import { DomainRouteService } from "@/app/modular-content/services/domain-route.service";
import { RouterService } from "@/app/shared/services/router.service";
import { translationDomainPrefix } from "@/app/modular-content/modules/functions/translation-domain-prefix.function";
let ContextMenuService = class ContextMenuService {
    routerService;
    store = useListIdStore();
    listKey = "";
    backLink = { label: "", route: "" };
    menuItems = [];
    header = undefined;
    constructor(routerService) {
        this.routerService = routerService;
    }
    setListKey(key) {
        this.listKey = key;
    }
    setBackLink(link) {
        this.backLink = link;
    }
    getListId() {
        return this.store.referringListByDomainId[this.listKey]?.listId;
    }
    getReferringDomainId() {
        return this.store.referringListByDomainId[this.listKey]?.domainId;
    }
    getBackRoute() {
        const routeService = new DomainRouteService(this.getReferringDomainId() ?? this.listKey, this.routerService);
        return routeService.getListRoute(this.getListId());
    }
    getBackLabel() {
        const referringDomainId = this.getReferringDomainId();
        if (referringDomainId) {
            return `${translationDomainPrefix({ id: referringDomainId })}.back-link-${this.getListId()}`;
        }
        return this.getListId() ? `${this.backLink.label}-${this.getListId()}` : this.backLink.label;
    }
    isVisible() {
        return this.menuItems.length > 0;
    }
    setMenuItems(menuItems) {
        this.menuItems.splice(0, this.menuItems.length);
        this.menuItems.push(...menuItems);
    }
    getMenuItems() {
        return this.menuItems;
    }
    setHeader(header) {
        this.header = header;
    }
    getHeader() {
        return this.header;
    }
    hide() {
        this.backLink = { label: "", route: "" };
        this.header = undefined;
        this.menuItems.splice(0, this.menuItems.length);
    }
};
__decorate([
    reactive(),
    __metadata("design:type", Object)
], ContextMenuService.prototype, "backLink", void 0);
__decorate([
    reactive(),
    __metadata("design:type", Array)
], ContextMenuService.prototype, "menuItems", void 0);
ContextMenuService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof RouterService !== "undefined" && RouterService) === "function" ? _a : Object])
], ContextMenuService);
export { ContextMenuService };
