import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f8367dcc"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "company-overview-title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EntityLabel = _resolveComponent("EntityLabel");
    const _component_Id = _resolveComponent("Id");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_EntityLabel, {
            name: _ctx.entityLabel,
            isGray: _ctx.bpcActive
        }, null, 8, ["name", "isGray"]),
        _createElementVNode("h1", null, _toDisplayString(_ctx.company?.name), 1),
        _createVNode(_component_Id, null, {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.company?.id), 1)
            ]),
            _: 1
        })
    ]));
}
