export const createInstanceManager = (decorators = []) => {
    const instances = new Map();
    const setInstance = (key, value) => {
        for (const decorator of decorators) {
            value = decorator(value);
        }
        instances.set(key, value);
        return value;
    };
    const getInstance = (key) => {
        return instances.get(key);
    };
    return {
        getInstance,
        setInstance,
    };
};
