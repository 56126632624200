import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-504455c6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "notification" };
const _hoisted_2 = { class: "icon" };
const _hoisted_3 = { class: "info" };
const _hoisted_4 = { class: "header" };
const _hoisted_5 = { class: "description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "header", {}, undefined, true)
            ]),
            _createElementVNode("p", _hoisted_5, [
                _renderSlot(_ctx.$slots, "description", {}, undefined, true)
            ])
        ])
    ]));
}
