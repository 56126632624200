import { useInstance } from "@/app/shared/composables/use-instance.composable";
import { onMounted } from "vue";
export const useMatomo = () => {
    let instance;
    onMounted(() => {
        instance = useInstance();
    });
    const setMatomoConsent = (consentGiven) => {
        if (consentGiven) {
            instance.proxy?.$matomo?.setConsentGiven();
            instance.proxy?.$matomo?.setCookieConsentGiven();
            return;
        }
        instance.proxy?.$matomo?.forgetConsentGiven();
        instance.proxy?.$matomo?.forgetCookieConsentGiven();
    };
    return {
        setMatomoConsent,
    };
};
