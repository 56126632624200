import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-51c6f4c1"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "content-wrapper px-2" };
const _hoisted_2 = { class: "mb-3" };
const _hoisted_3 = { class: "feedback-header mb-3" };
const _hoisted_4 = { class: "row mb-2 created-label" };
const _hoisted_5 = { class: "col-8" };
const _hoisted_6 = { class: "col-4" };
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "col-8" };
const _hoisted_9 = { class: "gray-tag" };
const _hoisted_10 = { class: "col-4" };
const _hoisted_11 = { class: "gray-tag" };
const _hoisted_12 = { class: "mt-4" };
const _hoisted_13 = { class: "feedback-label" };
const _hoisted_14 = { class: "feedback" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_EntityLabel = _resolveComponent("EntityLabel");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Button = _resolveComponent("Button");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        header: _ctx.translate('customer-feedback'),
        class: "feedback-modal"
    }, {
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feedbacks, (it) => {
                    return (_openBlock(), _createElementBlock("div", {
                        class: "feedback-container mb-4",
                        key: it.date
                    }, [
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate("feedback-for")) + " " + _toDisplayString(_ctx.translate(`modules.${_ctx.paramCase(it.sectionType)}.edit`)), 1),
                            _createVNode(_component_EntityLabel, {
                                class: "mx-4",
                                name: _ctx.getEntityName(it.entityType),
                                isGray: ""
                            }, null, 8, ["name"])
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("created-by")), 1),
                            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("created-on")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("span", _hoisted_9, _toDisplayString(it.verifierName) + " | " + _toDisplayString(it.verifierEmail), 1)
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getDateTime(it.date)), 1)
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.translate("feedback-label")), 1),
                            _createElementVNode("div", _hoisted_14, _toDisplayString(it.feedback), 1)
                        ]),
                        _createVNode(_component_ButtonGroup, {
                            orientation: "horizontal",
                            class: "mt-2"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_AppButton, {
                                    class: "mt-3",
                                    onClick: ($event) => (_ctx.openLink(it))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("go-to-section")) + " ", 1),
                                        _createVNode(_component_Icon, {
                                            class: "icon-button",
                                            name: "launch"
                                        })
                                    ]),
                                    _: 2
                                }, 1032, ["onClick"])
                            ]),
                            _: 2
                        }, 1024)
                    ]));
                }), 128))
            ]),
            _createVNode(_component_ButtonGroup, {
                orientation: "horizontal",
                class: "mt-2"
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_Button, {
                        class: "p-button-lg mx-2",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.close")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
