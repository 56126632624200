import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-68dd3e8b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "form" };
const _hoisted_2 = { class: "label" };
const _hoisted_3 = { class: "section mb-4" };
const _hoisted_4 = { class: "label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_RequiredTextInput = _resolveComponent("RequiredTextInput");
    const _component_Button = _resolveComponent("Button");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        class: "summary-feedback-form-modal",
        header: _ctx.translate('feedback-form-modal.header'),
        "modal-id": _ctx.modalId,
        showCloseIcon: false
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.translate("feedback-form-modal.section-label")), 1),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.translate(`modules.${_ctx.paramCase(_ctx.sectionType)}.edit`)), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.translate("feedback-form-modal.feedback.label")) + ":", 1),
                _createVNode(_component_RequiredTextInput, {
                    class: "text-input",
                    modelValue: _ctx.feedback,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.feedback) = $event)),
                    onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.hasFeedback = $event)),
                    placeholder: _ctx.translate('feedback-form-modal.feedback.placeholder'),
                    rows: "6",
                    "max-length": "1000"
                }, null, 8, ["modelValue", "placeholder"])
            ]),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_Button, {
                        onClick: _ctx.modalService.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_Button, {
                        onClick: _ctx.submit,
                        loading: _ctx.isSubmitting,
                        disabled: !_ctx.canSubmit
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("feedback-form-modal.create-feedback-btn")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick", "loading", "disabled"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["header", "modal-id"]));
}
