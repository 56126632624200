import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e0627f24"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "content-container" };
const _hoisted_2 = { class: "messages-container" };
const _hoisted_3 = { class: "mb-3" };
const _hoisted_4 = { class: "sub-message" };
const _hoisted_5 = { class: "sub-message" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_Button = _resolveComponent("Button");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        class: "feedback-confirmation-success-modal",
        "modal-id": _ctx.modalId,
        showCloseIcon: false,
        "show-header": false
    }, {
        default: _withCtx(() => [
            _createVNode(_component_Icon, {
                class: "close",
                name: "close",
                onClick: _ctx.close
            }, null, 8, ["onClick"]),
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_Icon, {
                    class: "checkmark",
                    name: "check_circle"
                }),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.translate("feedback-success-modal.header")), 1),
                    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.translate("feedback-success-modal.sub-message-first")), 1),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.translate("feedback-success-modal.sub-message-second")), 1)
                ]),
                _createVNode(_component_Button, { onClick: _ctx.close }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("feedback-success-modal.button")), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"])
            ])
        ]),
        _: 1
    }, 8, ["modal-id"]));
}
