var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { CompanyGroupsUrl } from "@/app/domains/public-data/public-data.constants";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { ToastService } from "@/app/shared/services/toast.service";
import { TranslationService } from "@/app/translation/services/translation.service";
let CompanyGroupService = class CompanyGroupService {
    httpClient;
    toastService;
    translationService;
    constructor(httpClient, toastService, translationService) {
        this.httpClient = httpClient;
        this.toastService = toastService;
        this.translationService = translationService;
    }
    async create(dto) {
        const companyGroup = await this.httpClient.post(CompanyGroupsUrl, dto);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate("modular-content.services.domain.create.company-group-toast-message"),
        });
        return companyGroup;
    }
    async fetchCompanyGroups() {
        return this.httpClient
            .post(`${CompanyGroupsUrl}/search`, {})
            .then((res) => (res.results ?? []));
    }
};
CompanyGroupService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object])
], CompanyGroupService);
export { CompanyGroupService };
