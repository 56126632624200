var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { LocalStorageService } from "@/app/shared/services/local-storage.service";
import { UserStorageService } from "@/app/shared/services/user-storage.service";
import { service } from "@/di/decorators/service.decorator";
import { redirectToSignOut } from "@/app/auth/functions/auth.functions";
import { useCompaniesStore } from "@/app/domains/public-data/companies/state/companies.state";
import { useAuthStore } from "@/app/auth/state/auth.state";
import { SessionStorageService } from "@/app/shared/services/session-storage.service";
let SessionService = class SessionService {
    localStorageService;
    userStorageService;
    sessionStorageService;
    companiesStore;
    authStore;
    constructor(localStorageService, userStorageService, sessionStorageService) {
        this.localStorageService = localStorageService;
        this.userStorageService = userStorageService;
        this.sessionStorageService = sessionStorageService;
        this.companiesStore = useCompaniesStore();
        this.authStore = useAuthStore();
    }
    logout() {
        this.sessionStorageService.clear("companies.selectedBusinessPartnerId");
        this.userStorageService.clear("companies.selectedBusinessPartnerId");
        this.companiesStore.setSelectedBusinessPartnerId(undefined);
        this.authStore.clearToken();
        this.localStorageService.clear("auth.token");
        redirectToSignOut();
    }
};
SessionService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof LocalStorageService !== "undefined" && LocalStorageService) === "function" ? _a : Object, typeof (_b = typeof UserStorageService !== "undefined" && UserStorageService) === "function" ? _b : Object, typeof (_c = typeof SessionStorageService !== "undefined" && SessionStorageService) === "function" ? _c : Object])
], SessionService);
export { SessionService };
