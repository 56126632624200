var _a, _b;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { CompanyService } from "@/app/domains/public-data/companies/services/company.service";
import { RouterService } from "@/app/shared/services/router.service";
let SelectedTendersLanesFilterService = class SelectedTendersLanesFilterService {
    companyService;
    routerService;
    constructor(companyService, routerService) {
        this.companyService = companyService;
        this.routerService = routerService;
    }
    createConditionalEqualFilter() {
        const companyGroupId = this.companyService.getSelectedCompany()?.companyGroupId;
        const companyId = this.companyService.getSelectedCompany()?.id;
        const query = this.routerService.getCurrentRoute()?.query;
        const filters = companyGroupId
            ? [
                {
                    propertyNames: ["CompanyFromGroupId", "CompanyToGroupId"],
                    propertyValues: [companyGroupId, companyGroupId],
                },
            ]
            : [
                {
                    propertyNames: ["CompanyFromId", "CompanyToId"],
                    propertyValues: [companyId, companyId],
                },
            ];
        if (query?.filter) {
            filters.push({
                propertyName: "TenderId",
                propertyValue: query.filter,
            });
        }
        return filters;
    }
};
SelectedTendersLanesFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof CompanyService !== "undefined" && CompanyService) === "function" ? _a : Object, typeof (_b = typeof RouterService !== "undefined" && RouterService) === "function" ? _b : Object])
], SelectedTendersLanesFilterService);
export { SelectedTendersLanesFilterService };
