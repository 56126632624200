var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { DictionariesServiceToken } from "@/app/dictionaries/tokens/dictionaries.token";
let DictionariesService = class DictionariesService {
    httpService;
    constructor(httpService) {
        this.httpService = httpService;
    }
    async getDictionary(dictionaryId) {
        const url = `dictionaries/${dictionaryId}`;
        return await this.httpService.get(url);
    }
    async getDictionaries(dictionaryIds) {
        const url = `dictionaries/?ids=${dictionaryIds}`;
        return await this.httpService.get(url);
    }
    async getDictionaryGroup(dictionaryId, group) {
        const url = `dictionaries/${dictionaryId}/${group}`;
        return await this.httpService.get(url);
    }
};
DictionariesService = __decorate([
    service({ token: DictionariesServiceToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object])
], DictionariesService);
export { DictionariesService };
