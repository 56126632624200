export const updateLocalizedModel = (modelValue, value, selectedDataLanguage) => {
    const model = modelValue ?? [];
    const currentLocalizedText = model?.find((it) => it.languageCode === selectedDataLanguage);
    let updated;
    if (currentLocalizedText) {
        currentLocalizedText.value = value;
        updated = [...model];
    }
    else {
        updated = [...model, { languageCode: selectedDataLanguage, value }];
    }
    return updated;
};
