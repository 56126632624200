var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { LocalStorageService } from "@/app/shared/services/local-storage.service";
import { parseRedirectHash, redirectToSignIn } from "@/app/auth/functions/auth.functions";
import { useAuthStore } from "@/app/auth/state/auth.state";
let AuthService = class AuthService {
    localStorageService;
    authStore;
    constructor(localStorageService) {
        this.localStorageService = localStorageService;
        this.authStore = useAuthStore();
    }
    getToken() {
        return this.authStore.token;
    }
    isLocalModeEnabled() {
        return process.env.VUE_APP_AUTH_ENABLE_LOCAL_MODE === "true";
    }
    getLocalCompanyId() {
        return process.env.VUE_APP_AUTH_LOCAL_COMPANY_ID;
    }
    getLocalUserId() {
        return process.env.VUE_APP_AUTH_LOCAL_USER_ID;
    }
    handleRedirectResponse() {
        if (!location.hash) {
            return;
        }
        const { idToken: token } = parseRedirectHash();
        this.localStorageService.set("auth.token", token);
    }
    onAppInit() {
        if (this.isLocalModeEnabled()) {
            return;
        }
        this.handleRedirectResponse();
        const token = this.localStorageService.get("auth.token");
        if (token !== undefined) {
            this.authStore.setToken(token);
            return;
        }
        redirectToSignIn();
    }
};
AuthService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof LocalStorageService !== "undefined" && LocalStorageService) === "function" ? _a : Object])
], AuthService);
export { AuthService };
