import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7eaadc4f"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-loading-related-parameters" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = { class: "row" };
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6 column-value" };
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col-6 column-header" };
const _hoisted_10 = { class: "col-6 column-value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
                class: "col-6 column-header",
                innerHTML: _ctx.translate('form-fields.loading-rate.label')
            }, null, 8, _hoisted_3),
            _createElementVNode("div", {
                class: "col-6 column-value",
                innerHTML: _ctx.loadingRate
            }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("form-fields.minimum-loading-time.label")), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.minLoadingTime), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("form-fields.loading-pressure.label")), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.loadingPressure), 1)
        ])
    ]));
}
