import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6863bd6d"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "d-flex container-fluid align-content-center" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 justify-content-center d-flex align-items-center" };
const _hoisted_4 = { class: "col-6 text-column" };
const _hoisted_5 = { class: "text-header" };
const _hoisted_6 = { class: "text-content" };
const _hoisted_7 = { class: "text-content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InlineSvg = _resolveComponent("InlineSvg");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_InlineSvg, {
                    class: "maintanance-img",
                    href: "/assets/images/maintenance/under_maintenance.svg"
                })
            ]),
            _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate("title")), 1),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.translate("body")), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.translate("caution")), 1)
            ])
        ])
    ]));
}
