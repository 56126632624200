import { onMounted, onUnmounted, reactive } from "vue";
import { useInstance } from "@/app/shared/composables/use-instance.composable";
export const useElementSize = (ref) => {
    const size = reactive({
        width: 0,
        height: 0,
    });
    const observer = new ResizeObserver((entries) => {
        const element = entries[0].target;
        size.width = element.offsetWidth;
        size.height = element.offsetHeight;
    });
    onMounted(() => {
        const instance = useInstance();
        const reference = instance.refs[ref];
        const element = reference.$el || reference;
        observer.observe(element);
    });
    onUnmounted(() => {
        observer.disconnect();
    });
    return size;
};
