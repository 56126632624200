import { camelCase, paramCase } from "change-case";
import { RoutesModule } from "@/app/shared/modules/routes.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EntityPageComponent from "@/app/modular-content/components/entity-page.component.vue";
import { RouteMetaDomainId, RouteMetaListId } from "@/app/modular-content/modular-content.constants";
import ModularContentComponent from "@/app/modular-content/modular-content.component.vue";
import EntityListsComponent from "@/app/modular-content/components/entity-lists.component.vue";
import EntityListTabComponent from "@/app/modular-content/components/entity-list-tab.component.vue";
import { ClusterDomainId } from "@/app/domains/product-clusters/product-clusters.constants";
import { LoadingPointAssignedProductClustersDomainId, UnloadingPointAssignedProductClustersDomainId, } from "@/app/domains/assigned-product-clusters/assigned-product-cluster.constants";
export const DomainRoutesModule = (options) => {
    const routeDomainId = paramCase(options.id);
    const routePath = paramCase(options.id);
    const routeIdParam = `${camelCase(options.id)}Id`;
    const fallbackRouteNames = {
        [ClusterDomainId]: "products-list/cluster",
        [LoadingPointAssignedProductClustersDomainId]: "loading-products-list/clusters",
        [UnloadingPointAssignedProductClustersDomainId]: "unloading-products-list/clusters",
    };
    if (options.standAloneDomain) {
        return RoutesModule({
            parent: options.parent ? `${paramCase(options.parent)}-single` : undefined,
            routes: [
                {
                    path: options.parent ? `${routePath}/:${routeIdParam}` : `/${routePath}/:${routeIdParam}`,
                    name: `${routeDomainId}-single`,
                    component: suffixComponentName(ModularContentComponent, options.id),
                    meta: {
                        [RouteMetaDomainId]: options.id,
                    },
                },
                {
                    path: options.parent ? routePath : `/${routePath}`,
                    name: `${routeDomainId}-root`,
                    redirect: {
                        name: fallbackRouteNames[options.id],
                    },
                },
                {
                    path: `/${routeDomainId}/${routeIdParam}/${routeDomainId}-list`,
                    name: `${routeDomainId}-list`,
                    redirect: {
                        name: fallbackRouteNames[options.id],
                    },
                },
            ],
        });
    }
    if (!options.lists?.length) {
        return RoutesModule({
            parent: options.parent ? `${paramCase(options.parent)}-single` : undefined,
            routes: [
                {
                    path: options.parent ? `${routePath}/` : `/${routePath}`,
                    name: `${routeDomainId}-page`,
                    component: suffixComponentName(EntityPageComponent, options.id),
                    meta: {
                        [RouteMetaDomainId]: options.id,
                    },
                },
            ],
        });
    }
    const firstList = options.lists[0];
    const defaultList = options.lists.find((it) => it.default) ?? firstList;
    const module = {
        parent: options.parent ? `${paramCase(options.parent)}-single` : undefined,
        routes: [
            {
                path: options.parent ? `${routePath}/:${routeIdParam}` : `/${routePath}/:${routeIdParam}`,
                name: `${routeDomainId}-single`,
                component: suffixComponentName(ModularContentComponent, options.id),
                meta: {
                    [RouteMetaDomainId]: options.id,
                },
            },
            {
                path: options.parent ? routePath : `/${routePath}`,
                component: suffixComponentName(ModularContentComponent, options.id),
                name: `${routeDomainId}-root`,
                children: [
                    {
                        path: "",
                        name: `${routeDomainId}-list`,
                        component: suffixComponentName(EntityListsComponent, options.id),
                        redirect: {
                            name: `${routeDomainId}-list/${defaultList.id}`,
                        },
                        children: options.lists.map((it) => {
                            return {
                                path: `${it.id}`,
                                name: `${routeDomainId}-list/${it.id}`,
                                meta: {
                                    [RouteMetaDomainId]: options.id,
                                    [RouteMetaListId]: it.id,
                                },
                                component: suffixComponentName(EntityListTabComponent, options.id, it.id),
                            };
                        }),
                    },
                ],
            },
        ],
    };
    return RoutesModule(module);
};
