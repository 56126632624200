var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let MinValidator = class MinValidator {
    translationService;
    name = "min";
    arg = String;
    constructor(translationService) {
        this.translationService = translationService;
    }
    message(min) {
        return this.translationService.translate("validation.messages.min", { min });
    }
    validate(value, min) {
        if (min === "null") {
            return true;
        }
        return (value !== 0 && !value) || value >= parseInt(min);
    }
};
MinValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], MinValidator);
export { MinValidator };
