var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { reactive } from "@/di/decorators/computed.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { OK, NOT_FOUND, CONFLICT, CREATED } from "http-status";
import { CompanyIdHeader } from "@/app/http/constants/custom-headers";
import { TranslationService } from "@/app/translation/services/translation.service";
let ManageMembersService = class ManageMembersService {
    httpClient;
    toastService;
    translationService;
    members = [];
    count = 0;
    constructor(httpClient, toastService, translationService) {
        this.httpClient = httpClient;
        this.toastService = toastService;
        this.translationService = translationService;
    }
    async loadMembersCountByCompanyId(companyId, errorCodesToSuppress) {
        if (!companyId) {
            return;
        }
        this.count = 0;
        this.count = (await this.fetchMembersByCompanyId(companyId, errorCodesToSuppress))?.members?.length ?? 0;
    }
    async loadMembersByCompanyId(companyId, errorCodesToSuppress) {
        this.members = [];
        this.members = (await this.fetchMembersByCompanyId(companyId, errorCodesToSuppress))?.members;
        this.count = this.members?.length ?? 0;
    }
    async fetchMembersByCompanyId(companyId, errorCodesToSuppress) {
        return this.httpClient.get(`members/${companyId}`, { errorCodesToSuppress });
    }
    async setRole(role, memberId, companyId) {
        await this.httpClient.put(`members/${memberId}/role`, { role }, { headers: { [CompanyIdHeader]: companyId } });
        this.members = this.members.map((it) => (it.id === memberId ? { ...it, roleId: role } : it));
    }
    getMembers() {
        return this.members;
    }
    async inviteMembers(companyId, emails) {
        if (!companyId) {
            return;
        }
        const dto = {
            companyId,
            emails,
        };
        const response = await this.httpClient.post("members", dto, {
            headers: { [CompanyIdHeader]: dto.companyId },
        });
        this.handleToastResponse(response);
        await this.loadMembersByCompanyId(dto.companyId);
    }
    async removeMember(companyId, userId) {
        const dto = {
            companyId,
            userId,
        };
        await this.httpClient.post(`members/${dto.userId}/remove`, undefined, {
            headers: { [CompanyIdHeader]: dto.companyId },
        });
        await this.loadMembersByCompanyId(dto.companyId);
    }
    async leaveCompany(companyId) {
        const dto = {
            companyId,
        };
        await this.httpClient.post("members/leave", undefined, { headers: { [CompanyIdHeader]: dto.companyId } });
    }
    getMembersTotalCount() {
        return this.count;
    }
    handleToastResponse(response) {
        this.displayEmailToasts(ToastSeverity.Success, response.results.filter((item) => item.statusCode === OK).map((item) => item.href), OK);
        this.displayEmailToasts(ToastSeverity.Success, response.results.filter((item) => item.statusCode === CREATED).map((item) => item.href), CREATED);
        this.displayEmailToasts(ToastSeverity.Error, response.results.filter((item) => item.statusCode === NOT_FOUND).map((item) => item.href), NOT_FOUND);
        this.displayEmailToasts(ToastSeverity.Error, response.results
            .filter((item) => item.statusCode === CONFLICT && item.errorCode === "A1049")
            .map((item) => item.href), CONFLICT, "A1049");
        this.displayEmailToasts(ToastSeverity.Error, response.results
            .filter((item) => item.statusCode === CONFLICT && item.errorCode === "A1055")
            .map((item) => item.href), CONFLICT, "A1055");
        this.displayEmailToasts(ToastSeverity.Error, response.results
            .filter((item) => item.statusCode === CONFLICT && item.errorCode === "A1064")
            .map((item) => item.href), CONFLICT, "A1064");
        this.displayEmailToasts(ToastSeverity.Error, response.results
            .filter((item) => item.statusCode === CONFLICT && item.errorCode === "A1084")
            .map((item) => item.href), CONFLICT, "A1084");
    }
    displayEmailToasts(severity, emails, statusCode, errorCode) {
        if (!emails.length) {
            return;
        }
        let text;
        let key;
        let translationKey;
        const translationPrefix = "domains.companies.manage-company.manage-members.components.invite-members-modal.toasts";
        const errorToasts = [
            { status: NOT_FOUND, code: undefined, translationKey: `${translationPrefix}.not-registered-error` },
            { status: CONFLICT, code: "A1049", translationKey: `${translationPrefix}.already-invited-error` },
            { status: CONFLICT, code: "A1055", translationKey: `${translationPrefix}.transfered-user-error` },
            { status: CONFLICT, code: "A1064", translationKey: `${translationPrefix}.public-api-consumer-error` },
            { status: CONFLICT, code: "A1084", translationKey: `${translationPrefix}.already-tender-manager-user-error` },
        ];
        switch (statusCode) {
            case OK:
                text = this.translationService.translate(`${translationPrefix}.invitation-success`, {
                    emails: emails.join(", "),
                });
                break;
            case CREATED:
                text = this.translationService.translate(`${translationPrefix}.creation-and-invitation-success`, {
                    emails: emails.join(", "),
                });
                break;
            default:
                key = errorToasts.find((toast) => toast.status === statusCode && toast.code === errorCode)?.translationKey;
                translationKey = `${key}-${emails.length === 1 ? "single" : "plural"}`;
                text = this.translationService.translate(translationKey, { emails: emails.join(", ") });
                break;
        }
        this.toastService.showToast({
            severity,
            message: text,
        });
    }
};
__decorate([
    reactive(),
    __metadata("design:type", Array)
], ManageMembersService.prototype, "members", void 0);
__decorate([
    reactive(),
    __metadata("design:type", Object)
], ManageMembersService.prototype, "count", void 0);
ManageMembersService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object])
], ManageMembersService);
export { ManageMembersService };
