var _a, _b;
import { __decorate, __metadata } from "tslib";
import { reactive } from "@/di/decorators/computed.decorator";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { formatDate, DateFormat } from "@/app/shared/utils/date-time.utils";
import { Nullable } from "@/types/nullable.type";
const ENTRIES_PER_PAGE = 10;
let ChangeHistoryService = class ChangeHistoryService {
    httpService;
    records = [];
    entries = [];
    continuationToken;
    constructor(httpService) {
        this.httpService = httpService;
    }
    getChanges() {
        return this.entries;
    }
    async loadChanges(baseUrl, moduleId, subModuleId, continuationToken) {
        const payload = {
            moduleId,
            subModuleId,
            continuationToken,
            pageSize: this.getPageSize(),
        };
        const data = await this.httpService.post(this.getUrl(baseUrl), payload);
        this.addRecords(data.history);
        this.buildEntries(moduleId, subModuleId);
        this.continuationToken = data.continuationToken;
    }
    async loadMoreChanges(baseUrl, moduleId, subModuleId) {
        if (this.hasMoreChanges()) {
            await this.loadChanges(baseUrl, moduleId, subModuleId, this.continuationToken);
        }
    }
    hasMoreChanges() {
        return Boolean(this.continuationToken);
    }
    resetChanges() {
        this.records = [];
        this.entries = [];
        this.continuationToken = null;
    }
    getUrl(baseUrl) {
        return `${baseUrl}/history`;
    }
    getPageSize() {
        return ENTRIES_PER_PAGE + 1;
    }
    addRecords(records) {
        this.records = [...this.records, ...records];
    }
    buildEntries(moduleId, subModuleId) {
        this.entries = this.records.reduce((entries, record, index, records) => {
            if (index === records.length - 1) {
                return entries;
            }
            const oldRecord = records[index + 1];
            const newRecord = record;
            const oldSubModuleData = oldRecord.entity[moduleId][subModuleId];
            const newSubModuleData = newRecord.entity[moduleId][subModuleId];
            entries.push({
                date: formatDate(newRecord.updatedTimeUtc, DateFormat.Full),
                user: newRecord.updatedByUserName,
                old: oldSubModuleData,
                new: newSubModuleData,
            });
            return entries;
        }, []);
    }
};
__decorate([
    reactive(),
    __metadata("design:type", Array)
], ChangeHistoryService.prototype, "entries", void 0);
__decorate([
    reactive(),
    __metadata("design:type", typeof (_b = typeof Nullable !== "undefined" && Nullable) === "function" ? _b : Object)
], ChangeHistoryService.prototype, "continuationToken", void 0);
ChangeHistoryService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object])
], ChangeHistoryService);
export { ChangeHistoryService };
