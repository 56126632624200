import _ from "lodash";
import { isLiteralClassProvider, toClassProvider } from "@/di/types/provider";
import { createAppProviderContainer, vueProviderContainer, } from "@/di/container";
import { isStringToken, toStringToken } from "@/di/types/token";
import { resolveAll } from "@/di/composables/resolve";
import { AppErrorHandlerToken } from "@/app/shared/tokens/app-error-handler.token";
import { RoutesToken } from "@/app/shared/tokens/routes.token";
import Router from "@/router";
import { v4 as uuid } from "uuid";
import { createContext } from "@/di/context";
import { ModuleIdMetaKey } from "@/di/tokens";
export const globalModuleContext = createContext();
export const createModule = (options) => {
    const id = uuid();
    const context = options.context ?? globalModuleContext;
    options.providers = options.providers?.map((it) => {
        let provider = it;
        if (isLiteralClassProvider(it)) {
            provider = toClassProvider(it);
        }
        provider.meta = { ...it.meta, [ModuleIdMetaKey]: id };
        return provider;
    });
    const resolveProviders = (current = options) => {
        const imports = current.imports ?? [];
        const providers = current.providers ?? [];
        const importedProviders = imports.flatMap((it) => resolveProviders(it));
        return [...providers, ...importedProviders]
            .map((it) => {
            if (isLiteralClassProvider(it)) {
                return toClassProvider(it);
            }
            return it;
        })
            .map((it) => {
            const token = isStringToken(it.token) ? it.token : toStringToken(it.token);
            return {
                ...it,
                token,
            };
        });
    };
    const provideAll = (container) => {
        const providerContainer = container ?? vueProviderContainer();
        const providers = _.uniq(resolveProviders());
        _.forEach(_.groupBy(providers, "token"), (providers, token) => {
            providerContainer.provide(token, providers);
        });
    };
    const resolveRoutes = (container) => {
        const providers = container.inject(RoutesToken) ?? [];
        return providers.flatMap((it) => {
            return it.useValue.map((route) => {
                route.meta = { ...route.meta, ..._.get(it, "meta", ModuleIdMetaKey) };
                return route;
            });
        });
    };
    const mergeRoutesByName = (routes) => {
        const merged = [];
        routes.forEach((it) => {
            const route = merged.find(({ name }) => name === it.name);
            if (!route) {
                merged.push(it);
                return;
            }
            route.children = [...(route.children ?? []), ...(it.children ?? [])];
        });
        return merged;
    };
    const addRoutes = (container) => {
        const routes = mergeRoutesByName(resolveRoutes(container));
        const resolvedRoutes = [];
        const findParent = (record, currentRoutes = resolvedRoutes) => {
            if (!hasParent(record)) {
                return undefined;
            }
            const parentName = _.get(record, "parent");
            const parent = currentRoutes.find((it) => it.name === parentName);
            if (parent) {
                return parent;
            }
            const children = _.flatMap(currentRoutes, (it) => it.children ?? []);
            if (children.length === 0) {
                return;
            }
            return findParent(record, children);
        };
        const hasParent = (record) => {
            return !!_.get(record, "parent");
        };
        const alreadyExists = (record, currentRoutes = resolvedRoutes) => {
            if (currentRoutes.length === 0) {
                return false;
            }
            const inRoutes = !!currentRoutes.find((it) => it.name === record.name);
            const inChildRoutes = alreadyExists(record, currentRoutes.flatMap((it) => it.children ?? []));
            return inRoutes || inChildRoutes;
        };
        while (routes.length > 0) {
            const length = routes.length;
            routes.forEach((record, index) => {
                if (alreadyExists(record)) {
                    routes.splice(index, 1);
                    return;
                }
                if (!hasParent(record)) {
                    resolvedRoutes.push(record);
                    routes.splice(index, 1);
                    return;
                }
                const parent = findParent(record);
                if (!parent) {
                    return;
                }
                parent.children = [...(parent.children ?? []), record];
                routes.splice(index, 1);
            });
            if (routes.length === length) {
                break;
            }
        }
        resolvedRoutes.forEach((record) => {
            Router.addRoute(record);
        });
    };
    const setupErrorHandlers = (app, container) => {
        const appErrorHandlers = resolveAll(AppErrorHandlerToken, container);
        app.config.errorHandler = (err, instance, info) => {
            appErrorHandlers.forEach((it) => {
                it.handleError(err, instance, info);
            });
        };
    };
    const install = (app) => {
        const container = createAppProviderContainer(app);
        provideAll(container);
        setupErrorHandlers(app, container);
        addRoutes(container);
    };
    const getId = () => {
        return id;
    };
    const self = {
        ...options,
        getId,
        provideAll,
        install,
    };
    context.register(self);
    return self;
};
