var _a, _b, _c, _d;
import { __decorate, __metadata, __param } from "tslib";
import { ModuleService } from "@/app/modular-content/services/module.service";
import { service } from "@/di/decorators/service.decorator";
import { merge } from "@/app/shared/functions/merge";
import { ChangeDetectionServiceToken, DomainIdToken, ModuleIdToken, } from "@/app/modular-content/token/modular-content.token";
import { inject } from "@/di/decorators/inject.decorator";
import { ChangeDetectionService } from "@/app/modular-content/interfaces/change-detection.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { TranslationService } from "@/app/translation/services/translation.service";
import { useDomainTranslationPrefix } from "@/app/translation/composables/use-domain-translation-prefix.composable";
let UpdateModuleService = class UpdateModuleService {
    moduleService;
    moduleId;
    domainId;
    changeDetectionService;
    toastService;
    translationService;
    domainTranslationPrefix;
    constructor(moduleService, moduleId, domainId, changeDetectionService, toastService, translationService) {
        this.moduleService = moduleService;
        this.moduleId = moduleId;
        this.domainId = domainId;
        this.changeDetectionService = changeDetectionService;
        this.toastService = toastService;
        this.translationService = translationService;
        this.domainTranslationPrefix = useDomainTranslationPrefix(domainId);
    }
    async updateModule() {
        if (!this.changeDetectionService.hasChanges()) {
            return;
        }
        const changes = this.changeDetectionService.getChanges();
        const current = this.moduleService.getData();
        const merged = merge(current, changes);
        await this.moduleService.updateModuleData(merged);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate(`modular-content.components.entity-edit.toast-messages.${this.domainTranslationPrefix("save", true)}`),
        });
    }
};
UpdateModuleService = __decorate([
    service(),
    __param(1, inject(ModuleIdToken)),
    __param(2, inject(DomainIdToken)),
    __param(3, inject(ChangeDetectionServiceToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof ModuleService !== "undefined" && ModuleService) === "function" ? _a : Object, String, String, typeof (_b = typeof ChangeDetectionService !== "undefined" && ChangeDetectionService) === "function" ? _b : Object, typeof (_c = typeof ToastService !== "undefined" && ToastService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object])
], UpdateModuleService);
export { UpdateModuleService };
