var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { CompanyService } from "@/app/domains/public-data/companies/services/company.service";
import _ from "lodash";
let UpdateCompanyService = class UpdateCompanyService {
    companyService;
    initial = {};
    data = {};
    constructor(companyService) {
        this.companyService = companyService;
    }
    setInitial(company) {
        this.initial = company;
    }
    set(company) {
        this.data = company;
    }
    getChanges() {
        const changes = _.pickBy(_.omit(this.data, "address"), (value, key) => {
            return !_.isEqual(value, this.initial[key]);
        });
        const addressChanges = _.pickBy(this.data.address, (value, key) => {
            const address = this.initial.address ?? {};
            return !_.isEqual(value, address[key]);
        });
        if (!_.isEmpty(addressChanges)) {
            changes.address = addressChanges;
        }
        return changes;
    }
    hasChanges() {
        return !_.isEmpty(this.getChanges());
    }
    discard() {
        this.initial = {};
        this.data = {};
    }
    async update() {
        if (!this.data.id) {
            return;
        }
        await this.companyService.updateCompany(this.data.id, this.data);
        this.discard();
    }
};
UpdateCompanyService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof CompanyService !== "undefined" && CompanyService) === "function" ? _a : Object])
], UpdateCompanyService);
export { UpdateCompanyService };
