import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_DropdownMenuOverlay = _resolveComponent("DropdownMenuOverlay");
    return (_ctx.visible)
        ? (_openBlock(), _createBlock(_component_DropdownMenuOverlay, {
            key: 0,
            class: "data-localization-dropdown",
            overlayClass: "data-localised-lang-dropdown"
        }, {
            button: _withCtx(() => [
                _createVNode(_component_Icon, {
                    class: "icon",
                    name: "language"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.selectedLabel(_ctx.selectedDataLanguage)), 1),
                _createVNode(_component_Icon, {
                    name: "arrow_drop_down",
                    variant: "rounded"
                })
            ]),
            items: _withCtx(({ hideOverlay }) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataLanguages, (language) => {
                    return (_openBlock(), _createBlock(_component_MenuItem, {
                        key: language.value,
                        icon: _ctx.isSelected(language.value) ? 'check' : 'none',
                        label: language.label,
                        tag: _ctx.isPrimary(language.value) ? _ctx.translate(`data-localization.primary`) : undefined,
                        id: "data-localization-dropdown-menu-item",
                        onClick: ($event) => (_ctx.changeDataLangauge(language.value, { hideOverlay }))
                    }, null, 8, ["icon", "label", "tag", "onClick"]));
                }), 128))
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
