import FormInputComponent from "@/app/form/components/form-input.component.vue";
import { useComponent } from "@/app/shared/composables/use-component.composable";
const formInputMixin = (component) => ({
    created() {
        this.componentName = useComponent(this, component);
    },
});
export const formInput = (component) => {
    return {
        ...FormInputComponent,
        mixins: [formInputMixin(component)],
    };
};
