import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-24cdbf04"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 2,
    class: "company-overview"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_NoPermissionsInfo = _resolveComponent("NoPermissionsInfo");
    const _component_CompanyOverviewTitle = _resolveComponent("CompanyOverviewTitle");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_SaveEntityButton = _resolveComponent("SaveEntityButton");
    const _component_Icon = _resolveComponent("Icon");
    const _component_PageHeader = _resolveComponent("PageHeader");
    const _component_PanelTopNavigation = _resolveComponent("PanelTopNavigation");
    const _component_RouterView = _resolveComponent("RouterView");
    const _component_Panel = _resolveComponent("Panel");
    return (_ctx.isLoading || _ctx.isMembersCountLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            class: "loader",
            center: ""
        }))
        : (_ctx.isForbiddenError)
            ? (_openBlock(), _createBlock(_component_NoPermissionsInfo, { key: 1 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_PageHeader, null, _createSlots({
                    title: _withCtx(() => [
                        _createVNode(_component_CompanyOverviewTitle)
                    ]),
                    _: 2
                }, [
                    (_ctx.manageCompanySelected && _ctx.isInEditMode)
                        ? {
                            name: "buttons",
                            fn: _withCtx(() => [
                                _createVNode(_component_AppButton, {
                                    class: "p-button p-button-tertiary",
                                    onClick: _ctx.navigateToDetails
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["onClick"]),
                                _createVNode(_component_SaveEntityButton, {
                                    label: _ctx.translate('buttons.save'),
                                    disabled: !_ctx.hasChanges,
                                    onSave: _cache[0] || (_cache[0] = ($event) => (_ctx.navigateToDetails()))
                                }, null, 8, ["label", "disabled"])
                            ]),
                            key: "0"
                        }
                        : (_ctx.roleCheck(_ctx.$permissions.isAdmin, _ctx.companyId) && _ctx.manageCompanySelected && !_ctx.isInEditMode)
                            ? {
                                name: "buttons",
                                fn: _withCtx(() => [
                                    _createVNode(_component_AppButton, {
                                        class: "p-button p-button-secondary",
                                        onClick: _ctx.navigateToEditPage
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_Icon, { name: "edit" }),
                                            _createTextVNode(" " + _toDisplayString(_ctx.translate("buttons.edit")), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["onClick"])
                                ]),
                                key: "1"
                            }
                            : undefined
                ]), 1024),
                _createVNode(_component_Panel, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_PanelTopNavigation, { "menu-items": _ctx.menuItems }, null, 8, ["menu-items"]),
                        _createVNode(_component_RouterView)
                    ]),
                    _: 1
                })
            ]));
}
