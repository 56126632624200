import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f2478ed6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-un-loading-area" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row section" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = {
    key: 0,
    class: "col-6"
};
const _hoisted_6 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_7 = { class: "row section" };
const _hoisted_8 = { class: "col-6 column-header" };
const _hoisted_9 = {
    key: 0,
    class: "col-6"
};
const _hoisted_10 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_11 = { class: "section" };
const _hoisted_12 = { class: "row section" };
const _hoisted_13 = { class: "col-6 column-header" };
const _hoisted_14 = { class: "col-6 column-description" };
const _hoisted_15 = { class: "row section" };
const _hoisted_16 = { class: "col-6 column-header" };
const _hoisted_17 = { class: "col-6 column-description" };
const _hoisted_18 = { class: "row section" };
const _hoisted_19 = { class: "col-6 column-header" };
const _hoisted_20 = {
    key: 0,
    class: "col-6"
};
const _hoisted_21 = {
    key: 1,
    class: "col-6 column-description ex-zone-value-wrapper"
};
const _hoisted_22 = {
    key: 0,
    class: "key-location"
};
const _hoisted_23 = { key: 1 };
const _hoisted_24 = { class: "row section" };
const _hoisted_25 = { class: "col-6 column-header" };
const _hoisted_26 = {
    key: 0,
    class: "tags col-6"
};
const _hoisted_27 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_28 = { class: "row section" };
const _hoisted_29 = { class: "col-6 column-header" };
const _hoisted_30 = {
    key: 0,
    class: "col-6"
};
const _hoisted_31 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_32 = { class: "row section" };
const _hoisted_33 = { class: "col-6 column-header" };
const _hoisted_34 = { class: "col-6 column-description" };
const _hoisted_35 = { class: "row section" };
const _hoisted_36 = { class: "col-6 column-header" };
const _hoisted_37 = {
    key: 0,
    class: "tags col-6"
};
const _hoisted_38 = {
    key: 1,
    class: "col-6 column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("cargo-types")), 1),
                (!!_ctx.model.cargoTypes?.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getDictionaryValues(_ctx.model.cargoTypes, 'FillingTypes'), (it) => {
                            return (_openBlock(), _createElementBlock("span", {
                                class: "chip-label",
                                key: it
                            }, _toDisplayString(it), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
            ]),
            _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("transport-modes")), 1),
                (!!_ctx.model.transportModes?.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getDictionaryValues(_ctx.model.transportModes, 'TransportModes'), (it) => {
                            return (_openBlock(), _createElementBlock("span", {
                                class: "chip-label",
                                key: it
                            }, _toDisplayString(it), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, "-"))
            ])
        ]),
        _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("location")), 1),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.location), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate("rail-connection")), 1),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.railConnection), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.translate("ex-zone")), 1),
                (!_ctx.model?.exZone)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, "-"))
                    : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.getValue(_ctx.model?.exZone, "ExZone")), 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.translate("key-location")), 1),
                        (_ctx.model?.keyLocation)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString(_ctx.getValue(`${_ctx.model?.keyLocation}`, "KeyLocation")), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_23, "-"))
                    ]))
            ]),
            _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.translate("area-provisions")), 1),
                (!!_ctx.model?.areaProvisions?.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.areaProvisions, (head, index) => {
                            return (_openBlock(), _createElementBlock("span", {
                                key: head + index,
                                class: "tag"
                            }, _toDisplayString(_ctx.getValue(head, "AreaProvisions")), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_27, "-"))
            ]),
            _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.translate("surface-type")), 1),
                (!_ctx.model?.surfaceType)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_30, "-"))
                    : (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(_ctx.getValue(_ctx.model?.surfaceType, "SurfaceType")), 1))
            ]),
            _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.translate("vehicle-position")), 1),
                _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.vehiclePosition), 1)
            ]),
            _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.translate("vehicle-movement-protection")), 1),
                (!!_ctx.model?.protectionAgainstVehicleMovement?.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.protectionAgainstVehicleMovement, (head, index) => {
                            return (_openBlock(), _createElementBlock("span", {
                                key: head + index,
                                class: "tag"
                            }, _toDisplayString(_ctx.getValue(head, "ProtectionAgainstVehicleMovement")), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_38, "-"))
            ])
        ])
    ]));
}
