import { ManageCompanyAccountEditRouteName, ManageCompanyAccountRouteName, ManageCompanyRouteName, } from "@/app/domains/public-data/companies/company-overview/company-account/company-account.constants";
export const CompanyOverviewRoutes = [
    {
        path: "/companies/manage/:companyId",
        name: "manage-company-root",
        component: () => import("./company-overview.component.vue"),
        props: (route) => ({ query: route.query }),
        children: [
            {
                path: "",
                name: ManageCompanyRouteName,
                redirect: {
                    name: ManageCompanyAccountRouteName,
                },
            },
            {
                path: "edit/account",
                name: ManageCompanyAccountEditRouteName,
                component: () => import("./company-account/components/edit-company-account.component.vue"),
            },
            {
                path: "account",
                name: ManageCompanyAccountRouteName,
                component: () => import("./company-account/components/display-company-account.component.vue"),
            },
        ],
    },
];
