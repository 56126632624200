export const createMetadata = (key, fallback) => {
    const set = (target, value) => {
        Reflect.defineMetadata(key, value ?? fallback, target);
    };
    const get = (target) => {
        try {
            return Reflect.getMetadata(key, target) ?? fallback;
        }
        catch (e) {
            return fallback;
        }
    };
    return {
        get,
        set,
    };
};
export const createListMetadata = (key) => {
    const metadata = createMetadata(key, []);
    const add = (target, value) => {
        metadata.set(target, [...metadata.get(target), value]);
    };
    return {
        ...metadata,
        add,
    };
};
