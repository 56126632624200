import { createModule } from "@/di/module";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { ParentDomainServiceToken } from "@/app/modular-content/token/modular-content.token";
export const ParentDomainServiceModule = (options) => {
    if (!options.parent) {
        return createModule({});
    }
    const parentTokens = domainTokensFactory(options.parent);
    return createModule({
        providers: [{ token: ParentDomainServiceToken, useToken: parentTokens.service }],
    });
};
