import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3967f9e6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "manage-members" };
const _hoisted_2 = { class: "header" };
const _hoisted_3 = { class: "content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ManageMembersTable = _resolveComponent("ManageMembersTable");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.translate("header")), 1),
            (_ctx.roleCheck(_ctx.$permissions.isAdmin, _ctx.companyId))
                ? (_openBlock(), _createBlock(_component_AppButton, {
                    key: 0,
                    onClick: _ctx.openInviteMembersModal
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_Icon, { name: "add" }),
                        _createTextVNode(" " + _toDisplayString(_ctx.inviteLabel), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"]))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ManageMembersTable, {
                delegate: _ctx.delegate,
                onSetRole: _ctx.setRole,
                isLoading: _ctx.isLoading || _ctx.isSettingRole,
                isError: _ctx.isError,
                error: _ctx.errorResponse,
                roles: _ctx.roles
            }, null, 8, ["delegate", "onSetRole", "isLoading", "isError", "error", "roles"])
        ])
    ]));
}
