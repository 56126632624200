import _ from "lodash";
import { resolve, tryResolve } from "@/di/composables/resolve";
import { TranslationService } from "@/app/translation/services/translation.service";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { useInstance } from "@/app/shared/composables/use-instance.composable";
import { onMounted, reactive } from "vue";
import { DomainIdToken } from "@/app/modular-content/token/modular-content.token";
import { paramCase } from "change-case";
export const useTranslate = (prefix) => {
    const translationService = resolve(TranslationService);
    const translatePrefix = tryResolve(TranslationPrefixToken);
    const domainId = tryResolve(DomainIdToken) ?? "unknown";
    const data = reactive({
        componentName: "",
    });
    onMounted(() => {
        const instance = useInstance();
        data.componentName = _.get(instance.type, "originalName") ?? instance.type.name ?? "";
    });
    return (key, params = {}) => {
        const localPrefixedKeys = [
            _.compact([prefix, key]).join("."),
            _.compact([prefix, "components", data.componentName, key]).join("."),
            _.compact([prefix, data.componentName, key]).join("."),
        ];
        const injectedPrefixedKeys = [
            _.compact([translatePrefix, "components", data.componentName, key]).join("."),
            _.compact([translatePrefix, data.componentName, key]).join("."),
            _.compact([translatePrefix, key]).join("."),
        ];
        const keys = [
            ...(prefix ? localPrefixedKeys : []),
            ...(translatePrefix ? injectedPrefixedKeys : []),
            _.compact(["components", data.componentName, key]).join("."),
            _.compact([data.componentName, key]).join("."),
            key,
            ["global", key].join("."),
        ];
        const paramsCopy = Object.assign({}, params);
        _.forEach(paramsCopy, (value, key) => {
            if (typeof value === "function") {
                paramsCopy[key] = value();
            }
        });
        for (const k of keys) {
            const translated = translationService.translate(k, {
                domain: `domains.${paramCase(domainId)}`,
                ...paramsCopy,
            });
            if (translated !== k) {
                return translated;
            }
        }
        return key;
    };
};
