import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "summary"
};
const _hoisted_2 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_3 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_4 = {
    key: 2,
    class: "sub-module-section"
};
const _hoisted_5 = {
    key: 3,
    class: "sub-module-section"
};
const _hoisted_6 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_7 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_8 = {
    key: 2,
    class: "sub-module-section"
};
const _hoisted_9 = {
    key: 3,
    class: "sub-module-section"
};
const _hoisted_10 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_11 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_12 = {
    key: 2,
    class: "sub-module-section"
};
const _hoisted_13 = {
    key: 3,
    class: "sub-module-section"
};
const _hoisted_14 = {
    key: 4,
    class: "sub-module-section"
};
const _hoisted_15 = {
    key: 5,
    class: "sub-module-section"
};
const _hoisted_16 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_17 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_18 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_19 = {
    key: 2,
    class: "sub-module-section"
};
const _hoisted_20 = {
    key: 3,
    class: "sub-module-section"
};
const _hoisted_21 = {
    key: 4,
    class: "sub-module-section"
};
const _hoisted_22 = {
    key: 5,
    class: "sub-module-section"
};
const _hoisted_23 = {
    key: 6,
    class: "sub-module-section"
};
const _hoisted_24 = {
    key: 7,
    class: "sub-module-section"
};
const _hoisted_25 = {
    key: 8,
    class: "sub-module-section"
};
const _hoisted_26 = {
    key: 9,
    class: "sub-module-section"
};
const _hoisted_27 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_28 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_29 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_30 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_31 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_32 = {
    key: 2,
    class: "sub-module-section"
};
const _hoisted_33 = {
    key: 3,
    class: "sub-module-section"
};
const _hoisted_34 = {
    key: 4,
    class: "sub-module-section"
};
const _hoisted_35 = {
    key: 5,
    class: "sub-module-section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_DisplayProductBasicInformationView = _resolveComponent("DisplayProductBasicInformationView");
    const _component_SubModuleTitle = _resolveComponent("SubModuleTitle");
    const _component_DisplayProductParametersView = _resolveComponent("DisplayProductParametersView");
    const _component_DisplayProductGroupAssignmentView = _resolveComponent("DisplayProductGroupAssignmentView");
    const _component_DisplayProductAdditionalInformationView = _resolveComponent("DisplayProductAdditionalInformationView");
    const _component_ModuleSectionPanel = _resolveComponent("ModuleSectionPanel");
    const _component_DisplayPrlRestrictionsSettingsView = _resolveComponent("DisplayPrlRestrictionsSettingsView");
    const _component_DisplayDgClassificationView = _resolveComponent("DisplayDgClassificationView");
    const _component_DisplaySpecialProductRequirementsView = _resolveComponent("DisplaySpecialProductRequirementsView");
    const _component_DisplaySafetyDataSheetView = _resolveComponent("DisplaySafetyDataSheetView");
    const _component_DisplayGeneralCleaningInfoView = _resolveComponent("DisplayGeneralCleaningInfoView");
    const _component_DisplayEuropeanCleaningDocumentView = _resolveComponent("DisplayEuropeanCleaningDocumentView");
    const _component_DisplayPreloadConfirmationView = _resolveComponent("DisplayPreloadConfirmationView");
    const _component_DisplayVacuumTestConfirmationView = _resolveComponent("DisplayVacuumTestConfirmationView");
    const _component_DisplayRequiredEquipmentStateView = _resolveComponent("DisplayRequiredEquipmentStateView");
    const _component_DisplayCleaningOperatorsView = _resolveComponent("DisplayCleaningOperatorsView");
    const _component_DisplayVehicleAndPPEApView = _resolveComponent("DisplayVehicleAndPPEApView");
    const _component_DisplayVehicleView = _resolveComponent("DisplayVehicleView");
    const _component_DisplayEquipmentPackedView = _resolveComponent("DisplayEquipmentPackedView");
    const _component_DisplayTankView = _resolveComponent("DisplayTankView");
    const _component_DisplayConnectionsView = _resolveComponent("DisplayConnectionsView");
    const _component_DisplayHoseView = _resolveComponent("DisplayHoseView");
    const _component_DisplayPumpView = _resolveComponent("DisplayPumpView");
    const _component_DisplayCompressorView = _resolveComponent("DisplayCompressorView");
    const _component_DisplayVapourReturnView = _resolveComponent("DisplayVapourReturnView");
    const _component_DisplayCargoSecuringView = _resolveComponent("DisplayCargoSecuringView");
    const _component_DisplayTemperatureRequirementsView = _resolveComponent("DisplayTemperatureRequirementsView");
    const _component_DisplayPackagingView = _resolveComponent("DisplayPackagingView");
    const _component_DisplayPalletsView = _resolveComponent("DisplayPalletsView");
    const _component_DisplayDocumentsView = _resolveComponent("DisplayDocumentsView");
    const _component_DisplayGeneralInstructionsView = _resolveComponent("DisplayGeneralInstructionsView");
    const _component_DisplayWeighingView = _resolveComponent("DisplayWeighingView");
    const _component_DisplayFillingView = _resolveComponent("DisplayFillingView");
    const _component_DisplaySealingView = _resolveComponent("DisplaySealingView");
    const _component_DisplaySamplingView = _resolveComponent("DisplaySamplingView");
    const _component_DisplayProcessesView = _resolveComponent("DisplayProcessesView");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, [
                (!_ctx.verificationAllowed)
                    ? (_openBlock(), _createBlock(_component_ModuleSectionPanel, {
                        key: 0,
                        title: _ctx.translate('modules.general-information.display'),
                        hasData: _ctx.hasData(_ctx.model?.generalInformation)
                    }, {
                        default: _withCtx(() => [
                            (_ctx.model?.generalInformation?.basicInformation)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                    _createVNode(_component_DisplayProductBasicInformationView, {
                                        model: _ctx.model?.generalInformation?.basicInformation,
                                        hideBasicInfoHeader: ""
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.model?.generalInformation?.physicalAndChemicalParameters)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "tune",
                                        headerText: _ctx.translate('sub-modules.physical-and-chemical-parameters.name'),
                                        subModuleKey: "physical-and-chemical-parameters"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayProductParametersView, {
                                        model: _ctx.model?.generalInformation?.physicalAndChemicalParameters
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.model?.generalInformation?.productGroupAssignment)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "assignment_turned_in",
                                        headerText: _ctx.translate('sub-modules.product-group-assignment.name'),
                                        subModuleKey: "product-group-assignment"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayProductGroupAssignmentView, {
                                        model: _ctx.model?.generalInformation?.productGroupAssignment
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.model?.generalInformation?.additionalInformation)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "error",
                                        headerText: _ctx.translate('sub-modules.additional-information.product.name'),
                                        subModuleKey: "additional-information"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayProductAdditionalInformationView, {
                                        model: _ctx.model?.generalInformation?.additionalInformation
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }, 8, ["title", "hasData"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.products-safety.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['productsSafety'],
                    hasData: _ctx.hasData(_ctx.model?.productsSafety),
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.flags.productsSafety = true)),
                    onFeedback: _cache[1] || (_cache[1] = ($event) => (_ctx.openSectionFeedback('productsSafety')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.model?.productsSafety?.prlRestrictionsSettings)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "not_interested",
                                    headerText: _ctx.translate('sub-modules.prl-restrictions-settings.name'),
                                    subModuleKey: "prl-restrictions-settings"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayPrlRestrictionsSettingsView, {
                                    model: _ctx.model?.productsSafety?.prlRestrictionsSettings
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.productsSafety?.dgClassification)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "account_tree",
                                    headerText: _ctx.translate('sub-modules.dg-classification.name'),
                                    subModuleKey: "dg-classification"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayDgClassificationView, {
                                    model: _ctx.model?.productsSafety?.dgClassification
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.productsSafety?.specialProductRequirements)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "home_repair_service",
                                    headerText: _ctx.translate('sub-modules.special-product-requirements.name'),
                                    subModuleKey: "special-product-requirements"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplaySpecialProductRequirementsView, {
                                    model: _ctx.model?.productsSafety?.specialProductRequirements
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.productsSafety?.safetyDataSheet)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "format_list_bulleted",
                                    headerText: _ctx.translate('sub-modules.safety-data-sheet.name'),
                                    subModuleKey: "safety-data-sheet"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplaySafetyDataSheetView, {
                                    model: _ctx.model?.productsSafety?.safetyDataSheet
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                (_ctx.model?.cleaningRequirements)
                    ? (_openBlock(), _createBlock(_component_ModuleSectionPanel, {
                        key: 1,
                        title: _ctx.translate('modules.cleaning-requirements.display'),
                        verificationAllowed: _ctx.verificationAllowed,
                        hasFeedback: !!_ctx.feedbackBySection['cleaningRequirements'],
                        hasData: _ctx.hasData(_ctx.model?.cleaningRequirements),
                        onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.flags.cleaningRequirements = true)),
                        onFeedback: _cache[3] || (_cache[3] = ($event) => (_ctx.openSectionFeedback('cleaningRequirements')))
                    }, {
                        default: _withCtx(() => [
                            (_ctx.model?.cleaningRequirements?.generalCleaningInfo)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "info",
                                        headerText: _ctx.translate('sub-modules.general-cleaning-info.name'),
                                        subModuleKey: "general-cleaning-info"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayGeneralCleaningInfoView, {
                                        model: _ctx.model?.cleaningRequirements?.generalCleaningInfo
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.model?.cleaningRequirements?.europeanCleaningDocument)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "document_scanner",
                                        headerText: _ctx.translate('sub-modules.european-cleaning-document.name'),
                                        subModuleKey: "european-cleaning-document"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayEuropeanCleaningDocumentView, {
                                        model: _ctx.model?.cleaningRequirements?.europeanCleaningDocument
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.model?.cleaningRequirements?.preloadConfirmation)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "document_scanner",
                                        iconVariant: "outlined",
                                        headerText: _ctx.translate('sub-modules.preload-confirmation.name'),
                                        subModuleKey: "preload-confirmation"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayPreloadConfirmationView, {
                                        model: _ctx.model?.cleaningRequirements?.preloadConfirmation
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.model?.cleaningRequirements?.vacuumTestConfirmation)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "sanitizer",
                                        headerText: _ctx.translate('sub-modules.vacuum-test-confirmation.name'),
                                        subModuleKey: "vacuum-test-confirmation"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayVacuumTestConfirmationView, {
                                        model: _ctx.model?.cleaningRequirements?.vacuumTestConfirmation
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.model?.cleaningRequirements?.requiredEquipmentState)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "construction",
                                        headerText: _ctx.translate('sub-modules.required-equipment-state.name'),
                                        subModuleKey: "required-equipment-state"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayRequiredEquipmentStateView, {
                                        model: _ctx.model?.cleaningRequirements?.requiredEquipmentState
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.model?.cleaningRequirements?.cleaningOperators)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "cleaning_services",
                                        headerText: _ctx.translate('sub-modules.cleaning-operators.name'),
                                        subModuleKey: "cleaning-operators"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayCleaningOperatorsView, {
                                        model: _ctx.model?.cleaningRequirements?.cleaningOperators
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.safety-and-security.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['safetyAndSecurity'],
                    hasData: _ctx.hasData(_ctx.model?.safetyAndSecurity),
                    onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.flags.safetyAndSecurity = true)),
                    onFeedback: _cache[5] || (_cache[5] = ($event) => (_ctx.openSectionFeedback('safetyAndSecurity')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.model?.safetyAndSecurity?.vehicleAndPersonalProtectiveEquipment)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "masks",
                                    headerText: _ctx.translate('sub-modules.ppe-requirement.name'),
                                    subModuleKey: "ppe-requirement"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayVehicleAndPPEApView, {
                                    model: _ctx.model?.safetyAndSecurity?.vehicleAndPersonalProtectiveEquipment
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.technical-requirements.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['technicalRequirements'],
                    hasData: _ctx.hasData(_ctx.model?.technicalRequirements),
                    onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.flags.technicalRequirements = true)),
                    onFeedback: _cache[7] || (_cache[7] = ($event) => (_ctx.openSectionFeedback('technicalRequirements')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.model?.technicalRequirements?.vehicle)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "local_shipping",
                                    headerText: _ctx.translate('sub-modules.vehicle.name'),
                                    subModuleKey: "vehicle"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayVehicleView, {
                                    model: _ctx.model?.technicalRequirements?.vehicle
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.technicalRequirements?.equipmentPacked)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "home_repair_service",
                                    headerText: _ctx.translate('sub-modules.equipment-packed.name'),
                                    subModuleKey: "equipment-packed"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayEquipmentPackedView, {
                                    model: _ctx.model?.technicalRequirements?.equipmentPacked
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.technicalRequirements?.tank)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "signal_wifi_statusbar_connected_no_internet_4",
                                    headerText: _ctx.translate('sub-modules.tank.name'),
                                    subModuleKey: "tank"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayTankView, {
                                    model: _ctx.model?.technicalRequirements?.tank
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.technicalRequirements?.connections)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "cable",
                                    headerText: _ctx.translate('sub-modules.connections.name'),
                                    subModuleKey: "connections"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayConnectionsView, {
                                    model: _ctx.model?.technicalRequirements?.connections
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.technicalRequirements?.hose)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "gesture",
                                    headerText: _ctx.translate('sub-modules.hose.name'),
                                    subModuleKey: "hose"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayHoseView, {
                                    model: _ctx.model?.technicalRequirements?.hose
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.technicalRequirements?.pump)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "sanitizer",
                                    headerText: _ctx.translate('sub-modules.pump.name'),
                                    subModuleKey: "pump"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayPumpView, {
                                    model: _ctx.model?.technicalRequirements?.pump
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.technicalRequirements?.compressor)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "compress",
                                    headerText: _ctx.translate('sub-modules.compressor.name'),
                                    subModuleKey: "compressor"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayCompressorView, {
                                    model: _ctx.model?.technicalRequirements?.compressor
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.technicalRequirements?.vapourReturn)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "repeat",
                                    headerText: _ctx.translate('sub-modules.vapour-return.name'),
                                    subModuleKey: "vapour"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayVapourReturnView, {
                                    model: _ctx.model?.technicalRequirements?.vapourReturn
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.technicalRequirements?.cargoSecuring)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "vpn_lock",
                                    headerText: _ctx.translate('sub-modules.cargo-securing.name'),
                                    subModuleKey: "cargo-securing"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayCargoSecuringView, {
                                    model: _ctx.model?.technicalRequirements?.cargoSecuring
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.technicalRequirements?.temperatureRequirements)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "thermostat",
                                    headerText: _ctx.translate('sub-modules.temperature-requirements.name'),
                                    subModuleKey: "temperature-requirements"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayTemperatureRequirementsView, {
                                    model: _ctx.model?.technicalRequirements?.temperatureRequirements
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                (_ctx.model?.packaging)
                    ? (_openBlock(), _createBlock(_component_ModuleSectionPanel, {
                        key: 2,
                        title: _ctx.translate('modules.packaging.display'),
                        verificationAllowed: _ctx.verificationAllowed,
                        hasFeedback: !!_ctx.feedbackBySection['packaging'],
                        hasData: _ctx.hasData(_ctx.model?.packaging),
                        onClick: _cache[8] || (_cache[8] = ($event) => (_ctx.flags.packaging = true)),
                        onFeedback: _cache[9] || (_cache[9] = ($event) => (_ctx.openSectionFeedback('packaging')))
                    }, {
                        default: _withCtx(() => [
                            (_ctx.model?.packaging?.packaging)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "inventory_2",
                                        headerText: _ctx.translate('sub-modules.packaging.name'),
                                        subModuleKey: "packaging"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayPackagingView, {
                                        model: _ctx.model?.packaging?.packaging
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.model?.packaging?.pallets)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                    _createVNode(_component_SubModuleTitle, {
                                        icon: "dashboard",
                                        headerText: _ctx.translate('sub-modules.pallets.name'),
                                        subModuleKey: "pallets"
                                    }, null, 8, ["headerText"]),
                                    _createVNode(_component_DisplayPalletsView, {
                                        model: _ctx.model?.packaging?.pallets
                                    }, null, 8, ["model"])
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.documents.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['documents'],
                    hasData: _ctx.hasData(_ctx.model?.documents),
                    onClick: _cache[10] || (_cache[10] = ($event) => (_ctx.flags.documents = true)),
                    onFeedback: _cache[11] || (_cache[11] = ($event) => (_ctx.openSectionFeedback('documents')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.model?.documents?.documents)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "description",
                                    headerText: _ctx.translate('sub-modules.documents.name'),
                                    subModuleKey: "documents"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayDocumentsView, {
                                    model: _ctx.model?.documents?.documents
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.instructions.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['instructions'],
                    hasData: _ctx.hasData(_ctx.model?.instructions),
                    onClick: _cache[12] || (_cache[12] = ($event) => (_ctx.flags.instructions = true)),
                    onFeedback: _cache[13] || (_cache[13] = ($event) => (_ctx.openSectionFeedback('instructions')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.model?.instructions?.generalInstructions)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "tips_and_updates",
                                    headerText: _ctx.translate('sub-modules.general-instructions.name'),
                                    subModuleKey: "general-instructions"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayGeneralInstructionsView, {
                                    model: _ctx.model?.instructions?.generalInstructions
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.instructions?.weighing)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "balance",
                                    headerText: _ctx.translate('sub-modules.weighing.name'),
                                    subModuleKey: "weighing"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayWeighingView, {
                                    model: _ctx.model?.instructions?.weighing
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.instructions?.filling)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "invert_colors",
                                    headerText: _ctx.translate('sub-modules.filling.name'),
                                    subModuleKey: "filling"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayFillingView, {
                                    model: _ctx.model?.instructions?.filling
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.instructions?.sealing)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "check_circle",
                                    headerText: _ctx.translate('sub-modules.sealing.name'),
                                    subModuleKey: "sealing"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplaySealingView, {
                                    model: _ctx.model?.instructions?.sealing
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.instructions?.sampling)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "science",
                                    headerText: _ctx.translate('sub-modules.sampling.name'),
                                    subModuleKey: "sampling"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplaySamplingView, {
                                    model: _ctx.model?.instructions?.sampling
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.instructions?.processes)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "apps",
                                    headerText: _ctx.translate('sub-modules.processes.name'),
                                    subModuleKey: "processes"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayProcessesView, {
                                    model: _ctx.model?.instructions?.processes
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"])
            ])
        ]));
}
