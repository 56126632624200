import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayVehicleAndPPEChips = _resolveComponent("DisplayVehicleAndPPEChips");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_DisplayVehicleAndPPEChips, {
            label: _ctx.translate('minimum-ppe-requirements.label'),
            data: _ctx.model.minPPEToEnterSite,
            anyChips: _ctx.model.minPPEToEnterSite?.length,
            noChipsString: _ctx.translate('no-special-requirements')
        }, null, 8, ["label", "data", "anyChips", "noChipsString"]),
        _createVNode(_component_DisplayVehicleAndPPEChips, {
            label: _ctx.translate('additional-ppe-requirements.label'),
            data: _ctx.model.additionalPPEForDGGoods,
            anyChips: _ctx.model.additionalPPEForDGGoods?.length,
            noChipsString: _ctx.translate('no-special-requirements')
        }, null, 8, ["label", "data", "anyChips", "noChipsString"]),
        _createVNode(_component_DisplayVehicleAndPPEChips, {
            label: _ctx.translate(`un-loading-point-specific-requirements.${_ctx.domainTranslationPrefix('label')}`),
            data: _ctx.model.additionalULPSpecificPPErequirements,
            anyChips: _ctx.model.additionalULPSpecificPPErequirements?.length,
            noChipsString: _ctx.translate('no-special-requirements')
        }, null, 8, ["label", "data", "anyChips", "noChipsString"]),
        _createVNode(_component_DisplayVehicleAndPPEChips, {
            label: _ctx.translate('additional-product-ppe-requirements.label'),
            data: _ctx.model.additionalAssignedProductSpecificPPErequirements,
            anyChips: _ctx.model.additionalAssignedProductSpecificPPErequirements?.length,
            noChipsString: _ctx.translate('additional-product-ppe-requirements.loading-placeholder'),
            editableDomain: ""
        }, null, 8, ["label", "data", "anyChips", "noChipsString"])
    ], 64));
}
