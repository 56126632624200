export var ToastSeverity;
(function (ToastSeverity) {
    ToastSeverity["Info"] = "info";
    ToastSeverity["Success"] = "success";
    ToastSeverity["Warning"] = "warn";
    ToastSeverity["Error"] = "error";
})(ToastSeverity || (ToastSeverity = {}));
export class ToastMessage {
    message;
    condition;
    severity;
    constructor(message, condition, severity) {
        this.message = message;
        this.condition = condition;
        this.severity = severity;
    }
}
