var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
let TendersService = class TendersService {
    httpService;
    constructor(httpService) {
        this.httpService = httpService;
    }
    async assignCarrierToTender(tenderId, carrierIds) {
        return await this.httpService.post("tenders/assignCarrierToTender", {
            carrierIds,
            tenderId,
        });
    }
    async createSpecificationFile(tenderIds, columnIds) {
        return await this.httpService
            .plainRequest("POST", "tenders/createSpecificationFile", {
            headers: {
                "Content-Type": "text/csv",
            },
        }, {
            tenderIds,
            columnIds,
        })
            .then((response) => {
            const contentDispositionHeader = response.headers["content-disposition"];
            let fileName = "specification.csv";
            if (contentDispositionHeader) {
                const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = fileNameRegex.exec(contentDispositionHeader);
                if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, "");
                }
            }
            return { fileContent: response.data, fileName };
        });
    }
};
TendersService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object])
], TendersService);
export { TendersService };
