import _ from "lodash";
const mergeObjects = (a, b) => {
    const keys = [..._.keys(a), ..._.keys(b)];
    const result = {};
    keys.forEach((it) => {
        const valueA = _.get(a, it);
        const valueB = _.get(b, it);
        _.set(result, it, merge(valueA, valueB));
    });
    return result;
};
export const merge = (a, b) => {
    if (_.isUndefined(a) && _.isUndefined(b)) {
        return undefined;
    }
    if (_.isArray(a) && _.isArray(b)) {
        return [...b];
    }
    if (_.isObject(a) && _.isObject(b)) {
        return mergeObjects(a, b);
    }
    if (!_.isUndefined(a) && _.isUndefined(b)) {
        return a;
    }
    return b;
};
