var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let MaxLengthValidator = class MaxLengthValidator {
    translationService;
    name = "max-length";
    arg = Number;
    constructor(translationService) {
        this.translationService = translationService;
    }
    message(max) {
        return this.translationService.translate("validation.messages.max-length", { max: max + 1 });
    }
    validate(value, max) {
        return !value || value.length <= max;
    }
};
MaxLengthValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], MaxLengthValidator);
export { MaxLengthValidator };
