import { paramCase } from "change-case";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import SiteSummaryComponent from "@/app/domains/summary/components/site-summary.component.vue";
import ULPSummaryComponent from "@/app/domains/summary/components/ulp-summary.component.vue";
import ProductSummaryComponent from "@/app/domains/summary/components/product-summary.component.vue";
import { VerificationRequestsSummaryDomainId, LoadingSummaryProductsDomainId, UnloadingSummaryProductsDomainId, ArchivedLoadingSummaryDomainId, ArchivedUnloadingSummaryDomainId, } from "@/app/domains/summary/summary.constants";
const Domain = {
    [VerificationRequestsSummaryDomainId]: "unloadingPoint",
    [LoadingSummaryProductsDomainId]: "loadingPoint",
    [UnloadingSummaryProductsDomainId]: "unloadingPoint",
    [ArchivedLoadingSummaryDomainId]: "loadingPoint",
    [ArchivedUnloadingSummaryDomainId]: "unloadingPoint",
};
export const getSummaryRoutes = (domainId, parentDomainId) => {
    const componentSuffixes = [parentDomainId, domainId];
    const domainIdParam = `${paramCase(domainId)}-page`;
    const siteSummaryComponent = suffixComponentName(SiteSummaryComponent, ...componentSuffixes);
    const ulpSummaryComponent = suffixComponentName(ULPSummaryComponent, ...componentSuffixes);
    const productSummaryComponent = suffixComponentName(ProductSummaryComponent, ...componentSuffixes);
    return [
        {
            path: "",
            name: `${domainIdParam}`,
            children: [
                {
                    path: "site",
                    name: `${domainIdParam}/summary-site`,
                    meta: {
                        entityId: "site",
                    },
                    component: siteSummaryComponent,
                },
                {
                    path: "ulp",
                    name: `${domainIdParam}/summary-ulp`,
                    meta: {
                        entityId: Domain[domainId],
                    },
                    component: ulpSummaryComponent,
                },
                {
                    path: "product",
                    name: `${domainIdParam}/summary-product`,
                    meta: {
                        entityId: "product",
                    },
                    component: productSummaryComponent,
                },
            ],
        },
    ];
};
