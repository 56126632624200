import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        header: _ctx.translate('modal-header'),
        class: "assign-carrier-modal"
    }, {
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    initial: _ctx.initial,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event))
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("select-carrier-label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_AppMultiSelect, {
                                    name: "carrierIds",
                                    options: _ctx.carrierOptions,
                                    optionValue: "id",
                                    optionLabel: "name",
                                    placeholder: _ctx.translate('select-carrier-placeholder')
                                }, null, 8, ["options", "placeholder"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["initial", "modelValue"])),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.modalService.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        onClick: _ctx.assign,
                        loading: _ctx.isAssigning
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.assign")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick", "loading"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
