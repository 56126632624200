import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import EditPostalAddressComponent from "@/app/sub-modules/postal-address/components/edit-postal-address.component.vue";
import DisplayPostalAddressComponent from "@/app/sub-modules/postal-address/components/display-postal-address.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { PostalAddressSubModuleId } from "@/app/sub-modules/postal-address/postal-address.constants";
export const PostalAddressSubModule = (domainId, options) => {
    const id = PostalAddressSubModuleId;
    const moduleId = GeneralInformationModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditPostalAddressComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayPostalAddressComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                displayTitle: "sub-modules.address.name-display",
                icon: "place",
                publicInformation: options?.publicInformation,
                editGuards: options?.editGuards,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.address" }],
    });
};
