import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InlineSvg = _resolveComponent("InlineSvg");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_NotificationCard = _resolveComponent("NotificationCard");
    return (_openBlock(), _createBlock(_component_NotificationCard, { notification: _ctx.notification }, {
        icon: _withCtx(() => [
            _createVNode(_component_InlineSvg, {
                class: "logo-icon",
                href: "/assets/icons/logo.png"
            })
        ]),
        header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.notification?.header), 1)
        ]),
        description: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.notification?.text), 1)
        ]),
        footer: _withCtx(() => [
            (_ctx.notification?.link)
                ? (_openBlock(), _createBlock(_component_AppButton, {
                    key: 0,
                    class: "button",
                    onClick: _ctx.openLink
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("button")) + " ", 1),
                        _createVNode(_component_Icon, {
                            class: "icon-button",
                            name: "arrow_forward"
                        })
                    ]),
                    _: 1
                }, 8, ["onClick"]))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }, 8, ["notification"]));
}
