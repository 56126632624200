import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2fc60f9c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "input-section" };
const _hoisted_2 = { class: "input-section" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            class: "edit-site-additional-information"
        }, {
            default: _withCtx(() => [
                _createElementVNode("section", _hoisted_1, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.translate("decoupled-loading")), 1),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_RadioButton, {
                                modelValue: _ctx.model.decoupledLoading,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.decoupledLoading) = $event)),
                                truthyLabel: "phrases.possible",
                                falsyLabel: "phrases.not-possible"
                            }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                    })
                ]),
                _createElementVNode("section", _hoisted_2, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.translate("external-cargo")), 1),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_RadioButton, {
                                modelValue: _ctx.model.externalCargo,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.externalCargo) = $event)),
                                truthyLabel: "phrases.allowed",
                                falsyLabel: "phrases.not-allowed"
                            }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                    })
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "initial"]));
}
