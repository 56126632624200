import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import _ from "lodash";
const LOCAL_STORAGE_KEY = "loady-local";
let LocalStorageService = class LocalStorageService {
    get(path) {
        const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? "{}");
        return _.get(data, path);
    }
    set(path, value) {
        const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? "{}");
        _.set(data, path, value);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
    }
    clear(path) {
        this.set(path, undefined);
    }
};
LocalStorageService = __decorate([
    service()
], LocalStorageService);
export { LocalStorageService };
