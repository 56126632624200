var _a;
import { __decorate, __metadata } from "tslib";
import { UpdateCompanyService } from "@/app/domains/public-data/companies/services/update-company.service";
import { service } from "@/di/decorators/service.decorator";
import { ChangeDetectionServiceToken } from "@/app/modular-content/token/modular-content.token";
let CompanyChangeDetectionService = class CompanyChangeDetectionService {
    updateCompanyService;
    constructor(updateCompanyService) {
        this.updateCompanyService = updateCompanyService;
    }
    getChanges() {
        return this.updateCompanyService.getChanges();
    }
    hasChanges() {
        return this.updateCompanyService.hasChanges();
    }
    discardChanges() {
        this.updateCompanyService.discard();
    }
    getDomainId() {
        return "";
    }
    getModuleId() {
        return "";
    }
};
CompanyChangeDetectionService = __decorate([
    service({ token: ChangeDetectionServiceToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof UpdateCompanyService !== "undefined" && UpdateCompanyService) === "function" ? _a : Object])
], CompanyChangeDetectionService);
export { CompanyChangeDetectionService };
