var _a, _b;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { DomainMetadataToken, UrlFactoryToken } from "@/app/modular-content/token/modular-content.token";
import { DomainRouteService } from "@/app/modular-content/services/domain-route.service";
import { inject } from "@/di/decorators/inject.decorator";
import { DomainMetadata } from "@/app/modular-content/type/domain-metadata.type";
let ModuleConfigurationUrlFactoryService = class ModuleConfigurationUrlFactoryService {
    domainRouteService;
    meta;
    constructor(domainRouteService, meta) {
        this.domainRouteService = domainRouteService;
        this.meta = meta;
    }
    createUrl() {
        const baseUrl = this.meta.baseUrlCallback();
        const entityId = this.domainRouteService.getCurrentEntityId();
        return `${baseUrl}/${entityId}/modules`;
    }
};
ModuleConfigurationUrlFactoryService = __decorate([
    service({ token: UrlFactoryToken }),
    __param(1, inject(DomainMetadataToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof DomainRouteService !== "undefined" && DomainRouteService) === "function" ? _a : Object, typeof (_b = typeof DomainMetadata !== "undefined" && DomainMetadata) === "function" ? _b : Object])
], ModuleConfigurationUrlFactoryService);
export { ModuleConfigurationUrlFactoryService };
