import { BackendErrorResolverToken } from "@/app/validation/tokens/backend-error-resolver.token";
import { BackendValidationService } from "@/app/validation/services/backend-validation.service";
import { ConflictErrorResolver } from "@/app/validation/resolvers/conflict-error.resolver";
import { createModule } from "@/di/module";
import { DiacriticalValidator } from "@/app/validation/validators/diacritical.validator";
import { EmailValidator } from "@/app/validation/validators/email.validator";
import { FormatValidator } from "@/app/validation/validators/format.validator";
import { GeoLatitudeValidator } from "@/app/validation/validators/geo-latitude.validator";
import { GeoLongitudeValidator } from "@/app/validation/validators/geo-longitude.validator";
import { MaxLengthValidator } from "@/app/validation/validators/max-length.validator";
import { MaxValidator } from "@/app/validation/validators/max.validator";
import { MinLengthValidator } from "@/app/validation/validators/min-length.validator";
import { MinValidator } from "@/app/validation/validators/min.validator";
import { OneWordValidator } from "@/app/validation/validators/one-word.validator";
import { RequiredErrorResolver } from "@/app/validation/resolvers/required-error.resolver";
import { ConditionValidationErrorResolver } from "@/app/validation/resolvers/condition-validation-error.resolver";
import { RequiredValidator } from "@/app/validation/validators/required.validator";
import { UrlValidator } from "@/app/validation/validators/url.validator";
import { UrlWithOptionalSchemaValidator } from "@/app/validation/validators/url-with-optional-schema.validator";
import { ValidationService } from "@/app/validation/services/validation.service";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { BackendValidationErrorsService } from "@/app/validation/services/backend-validation-errors.service";
import { MaxIntegerDigitsValidator } from "@/app/validation/validators/max-integer-digits.validator";
import { MinFractionDigitsValidator } from "@/app/validation/validators/min-fraction-digits.validator";
import { MaxFractionDigitsValidator } from "@/app/validation/validators/max-fraction-digits.validator";
import { ExactLengthValidator } from "@/app/validation/validators/exact-length.validator";
import { AlphanumericValidator } from "@/app/validation/validators/alphanumeric.validator";
import { CasNumberValidator } from "@/app/validation/validators/cas-number.validator";
import { EcNumberValidator } from "@/app/validation/validators/ec-number.validator";
const validators = [
    RequiredValidator,
    EmailValidator,
    UrlValidator,
    UrlWithOptionalSchemaValidator,
    GeoLatitudeValidator,
    GeoLongitudeValidator,
];
export const ValidationModule = createModule({
    providers: [
        ValidationService,
        BackendValidationService,
        BackendValidationErrorsService,
        FormatValidator,
        MaxValidator,
        MaxLengthValidator,
        MaxIntegerDigitsValidator,
        MinFractionDigitsValidator,
        MaxFractionDigitsValidator,
        MinValidator,
        MinLengthValidator,
        ExactLengthValidator,
        OneWordValidator,
        DiacriticalValidator,
        AlphanumericValidator,
        CasNumberValidator,
        EcNumberValidator,
        ...validators.map((it) => ({
            token: ValidatorToken,
            useValue: it,
        })),
        {
            token: BackendErrorResolverToken,
            useClass: ConflictErrorResolver,
        },
        {
            token: BackendErrorResolverToken,
            useClass: RequiredErrorResolver,
        },
        {
            token: BackendErrorResolverToken,
            useClass: ConditionValidationErrorResolver,
        },
    ],
});
