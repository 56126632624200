import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4d610b8e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OpeningHoursTable = _resolveComponent("OpeningHoursTable");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("general-opening-hours"))), 1)
            ]))
            : _createCommentVNode("", true),
        (_ctx.isOpeningHoursDefined)
            ? (_openBlock(), _createBlock(_component_OpeningHoursTable, {
                key: 1,
                "opening-hours": _ctx.openingHours,
                dayAndHours: ""
            }, null, 8, ["opening-hours"]))
            : _createCommentVNode("", true),
        _createVNode(_component_AppButton, {
            class: "p-button-tertiary p-button-sm no-hover",
            onClick: _ctx.editOpeningHours
        }, {
            default: _withCtx(() => [
                _createVNode(_component_Icon, { name: "edit" }),
                _createTextVNode(" " + _toDisplayString(_ctx.translate(_ctx.domainTranslationPrefix("set-opening-hours"))), 1)
            ]),
            _: 1
        }, 8, ["onClick"])
    ], 64));
}
