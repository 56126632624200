import { __decorate, __metadata, __param } from "tslib";
import { injectAll } from "@/di/decorators/inject-all.decorator";
import { SideNavigationMenuItemToken } from "@/app/core/tokens/side-navigation-menu-item.token";
import { service } from "@/di/decorators/service.decorator";
let SideNavigationService = class SideNavigationService {
    menuItems;
    constructor(menuItems) {
        this.menuItems = menuItems;
    }
    getMenuItems() {
        return this.menuItems;
    }
};
SideNavigationService = __decorate([
    service(),
    __param(0, injectAll(SideNavigationMenuItemToken)),
    __metadata("design:paramtypes", [Array])
], SideNavigationService);
export { SideNavigationService };
