import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isIcon ? 'svg' : 'img'), _mergeProps({ class: "inline-svg" }, _ctx.attrs), {
        default: _withCtx(() => [
            (_ctx.isIcon)
                ? (_openBlock(), _createElementBlock("use", {
                    key: 0,
                    "xlink:href": _ctx.path,
                    "xmlns:xlink": "http://www.w3.org/1999/xlink"
                }, null, 8, _hoisted_1))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }, 16));
}
