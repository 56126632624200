import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import DisplaySafetyDataSheet from "@/app/sub-modules/safety-data-sheet/components/display-safety-data-sheet.vue";
import DisplaySafetyDataSheetView from "@/app/sub-modules/safety-data-sheet/components/display-safety-data-sheet-view.vue";
import EditSafetyDataSheet from "@/app/sub-modules/safety-data-sheet/components/edit-safety-data-sheet.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { ProductsSafetyModuleId } from "@/app/modules/products-safety/products-safety.module";
export const SafetyDataSheetSubModule = (domainId) => {
    const id = "safetyDataSheet";
    const moduleId = ProductsSafetyModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplaySafetyDataSheet, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplaySafetyDataSheetView, ...componentSuffixes);
    const editComponent = suffixComponentName(EditSafetyDataSheet, ...componentSuffixes);
    return createModule({
        components: [displayComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "format_list_bulleted",
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.safety-data-sheet",
            },
        ],
    });
};
