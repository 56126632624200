var _a, _b, _c;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { InjectionScope } from "@/di/types/scope";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { paramCase } from "change-case";
import _ from "lodash";
import { merge } from "@/app/shared/functions/merge";
import { inject } from "@/di/decorators/inject.decorator";
import { RestClientBaseUrlCallbackToken } from "@/app/http/tokens/rest-client-base-url-callback.token";
import { BaseUrlCallback } from "@/app/modular-content/modules/types/domain-module.types";
import { RouterService } from "@/app/shared/services/router.service";
let RestClientService = class RestClientService {
    httpClient;
    routerService;
    baseUrlCallback;
    headerIds = ["companyId", "siteId"];
    constructor(httpClient, routerService, baseUrlCallback) {
        this.httpClient = httpClient;
        this.routerService = routerService;
        this.baseUrlCallback = baseUrlCallback;
    }
    /**
     * Context ids are removed from the payload and applied to the headers.
     */
    create(dto) {
        const isHeaderId = (val, key) => this.headerIds.includes(key);
        const headerValue = (val, key) => `x-${paramCase(key)}`;
        const filteredDto = _.pickBy(dto ?? {}, (val, key) => !isHeaderId(val, key));
        const headers = _.mapKeys(_.pickBy(dto ?? {}, isHeaderId), headerValue);
        return this.httpClient.post(`${this.getBaseUrl()}`, filteredDto, { headers });
    }
    get(id) {
        return this.httpClient.get(`${this.getBaseUrl()}/${id}`);
    }
    getData() {
        return this.httpClient.get(`${this.getBaseUrl()}`);
    }
    /**
     * WORKAROUND:
     * PUT doesn't actually put the resource at the specified url. It sometimes applies additional changes, so we just
     * assume it worked correctly and return what we requested to be put at the url.
     */
    async replace(id, entity) {
        const updated = await this.httpClient.put(`${this.getBaseUrl()}/${id}`, entity);
        const updatedMerge = merge(entity, updated);
        return updated ? updatedMerge : entity;
    }
    /**
     * WORKAROUND:
     * There is no PATCH endpoint, so we emulate it using a combination of GET and PUT.
     * Also, we need to use the emulated PUT because it doesn't work as expected.
     */
    async update(id, dto) {
        const current = await this.httpClient.get(`${this.getBaseUrl()}/${id}`);
        const updated = merge(current, dto);
        return this.replace(id, updated);
    }
    delete(id, config) {
        return this.httpClient.delete(`${this.getBaseUrl()}/${id}`, config);
    }
    getBaseUrl() {
        return this.baseUrlCallback(this.routerService);
    }
};
RestClientService = __decorate([
    service({ scope: InjectionScope.Module }),
    __param(2, inject(RestClientBaseUrlCallbackToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof RouterService !== "undefined" && RouterService) === "function" ? _b : Object, typeof (_c = typeof BaseUrlCallback !== "undefined" && BaseUrlCallback) === "function" ? _c : Object])
], RestClientService);
export { RestClientService };
