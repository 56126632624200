import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { InjectionScope } from "@/di/types/scope";
import { injectAll } from "@/di/decorators/inject-all.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import _ from "lodash";
import { paramCase } from "change-case";
import { error } from "@/app/shared/functions/error";
let ValidationService = class ValidationService {
    validatorsByName;
    constructor(validators) {
        this.validatorsByName = _.keyBy(validators, "name");
    }
    getValidatorByName(name) {
        return (this.validatorsByName[paramCase(name)] ??
            this.validatorsByName[name] ??
            error(`Encountered an unknown validator '${name}'.`));
    }
    validate(data, schema) {
        const schemaPaths = calculateSchemaPaths(schema);
        const results = [];
        schemaPaths.forEach((it) => {
            const validators = _.get(schema, it);
            _.forEach(validators, (arg, name) => {
                results.push(validatePath(data, it, this.getValidatorByName(name), arg));
            });
        });
        return results;
    }
};
ValidationService = __decorate([
    service({ scope: InjectionScope.Global }),
    __param(0, injectAll(ValidatorToken)),
    __metadata("design:paramtypes", [Array])
], ValidationService);
export { ValidationService };
const validatePath = (data, path, validator, arg) => {
    const value = _.get(data, path);
    const partialResult = {
        validator: validator.name,
        path,
    };
    try {
        const valid = validator.validate(value, arg);
        return {
            ...partialResult,
            valid,
        };
    }
    catch (error) {
        return {
            ...partialResult,
            valid: false,
            error,
        };
    }
};
const calculatePaths = (value, parent) => {
    if (_.isString(value)) {
        return _.compact([parent]);
    }
    if (!_.isObject(value)) {
        return _.compact([parent]);
    }
    return _.values(_.mapValues(value, (it, key) => {
        const isArrayKey = /^\d+$/.test(key);
        const properKey = isArrayKey ? `[${key}]` : key;
        return calculatePaths(it, _.compact([parent, properKey]).join(isArrayKey ? "" : "."));
    })).flat();
};
const calculateSchemaPaths = (schema) => {
    const paths = calculatePaths(schema);
    return _.uniq(paths.map((it) => {
        const parts = it.split(".");
        parts.pop();
        return parts.join(".");
    }));
};
