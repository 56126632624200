import { createModule } from "@/di/module";
import EntityConnectModalComponent from "@/app/modular-content/components/entity-connect-modal.component.vue";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { combinedIdFactory } from "@/app/shared/factories/combined-id.factory";
export const ConnectModalModule = (options) => {
    if (options.connectingDisabled) {
        return createModule({});
    }
    const modalId = combinedIdFactory("ConnectEntity", options.id);
    const token = domainTokensFactory(options.id);
    const component = EntityConnectModalComponent;
    return createModule({
        imports: [ModalModule(modalId, suffixComponentName(component, options.id))],
        providers: [{ token: token.connectModal, useValue: modalId }],
    });
};
