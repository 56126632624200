import { createModule } from "@/di/module";
import { modalServiceTokenFactory } from "@/app/modals/factories/modal-service-token.factory";
import { ModalService } from "@/app/modals/services/modal.service";
import { modalComponentTokenFactory } from "@/app/modals/factories/modal-component-token.factory";
import { ModalIdToken } from "@/app/modals/tokens/modal-id.token";
export const ModalModule = (modalId, component) => {
    return createModule({
        providers: [
            { token: ModalIdToken, useValue: modalId },
            { token: modalServiceTokenFactory(modalId), useClass: ModalService },
            { token: modalComponentTokenFactory(modalId), useValue: component },
            { token: ModalService, useToken: modalServiceTokenFactory(modalId) },
        ],
    });
};
