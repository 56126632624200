import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-61f64b80"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "title col-6" };
const _hoisted_3 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_4 = {
    key: 1,
    class: "col-6 description"
};
const _hoisted_5 = {
    key: 0,
    class: "col-6 description"
};
const _hoisted_6 = {
    key: 1,
    class: "col-6 description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
        (_ctx.anyChips)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (tag, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                        key: tag + index,
                        class: "tag"
                    }, _toDisplayString(_ctx.getValue(tag, "VehicleAndPersonalProtectiveEquipment")), 1));
                }), 128))
            ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.editableDomain)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, "-"))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.noChipsString), 1))
            ]))
    ]));
}
