import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-18c08677"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-processes" };
const _hoisted_2 = {
    key: 0,
    class: "tags"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.model?.processes?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.processes, (tag, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                        key: tag + index,
                        class: "tag"
                    }, _toDisplayString(_ctx.getValue(tag, "Processes")), 1));
                }), 128))
            ]))
            : (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 1 }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("empty")), 1)
                ]),
                _: 1
            }))
    ]));
}
