import { createModule } from "@/di/module";
import { AppInitServiceToken } from "@/app/shared/tokens/app-init-service.token";
import { DataLocalizationService } from "./services/data-localization-service.service";
export const DataLocalizationModule = createModule({
    providers: [
        DataLocalizationService,
        {
            token: AppInitServiceToken,
            useToken: DataLocalizationService,
        },
    ],
});
