import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-15d2326c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 2,
    class: "info"
};
const _hoisted_2 = { class: "header" };
const _hoisted_3 = { class: "description" };
const _hoisted_4 = { class: "flex-row" };
const _hoisted_5 = { class: "date" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["notification-card", `${_ctx.notification?.notificationType?.toLowerCase()}-card`])
    }, [
        _renderSlot(_ctx.$slots, "icon", {}, () => [
            _createVNode(_component_Icon, {
                class: "icon-group",
                name: _ctx.iconTypeName
            }, null, 8, ["name"])
        ], true),
        (_ctx.notification.showXButton)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                class: _normalizeClass(["icon-close", { disabled: _ctx.isLoading }]),
                name: "close",
                onClick: _ctx.removeMethod
            }, null, 8, ["class", "onClick"]))
            : _createCommentVNode("", true),
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 1,
                center: ""
            }))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                ]),
                _createElementVNode("p", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "description", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.getDateTime(_ctx.notification.createdTime)), 1),
                    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                ])
            ]))
    ], 2));
}
