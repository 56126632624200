export const Roles = {
    member: "member",
    dataManager: "dataManager",
    requirementManager: "requirementManager",
    tenderManager: "tenderManager",
    companyAdmin: "companyAdmin",
    loadyAdmin: "loadyAdmin",
    verifier: "verifier",
    productManager: "productManager",
};
