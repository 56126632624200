import { createModule } from "@/di/module";
import { AuthLocalIdentityInterceptorService } from "@/app/auth/services/auth-local-identity-interceptor.service";
import { AuthService } from "@/app/auth/services/auth.service";
import { AuthTokenInterceptorService } from "@/app/auth/services/auth-token-interceptor.service";
import { SessionService } from "@/app/auth/services/session.service";
import { UnauthorizedInterceptorService } from "@/app/auth/services/unauthorized-interceptor.service";
import { AppInitServiceToken } from "@/app/shared/tokens/app-init-service.token";
export const AuthModule = createModule({
    providers: [
        SessionService,
        AuthService,
        {
            token: AppInitServiceToken,
            useClass: AuthService,
        },
        AuthTokenInterceptorService,
        AuthLocalIdentityInterceptorService,
        UnauthorizedInterceptorService,
    ],
});
