import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-92f59ad0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-documents" };
const _hoisted_2 = {
    key: 0,
    class: "description"
};
const _hoisted_3 = { class: "title col-6" };
const _hoisted_4 = { class: "tags col-6" };
const _hoisted_5 = {
    key: 1,
    class: "description"
};
const _hoisted_6 = { class: "row" };
const _hoisted_7 = { class: "title col-6" };
const _hoisted_8 = { class: "tags col-6" };
const _hoisted_9 = {
    key: 1,
    class: "description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialDocumentRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("no-special-documents-required")), 1))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", {
                    class: _normalizeClass(["row", { section: _ctx.model?.documentsForUnloadingLoading.length }])
                }, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate(`${_ctx.domainTranslationPrefix("documents")}`)), 1),
                    _createElementVNode("div", _hoisted_4, [
                        (!!_ctx.model?.documentsForUnloadingLoading?.length)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.model.documentsForUnloadingLoading, (value, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: value + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(value, "DocumentForUnloadingLoading")), 1));
                            }), 128))
                            : (_openBlock(), _createElementBlock("p", _hoisted_5, "-"))
                    ])
                ], 2),
                _createVNode(_component_DisplayAttachments, {
                    class: _normalizeClass(["mb-4", { section: _ctx.model?.restrictionsOnDocuments.length }]),
                    attachments: _ctx.model?.restrictionsOnDocuments,
                    title: _ctx.translate('additional-requirements-on-documents')
                }, null, 8, ["class", "attachments", "title"]),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("carrier-must-be-certified")), 1),
                    _createElementVNode("div", _hoisted_8, [
                        (!!_ctx.model?.carrierCertificates?.length)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.model.carrierCertificates, (value, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: value + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(value, "CarrierCertificates")), 1));
                            }), 128))
                            : (_openBlock(), _createElementBlock("p", _hoisted_9, "-"))
                    ])
                ])
            ], 64))
    ]));
}
