import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_WeighingBridgeInput = _resolveComponent("WeighingBridgeInput");
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_WeighingBridgesInput = _resolveComponent("WeighingBridgesInput");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.temporaryMode)
            ? (_openBlock(), _createBlock(_component_WeighingBridgeInput, {
                key: 0,
                presence: _ctx.presence,
                errors: _ctx.errors
            }, null, 8, ["presence", "errors"]))
            : _createCommentVNode("", true),
        _createElementVNode("div", {
            class: _normalizeClass(["edit-weighing-bridges", { 'temporary-mode': _ctx.temporaryMode }])
        }, [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
                    initial: _ctx.initial
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_WeighingBridgesInput, {
                            name: "weighingBridges",
                            modelValue: _ctx.model.weighingBridges,
                            "onUpdate:modelValue": [
                                _cache[0] || (_cache[0] = ($event) => ((_ctx.model.weighingBridges) = $event)),
                                _cache[1] || (_cache[1] = ($event) => (_ctx.model.weighingBridges = $event))
                            ],
                            presence: _ctx.presence,
                            id: _ctx.confirmId
                        }, null, 8, ["modelValue", "presence", "id"])
                    ]),
                    _: 1
                }, 8, ["modelValue", "initial"]))
        ], 2)
    ], 64));
}
