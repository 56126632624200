import _ from "lodash";
import { createModule } from "@/di/module";
import { EntityTableSearchDelegateService } from "@/app/modular-content/services/entity-table-search-delegate.service";
import { NullEntityTableFilterService } from "@/app/modular-content/services/null-entity-table-filter.service";
import EntityListsComponent from "@/app/modular-content/components/entity-lists.component.vue";
import EntityListTabComponent from "@/app/modular-content/components/entity-list-tab.component.vue";
import { domainListTokensFactory } from "@/app/modular-content/factories/domain-list-tokens.factory";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { translationDomainPrefix } from "@/app/modular-content/modules/functions/translation-domain-prefix.function";
import { EntityListsMetaToken, EntityTableFilterServiceToken } from "@/app/modular-content/token/modular-content.token";
export const DomainListsModule = (options) => {
    if (!options.lists?.length) {
        return createModule({});
    }
    const moduleTokens = domainTokensFactory(options.id);
    const listsMetadata = [];
    const listModules = _.flatMap(options.lists, (list) => {
        const listTokens = domainListTokensFactory(options.id, list.id);
        const listMetadata = {
            id: list.id,
            default: list.default ?? false,
            title: `${translationDomainPrefix(options)}.lists.${list.id}`,
            createRoles: list.createRoles,
            listAccessCondition: list.listAccessCondition,
            hideForBusinessPartners: list.hideForBusinessPartners ?? false,
            publicList: list.publicList ?? false,
            copyAvailable: list.copyAvailable ?? false,
        };
        listsMetadata.push(listMetadata);
        return createModule({
            components: [
                suffixComponentName(EntityListsComponent, options.id),
                suffixComponentName(EntityListTabComponent, options.id, list.id),
            ],
            providers: [
                { token: listTokens.meta, useValue: listMetadata },
                { token: moduleTokens.listsMeta, useToken: listTokens.meta },
                {
                    token: listTokens.tableDelegate,
                    useClass: EntityTableSearchDelegateService,
                },
                {
                    token: EntityTableSearchDelegateService,
                    useToken: listTokens.tableDelegate,
                },
                {
                    token: EntityTableFilterServiceToken,
                    useToken: list.filter ?? NullEntityTableFilterService,
                },
            ],
        });
    });
    return createModule({
        imports: listModules,
        providers: [{ token: EntityListsMetaToken, useValue: listsMetadata }],
    });
};
