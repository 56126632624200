var _a, _b;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
let RequestVerificationService = class RequestVerificationService {
    httpService;
    toastService;
    constructor(httpService, toastService) {
        this.httpService = httpService;
        this.toastService = toastService;
    }
    async requestVerification(requirementProfileId) {
        await this.httpService.post("requirementprofiles/sendRequestVerification", {
            requirementProfileIds: requirementProfileId,
        });
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: "domains.requirement-profiles.request-verification.toast-message",
        });
    }
};
RequestVerificationService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object])
], RequestVerificationService);
export { RequestVerificationService };
