import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-81b66a74"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 1,
    class: "edit-opening-hours"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_EditOpeningHoursWidget = _resolveComponent("EditOpeningHoursWidget");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_EditOpeningHoursWidget, {
                "opening-hours": _ctx.openingHours,
                "onUpdate:openingHours": _cache[0] || (_cache[0] = ($event) => (_ctx.openingHours = $event)),
                title: ""
            }, null, 8, ["opening-hours"])
        ]));
}
