import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2f9e29fc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "clean-button",
    type: "button"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("button", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]));
}
