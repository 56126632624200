var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { RouterService } from "@/app/shared/services/router.service";
let SelectedCarriersLanesFilterService = class SelectedCarriersLanesFilterService {
    routerService;
    constructor(routerService) {
        this.routerService = routerService;
    }
    createEqualsFilter() {
        const query = this.routerService.getCurrentRoute()?.query;
        const filters = [];
        if (query?.filter) {
            filters.push({
                propertyName: "TenderId",
                propertyValue: query.filter,
            });
        }
        return filters;
    }
};
SelectedCarriersLanesFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof RouterService !== "undefined" && RouterService) === "function" ? _a : Object])
], SelectedCarriersLanesFilterService);
export { SelectedCarriersLanesFilterService };
