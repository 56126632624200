export const PreloadingRestrictionsDomainId = "PreloadingRestrictions";
export const EditPreloadingRestrictionsModalId = "EditEntityPreloadingRestrictions";
export const DisplayPreProductModalId = "DisplayPreProductModal";
export const CopyPreLoadingRestrictionsModalId = "CopyEntityPreloadingRestrictions";
export const loadCountOptions = [
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
];
