import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-289e04d4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-6" };
const _hoisted_3 = { class: "col-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_FormField, { class: "mb-0" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.location.latitude.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputNumber, {
                                class: "inputnumber",
                                name: "latitude",
                                placeholder: _ctx.translate('form-fields.location.latitude.placeholder'),
                                "geo-latitude": "",
                                id: "location-latitude",
                                mode: "decimal",
                                maxFractionDigits: 15,
                                onPaste: _ctx.onPaste
                            }, null, 8, ["placeholder", "onPaste"]),
                            _createVNode(_component_FormError, { name: "latitude" })
                        ]),
                        _: 1
                    })
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_FormField, { class: "mb-0" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("form-fields.location.longitude.label")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_InputNumber, {
                                class: "inputnumber",
                                name: "longitude",
                                placeholder: _ctx.translate('form-fields.location.longitude.placeholder'),
                                id: "location-longitude",
                                "geo-longitude": "",
                                mode: "decimal",
                                maxFractionDigits: 15,
                                onPaste: _ctx.onPaste
                            }, null, 8, ["placeholder", "onPaste"]),
                            _createVNode(_component_FormError, { name: "longitude" })
                        ]),
                        _: 1
                    })
                ])
            ])
        ]),
        _: 1
    }, 8, ["modelValue", "initial"]));
}
