import { createModule } from "@/di/module";
import EntityCopyModalComponent from "@/app/modular-content/components/entity-copy-modal.component.vue";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { combinedIdFactory } from "@/app/shared/factories/combined-id.factory";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { CopyEntityModalIdToken } from "@/app/modular-content/token/modular-content.token";
export const CopyEntityModalModule = (options) => {
    if (!options.components.copyModal) {
        return createModule({});
    }
    const modalId = combinedIdFactory("CopyEntity", options.id);
    const component = EntityCopyModalComponent;
    return createModule({
        imports: [ModalModule(modalId, suffixComponentName(component, options.id))],
        providers: [{ token: CopyEntityModalIdToken, useValue: modalId }],
    });
};
