import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-485dbee1"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-filling" };
const _hoisted_2 = {
    key: 0,
    class: "column-value mb-0"
};
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 column-value" };
const _hoisted_6 = { class: "row" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = { class: "col-6 column-value" };
const _hoisted_9 = { class: "row" };
const _hoisted_10 = { class: "col-6 column-header" };
const _hoisted_11 = { class: "col-6 column-value" };
const _hoisted_12 = { class: "row" };
const _hoisted_13 = { class: "col-6 column-header" };
const _hoisted_14 = { class: "col-6 column-value" };
const _hoisted_15 = { class: "row" };
const _hoisted_16 = { class: "col-6 column-header" };
const _hoisted_17 = { class: "col-6 column-value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("reactor-loading")), 1),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getReactorLoadingValue(_ctx.model?.reactorLoading)), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("filling-rule")), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getValue(_ctx.model?.fillingRule, "FillingRule")), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("maximum-filling-grade-wo-baffles")), 1),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getPercentageValue(_ctx.model?.maxFillingGrade)), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("minimum-filling-grade-wo-baffles")), 1),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.getPercentageValue(_ctx.model?.minFillingGrade)), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate("maximum-technical-grade")), 1),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.getPercentageValue(_ctx.model?.maxTechnicalFillingGrade)), 1)
                ]),
                _createVNode(_component_LocalizedField, {
                    modelValue: _ctx.model?.additionalRequirements
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("additional-filling-requirements")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"])
            ], 64))
    ]));
}
