import { Subject, takeUntil } from "rxjs";
import { onMounted, onUnmounted } from "vue";
import { useInstance } from "@/app/shared/composables/use-instance.composable";
import _ from "lodash";
export const observe = (observable, method) => {
    const unsubscribe = new Subject();
    onMounted(() => {
        const instance = useInstance();
        observable.pipe(takeUntil(unsubscribe)).subscribe((value) => {
            _.get(instance, `ctx.${method}`)(value);
        });
    });
    onUnmounted(() => {
        unsubscribe.next();
    });
};
