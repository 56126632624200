import { ref, onMounted, onBeforeUnmount, watch, nextTick, computed } from "vue";
export function useExpandableColumn(myRef) {
    const scrollHeight = ref(0);
    const clientHeight = ref(0);
    const isExpanded = ref(false);
    onMounted(() => {
        updateHeights();
        window.addEventListener("click", updateHeights);
    });
    onBeforeUnmount(() => {
        window.removeEventListener("click", updateHeights);
    });
    async function updateHeights() {
        await nextTick();
        scrollHeight.value = myRef.value?.scrollHeight;
        clientHeight.value = myRef.value?.clientHeight;
    }
    function toggleExpansion() {
        isExpanded.value = !isExpanded.value;
    }
    const isOverflowed = computed(() => {
        return scrollHeight.value > clientHeight.value;
    });
    const fitsTheContainer = computed(() => {
        return scrollHeight.value === clientHeight.value;
    });
    watch(myRef, () => {
        updateHeights();
    }, { deep: true });
    watch(fitsTheContainer, () => {
        isExpanded.value = false;
    });
    return {
        isOverflowed,
        fitsTheContainer,
        updateHeights,
        isExpanded,
        toggleExpansion,
    };
}
