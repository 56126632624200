import { computed, toValue } from "vue";
import { ProductType } from "@/app/domains/products/entities/product.entity";
const icons = {
    [ProductType.Bulk]: "science",
    [ProductType.Packed]: "inventory",
};
export const useProductIcon = (product) => {
    return computed(() => {
        const rawProduct = toValue(product);
        return rawProduct ? icons[rawProduct.productType] : ProductType.Bulk;
    });
};
