var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
let CopyPreloadingRestrictionsService = class CopyPreloadingRestrictionsService {
    httpService;
    constructor(httpService) {
        this.httpService = httpService;
    }
    async copyData(productId, companyFromId, productFromId) {
        return await this.httpService.post(`products/${productId}/preLoadingRestriction/copyFromProduct`, {
            companyFromId,
            productFromId,
        });
    }
};
CopyPreloadingRestrictionsService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object])
], CopyPreloadingRestrictionsService);
export { CopyPreloadingRestrictionsService };
