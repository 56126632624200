import { provide, reactive } from "vue";
import { FormContextToken } from "@/app/shared/tokens/form-context.token";
import { FormService } from "@/app/form/services/form.service";
import { reactiveInstanceSetup } from "@/di/setups/reactive-instance-setup";
import { reactivePropertiesSetup } from "@/di/setups/reactive-properties-setup";
import { returnWithSetup } from "@/di/setups/return-with-setup";
import { resolve } from "@/di/composables/resolve";
import { ValidationService } from "@/app/validation/services/validation.service";
const createFormContextService = () => {
    const validationService = resolve(ValidationService);
    let instance = new FormService(validationService);
    instance = reactiveInstanceSetup(instance);
    instance = reactivePropertiesSetup(instance);
    instance = returnWithSetup(instance);
    return reactive(instance);
};
export const useFormSetup = () => {
    const context = createFormContextService();
    provide(FormContextToken, context);
    return context;
};
