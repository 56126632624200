import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-36fd63f5"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "other-resources" };
const _hoisted_2 = { class: "resources-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ResourceCard = _resolveComponent("ResourceCard");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.translate("title")), 1),
        _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resources, (item) => {
                return (_openBlock(), _createBlock(_component_ResourceCard, {
                    iconName: item.iconName,
                    title: _ctx.translate(`${item.variable}.title`),
                    description: _ctx.translate(`${item.variable}.description`),
                    button: _ctx.translate(`${item.variable}.button`),
                    link: _ctx.translate(`${item.variable}.link`),
                    imageUrl: item.image,
                    key: item.variable
                }, null, 8, ["iconName", "title", "description", "button", "link", "imageUrl"]));
            }), 128))
        ])
    ]));
}
