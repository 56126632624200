import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2c5541a0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "no-permissions" };
const _hoisted_2 = { class: "mb-1" };
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.translate("error.forbidden.header")), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.translate("error.forbidden.message")), 1),
        _createElementVNode("p", {
            innerHTML: _ctx.translate('error.forbidden.contact-message')
        }, null, 8, _hoisted_3)
    ]));
}
