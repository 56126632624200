import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9353e6dc"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "modal" };
const _hoisted_2 = {
    key: 0,
    class: "header-container"
};
const _hoisted_3 = { class: "header" };
const _hoisted_4 = { class: "header-text" };
const _hoisted_5 = {
    key: 0,
    class: "subheader"
};
const _hoisted_6 = { class: "content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DataLocalization = _resolveComponent("DataLocalization");
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showHeader !== false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.headerText), 1),
                    _createVNode(_component_DataLocalization, { visible: _ctx.showLocalized }, null, 8, ["visible"]),
                    (_ctx.modalService.getClosable() ?? _ctx.showCloseIcon)
                        ? (_openBlock(), _createBlock(_component_Icon, {
                            key: 0,
                            class: "close-modal",
                            name: "close",
                            onClick: _ctx.close
                        }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                ]),
                (_ctx.subheader)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.subheader), 1))
                    : _createCommentVNode("", true)
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ]));
}
