import { resolve, resolveAll } from "@/di/composables/resolve";
import { ContextMenuService } from "@/app/core/services/context-menu.service";
import { onMounted, onUnmounted } from "vue";
import { contextMenuTokenFactory } from "@/app/shared/factories/context-menu-token.factory";
import { contextMenuItemTokenFactory } from "@/app/shared/factories/context-menu-item-token.factory";
export const useContextMenu = (contextMenuId) => {
    const options = resolve(contextMenuTokenFactory(contextMenuId));
    const menuItems = resolveAll(contextMenuItemTokenFactory(contextMenuId));
    const contextMenuService = resolve(ContextMenuService);
    onMounted(() => {
        contextMenuService.setMenuItems(menuItems);
        contextMenuService.setListKey(options.listKey);
        contextMenuService.setBackLink(options.backLink);
        contextMenuService.setHeader(options.header);
    });
    onUnmounted(() => {
        contextMenuService.hide();
    });
    return {
        isVisible: () => contextMenuService.isVisible(),
    };
};
