import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import EditSiteIncidentManagementComponent from "@/app/sub-modules/site-incident-management/components/edit-site-incident-management.component.vue";
import DisplaySiteIncidentManagementComponent from "@/app/sub-modules/site-incident-management/components/display-site-incident-management.component.vue";
import { SafetyAndSecurityModuleId } from "@/app/modules/safety-and-security/safety-and-security.module";
export const SiteIncidentManagementSubModule = (domainId) => {
    const id = "siteIncidentManagement";
    const moduleId = SafetyAndSecurityModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditSiteIncidentManagementComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplaySiteIncidentManagementComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "fire_extinguisher",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.site-incident-management",
            },
        ],
    });
};
