import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AutoComplete = _resolveComponent("AutoComplete");
    return (_openBlock(), _createBlock(_component_AutoComplete, {
        suggestions: _ctx.suggestedItems,
        onComplete: _cache[0] || (_cache[0] = (event) => _ctx.filterSuggestions(event)),
        optionLabel: "label",
        optionGroupLabel: "label",
        optionGroupChildren: "items",
        scrollHeight: "300px",
        panelClass: "autocomplete"
    }, {
        item: _withCtx(({ item }) => [
            _createElementVNode("div", null, _toDisplayString(item), 1)
        ]),
        _: 1
    }, 8, ["suggestions"]));
}
