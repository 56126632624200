import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-24aef394"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "no-permission" };
const _hoisted_2 = { class: "mb-3" };
const _hoisted_3 = { class: "mb-2" };
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InlineSvg = _resolveComponent("InlineSvg");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.translate("title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.translate("body")), 1),
        _createElementVNode("span", {
            class: "contact",
            innerHTML: _ctx.translate('contact')
        }, null, 8, _hoisted_4),
        _createVNode(_component_InlineSvg, {
            class: "no-permission-img",
            href: "/assets/images/no-permission/no_permission.svg"
        })
    ]));
}
