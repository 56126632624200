import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d0af49bc"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-access-restrictions" };
const _hoisted_2 = {
    key: 0,
    class: "restrictions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AccessRestriction = _resolveComponent("AccessRestriction");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.isEmpty)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_AccessRestriction, {
                    label: _ctx.translate('form-fields.height.label'),
                    value: _ctx.accessRestriction?.height,
                    unit: "m",
                    image: "height"
                }, null, 8, ["label", "value"]),
                _createVNode(_component_AccessRestriction, {
                    label: _ctx.translate('form-fields.length.label'),
                    value: _ctx.accessRestriction?.length,
                    unit: "m",
                    image: "length"
                }, null, 8, ["label", "value"]),
                _createVNode(_component_AccessRestriction, {
                    label: _ctx.translate('form-fields.width.label'),
                    value: _ctx.accessRestriction?.width,
                    unit: "m",
                    image: "width"
                }, null, 8, ["label", "value"]),
                _createVNode(_component_AccessRestriction, {
                    label: _ctx.translate('form-fields.weight.label'),
                    value: _ctx.accessRestriction?.weight,
                    unit: "t",
                    image: "weight"
                }, null, 8, ["label", "value"])
            ]))
            : _createCommentVNode("", true)
    ]));
}
