import { computed, watchEffect } from "vue";
import { useEntityDetailsStore } from "@/app/modular-content/stores/entity-details.state";
import { LoadingPointAssignedProductMastersDomainId, UnloadingPointAssignedProductMastersDomainId, } from "@/app/domains/assigned-product-masters/assigned-product-masters.constants";
import { LoadingPointAssignedProductsDomainId, UnloadingPointAssignedProductsDomainId, } from "@/app/domains/assigned-products/assigned-products.constants";
export function useEntityProductMaster(domainService) {
    const entity = computed(() => domainService.getCurrent());
    const entityProductMasterId = computed(() => entity.value?.productMasterId);
    const entityHasProductMaster = computed(() => Boolean(entityProductMasterId.value));
    const entityDetailsStore = useEntityDetailsStore();
    watchEffect(updateOrigin);
    function updateOrigin() {
        if (entityHasProductMaster.value) {
            entityDetailsStore.originDomain = getAssignedProductMastersDomain(domainService.getDomainId());
            entityDetailsStore.originId = entityProductMasterId.value;
        }
    }
    return { entityHasProductMaster };
}
function getAssignedProductMastersDomain(domainId) {
    switch (domainId) {
        case LoadingPointAssignedProductsDomainId:
            return LoadingPointAssignedProductMastersDomainId;
        case UnloadingPointAssignedProductsDomainId:
            return UnloadingPointAssignedProductMastersDomainId;
    }
}
