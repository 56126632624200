import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7c1d8c89"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "gates-input" };
const _hoisted_2 = { class: "header col-2" };
const _hoisted_3 = { class: "header col-3" };
const _hoisted_4 = { class: "header col-6" };
const _hoisted_5 = { class: "column col-2" };
const _hoisted_6 = { class: "name bold" };
const _hoisted_7 = { class: "column col-3" };
const _hoisted_8 = { class: "column col-lane col-6" };
const _hoisted_9 = { class: "number bold" };
const _hoisted_10 = { class: "purpose" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EditableTemporaryListInput = _resolveComponent("EditableTemporaryListInput");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_EditableTemporaryListInput, {
            modelValue: _ctx.model,
            "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                _cache[1] || (_cache[1] = ($event) => (_ctx.model = $event))
            ],
            addBtn: _ctx.translate('gate-widget.buttons.add-gate'),
            emptyMsg: _ctx.translate('empty'),
            name: _ctx.translate('gate'),
            id: _ctx.id
        }, {
            header: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("gate-widget.columns.name")), 1),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("gate-widget.columns.geo")), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("gate-widget.columns.lane")), 1)
            ]),
            row: _withCtx((gate) => [
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getText(gate?.name)), 1)
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getText(gate?.location?.latitude)) + ", " + _toDisplayString(_ctx.getText(gate?.location?.longitude)), 1),
                _createElementVNode("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drivingLanesLocalized(gate?.drivingLanes), (lane) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: lane.laneNumber,
                            class: "lane"
                        }, [
                            _createElementVNode("div", _hoisted_9, _toDisplayString(lane.laneNumber) + ":", 1),
                            _createTextVNode("   "),
                            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getText(lane.lanePurpose)), 1)
                        ]));
                    }), 128))
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "addBtn", "emptyMsg", "name", "id"])
    ]));
}
