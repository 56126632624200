import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import CryptoJS from "crypto-js";
import _ from "lodash";
let RequestBatchingInterceptorService = class RequestBatchingInterceptorService {
    requests = new Map();
    interceptRequest(request, next) {
        const hash = createHash(request);
        const response = this.requests.get(hash) ?? next(request);
        this.requests.set(hash, response);
        return response;
    }
    onResponseError(request, error) {
        const hash = createHash(request);
        this.requests.delete(hash);
        return error;
    }
    async interceptResponse(config, response) {
        const hash = createHash(config);
        this.requests.delete(hash);
        return response;
    }
};
RequestBatchingInterceptorService = __decorate([
    service()
], RequestBatchingInterceptorService);
export { RequestBatchingInterceptorService };
const createHash = (request) => {
    const data = JSON.stringify(request.data);
    const headers = JSON.stringify(request.headers);
    const method = request.method;
    const url = request.url;
    const params = JSON.stringify(request.params);
    return CryptoJS.MD5(_.compact([method, url, headers, data, params]).join("")).toString();
};
