import { createModule } from "@/di/module";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { SiteDomainId } from "@/app/domains/sites/sites.constants";
import { BuildingDomainId } from "@/app/domains/buildings/buildings.constants";
import BuildingsContextMenuHeaderComponent from "@/app/domains/buildings/components/buildings-context-menu-header.component.vue";
import CreateBuildingFormComponent from "@/app/domains/buildings/components/create-building-form.component.vue";
import BuildingsListComponent from "@/app/domains/buildings/components/buildings-list.component.vue";
import { GeneralInformationModule } from "@/app/modules/general-information/general-information.module";
import { PostalAddressSubModule } from "@/app/sub-modules/postal-address/postal-address.module";
import { AccessRestrictionsSubModule } from "@/app/sub-modules/access-restrictions/access-restrictions.module";
import { RegistrationSubModule } from "@/app/sub-modules/registration/registration.module";
import { OpeningHoursSubModule } from "@/app/sub-modules/opening-hours/opening-hours.module";
import { ContactDataSubModule } from "@/app/sub-modules/contact-data/contact-data.module";
import { ProcessesSubModule } from "@/app/sub-modules/processes/processes.module";
import { BuildingBasicInformationSubModule } from "@/app/sub-modules/basic-information/building/building-basic-information.module";
import { SelectedSiteFilterService } from "@/app/domains/sites/services/selected-site-filter.service";
export const BuildingsModule = createModule({
    components: [BuildingsContextMenuHeaderComponent, CreateBuildingFormComponent, BuildingsListComponent],
    imports: [
        DomainModule({
            parent: SiteDomainId,
            id: BuildingDomainId,
            icon: "home_work",
            detailsIcon: "home_work",
            lists: [
                {
                    id: "all",
                    default: true,
                    filter: SelectedSiteFilterService,
                },
            ],
            domainTranslationPrefix: "building",
            forBusinessPartner: true,
            baseUrl: "buildings",
            connectingDisabled: true,
            components: {
                contextMenuHeader: BuildingsContextMenuHeaderComponent,
                createForm: CreateBuildingFormComponent,
                table: BuildingsListComponent,
            },
        }),
        GeneralInformationModule(BuildingDomainId),
        BuildingBasicInformationSubModule(BuildingDomainId),
        PostalAddressSubModule(BuildingDomainId),
        AccessRestrictionsSubModule(BuildingDomainId),
        RegistrationSubModule(BuildingDomainId),
        OpeningHoursSubModule(BuildingDomainId),
        ContactDataSubModule(BuildingDomainId),
        ProcessesSubModule(BuildingDomainId),
    ],
    providers: [SelectedSiteFilterService, { token: TranslationPrefixToken, useValue: "domains.buildings" }],
});
