import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-635bf94a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "mb-4"
};
const _hoisted_2 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_SearchDropdown = _resolveComponent("SearchDropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_DropdownOptionsReload = _resolveComponent("DropdownOptionsReload");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_Calendar = _resolveComponent("Calendar");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        showCloseIcon: true,
        "modal-id": _ctx.modalId,
        header: _ctx.translate('modular-content.components.entity-create-modal.lanes-header')
    }, {
        default: _withCtx(() => [
            (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                    key: 0,
                    center: ""
                }))
                : (_openBlock(), _createBlock(_component_AppForm, {
                    key: 1,
                    class: "create-lane-modal",
                    initial: _ctx.initial,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.model) = $event)),
                    onIsValid: _cache[5] || (_cache[5] = ($event) => (_ctx.isValid = $event)),
                    errors: _ctx.errors,
                    errorsToMap: { validRange: ['validFrom', 'validTo'] },
                    onContext: _cache[6] || (_cache[6] = ($event) => (_ctx.formService = $event))
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_FormField, { class: "end-of-section-element" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("lane-form.company-from.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                (_ctx.selectedProductData.loadingPointId)
                                    ? (_openBlock(), _createBlock(_component_InputText, {
                                        key: 0,
                                        disabled: "",
                                        placeholder: _ctx.prefilledData.companyFromName
                                    }, null, 8, ["placeholder"]))
                                    : (_openBlock(), _createBlock(_component_SearchDropdown, {
                                        key: 1,
                                        name: "companyFromId",
                                        optionValue: "id",
                                        optionLabel: "name",
                                        additionalLabel: "referenceNumber",
                                        filterFields: ['referenceNumber'],
                                        placeholder: _ctx.translate('lane-form.company-from.placeholder'),
                                        searchService: _ctx.companiesSearchService,
                                        searchSelect: ['id', 'name', 'referenceNumber'],
                                        searchFields: ['name', 'referenceNumber'],
                                        searchMode: "all",
                                        searchInclude: "OnlyMineOrOnlyBusinessPartners"
                                    }, null, 8, ["placeholder", "searchService"])),
                                _createVNode(_component_FormError, { name: "companyFromId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: _ctx.isCompanyFromSelected }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("lane-form.site-from.label")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["required"]),
                                (_ctx.selectedProductData.loadingPointId)
                                    ? (_openBlock(), _createBlock(_component_InputText, {
                                        key: 0,
                                        disabled: "",
                                        placeholder: _ctx.prefilledData.siteFromName
                                    }, null, 8, ["placeholder"]))
                                    : (_ctx.sitesFromLoad.isError.value)
                                        ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                            key: 1,
                                            onClick: _ctx.sitesFromLoad.loadMethod
                                        }, null, 8, ["onClick"]))
                                        : (_openBlock(), _createBlock(_component_Dropdown, {
                                            key: 2,
                                            required: "",
                                            name: "siteFromId",
                                            options: _ctx.sitesFrom,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            additionalLabel: "referenceNumber",
                                            filterFields: ['referenceNumber'],
                                            placeholder: _ctx.translate('lane-form.site-from.placeholder'),
                                            loading: _ctx.sitesFromLoad.isLoading.value,
                                            disabled: _ctx.sitesFromLoad.isLoading.value || _ctx.isSiteFromDisabled
                                        }, null, 8, ["options", "placeholder", "loading", "disabled"])),
                                _createVNode(_component_FormError, { name: "siteFromId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: _ctx.isCompanyFromSelected }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("lane-form.loading-point.label")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["required"]),
                                (_ctx.selectedProductData.loadingPointId)
                                    ? (_openBlock(), _createBlock(_component_InputText, {
                                        key: 0,
                                        disabled: "",
                                        placeholder: _ctx.prefilledData.loadingPointName
                                    }, null, 8, ["placeholder"]))
                                    : (_ctx.loadingPointsLoad.isError.value)
                                        ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                            key: 1,
                                            onClick: _ctx.loadingPointsLoad.loadMethod
                                        }, null, 8, ["onClick"]))
                                        : (_openBlock(), _createBlock(_component_Dropdown, {
                                            key: 2,
                                            required: "",
                                            name: "loadingPointId",
                                            options: _ctx.loadingPoints,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            additionalLabel: "referenceNumber",
                                            filterFields: ['referenceNumber'],
                                            placeholder: _ctx.translate('lane-form.loading-point.placeholder'),
                                            loading: _ctx.loadingPointsLoad.isLoading.value,
                                            disabled: _ctx.loadingPointsLoad.isLoading.value || _ctx.isLoadingPointDisabled
                                        }, null, 8, ["options", "placeholder", "loading", "disabled"])),
                                _createVNode(_component_FormError, { name: "loadingPointId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("lane-form.product.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                (_ctx.selectedProductData.loadingPointId)
                                    ? (_openBlock(), _createBlock(_component_InputText, {
                                        key: 0,
                                        disabled: "",
                                        placeholder: _ctx.prefilledData.productName
                                    }, null, 8, ["placeholder"]))
                                    : (_ctx.loadedProductsLoad.isError.value)
                                        ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                            key: 1,
                                            onClick: _ctx.loadedProductsLoad.loadMethod
                                        }, null, 8, ["onClick"]))
                                        : (_openBlock(), _createBlock(_component_Dropdown, {
                                            key: 2,
                                            name: "productId",
                                            options: _ctx.loadedProducts,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            additionalLabel: "referenceNumber",
                                            filterFields: ['referenceNumber'],
                                            placeholder: _ctx.translate('lane-form.product.placeholder'),
                                            loading: _ctx.loadedProductsLoad.isLoading.value,
                                            disabled: _ctx.loadedProductsLoad.isLoading.value || _ctx.isLoadedProductDisabled
                                        }, null, 8, ["options", "placeholder", "loading", "disabled"])),
                                _createVNode(_component_FormError, { name: "productId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, { class: "end-of-section-element" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("lane-form.requirement-profile.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                (_ctx.selectedProductData.unloadingPointId)
                                    ? (_openBlock(), _createBlock(_component_InputText, {
                                        key: 0,
                                        disabled: ""
                                    }))
                                    : (_ctx.requirementProfilesLoad.isError.value)
                                        ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                            key: 1,
                                            onClick: _ctx.requirementProfilesLoad.loadMethod
                                        }, null, 8, ["onClick"]))
                                        : (_openBlock(), _createBlock(_component_Dropdown, {
                                            key: 2,
                                            name: "requirementProfile",
                                            modelValue: _ctx.requirementProfile,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.requirementProfile) = $event)),
                                            options: _ctx.requirementProfiles,
                                            optionValue: "id",
                                            optionLabel: "id",
                                            placeholder: _ctx.translate('lane-form.requirement-profile.placeholder'),
                                            loading: _ctx.requirementProfilesLoad.isLoading.value,
                                            disabled: _ctx.requirementProfilesLoad.isLoading.value || _ctx.isRequirementProfilesDisabled,
                                            additionalLabel: "siteToName",
                                            filterFields: ['siteToName']
                                        }, null, 8, ["modelValue", "options", "placeholder", "loading", "disabled"])),
                                _createVNode(_component_FormError, { name: "requirementProfile" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("lane-form.company-to.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                (_ctx.selectedProductData.unloadingPointId)
                                    ? (_openBlock(), _createBlock(_component_InputText, {
                                        key: 0,
                                        disabled: "",
                                        placeholder: _ctx.prefilledData.companyToName
                                    }, null, 8, ["placeholder"]))
                                    : (_openBlock(), _createBlock(_component_SearchDropdown, {
                                        key: 1,
                                        name: "companyToId",
                                        initial: _ctx.initialCompanyTo,
                                        optionValue: "id",
                                        optionLabel: "name",
                                        additionalLabel: "referenceNumber",
                                        filterFields: ['referenceNumber'],
                                        placeholder: _ctx.translate('lane-form.company-to.placeholder'),
                                        disabled: _ctx.isCompanyToSetByRequirementProfile,
                                        searchService: _ctx.companiesSearchService,
                                        searchSelect: ['id', 'name', 'referenceNumber'],
                                        searchFields: ['name', 'referenceNumber'],
                                        searchMode: "all",
                                        searchInclude: "OnlyMineOrOnlyBusinessPartners"
                                    }, null, 8, ["initial", "placeholder", "disabled", "searchService"])),
                                _createVNode(_component_FormError, { name: "companyToId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: _ctx.isCompanyToSelected }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("lane-form.site-to.label")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["required"]),
                                (_ctx.selectedProductData.unloadingPointId)
                                    ? (_openBlock(), _createBlock(_component_InputText, {
                                        key: 0,
                                        disabled: "",
                                        placeholder: _ctx.prefilledData.siteToName
                                    }, null, 8, ["placeholder"]))
                                    : (_ctx.sitesToLoad.isError.value)
                                        ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                            key: 1,
                                            onClick: _ctx.sitesToLoad.loadMethod
                                        }, null, 8, ["onClick"]))
                                        : (_openBlock(), _createBlock(_component_Dropdown, {
                                            key: 2,
                                            required: "",
                                            name: "siteToId",
                                            options: _ctx.sitesTo,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            additionalLabel: "referenceNumber",
                                            filterFields: ['referenceNumber'],
                                            placeholder: _ctx.translate('lane-form.site-to.placeholder'),
                                            loading: _ctx.sitesToLoad.isLoading.value,
                                            disabled: _ctx.sitesToLoad.isLoading.value || _ctx.isSiteToDisabled
                                        }, null, 8, ["options", "placeholder", "loading", "disabled"])),
                                _createVNode(_component_FormError, { name: "siteToId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: _ctx.isCompanyToSelected }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("lane-form.unloading-point.label")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["required"]),
                                (_ctx.selectedProductData.unloadingPointId)
                                    ? (_openBlock(), _createBlock(_component_InputText, {
                                        key: 0,
                                        disabled: "",
                                        placeholder: _ctx.prefilledData.unloadingPointName
                                    }, null, 8, ["placeholder"]))
                                    : (_ctx.unloadingPointsLoad.isError.value)
                                        ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                            key: 1,
                                            onClick: _ctx.unloadingPointsLoad.loadMethod
                                        }, null, 8, ["onClick"]))
                                        : (_openBlock(), _createBlock(_component_Dropdown, {
                                            key: 2,
                                            required: "",
                                            name: "unloadingPointId",
                                            options: _ctx.unloadingPoints,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            additionalLabel: "referenceNumber",
                                            filterFields: ['referenceNumber'],
                                            placeholder: _ctx.translate('lane-form.unloading-point.placeholder'),
                                            loading: _ctx.unloadingPointsLoad.isLoading.value,
                                            disabled: _ctx.unloadingPointsLoad.isLoading.value || _ctx.isUnloadingPointDisabled
                                        }, null, 8, ["options", "placeholder", "loading", "disabled"])),
                                _createVNode(_component_FormError, { name: "unloadingPointId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, { class: "end-of-section-element" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("lane-form.unloaded-product.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                (_ctx.selectedProductData.unloadingPointId)
                                    ? (_openBlock(), _createBlock(_component_InputText, {
                                        key: 0,
                                        disabled: "",
                                        placeholder: _ctx.prefilledData.unloadedProductName
                                    }, null, 8, ["placeholder"]))
                                    : (_ctx.unloadedProductsLoad.isError.value)
                                        ? (_openBlock(), _createBlock(_component_DropdownOptionsReload, {
                                            key: 1,
                                            onClick: _ctx.unloadedProductsLoad.loadMethod
                                        }, null, 8, ["onClick"]))
                                        : (_openBlock(), _createBlock(_component_Dropdown, {
                                            key: 2,
                                            name: "unloadedProductId",
                                            options: _ctx.unloadedProducts,
                                            optionValue: "id",
                                            optionLabel: "name",
                                            additionalLabel: "referenceNumber",
                                            filterFields: ['referenceNumber'],
                                            placeholder: _ctx.translate('lane-form.unloaded-product.placeholder'),
                                            loading: _ctx.unloadedProductsLoad.isLoading.value,
                                            disabled: _ctx.unloadedProductsLoad.isLoading.value || _ctx.isUnloadedProductDisabled
                                        }, null, 8, ["options", "placeholder", "loading", "disabled"])),
                                _createVNode(_component_FormError, { name: "unloadedProductId" })
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("lane-form.unlimited-validation")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_RadioButton, {
                                    modelValue: _ctx.model.unlimitedValidation,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.unlimitedValidation) = $event))
                                }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                        }),
                        (_ctx.isUnlimitedValidation || _ctx.isEmptyValidation)
                            ? (_openBlock(), _createElementBlock("hr", _hoisted_1))
                            : _createCommentVNode("", true),
                        (!_ctx.isUnlimitedValidation && !_ctx.isEmptyValidation)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createVNode(_component_FormField, { class: "end-of-section-element" }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_InputLabel, { required: "" }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("lane-form.valid-from-to")), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_Calendar, {
                                            inputId: "range",
                                            modelValue: _ctx.validRange,
                                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.validRange) = $event)),
                                            name: "validRange",
                                            selectionMode: "range",
                                            dateFormat: "dd.mm.yy",
                                            manualInput: false,
                                            placeholder: _ctx.translate('lane-form.valid-from-to'),
                                            showIcon: true,
                                            required: ""
                                        }, null, 8, ["modelValue", "placeholder"]),
                                        _createVNode(_component_FormError, { name: "validRange" })
                                    ]),
                                    _: 1
                                })
                            ]))
                            : _createCommentVNode("", true),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("assign-partner.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_AppMultiSelect, {
                                    name: "assignedPartners",
                                    options: _ctx.partnerCompanies,
                                    optionValue: "id",
                                    optionLabel: "name",
                                    display: "chip",
                                    placeholder: _ctx.translate('assign-partner.placeholder')
                                }, null, 8, ["options", "placeholder"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("assign-partner.validity.label")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_Calendar, {
                                    inputId: "range",
                                    modelValue: _ctx.assignPartnerValidRange,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.assignPartnerValidRange) = $event)),
                                    name: "assignmentValidFrom",
                                    selectionMode: "range",
                                    dateFormat: "dd.mm.yy",
                                    manualInput: false,
                                    placeholder: _ctx.translate('assign-partner.validity.placeholder'),
                                    showIcon: true,
                                    disabled: _ctx.isAssignmentsEmpty
                                }, null, 8, ["modelValue", "placeholder", "disabled"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["initial", "modelValue", "errors"])),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.modalService.close())),
                        id: "cancel-button"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_AppButton, {
                        loading: _ctx.isCreating,
                        onClick: _ctx.createMethod,
                        disabled: !_ctx.isCreateValid
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.create")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "onClick", "disabled"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
