import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { BaseTranslationService } from "@/app/translation/services/base-translation.service";
let DictionaryTranslationService = class DictionaryTranslationService extends BaseTranslationService {
    getTranslationsPath(language) {
        return `/translations/LOADYBACKEND.${language.toUpperCase()}.json`;
    }
};
DictionaryTranslationService = __decorate([
    service()
], DictionaryTranslationService);
export { DictionaryTranslationService };
