var _a;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { inject } from "@/di/decorators/inject.decorator";
import { InjectionContainerToken } from "@/di/tokens";
import { InjectionContainer } from "@/di/container";
let InjectorService = class InjectorService {
    container;
    constructor(container) {
        this.container = container;
    }
    resolve(token) {
        return this.container.resolve(token);
    }
    resolveAll(token) {
        return this.container.resolveAll(token);
    }
};
InjectorService = __decorate([
    service(),
    __param(0, inject(InjectionContainerToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof InjectionContainer !== "undefined" && InjectionContainer) === "function" ? _a : Object])
], InjectorService);
export { InjectorService };
