import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d04e73f8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-available-equipment-packed" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row section" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 column-description" };
const _hoisted_6 = { class: "row section" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_9 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_10 = { class: "row section" };
const _hoisted_11 = { class: "col-6 column-header" };
const _hoisted_12 = { class: "col-6 column-description" };
const _hoisted_13 = { class: "row section" };
const _hoisted_14 = { class: "col-6 column-header" };
const _hoisted_15 = { class: "col-6 column-description" };
const _hoisted_16 = { class: "row section" };
const _hoisted_17 = { class: "col-6 column-header" };
const _hoisted_18 = { class: "col-6 column-description" };
const _hoisted_19 = { class: "row section" };
const _hoisted_20 = { class: "col-6 column-header" };
const _hoisted_21 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("loading-ramp")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.loadingRamp, "available", "not-available")), 1)
            ]),
            (_ctx.model.loadingRamp)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("loading-ramp-type")), 1),
                        (_ctx.model?.loadingRampType?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.loadingRampType, (tag, index) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: tag + index,
                                        class: "tag"
                                    }, _toDisplayString(_ctx.getValue(tag, "LoadingRampType")), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate("loading-ramp-height")), 1),
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.loadingRampHeight, "measurement.meter")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translate("loading-ramp-secured-by-railing")), 1),
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.loadingRampSecuredByRailing, "yes", "no")), 1)
                    ])
                ], 64))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translate("forklift")), 1),
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.forkLift, "available", "not-available")), 1)
            ]),
            _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.translate("lifting-platform")), 1),
                _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.liftingPlatform, "available", "not-available")), 1)
            ])
        ])
    ]));
}
