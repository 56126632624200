import { AuthModule } from "@/app/auth/auth.module";
import { CoreModule } from "@/app/core/core.module";
import { createModule } from "@/di/module";
import { DashboardModule } from "@/app/dashboard/dashboard.module";
import Router from "@/router";
import { SharedModule } from "@/app/shared/shared.module";
import { ProductsModule } from "@/app/domains/products/products.module";
import { RequirementProfileModule } from "@/app/domains/requirement-profiles/requirement-profile.module";
import { RouterToken } from "@/app/shared/tokens/router.token";
import { SitesModule } from "@/app/domains/sites/sites.module";
import { UsersModule } from "@/app/users/users.module";
import { DictionariesModule } from "@/app/dictionaries/dictionaries.module";
import { ModularContentModule } from "@/app/modular-content/modular-content.module";
import { RoutesModule } from "@/app/shared/modules/routes.module";
import { AppRoutes } from "@/app/app.routes";
import AppComponent from "@/app/app.component.vue";
import { FormModule } from "@/app/form/form.module";
import { ModalsModule } from "@/app/modals/modals.module";
import { HttpModule } from "@/app/http/http.module";
import { ValidationModule } from "@/app/validation/validation.module";
import { TranslationModule } from "@/app/translation/translation.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { BusinessPartnersModule } from "@/app/domains/business-partners/busines-partners.module";
import { CompanyOverviewModule } from "@/app/domains/public-data/companies/company-overview/company-overview.module";
import { PublicDataModule } from "@/app/domains/public-data/public-data.module";
import { LanesModule } from "@/app/domains/lanes/lanes.module";
import { AssignedLanesModule } from "@/app/domains/assigned-lanes/assigned-lanes.module";
import { VerificationRequestsModule } from "@/app/domains/verification-requests/verification-requests.module";
import { TendersModule } from "@/app/domains/tenders/tenders.module";
import { TendersLanesModule } from "@/app/domains/tenders-lanes/tenders-lanes.module";
import { TendersCarriersModule } from "@/app/domains/tenders-carriers/tenders-carriers.module";
export const AppModule = createModule({
    components: [AppComponent],
    imports: [
        HttpModule,
        AuthModule,
        SharedModule,
        CoreModule,
        DashboardModule,
        CompanyOverviewModule,
        PublicDataModule,
        SitesModule,
        UsersModule,
        RequirementProfileModule,
        LanesModule,
        TendersCarriersModule,
        AssignedLanesModule,
        VerificationRequestsModule,
        ProductsModule,
        BusinessPartnersModule,
        TendersModule,
        TendersLanesModule,
        DictionariesModule,
        ModularContentModule,
        FormModule,
        ModalsModule,
        ValidationModule,
        TranslationModule,
        RoutesModule({
            routes: AppRoutes,
        }),
    ],
    providers: [
        {
            token: RouterToken,
            useValue: Router,
        },
        {
            token: TranslationPrefixToken,
            useValue: undefined,
        },
    ],
    root: true,
});
