import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-052a57a4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "title" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-8" };
const _hoisted_4 = { class: "col-4" };
const _hoisted_5 = { class: "title" };
const _hoisted_6 = { class: "title" };
const _hoisted_7 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors,
            class: "edit-product-product-parameters"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.translate("density")), 1),
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_InputNumber, {
                                    name: "density",
                                    min: 0,
                                    maxIntegerDigits: "10",
                                    mode: "decimal",
                                    maxFractionDigits: 4,
                                    placeholder: _ctx.translate('density'),
                                    class: "input"
                                }, null, 8, ["placeholder"])
                            ]),
                            _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_Dropdown, {
                                    name: "densityUnit",
                                    options: _ctx.dictionaries.densityUnit,
                                    optionValue: "id",
                                    optionLabel: "name",
                                    dictionaryId: "DensityUnit",
                                    display: "chip",
                                    placeholder: _ctx.translate('density-unit')
                                }, null, 8, ["options", "placeholder"])
                            ])
                        ]),
                        _createVNode(_component_FormError, { name: "density" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.translate("freezing-point")), 1),
                        _createVNode(_component_RadioButton, {
                            modelValue: _ctx.model.freezingPointUnit,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.freezingPointUnit) = $event)),
                            dictionary: _ctx.dictionaries.temperatureUnit,
                            dictionaryId: "TemperatureUnit",
                            isOptionDeselectable: false
                        }, null, 8, ["modelValue", "dictionary"]),
                        _createVNode(_component_InputNumber, {
                            name: "freezingPoint",
                            mode: "decimal",
                            maxIntegerDigits: "10",
                            maxFractionDigits: 2,
                            placeholder: _ctx.translate('freezing-point'),
                            class: "input"
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_FormError, { name: "freezingPoint" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.translate("flash-point")), 1),
                        _createVNode(_component_RadioButton, {
                            modelValue: _ctx.model.flashPointUnit,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.flashPointUnit) = $event)),
                            dictionary: _ctx.dictionaries.temperatureUnit,
                            dictionaryId: "TemperatureUnit",
                            isOptionDeselectable: false
                        }, null, 8, ["modelValue", "dictionary"]),
                        _createVNode(_component_InputNumber, {
                            name: "flashPoint",
                            mode: "decimal",
                            maxIntegerDigits: "10",
                            maxFractionDigits: 2,
                            placeholder: _ctx.translate('flash-point'),
                            class: "input"
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_FormError, { name: "flashPoint" })
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.translate("water-solubility")), 1),
                        _createVNode(_component_InputNumber, {
                            name: "waterSolubility",
                            min: 0,
                            maxIntegerDigits: "10",
                            mode: "decimal",
                            maxFractionDigits: 5,
                            placeholder: _ctx.translate('water-solubility'),
                            class: "input"
                        }, null, 8, ["placeholder"]),
                        _createVNode(_component_FormError, { name: "waterSolubility" })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
