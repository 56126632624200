var SearchService_1;
var _a, _b;
import { __decorate, __metadata } from "tslib";
import _ from "lodash";
import { reactive } from "@/di/decorators/computed.decorator";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { SearchUrlFactoryService } from "@/app/modular-content/services/search-url-factory.service";
import { Equal } from "@/app/modular-content/token/filter-operator.constants";
let SearchService = SearchService_1 = class SearchService {
    searchUrlFactoryService;
    httpService;
    data = { results: [] };
    lastDto = {};
    lastHeaders;
    constructor(searchUrlFactoryService, httpService) {
        this.searchUrlFactoryService = searchUrlFactoryService;
        this.httpService = httpService;
    }
    resetData() {
        this.data = { results: [] };
    }
    getData() {
        return this.data;
    }
    setData(newData) {
        this.data.results = newData;
    }
    addItem(item) {
        this.data.results?.unshift(item);
        if (this.data.count) {
            this.data.count += 1;
        }
    }
    editItem(item) {
        if (!this.data.results) {
            return;
        }
        const foundIndex = this.data.results.findIndex((it) => it.id == item.id);
        if (foundIndex === -1) {
            return;
        }
        const current = this.data.results[foundIndex];
        this.data.results[foundIndex] = { ...current, ...item };
    }
    deleteItem(item) {
        const newData = this.data.results?.filter((it) => it.id !== item.id) ?? [];
        this.setData(newData);
        if (this.data.count) {
            this.data.count -= 1;
        }
    }
    async reload() {
        this.data = await this.fetchPage(this.lastDto, undefined, this.lastHeaders);
    }
    async loadPage(dto, filters, headers) {
        this.data = await this.fetchPage(dto, filters, headers);
    }
    async fetchPage(dto, filters = [], headers) {
        const filterValue = this.getFilterValue(filters);
        if (!_.isEmpty(filterValue)) {
            dto.filter = filterValue;
        }
        this.lastDto = dto;
        this.lastHeaders = headers;
        return this.httpService.post(this.getUrl(), dto, { headers: headers });
    }
    getFilterValue(filters) {
        const filterStrings = [];
        const multipleValuesFilterStrings = [];
        const multipleValueFilters = [];
        filters.forEach((filter) => {
            const { propertyGroupName, propertyName, propertyValue, operatorValue, multipleValue, propertyNames, propertyValues, } = filter;
            if (multipleValue) {
                if (propertyGroupName) {
                    multipleValuesFilterStrings.push(SearchService_1.getAnyEqualsFilterValue(filter));
                }
                else if (propertyName && !_.isNil(propertyValue)) {
                    multipleValueFilters.push({
                        filterString: SearchService_1.getOperatorFilterValue(filter, operatorValue ?? Equal),
                        propertyName: propertyName,
                    });
                }
            }
            else {
                if (propertyGroupName) {
                    filterStrings.push(SearchService_1.getAnyEqualsFilterValue(filter));
                }
                else if (propertyName && !_.isNil(propertyValue)) {
                    filterStrings.push(SearchService_1.getOperatorFilterValue(filter, operatorValue ?? Equal));
                }
                else if (propertyNames && propertyValues) {
                    filterStrings.push(SearchService_1.getConditionalEqualFilterValue(filter));
                }
            }
        });
        const differentKeys = _.uniq(multipleValueFilters.map((element) => element.propertyName));
        differentKeys.forEach((element) => {
            const newFilter = multipleValueFilters.filter((it) => {
                return it.propertyName === element;
            });
            if (newFilter.length > 1) {
                multipleValuesFilterStrings.push(`(${newFilter.map((element) => element.filterString).join(" or ")})`);
            }
            else {
                filterStrings.push(newFilter[0].filterString);
            }
        });
        return multipleValuesFilterStrings.length
            ? _.compact(_.concat(_.flatten([filterStrings]), [`(${multipleValuesFilterStrings.join(" and ")})`])).join(" and ")
            : filterStrings.join(" and ");
    }
    static getOperatorFilterValue(filter, operator) {
        const { propertyName, propertyValue, operatorValue } = filter;
        const filterOperator = operator ?? operatorValue;
        const filterPropertyValue = typeof propertyValue === "boolean" ? `${propertyValue}` : `'${propertyValue}'`;
        return `${propertyName} ${filterOperator} ${filterPropertyValue}`;
    }
    static getAnyEqualsFilterValue(filter) {
        const { propertyGroupName, propertyValues, propertyValue, propertyName } = filter;
        if (propertyValues?.length) {
            const conditions = propertyValues.map((value) => `${propertyName ? `t/${propertyName}` : "t"} ${Equal} '${value}'`);
            return `${propertyGroupName}/any(t: ${conditions?.join(" or ")})`;
        }
        return `${propertyGroupName}(${propertyName}: ${propertyName} ${Equal} '${propertyValue}')`;
    }
    static getConditionalEqualFilterValue(filter) {
        const { propertyNames, propertyValues } = filter;
        const conditions = _.zipWith(propertyNames, propertyValues, (name, value) => {
            return `${name} eq '${value}'`;
        });
        return `(${conditions.join(" or ")})`;
    }
    getUrl() {
        return this.searchUrlFactoryService.createUrl();
    }
};
__decorate([
    reactive(),
    __metadata("design:type", Object)
], SearchService.prototype, "data", void 0);
__decorate([
    reactive(),
    __metadata("design:type", Object)
], SearchService.prototype, "lastDto", void 0);
SearchService = SearchService_1 = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof SearchUrlFactoryService !== "undefined" && SearchUrlFactoryService) === "function" ? _a : Object, typeof (_b = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _b : Object])
], SearchService);
export { SearchService };
