import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a2ca09f2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "section display-dg-classification" };
const _hoisted_2 = { class: "row section" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-description" };
const _hoisted_5 = { class: "row section" };
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6 column-description" };
const _hoisted_8 = { class: "row section" };
const _hoisted_9 = { class: "col-6 column-header" };
const _hoisted_10 = { class: "col-6 column-description" };
const _hoisted_11 = { class: "row section" };
const _hoisted_12 = { class: "col-6 column-header" };
const _hoisted_13 = { class: "col-6 column-description" };
const _hoisted_14 = { class: "row section" };
const _hoisted_15 = { class: "col-6 column-header" };
const _hoisted_16 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_17 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_18 = { class: "row section" };
const _hoisted_19 = { class: "col-6 column-header" };
const _hoisted_20 = { class: "col-6 column-description" };
const _hoisted_21 = { class: "row section" };
const _hoisted_22 = { class: "col-6 column-header" };
const _hoisted_23 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("un-number")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.model.unNumber || "-"), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("proper-shipping-name")), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.model.properShippingName || "-"), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("hazard-inducer")), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.model.hazardInducer || "-"), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate("class")), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.getValue(_ctx.model?.classOrDivision, "ClassOrDivision")), 1)
        ]),
        _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.translate("subsidiary-hazard")), 1),
            (_ctx.model?.subsidiaryHazards?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.subsidiaryHazards, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(_ctx.getValue(tag, "SubsidiaryHazard")), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_17, "-"))
        ]),
        _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.translate("packing-group")), 1),
            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.getValue(_ctx.model?.packingGroup, "PackingGroup")), 1)
        ]),
        _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.translate("environmental-hazards")), 1),
            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.getAvailabilityValue(_ctx.model.environmentalHazards)), 1)
        ])
    ]));
}
