import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-23c02129"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-traffic-related-rueles" };
const _hoisted_2 = { class: "title" };
const _hoisted_3 = { class: "checkbox-container" };
const _hoisted_4 = {
    key: 0,
    class: "container attachment-container"
};
const _hoisted_5 = { class: "container" };
const _hoisted_6 = { class: "title" };
const _hoisted_7 = { class: "container" };
const _hoisted_8 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AttachmentsInput = _resolveComponent("AttachmentsInput");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createBlock(_component_AppForm, {
                key: 1,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
                initial: _ctx.initial
            }, {
                default: _withCtx(() => [
                    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate("road-traffic-regulations")), 1),
                    _createElementVNode("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkBoxes, (checkBox) => {
                            return (_openBlock(), _createBlock(_component_FormField, {
                                key: checkBox.id,
                                class: "checkbox-item"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_AppCheckbox, {
                                        modelValue: checkBox.value,
                                        "onUpdate:modelValue": [($event) => ((checkBox.value) = $event), ($event) => (_ctx.updateCheckBox($event, checkBox.id))]
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_InputLabel, { class: "checkbox-label" }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.dictionaryTranslate("RoadTrafficRegulation", checkBox)), 1)
                                                ]),
                                                _: 2
                                            }, 1024)
                                        ]),
                                        _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                            }, 1024));
                        }), 128))
                    ]),
                    (_ctx.isSpecificActive)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_AttachmentsInput, {
                                        attachments: _ctx.model.otherRoadTrafficRegulations,
                                        title: _ctx.translate('other-regulations'),
                                        name: "otherRoadTrafficRegulations"
                                    }, null, 8, ["attachments", "title"])
                                ]),
                                _: 1
                            })
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.translate("dash-cam-restrictions")), 1),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_RadioButton, {
                                    modelValue: _ctx.model.dashCamRestriction,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.dashCamRestriction) = $event)),
                                    dictionary: _ctx.dictionaries.dashCamRestriction,
                                    dictionaryId: "DashCamRestriction"
                                }, null, 8, ["modelValue", "dictionary"])
                            ]),
                            _: 1
                        })
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("h5", _hoisted_8, _toDisplayString(_ctx.translate("speed-monitoring.title")), 1),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_RadioButton, {
                                    modelValue: _ctx.model.speedMonitoring,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.speedMonitoring) = $event)),
                                    truthyLabel: "sub-modules.traffic-rules.speed-monitoring.yes",
                                    falsyLabel: "sub-modules.traffic-rules.speed-monitoring.no"
                                }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, { class: "col-xl-6" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, {
                                    disabled: !_ctx.model.speedMonitoring
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("speed-monitoring.label")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["disabled"]),
                                _createVNode(_component_InputNumber, {
                                    name: "maximumAllowedSpeed",
                                    min: 0,
                                    mode: "integer",
                                    maxIntegerDigits: 5,
                                    placeholder: _ctx.translate('speed-monitoring.label'),
                                    disabled: !_ctx.model.speedMonitoring
                                }, null, 8, ["placeholder", "disabled"]),
                                _createVNode(_component_FormError, { name: "maximumAllowedSpeed" })
                            ]),
                            _: 1
                        })
                    ])
                ]),
                _: 1
            }, 8, ["modelValue", "initial"]))
    ]));
}
