import { resolve } from "@/di/composables/resolve";
import { RolesService } from "@/app/shared/services/roles.service";
import { CompanyRole } from "@/app/shared/types/access.type";
import { CompaniesDomainId, CreateCompanyModalId } from "@/app/domains/public-data/companies/companies.constants";
import { CreateEntityModalIdToken } from "@/app/modular-content/token/modular-content.token";
export const useCreateEntityModalId = (domainId) => {
    const rolesService = resolve(RolesService);
    const role = rolesService.getRoleForActiveCompany();
    let modalIdOverride = undefined;
    if (domainId === CompaniesDomainId && role === CompanyRole.LoadyAdmin) {
        modalIdOverride = CreateCompanyModalId;
    }
    return modalIdOverride ?? resolve(CreateEntityModalIdToken);
};
