import { createModule } from "@/di/module";
import CompanySettingsComponent from "@/app/domains/public-data/companies/company-overview/company-settings/company-settings.component.vue";
import SubmoduleConfigurationComponent from "@/app/domains/public-data/companies/company-overview/company-settings/components/submodule-configuration.component.vue";
import { RoutesModule } from "@/app/shared/modules/routes.module";
import { CompanySettingsRoutes, SubmoduleConfigurationRoutes, WebhooksConfigurationRoutes, } from "@/app/domains/public-data/companies/company-overview/company-settings/company-settings.routes";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { ManageCompanyNavigationItemToken } from "@/app/domains/public-data/companies/company-overview/tokens/manage-company-navigation-item.token";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EntitySubmoduleConfigurationComponent from "@/app/domains/public-data/companies/company-overview/company-settings/components/entity-submodule-configuration.component.vue";
import WebhooksListComponent from "@/app/domains/public-data/companies/company-overview/company-settings/components/webhooks-list.component.vue";
import { EntitySubmoduleConfigurationMenuItems, EntityWebhooksConfigurationMenuItems, CreateWebhookModalId, EditWebhookModalId, } from "@/app/domains/public-data/companies/company-overview/company-settings/company-settings.constants";
import { CompanySettingsService } from "@/app/domains/public-data/companies/company-overview/company-settings/services/company-settings.service";
import { WebhookService } from "@/app/domains/public-data/companies/company-overview/company-settings/services/webhook.service";
import { CompanySettingsChangeDetectionService } from "@/app/domains/public-data/companies/company-overview/company-settings/services/company-settings-change-detection.service";
import { LeaveEditGuardService } from "@/app/modular-content/services/leave-edit-guard.service";
import { Permissions } from "@/app/shared/constants/permissions.constants";
import { ModalModule } from "@/app/modals/modules/modal.module";
import ConfirmDeleteModalComponent from "@/app/core/components/confirm-delete-modal.component.vue";
import { ConfirmDeleteModalId } from "@/app/core/core.constants";
import CreateWebhookModalComponent from "@/app/domains/public-data/companies/company-overview/company-settings/components/create-webhook-modal.component.vue";
import EditWebhookModalComponent from "@/app/domains/public-data/companies/company-overview/company-settings/components/edit-webhook-modal.component.vue";
const navigationItem = {
    label: "settings.menu-item",
    route: "settings",
    icon: "settings",
    accessCondition(rolesService, companyId) {
        return !!rolesService?.hasRole(Permissions.isAdmin, companyId);
    },
    condition(it) {
        return !it?.managedByExternalCompany;
    },
};
export const CompanySettingsModule = createModule({
    components: [
        CompanySettingsComponent,
        SubmoduleConfigurationComponent,
        ...EntitySubmoduleConfigurationMenuItems.map((it) => suffixComponentName(EntitySubmoduleConfigurationComponent, it.id)),
        WebhooksListComponent,
        ...EntityWebhooksConfigurationMenuItems.map((it) => suffixComponentName(WebhooksListComponent, it.listId)),
    ],
    imports: [
        RoutesModule({
            routes: SubmoduleConfigurationRoutes,
        }),
        RoutesModule({
            routes: WebhooksConfigurationRoutes,
        }),
        RoutesModule({
            routes: CompanySettingsRoutes,
            parent: "manage-company-root",
        }),
        ModalModule(ConfirmDeleteModalId, ConfirmDeleteModalComponent),
        ModalModule(CreateWebhookModalId, CreateWebhookModalComponent),
        ModalModule(EditWebhookModalId, EditWebhookModalComponent),
    ],
    providers: [
        CompanySettingsService,
        CompanySettingsChangeDetectionService,
        LeaveEditGuardService,
        WebhookService,
        { token: ManageCompanyNavigationItemToken, useValue: navigationItem },
        { token: TranslationPrefixToken, useValue: "domains.companies.manage-company.settings" },
    ],
});
