import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4d6ef5f4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "section display-product-parameters" };
const _hoisted_2 = { class: "row section" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-description" };
const _hoisted_5 = { class: "row section" };
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6 column-description" };
const _hoisted_8 = { class: "row section" };
const _hoisted_9 = { class: "col-6 column-header" };
const _hoisted_10 = { class: "col-6 column-description" };
const _hoisted_11 = { class: "row section" };
const _hoisted_12 = { class: "col-6 column-header" };
const _hoisted_13 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("density")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.valueWithUnit(_ctx.model?.density, _ctx.model?.densityUnit, "DensityUnit")), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("freezing-point")), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.valueWithUnit(_ctx.model?.freezingPoint, _ctx.model?.freezingPointUnit, "TemperatureUnit")), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("flash-point")), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.valueWithUnit(_ctx.model?.flashPoint, _ctx.model?.flashPointUnit, "TemperatureUnit")), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate("water-solubility-title")), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.waterSolubility), 1)
        ])
    ]));
}
