var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
let PlatformLinkService = class PlatformLinkService {
    httpService;
    constructor(httpService) {
        this.httpService = httpService;
    }
    async generateLink(laneId, validity, platform) {
        return await this.httpService.post(`lanes/create${platform}Link`, {
            laneId,
            validity,
        });
    }
    async triggerLanesWebhook(laneIds) {
        await this.httpService.post("lanes/sendLaneOnDemand", {
            laneIds,
        });
    }
};
PlatformLinkService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object])
], PlatformLinkService);
export { PlatformLinkService };
