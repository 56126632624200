import { createModule } from "@/di/module";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import EditProductsBasicInformationComponent from "@/app/sub-modules/basic-information/product/components/edit-product-basic-information.component.vue";
import DisplayProductsBasicInformationComponent from "@/app/sub-modules/basic-information/product/components/display-product-basic-information.component.vue";
import DisplayProductsBasicInformationViewComponent from "@/app/sub-modules/basic-information/product/components/display-product-basic-information-view.component.vue";
export const ProductBasicInformationSubModule = (domainId) => {
    const id = "basicInformation";
    const moduleId = GeneralInformationModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditProductsBasicInformationComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayProductsBasicInformationComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayProductsBasicInformationViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "info",
                hideDisplayTitle: true,
                editTitle: "sub-modules.basic-information.product.name",
                configurationTranslationPrefix: "sub-modules.basic-information.product",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.basic-information.product" }],
    });
};
