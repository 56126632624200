var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { CompanyService } from "@/app/domains/public-data/companies/services/company.service";
let AssignedLaneFilterService = class AssignedLaneFilterService {
    companyService;
    constructor(companyService) {
        this.companyService = companyService;
    }
    createAnyEqualsFilter() {
        const selectedCompanyId = this.companyService.getSelectedCompany()?.id;
        return [
            {
                propertyGroupName: "AssignmentIds",
                propertyValues: [selectedCompanyId],
            },
        ];
    }
};
AssignedLaneFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof CompanyService !== "undefined" && CompanyService) === "function" ? _a : Object])
], AssignedLaneFilterService);
export { AssignedLaneFilterService };
