import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { InstructionsModuleId } from "@/app/modules/instructions/instructions.module";
import Display from "@/app/sub-modules/filling/components/display-filling.vue";
import DisplayView from "@/app/sub-modules/filling/components/display-filling-view.component.vue";
import Edit from "@/app/sub-modules/filling/components/edit-filling.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
const moduleId = InstructionsModuleId;
const id = "filling";
export const FillingSubModule = (domainId) => {
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(Display, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayView, ...componentSuffixes);
    const editComponent = suffixComponentName(Edit, ...componentSuffixes);
    return createModule({
        components: [displayComponent, displayViewComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "invert_colors",
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.filling" }],
    });
};
