var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { CompanyService } from "@/app/domains/public-data/companies/services/company.service";
let SelectedCompanyFromFilterService = class SelectedCompanyFromFilterService {
    companyService;
    constructor(companyService) {
        this.companyService = companyService;
    }
    createEqualsFilter() {
        const bpcId = this.companyService.getSelectedBusinessPartner()?.id;
        return bpcId
            ? [
                {
                    propertyName: "CompanyFromId",
                    propertyValue: this.companyService.getSelectedCompany()?.id,
                },
                {
                    propertyName: "CompanyToId",
                    propertyValue: bpcId,
                },
            ]
            : [
                {
                    propertyName: "CompanyFromId",
                    propertyValue: this.companyService.getSelectedCompany()?.id,
                },
            ];
    }
};
SelectedCompanyFromFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof CompanyService !== "undefined" && CompanyService) === "function" ? _a : Object])
], SelectedCompanyFromFilterService);
export { SelectedCompanyFromFilterService };
