import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TechnicalRequirementsModuleId } from "@/app/modules/technical-requirements/technical-requirements.module";
import DisplayTemperatureRequirements from "@/app/sub-modules/temperature-requirements/components/display-temperature-requirements.vue";
import DisplayViewTemperatureRequirements from "@/app/sub-modules/temperature-requirements/components/display-temperature-requirements-view.vue";
import EditTemperatureRequirements from "@/app/sub-modules/temperature-requirements/components/edit-temperature-requirements.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
export const TemperatureRequirementsSubModule = (domainId) => {
    const id = "temperatureRequirements";
    const moduleId = TechnicalRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplayTemperatureRequirements, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayViewTemperatureRequirements, ...componentSuffixes);
    const editComponent = suffixComponentName(EditTemperatureRequirements, ...componentSuffixes);
    return createModule({
        components: [displayComponent, displayViewComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "thermostat",
                showChangeHistory: true,
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.temperature-requirements",
            },
        ],
    });
};
