import { ModuleModule } from "@/app/modular-content/modules/module.module";
import { createModule } from "@/di/module";
export const CheckInModuleId = "checkIn";
export const CheckInModule = (domainId) => {
    return createModule({
        imports: [
            ModuleModule({
                id: CheckInModuleId,
                domainId,
            }),
        ],
    });
};
