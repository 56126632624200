import { paramCase } from "change-case";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EntitySubmoduleConfigurationComponent from "@/app/domains/public-data/companies/company-overview/company-settings/components/entity-submodule-configuration.component.vue";
import WebhooksListComponent from "@/app/domains/public-data/companies/company-overview/company-settings/components/webhooks-list.component.vue";
import { EntitySubmoduleConfigurationMenuItems, EntityWebhooksConfigurationMenuItems, } from "@/app/domains/public-data/companies/company-overview/company-settings/company-settings.constants";
import { RouteMetaDomainId, RouteMetaListId } from "@/app/modular-content/modular-content.constants";
export const CompanySettingsRoutes = [
    {
        path: "settings",
        name: "manage-company/settings",
        component: () => import("./company-settings.component.vue"),
    },
];
export const SubmoduleConfigurationRoutes = [
    {
        path: "/companies/manage/:companyId/settings/submodules",
        name: "submodules-root",
        component: () => import("./components/submodule-configuration.component.vue"),
        children: [
            {
                path: "",
                name: "submodules",
                redirect: {
                    name: EntitySubmoduleConfigurationMenuItems[0].route,
                },
            },
            ...EntitySubmoduleConfigurationMenuItems.map((it) => {
                return {
                    path: `${paramCase(it.id)}`,
                    name: it.route,
                    meta: {
                        id: it.id,
                        domain: it.domainId,
                    },
                    component: suffixComponentName(EntitySubmoduleConfigurationComponent, it.id),
                };
            }),
        ],
    },
];
export const WebhooksConfigurationRoutes = [
    {
        path: "/companies/manage/:companyId/settings/webhooks",
        name: "webhooks",
        component: () => import("./components/submodule-configuration.component.vue"),
        children: [
            {
                path: "",
                name: "webhooks-list",
                redirect: {
                    name: EntityWebhooksConfigurationMenuItems[0].route,
                },
            },
            ...EntityWebhooksConfigurationMenuItems.map((it) => {
                return {
                    path: `${paramCase(it.listId)}`,
                    name: it.route,
                    meta: {
                        [RouteMetaDomainId]: it.domainId,
                        [RouteMetaListId]: it.listId,
                    },
                    component: suffixComponentName(WebhooksListComponent, it.listId),
                };
            }),
        ],
    },
];
