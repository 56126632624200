import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b80bbbca"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "logo-upload" };
const _hoisted_2 = { class: "logo-container" };
const _hoisted_3 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppButton = _resolveComponent("AppButton");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.path)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "logo-img",
                    style: _normalizeStyle(`background-image: url(${_ctx.path})`)
                }, null, 4))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.companyLiteral), 1))
        ]),
        _createVNode(_component_AppButton, {
            onClick: _ctx.openUploadModal,
            class: "p-button mt-3 w-100 p-button-outlined"
        }, {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("button")), 1)
            ]),
            _: 1
        }, 8, ["onClick"])
    ]));
}
