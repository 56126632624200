import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-341a85e0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "submodules-configuration" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CompanyOverviewTitle = _resolveComponent("CompanyOverviewTitle");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_SaveEntityButton = _resolveComponent("SaveEntityButton");
    const _component_Icon = _resolveComponent("Icon");
    const _component_PageHeader = _resolveComponent("PageHeader");
    const _component_PanelTopNavigation = _resolveComponent("PanelTopNavigation");
    const _component_RouterView = _resolveComponent("RouterView");
    const _component_Panel = _resolveComponent("Panel");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PageHeader, null, {
            title: _withCtx(() => [
                _createVNode(_component_CompanyOverviewTitle)
            ]),
            buttons: _withCtx(() => [
                _createVNode(_component_AppButton, {
                    class: "p-button p-button-secondary",
                    onClick: _ctx.navigateToSettings
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("submodule-configuration.back-link")), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"]),
                (_ctx.isSubmoduleSettings)
                    ? (_openBlock(), _createBlock(_component_SaveEntityButton, {
                        key: 0,
                        label: _ctx.translate('buttons.save'),
                        disabled: !_ctx.hasChanges
                    }, null, 8, ["label", "disabled"]))
                    : (_openBlock(), _createBlock(_component_AppButton, {
                        key: 1,
                        onClick: _ctx.openCreateWebhookModal
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Icon, { name: "add" }),
                            _createTextVNode(" " + _toDisplayString(_ctx.translate("webhooks.create")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]))
            ]),
            _: 1
        }),
        _createVNode(_component_Panel, null, {
            default: _withCtx(() => [
                _createVNode(_component_PanelTopNavigation, { "menu-items": _ctx.menuItems }, null, 8, ["menu-items"]),
                _createVNode(_component_RouterView)
            ]),
            _: 1
        })
    ]));
}
