import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_DisplayGeneralInstructionsView = _resolveComponent("DisplayGeneralInstructionsView");
    return (_openBlock(), _createElementBlock("div", null, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createBlock(_component_DisplayGeneralInstructionsView, {
                key: 1,
                model: _ctx.data
            }, null, 8, ["model"]))
    ]));
}
