import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { AppErrorHandlerToken } from "@/app/shared/tokens/app-error-handler.token";
let ConsoleErrorHandlerService = class ConsoleErrorHandlerService {
    handleError(error, context, info) {
        console.error(error);
        console.error("Error: context", context);
        console.error("Error: info", info);
    }
};
ConsoleErrorHandlerService = __decorate([
    service({ token: AppErrorHandlerToken })
], ConsoleErrorHandlerService);
export { ConsoleErrorHandlerService };
