var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { RouterService } from "@/app/shared/services/router.service";
import { isEqualDeep } from "@/app/shared/functions/is-equal-deep";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
let CompanySettingsService = class CompanySettingsService {
    httpClient;
    routerService;
    toastService;
    initial = {};
    data = {};
    constructor(httpClient, routerService, toastService) {
        this.httpClient = httpClient;
        this.routerService = routerService;
        this.toastService = toastService;
    }
    companyId() {
        return this.routerService.getParam("companyId");
    }
    entityType() {
        return this.routerService.getMeta("id") ?? "";
    }
    setInitial(data) {
        this.initial = data;
    }
    set(data) {
        this.data = data;
    }
    getChanges() {
        return this.data;
    }
    hasChanges() {
        return !isEqualDeep(this.initial, this.data);
    }
    discard() {
        this.initial = {};
        this.data = {};
    }
    async updateEntityConfiguration() {
        await this.saveEntityConfiguration();
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: "domains.companies.services.company.submodule-configuration.toast-message",
        });
        this.discard();
    }
    async getEntityConfiguration() {
        return await this.httpClient.get(`companies/${this.companyId()}/settings/modulesConfiguration/${this.entityType()}`);
    }
    async saveEntityConfiguration() {
        return await this.httpClient.put(`companies/${this.companyId()}/settings/modulesConfiguration/${this.entityType()}`, this.data);
    }
};
CompanySettingsService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof RouterService !== "undefined" && RouterService) === "function" ? _b : Object, typeof (_c = typeof ToastService !== "undefined" && ToastService) === "function" ? _c : Object])
], CompanySettingsService);
export { CompanySettingsService };
