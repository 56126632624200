import { onMounted, onUnmounted, ref } from "vue";
import { resolve } from "@/di/composables/resolve";
import { BackendValidationErrorsService } from "@/app/validation/services/backend-validation-errors.service";
import { useInstance } from "@/app/shared/composables/use-instance.composable";
import { rootErrorPath } from "@/app/validation/constants";
/*
 * Use this composable if you need to pass validation errors from the backend to your AppForm component.
 *
 * This composable consumes and returns all FormError entities for the current module that are pushed to the
 * BackendValidationErrorService.
 * If it is used outside a submodule, it will consume FormError entities from the root path instead (this
 * is the case for entity-create-modals for example)
 *
 * The BackendValidationErrorsService will only receive errors that have a corresponding BackendErrorResolver.
 * Everything else will be ignored and thus also not returned by this composable.
 *
 */
export const useBackendValidation = () => {
    const errors = ref([]);
    const validationErrorsService = resolve(BackendValidationErrorsService);
    let subscription;
    const clearErrors = () => {
        errors.value = [];
    };
    onMounted(() => {
        const instance = useInstance();
        const moduleId = instance.props.submodule?.getMetadata().id ?? rootErrorPath;
        subscription = validationErrorsService.onChange().subscribe((errs) => {
            if (errs[moduleId]) {
                errors.value = validationErrorsService.consumeErrors(moduleId);
            }
        });
    });
    onUnmounted(() => {
        subscription.unsubscribe();
    });
    return {
        errors,
        clearErrors,
    };
};
