import { createModule } from "@/di/module";
import { GeneralInformationModule } from "@/app/modules/general-information/general-information.module";
import { ClusterBasicInformationSubModule } from "@/app/sub-modules/basic-information/cluster/cluster-basic-information.module";
import { ClusterDomainId } from "@/app/domains/product-clusters/product-clusters.constants";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import ClusterContextMenuHeaderComponent from "@/app/domains/product-clusters/components/product-clusters-context-menu-header.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { CompanyRole } from "@/app/shared/types/access.type";
export const ProductClustersModule = createModule({
    components: [ClusterContextMenuHeaderComponent],
    imports: [
        DomainModule({
            id: ClusterDomainId,
            icon: "science",
            detailsIcon: "schema",
            baseUrl: "productclusters",
            hideMenuItem: true,
            domainTranslationPrefix: "clusters",
            standAloneDomain: true,
            connectingDisabled: true,
            createRoles: [CompanyRole.ProductManager],
            components: {
                contextMenuHeader: ClusterContextMenuHeaderComponent,
            },
        }),
        //
        GeneralInformationModule(ClusterDomainId),
        ClusterBasicInformationSubModule(ClusterDomainId),
    ],
    providers: [{ token: TranslationPrefixToken, useValue: "domains.clusters" }],
});
