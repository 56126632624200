import { defineStore } from "pinia";
const initialState = {
    user: {
        id: "",
        email: "",
        firstName: "",
        lastName: "",
        isInactive: false,
    },
};
export const useUserStore = defineStore({
    id: "user-id",
    state: () => initialState,
    getters: {
        fullName: (state) => `${state.user.firstName} ${state.user.lastName}`,
        userId: (state) => state.user.id,
        isUserInactive: (state) => state.user.isInactive,
    },
    actions: {
        setUser(user) {
            this.user = user;
        },
        updateUser(user) {
            this.user.firstName = user.firstName;
            this.user.lastName = user.lastName;
        },
        setUserInactiveStatus(state) {
            this.user.isInactive = state;
        },
    },
});
