import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-17765e29"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 1,
    class: "label"
};
const _hoisted_2 = {
    key: 2,
    class: "counter"
};
const _hoisted_3 = {
    key: 3,
    class: "tag"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_CleanButton = _resolveComponent("CleanButton");
    const _directive_tooltip = _resolveDirective("tooltip");
    return _withDirectives((_openBlock(), _createBlock(_component_CleanButton, {
        class: _normalizeClass([{ active: _ctx.isActive, action: _ctx.action, disabled: !_ctx.isActive && _ctx.disabled }, "menu-item"]),
        id: _ctx.itemId,
        disabled: _ctx.disabled,
        "data-qa-id": _ctx.itemId,
        onClick: _ctx.onClick
    }, {
        default: _withCtx(() => [
            (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    name: _ctx.icon
                }, null, 8, ["name"]))
                : _createCommentVNode("", true),
            (_ctx.label)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
                : _createCommentVNode("", true),
            (_ctx.counter)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.counter), 1))
                : _createCommentVNode("", true),
            (_ctx.tag)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.tag), 1))
                : _createCommentVNode("", true),
            (_ctx.trailingIcon)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 4,
                    variant: "outlined",
                    name: _ctx.trailingIcon
                }, null, 8, ["name"]))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }, 8, ["class", "id", "disabled", "data-qa-id", "onClick"])), [
        [
            _directive_tooltip,
            _ctx.tooltip,
            void 0,
            { top: true }
        ]
    ]);
}
