import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_TabPanel = _resolveComponent("TabPanel");
    const _component_TabView = _resolveComponent("TabView");
    return (_openBlock(), _createBlock(_component_TabView, {
        class: "tab-view",
        lazy: "",
        scrollable: _ctx.isScrollable,
        activeIndex: _ctx.active,
        "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event) => ((_ctx.active) = $event))
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
                return (_openBlock(), _createBlock(_component_TabPanel, {
                    key: item?.label
                }, {
                    header: _withCtx(() => [
                        (_openBlock(), _createBlock(_component_MenuItem, {
                            label: item.label,
                            route: item.route,
                            icon: item.icon,
                            key: item.route,
                            action: item.action,
                            active: item.active,
                            counter: item.counter,
                            disabled: item.disabled,
                            "disabled-tooltip": item.disabledTooltip,
                            "trailing-icon": item.trailingIcon
                        }, null, 8, ["label", "route", "icon", "action", "active", "counter", "disabled", "disabled-tooltip", "trailing-icon"]))
                    ]),
                    _: 2
                }, 1024));
            }), 128))
        ]),
        _: 1
    }, 8, ["scrollable", "activeIndex"]));
}
