import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5926da76"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_Button = _resolveComponent("Button");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        visible: _ctx.visible,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event) => ((_ctx.visible) = $event)),
        showCloseIcon: true,
        "modal-id": _ctx.modalId,
        "show-header": false,
        class: "create-company-success-modal"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_Icon, {
                class: "close",
                name: "close",
                onClick: _ctx.close
            }, null, 8, ["onClick"]),
            _createVNode(_component_Icon, {
                class: "checkmark",
                name: "check_circle"
            }),
            _createElementVNode("h3", null, _toDisplayString(_ctx.translate(`${_ctx.translationPathKey}.header`)), 1),
            _createElementVNode("p", {
                class: "break-word",
                innerHTML: _ctx.translate(`${_ctx.translationPathKey}.message`, { companyName: _ctx.createdCompany?.name })
            }, null, 8, _hoisted_1),
            _createVNode(_component_Button, {
                class: "p-button-lg",
                onClick: _ctx.continueToCompany
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate(`${_ctx.translationPathKey}.continue`)), 1)
                ]),
                _: 1
            }, 8, ["onClick"])
        ]),
        _: 1
    }, 8, ["visible", "modal-id"]));
}
