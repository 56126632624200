import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3cc1064e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-packaging" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "column-header col-6" };
const _hoisted_4 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_5 = {
    key: 1,
    class: "col-6 column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("packaging-type")), 1),
            (_ctx.model?.packagingType?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.packagingType, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(_ctx.getValue(tag, "PackagingType")), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, "-"))
        ])
    ]));
}
