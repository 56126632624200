import { onMounted, onUnmounted } from "vue";
import { resolve } from "@/di/composables/resolve";
import { RouterService } from "@/app/shared/services/router.service";
import { UserStorageService } from "@/app/shared/services/user-storage.service";
import { getObserver } from "@/app/shared/functions/get-html-mutation-observer";
export const useStickyTableResize = () => {
    const userStorageService = resolve(UserStorageService);
    const routerService = resolve(RouterService);
    let table;
    let pageX;
    let columnEl;
    let hiddenColumnEl;
    let columnWidth;
    let columnMinWidth;
    let isMouseDown = false;
    onMounted(() => {
        table = document.querySelector("table[role='table']");
        if (!table) {
            return;
        }
        const tableHead = table.querySelector("thead");
        const observeDOM = getObserver();
        handleStickyTableHeader();
        setTableFixedLayout();
        setInitialColumnWidth();
        handleResizableColumns();
        observeDOM(tableHead, () => {
            handleStickyTableHeader();
            handleResizableColumns();
        });
    });
    onUnmounted(() => {
        const resizers = document.getElementsByClassName("resizer");
        [...resizers].forEach((resizer) => {
            resizer.removeEventListener("mouseover", mouseOverCallback);
            resizer.removeEventListener("mouseout", mouseOutCallback);
        });
    });
    function setTableFixedLayout() {
        const tables = document.querySelectorAll("table");
        tables.forEach((table) => {
            table.style.tableLayout = "fixed";
        });
    }
    function handleLastColumnWidth() {
        const tables = document.querySelectorAll("table");
        tables.forEach((table) => {
            const tableWidth = table.scrollWidth;
            const parentWidth = table.parentElement?.clientWidth;
            const lastColumn = [...getColumns(table)].pop();
            if (!lastColumn) {
                return;
            }
            if (tableWidth <= parentWidth) {
                lastColumn.style.width = "unset";
            }
            else {
                setColumnWidth(lastColumn, parseInt(lastColumn.style.width) || convertRemToPixels(lastColumn.style.minWidth));
            }
        });
    }
    function setInitialColumnWidth() {
        const widths = getColumnsWidthFromStorage() ?? [];
        const tables = document.querySelectorAll("table");
        tables.forEach((table) => {
            [...getColumns(table)].forEach((column, index) => {
                const columnMinWidth = convertRemToPixels(column.style.minWidth) ?? 0;
                const previousWidth = parseInt(widths[index] ?? 0);
                const initialMinWidth = previousWidth <= columnMinWidth ? columnMinWidth : previousWidth;
                setColumnWidth(column, initialMinWidth);
            });
        });
    }
    function handleResizableColumns() {
        const tables = document.querySelectorAll("table");
        tables.forEach((table) => {
            [...getColumns(table)].forEach((column) => {
                const noResizer = !column.getElementsByClassName("resizer").length;
                const isResizable = !column.classList.contains("not-resizable-column");
                if (noResizer && isResizable) {
                    const resizerEl = createResizer();
                    column.appendChild(resizerEl);
                    column.style.position = "relative";
                    setElementBorderRight(column, "0.1px solid #00000008");
                    setListeners(resizerEl);
                }
                setColumnWidth(column, parseInt(column.style.width) || convertRemToPixels(column.style.minWidth));
            });
        });
        handleLastColumnWidth();
    }
    function setListeners(resizer) {
        mouseMoveWhilstDown(resizer, onMouseMove);
        resizer.addEventListener("mouseover", mouseOverCallback);
        resizer.addEventListener("mouseout", mouseOutCallback);
    }
    function mouseMoveWhilstDown(target, whileMove) {
        const endMove = function () {
            document.removeEventListener("mousemove", whileMove);
            document.removeEventListener("mouseup", endMove);
            onMouseUp(target);
        };
        target.addEventListener("mousedown", function (e) {
            document.addEventListener("mousemove", whileMove);
            document.addEventListener("mouseup", endMove);
            onMouseDown(e);
        });
    }
    function onMouseDown(e) {
        isMouseDown = true;
        pageX = e.pageX;
        setElementBorderRight(e.target, "1px solid #dadee2");
        columnEl = e?.target?.parentElement;
        const index = [...columnEl.parentElement.children].indexOf(columnEl);
        hiddenColumnEl = table.querySelectorAll("thead th")[index];
        columnWidth = columnEl?.offsetWidth - paddingDiff(columnEl);
        columnMinWidth = convertRemToPixels(columnEl.style.minWidth) ?? 0;
    }
    function onMouseMove(e) {
        if (columnEl && columnWidth && pageX) {
            const diffX = e.pageX - pageX;
            const curSize = columnWidth + diffX;
            const size = curSize <= columnMinWidth ? columnMinWidth : curSize;
            setColumnWidth(columnEl, size);
            setColumnWidth(hiddenColumnEl, size);
            handleLastColumnWidth();
        }
    }
    function onMouseUp(resizer) {
        setColumnsWidthToStorage();
        setElementBorderRight(resizer, "");
        isMouseDown = false;
        columnEl = undefined;
        pageX = undefined;
        columnWidth = undefined;
    }
    function mouseOverCallback(e) {
        if (!isMouseDown) {
            setElementBorderRight(e.target, "1px solid #eceef1");
        }
    }
    function mouseOutCallback(e) {
        if (!isMouseDown) {
            setElementBorderRight(e.target, "");
        }
    }
    function setColumnWidth(column, width) {
        column.style.width = width + "px";
    }
    function createResizer() {
        const div = document.createElement("div");
        div.classList.add("resizer");
        div.style.top = "0";
        div.style.right = "0";
        div.style.bottom = "0";
        div.style.width = "5px";
        div.style.position = "absolute";
        div.style.cursor = "col-resize";
        div.style.userSelect = "none";
        return div;
    }
    function setElementBorderRight(element, style) {
        element.style.borderRight = style;
    }
    function paddingDiff(column) {
        if (getStyleVal(column, "box-sizing") == "border-box") {
            return 0;
        }
        const padLeft = getStyleVal(column, "padding-left");
        const padRight = getStyleVal(column, "padding-right");
        return parseInt(padLeft) + parseInt(padRight);
    }
    function getStyleVal(element, css) {
        return window.getComputedStyle(element, null).getPropertyValue(css);
    }
    function convertRemToPixels(sizeInRem) {
        return parseInt(sizeInRem) * parseFloat(getComputedStyle(document.documentElement).fontSize) ?? 0;
    }
    function getColumns(table) {
        const columns = table.querySelectorAll("thead th:not(.p-filter-column)");
        if (!columns) {
            return [];
        }
        return columns;
    }
    function setColumnsWidthToStorage() {
        const stickyTableHeader = document.getElementById("sticky-header")?.querySelector("table");
        const widths = [...getColumns(stickyTableHeader || table)].map((it) => it.style.width);
        const { domainId, listId } = routerService.getCurrentRoute().meta;
        userStorageService.set(`${domainId}.${listId}.columnsWidth`, widths);
    }
    function getColumnsWidthFromStorage() {
        const { domainId, listId } = routerService.getCurrentRoute().meta;
        return userStorageService.get(`${domainId}.${listId}.columnsWidth`);
    }
    const handleStickyTableHeader = () => {
        const tableBodyWrapper = document.querySelector(".p-datatable-wrapper");
        const tableHeader = document.querySelector(".p-datatable-header");
        const head = document.getElementsByClassName("p-datatable-thead")[0];
        const copyHead = document.getElementsByClassName("p-datatable-thead")[0]?.cloneNode(true);
        if (!tableBodyWrapper || !head) {
            return;
        }
        if (!document.getElementById("sticky-header")) {
            const stickyTableHeader = getStickyHeader();
            stickyTableHeader.appendChild(head);
            hideOriginalHeader(copyHead);
            table.prepend(copyHead);
            tableBodyWrapper.onscroll = function () {
                document.getElementById("sticky-header").scrollLeft = tableBodyWrapper.scrollLeft;
            };
            const stickyHeaderWrapper = getStickyWrapper();
            stickyHeaderWrapper.appendChild(stickyTableHeader);
            tableHeader?.parentNode?.insertBefore(stickyHeaderWrapper, tableHeader.nextSibling);
        }
        else {
            hideOriginalHeader(copyHead);
            table.getElementsByTagName("thead")[0].replaceWith(copyHead);
        }
        tableBodyWrapper.style.marginTop = `-${copyHead.offsetHeight}px`;
    };
    function getStickyHeader() {
        const stickyTableHeader = document.createElement("table");
        stickyTableHeader.id = "sticky-table-header";
        stickyTableHeader.className = "p-datatable-table p-datatable-scrollable-table";
        stickyTableHeader.style.tableLayout = "fixed";
        return stickyTableHeader;
    }
    function getStickyWrapper() {
        const stickyHeaderWrapper = document.createElement("div");
        stickyHeaderWrapper.innerHTML = `<div 
      class="p-datatable p-component p-datatable-hoverable-rows p-datatable-scrollable p-datatable-flex-scrollable p-datatable-responsive-scroll data-table" 
      id="sticky-header" style="overflow-x: hidden; position: sticky; top: 0; z-index: 10; height: auto; top: -18px;"></div>`;
        return stickyHeaderWrapper.firstChild;
    }
    function hideOriginalHeader(header) {
        header.className = "";
        header.style.visibility = "hidden";
        [...header.getElementsByClassName("p-column-title")].forEach((it) => (it.innerHTML = ""));
    }
};
