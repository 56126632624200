import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8ef9c688"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "dropdown-options-reload" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Button = _resolveComponent("Button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.translate("global.phrases.error-occurred")), 1),
        _createVNode(_component_Button, {
            class: "p-button-text",
            onClick: _ctx.onClick
        }, {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("global.buttons.retry")), 1)
            ]),
            _: 1
        }, 8, ["onClick"])
    ]));
}
