const ROUTE_PARAM_REPLACE_REGEX = /:([a-z]+)/gi;
const ROUTE_PARAM_REMOVE_REGEX = /:[a-z]+\/?/gi;
const TRAILING_SLASH_REGEX = /\/$/;
export function applyRouteUrlParams(url, params) {
    return url.replace(ROUTE_PARAM_REPLACE_REGEX, (match, param) => params[param] ?? match);
}
export function removeRouteUrlParams(url) {
    const urlWithoutParams = url.replace(ROUTE_PARAM_REMOVE_REGEX, "");
    const urlHasTrailingSlash = TRAILING_SLASH_REGEX.test(url);
    return urlHasTrailingSlash ? urlWithoutParams : urlWithoutParams.replace(TRAILING_SLASH_REGEX, "");
}
