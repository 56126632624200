import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8953a8da"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "restriction-inputs" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AccessRestrictionInput = _resolveComponent("AccessRestrictionInput");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial
    }, {
        default: _withCtx(() => [
            _createVNode(_component_FormField, { class: "no-restrictions" }, {
                default: _withCtx(() => [
                    _createVNode(_component_AppCheckbox, { name: "noRestrictions" }, {
                        default: _withCtx(() => [
                            _createElementVNode("label", null, _toDisplayString(_ctx.translate("form-fields.no-restrictions.label")), 1)
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_AccessRestrictionInput, {
                    modelValue: _ctx.model.height,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.height) = $event)),
                    name: "height",
                    image: "height",
                    unit: "m",
                    label: _ctx.translate('form-fields.height.label'),
                    placeholder: _ctx.translate('form-fields.height.placeholder', { unit: 'm' }),
                    disabled: _ctx.noRestrictions
                }, null, 8, ["modelValue", "label", "placeholder", "disabled"]),
                _createVNode(_component_AccessRestrictionInput, {
                    modelValue: _ctx.model['length'],
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model['length']) = $event)),
                    name: "length",
                    image: "length",
                    unit: "m",
                    label: _ctx.translate('form-fields.length.label'),
                    placeholder: _ctx.translate('form-fields.length.placeholder', { unit: 'm' }),
                    disabled: _ctx.noRestrictions
                }, null, 8, ["modelValue", "label", "placeholder", "disabled"]),
                _createVNode(_component_AccessRestrictionInput, {
                    modelValue: _ctx.model.width,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model.width) = $event)),
                    name: "width",
                    image: "width",
                    unit: "m",
                    label: _ctx.translate('form-fields.width.label'),
                    placeholder: _ctx.translate('form-fields.width.placeholder', { unit: 'm' }),
                    disabled: _ctx.noRestrictions
                }, null, 8, ["modelValue", "label", "placeholder", "disabled"]),
                _createVNode(_component_AccessRestrictionInput, {
                    modelValue: _ctx.model.weight,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.model.weight) = $event)),
                    name: "weight",
                    image: "weight",
                    unit: "t",
                    label: _ctx.translate('form-fields.weight.label'),
                    placeholder: _ctx.translate('form-fields.weight.placeholder', { unit: 't' }),
                    disabled: _ctx.noRestrictions
                }, null, 8, ["modelValue", "label", "placeholder", "disabled"])
            ])
        ]),
        _: 1
    }, 8, ["modelValue", "initial"]));
}
