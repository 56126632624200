import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditDocuments from "@/app/sub-modules/documents/components/edit-documents.component.vue";
import DisplayDocuments from "@/app/sub-modules/documents/components/display-documents.component.vue";
import DisplayDocumentsView from "@/app/sub-modules/documents/components/display-documents-view.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
export const DocumentsSubModule = (domainId, moduleId) => {
    const id = "documents";
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditDocuments, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayDocuments, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayDocumentsView, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "description",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.documents" }],
    });
};
