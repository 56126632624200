import { createModule } from "@/di/module";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { camelCase } from "change-case";
import { GeneralInformationModule } from "@/app/modules/general-information/general-information.module";
import { ClusterBasicInformationSubModule } from "@/app/sub-modules/basic-information/cluster/cluster-basic-information.module";
import AssignedProductsContextMenuHeaderComponent from "@/app/domains/assigned-products/components/assigned-products-context-menu-header.component.vue";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { LoadingPointDomainId } from "@/app/domains/loading-points/loading-points.constants";
import { UnloadingPointDomainId } from "@/app/domains/unloading-points/unloading-points.constants";
import { ClusterDomainId } from "@/app/domains/product-clusters/product-clusters.constants";
const ParentDomainBaseUrlEnum = {
    [LoadingPointDomainId]: "loadingpoints",
    [UnloadingPointDomainId]: "unloadingpoints",
};
export const AssignedProductClustersModule = (parentDomainId, id) => {
    const componentSuffixes = [parentDomainId, id];
    const contextMenuHeaderComponent = suffixComponentName(AssignedProductsContextMenuHeaderComponent, ...componentSuffixes);
    return createModule({
        components: [contextMenuHeaderComponent],
        imports: [
            createModule({
                imports: [
                    DomainModule({
                        parent: parentDomainId,
                        id: id,
                        icon: "science",
                        detailsIcon: "science",
                        standAloneDomain: true,
                        hideMenuItem: true,
                        domainTranslationPrefix: "assigned-clusters",
                        forBusinessPartner: true,
                        baseUrl: () => {
                            const paramId = camelCase(parentDomainId + "Id");
                            return `${ParentDomainBaseUrlEnum[parentDomainId]}/:${paramId}/productclusters`;
                        },
                        components: {
                            contextMenuHeader: contextMenuHeaderComponent,
                        },
                    }),
                    //
                    GeneralInformationModule(id, { readOnly: true, originDomain: ClusterDomainId }),
                    ClusterBasicInformationSubModule(id),
                ],
                providers: [{ token: TranslationPrefixToken, useValue: "domains.assigned-clusters" }],
            }),
        ],
    });
};
