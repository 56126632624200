import { createModule } from "@/di/module";
import CompaniesListComponent from "@/app/domains/public-data/companies/components/companies-list.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { PublicDataDomainId, AllCompaniesListId, AllSitesListId, CompanyGroupsListId, CompanyGroupsDomainPrefix, PublicDataDomainPrefix, CompaniesUrl, CompanyGroupsUrl, SitesUrl, } from "@/app/domains/public-data/public-data.constants";
import { CreateCompanySuccessModalId, CreateCompanyNoticeModalId, CompaniesDomainId, } from "@/app/domains/public-data/companies/companies.constants";
import { CompanyService } from "@/app/domains/public-data/companies/services/company.service";
import { UpdateCompanyService } from "@/app/domains/public-data/companies/services/update-company.service";
import CreateCompanyNoticeComponent from "@/app/domains/public-data/companies/components/create-company-notice.component.vue";
import { ModalModule } from "@/app/modals/modules/modal.module";
import CreateCompanyModalComponent from "@/app/domains/public-data/companies/components/create-company-modal.component.vue";
import CreateCompanySuccessModalComponent from "@/app/domains/public-data/companies/components/create-company-success-modal.component.vue";
import { SelectedCompanyFilterService } from "@/app/domains/public-data/companies/services/selected-company-filter.service";
import { ManagedByExternalCompanyFilterService } from "@/app/domains/public-data/companies/services/managed-by-external-company.service";
import { PublicSitesFilterService } from "@/app/domains/public-data/companies/services/public-sites-filter.service";
import CreateCompanyGroupModalComponent from "@/app/domains/public-data/companies/components/create-company-group-modal.component.vue";
import CompanyGroupsListComponent from "@/app/domains/public-data/companies/components/company-groups-list.component.vue";
import { CompanyGroupService } from "@/app/domains/public-data/companies/services/company-group.service";
import { RouteMetaListId } from "@/app/modular-content/modular-content.constants";
import { Permissions } from "@/app/shared/constants/permissions.constants";
import { CompanyFilterService } from "@/app/domains/public-data/companies/services/company-filter.service";
import SitesTableComponent from "@/app/domains/sites/components/sites-table.component.vue";
import { DomainMetadataModule } from "@/app/modular-content/modules/domain-modules/domain-metadata.module";
import { DomainSearchServiceModule } from "@/app/modular-content/modules/domain-modules/domain-search-service.module";
import { paramCase } from "change-case";
const companiesOptions = {
    id: CompaniesDomainId,
    icon: "maps_home_work",
    detailsIcon: "maps_home_work",
    domainTranslationPrefix: paramCase(CompaniesDomainId),
    baseUrl: paramCase(CompaniesDomainId),
    components: {},
};
export const PublicDataModule = createModule({
    components: [
        CompaniesListComponent,
        CompanyGroupsListComponent,
        SitesTableComponent,
        CreateCompanyNoticeComponent,
        CreateCompanyModalComponent,
        CreateCompanyGroupModalComponent,
        CreateCompanySuccessModalComponent,
    ],
    imports: [
        DomainModule({
            id: PublicDataDomainId,
            icon: "maps_home_work",
            detailsIcon: "maps_home_work",
            sideNavTier: 2,
            forLogisticsServiceProvider: true,
            public: true,
            lists: [
                {
                    id: AllCompaniesListId,
                    filter: ManagedByExternalCompanyFilterService,
                    default: true,
                    createRoles: Permissions.isLoadyAdmin,
                    publicList: true,
                },
                {
                    id: AllSitesListId,
                    filter: PublicSitesFilterService,
                    component: SitesTableComponent,
                    createRoles: [],
                    publicList: true,
                },
                {
                    id: CompanyGroupsListId,
                    component: CompanyGroupsListComponent,
                    createModal: CreateCompanyGroupModalComponent,
                    createRoles: Permissions.isLoadyAdmin,
                    listAccessCondition(rolesService) {
                        return !!rolesService?.hasRole(Permissions.isLoadyAdmin);
                    },
                },
            ],
            domainTranslationPrefix: (routerService) => {
                const listId = routerService?.getMeta(RouteMetaListId);
                return [CompanyGroupsListId].includes(listId) ? CompanyGroupsDomainPrefix : PublicDataDomainPrefix;
            },
            baseUrl: (routerService) => {
                if (routerService?.getMeta(RouteMetaListId) === CompanyGroupsListId) {
                    return CompanyGroupsUrl;
                }
                else if (routerService?.getMeta(RouteMetaListId) === AllSitesListId) {
                    return SitesUrl;
                }
                return CompaniesUrl;
            },
            components: {
                createModal: CreateCompanyModalComponent,
                table: CompaniesListComponent,
            },
        }),
        ModalModule(CreateCompanyNoticeModalId, CreateCompanyNoticeComponent),
        ModalModule(CreateCompanySuccessModalId, CreateCompanySuccessModalComponent),
        DomainMetadataModule(companiesOptions),
        DomainSearchServiceModule(companiesOptions),
    ],
    providers: [
        CompanyService,
        CompanyGroupService,
        UpdateCompanyService,
        CompanyFilterService,
        SelectedCompanyFilterService,
        ManagedByExternalCompanyFilterService,
        PublicSitesFilterService,
        { token: TranslationPrefixToken, useValue: "domains.public-data" },
    ],
});
