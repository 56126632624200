import moment from "moment";
export var DateFormat;
(function (DateFormat) {
    DateFormat["Default"] = "MMM. DD, YYYY HH:mm";
    DateFormat["Full"] = "DD.MM.YYYY, HH:mm";
    DateFormat["Short"] = "DD-MM-YYYY";
})(DateFormat || (DateFormat = {}));
export const formatDate = (value, format = DateFormat.Default) => {
    return moment(value).format(format);
};
export const timeToDate = (time) => {
    if (!time) {
        return null;
    }
    const [hours, minutes] = time.split(":").map((it) => parseInt(it, 10));
    const d = new Date();
    d.setHours(hours);
    d.setMinutes(Math.ceil(minutes / 10) * 10);
    return d;
};
export const dateToTime = (date) => {
    if (!date) {
        return null;
    }
    return moment(date).format("HH:mm");
};
export const dateRangeLabel = (timeRange, format) => {
    const [dateFrom, dateTo] = timeRange;
    const from = dateFrom ? formatDate(dateFrom.toString(), format) : "";
    const to = dateTo ? formatDate(dateTo.toString(), format) : "";
    return `${from} - ${to}`.trim() === "-" ? from : `${from} - ${to}`;
};
