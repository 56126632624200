import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import DisplayComponent from "@/app/sub-modules/basic-information/assigned-product-master/components/display-assigned-product-master-basic-information.component.vue";
import DisplayViewComponent from "@/app/sub-modules/basic-information/assigned-product-master/components/display-assigned-product-master-basic-information-view.component.vue";
import EditComponent from "@/app/sub-modules/basic-information/assigned-product-master/components/edit-assigned-product-master-basic-information.component.vue";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { BasicInformationSubModuleId } from "@/app/sub-modules/basic-information/basic-information.types";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
export const AssignedProductMasterBasicInformationSubModule = (domainId) => {
    const id = BasicInformationSubModuleId;
    const moduleId = GeneralInformationModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                domainId,
                moduleId,
                icon: "info",
                editTitle: "sub-modules.basic-information.assigned-product-master.name",
                hideDisplayTitle: true,
                configurationTranslationPrefix: "sub-modules.basic-information.assigned-product-master",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.basic-information.assigned-product-master" }],
    });
};
