import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f8aab722"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "sub-module-info" };
const _hoisted_2 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_ButtonClean = _resolveComponent("ButtonClean");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.publicInfo)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createTextVNode(_toDisplayString(_ctx.translate("public")), 1)
            ])), [
                [
                    _directive_tooltip,
                    _ctx.translate('public-tooltip'),
                    void 0,
                    { bottom: true }
                ]
            ])
            : _createCommentVNode("", true),
        (_ctx.descriptionIcon)
            ? (_openBlock(), _createBlock(_component_ButtonClean, {
                key: 1,
                onClick: _ctx.openSubModuleInfoModal
            }, {
                default: _withCtx(() => [
                    _withDirectives(_createVNode(_component_Icon, {
                        id: "info",
                        name: "info",
                        variant: "filled"
                    }, null, 512), [
                        [
                            _directive_tooltip,
                            {
                                value: _ctx.translate('info-button-tooltip'),
                                class: 'info-button-tooltip',
                            },
                            void 0,
                            { bottom: true }
                        ]
                    ])
                ]),
                _: 1
            }, 8, ["onClick"]))
            : _createCommentVNode("", true),
        (_ctx.subModuleId && _ctx.showChangeHistory)
            ? (_openBlock(), _createBlock(_component_ButtonClean, {
                key: 2,
                onClick: _ctx.openSubModuleChangeHistoryModal
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_Icon, {
                        id: "history",
                        name: "history",
                        variant: "filled"
                    })
                ]),
                _: 1
            }, 8, ["onClick"]))
            : _createCommentVNode("", true)
    ]));
}
