import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_DropdownMenuOverlay = _resolveComponent("DropdownMenuOverlay");
    return (_openBlock(), _createBlock(_component_DropdownMenuOverlay, {
        class: "table-row-context-menu",
        "data-qa-id": "table-row-context-menu"
    }, {
        button: _withCtx(() => [
            _createVNode(_component_Icon, {
                class: "icon",
                name: "more_horiz"
            })
        ]),
        items: _withCtx(({ hideOverlay }) => [
            _renderSlot(_ctx.$slots, "items", _normalizeProps(_guardReactiveProps({ data: _ctx.data, hideOverlay })), undefined, true),
            (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_MenuItem, {
                    key: 0,
                    label: _ctx.contextTranslate('row-context-menu.edit'),
                    icon: "edit",
                    onClick: ($event) => (_ctx.editEntity({ hideOverlay }))
                }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true),
            (_ctx.canDelete)
                ? (_openBlock(), _createBlock(_component_MenuItem, {
                    key: 1,
                    label: _ctx.contextTranslate('row-context-menu.delete'),
                    class: "text-red",
                    icon: "delete",
                    "data-qa-id": "delete-button",
                    onClick: ($event) => (_ctx.deleteEntity({ hideOverlay }))
                }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true)
        ]),
        _: 3
    }));
}
