import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fed74b30"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-safety-data-sheet" };
const _hoisted_2 = { class: "row mb-0" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-description" };
const _hoisted_5 = ["href"];
const _hoisted_6 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("link-to-sds")), 1),
            _createElementVNode("div", _hoisted_4, [
                (_ctx.model.sdsLink)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: _ctx.model.sdsLink,
                        target: "_blank",
                        class: "url"
                    }, [
                        _createTextVNode(_toDisplayString(_ctx.translate("open-sds")) + " ", 1),
                        _createVNode(_component_Icon, { name: "launch" })
                    ], 8, _hoisted_5))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, "-"))
            ])
        ])
    ]));
}
