import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { CleaningRequirementsModuleId } from "@/app/modules/cleaning-requirements/cleaning-requirements.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditPreloadConfirmationComponent from "@/app/sub-modules/preload-confirmation/components/edit-preload-confirmation.component.vue";
import DisplayPreloadConfirmationComponent from "@/app/sub-modules/preload-confirmation/components/display-preload-confirmation.component.vue";
import DisplayPreloadConfirmationViewComponent from "@/app/sub-modules/preload-confirmation/components/display-preload-confirmation-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const PreloadConfirmationSubModule = (domainId) => {
    const id = "preloadConfirmation";
    const moduleId = CleaningRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditPreloadConfirmationComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayPreloadConfirmationComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayPreloadConfirmationViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "document_scanner",
                iconVariant: "outlined",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.preload-confirmation",
            },
        ],
    });
};
