import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_OverlayPanel = _resolveComponent("OverlayPanel");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_AppButton, {
            class: "p-button-secondary",
            onClick: _ctx.toggleDropdown,
            disabled: _ctx.noLanesSelected
        }, {
            default: _withCtx(() => [
                _createVNode(_component_Icon, { name: "share" }),
                _createTextVNode(" " + _toDisplayString(_ctx.translate("global.phrases.share")), 1)
            ]),
            _: 1
        }, 8, ["onClick", "disabled"]),
        _createVNode(_component_OverlayPanel, {
            ref: "overlayPanel",
            onClick: _ctx.toggleDropdown
        }, {
            default: _withCtx(() => [
                _withDirectives(_createVNode(_component_MenuItem, {
                    icon: "share",
                    label: _ctx.translate('driver-view.name'),
                    onClick: _ctx.openDriverView,
                    disabled: !_ctx.isSingleLanePlatform
                }, null, 8, ["label", "onClick", "disabled"]), [
                    [
                        _directive_tooltip,
                        _ctx.multipleLanesSelectedTooltip,
                        void 0,
                        { bottom: true }
                    ]
                ]),
                _withDirectives(_createVNode(_component_MenuItem, {
                    icon: "share",
                    label: _ctx.translate('loady2share.name'),
                    onClick: _ctx.openLoady2Share,
                    disabled: !_ctx.isSingleLanePlatform
                }, null, 8, ["label", "onClick", "disabled"]), [
                    [
                        _directive_tooltip,
                        _ctx.multipleLanesSelectedTooltip,
                        void 0,
                        { bottom: true }
                    ]
                ]),
                (_ctx.isLanesDomains)
                    ? _withDirectives((_openBlock(), _createBlock(_component_MenuItem, {
                        key: 0,
                        icon: "share",
                        label: _ctx.translate('lane-for-tender.name'),
                        onClick: _ctx.openLane4Tender,
                        disabled: !_ctx.isLanesWebhookEnabled
                    }, null, 8, ["label", "onClick", "disabled"])), [
                        [
                            _directive_tooltip,
                            _ctx.disabledFeatureTooltip,
                            void 0,
                            { bottom: true }
                        ]
                    ])
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 8, ["onClick"])
    ]));
}
