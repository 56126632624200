import { RouteMetaDomainId, RouteMetaListId } from "@/app/modular-content/modular-content.constants";
export const ManageMembersRoutes = [
    {
        path: "users",
        name: "manage-company/users",
        component: () => import("./manage-members.component.vue"),
        meta: {
            [RouteMetaDomainId]: "ManageCompany",
            [RouteMetaListId]: "users",
        },
    },
];
