import { createModule } from "@/di/module";
import { DomainMetadataModule } from "@/app/modular-content/modules/domain-modules/domain-metadata.module";
import { DomainSearchServiceModule } from "@/app/modular-content/modules/domain-modules/domain-search-service.module";
import { DomainServiceModule } from "@/app/modular-content/modules/domain-modules/domain-service.module";
import { DomainIdToken } from "@/app/modular-content/token/modular-content.token";
export const PreProductsModule = () => {
    const options = {
        id: "PreProducts",
        icon: "assignment",
        detailsIcon: "assignment",
        baseUrl: "preproducts",
        domainTranslationPrefix: "pre-products",
        components: {},
    };
    return createModule({
        imports: [DomainMetadataModule(options), DomainServiceModule(options), DomainSearchServiceModule(options)],
        providers: [{ token: DomainIdToken, useValue: options.id }],
    });
};
