import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import EditReceivingTankComponent from "@/app/sub-modules/receiving-tank/components/edit-receiving-tank.component.vue";
import DisplayReceivingTankComponent from "@/app/sub-modules/receiving-tank/components/display-receiving-tank.component.vue";
import DisplayReceivingTankView from "@/app/sub-modules/receiving-tank/components/display-receiving-tank-view.component.vue";
import { InstallationsModuleId } from "@/app/modules/installations/installations.module";
export const ReceivingTankSubModule = (domainId) => {
    const id = "receivingTank";
    const moduleId = InstallationsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditReceivingTankComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayReceivingTankComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, DisplayReceivingTankView],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "shopping_basket",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.receiving-tank",
            },
        ],
    });
};
