var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { RouterService } from "@/app/shared/services/router.service";
import { createEntityIdRouteParam } from "@/app/modular-content/functions/create-entity-id-route-param.function";
import { LoadingPointDomainId } from "@/app/domains/loading-points/loading-points.constants";
let SelectedLPFilterService = class SelectedLPFilterService {
    routerService;
    constructor(routerService) {
        this.routerService = routerService;
    }
    createEqualsFilter() {
        return [
            {
                propertyName: "LoadingPointId",
                propertyValue: this.routerService.getParam(this.getIdRouteParam()),
            },
        ];
    }
    getIdRouteParam() {
        return createEntityIdRouteParam(LoadingPointDomainId);
    }
};
SelectedLPFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof RouterService !== "undefined" && RouterService) === "function" ? _a : Object])
], SelectedLPFilterService);
export { SelectedLPFilterService };
