var _a, _b;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
let TendersLanesService = class TendersLanesService {
    httpService;
    toastService;
    constructor(httpService, toastService) {
        this.httpService = httpService;
        this.toastService = toastService;
    }
    async addToTender(payload) {
        const updated = await this.httpService.post("lanes/assignTenderToLane", payload);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: "domains.tenders-lanes.add-to-tender.toast-message",
        });
        return updated;
    }
    async removeFromTender(payload) {
        const updated = await this.httpService.post("lanes/assignTenderToLane", payload);
        return updated[0];
    }
};
TendersLanesService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof ToastService !== "undefined" && ToastService) === "function" ? _b : Object])
], TendersLanesService);
export { TendersLanesService };
