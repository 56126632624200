import { LoadingPointAssignedProductsDomainId } from "@/app/domains/assigned-products/assigned-products.constants";
import { BuildingDomainId } from "@/app/domains/buildings/buildings.constants";
import { LoadingPointDomainId } from "@/app/domains/loading-points/loading-points.constants";
import { ProductDomainId } from "@/app/domains/products/products.constants";
import { SiteDomainId } from "@/app/domains/sites/sites.constants";
import { UnloadingPointDomainId } from "@/app/domains/unloading-points/unloading-points.constants";
export const SubmodulesEditRouteName = "submodules-edit";
export const WebhooksRouteName = "webhooks-list";
export const WebhooksDomainId = "Webhooks";
export const CreateWebhookModalId = "CreateWebhook";
export const EditWebhookModalId = "EditEntityWebhooks";
export var WebhookTriggerType;
(function (WebhookTriggerType) {
    WebhookTriggerType["BPUnloadingPointOpeningHours"] = "BPUnloadingPointOpeningHours";
    WebhookTriggerType["SendLaneOnDemand"] = "SendLaneOnDemand";
})(WebhookTriggerType || (WebhookTriggerType = {}));
export const EntitySubmoduleConfigurationMenuItems = [
    {
        domainId: SiteDomainId,
        id: "Site",
        route: `${SubmodulesEditRouteName}/site`,
    },
    {
        domainId: BuildingDomainId,
        id: "Building",
        route: `${SubmodulesEditRouteName}/building`,
    },
    {
        domainId: LoadingPointDomainId,
        id: "LoadingPoint",
        route: `${SubmodulesEditRouteName}/loading-point`,
    },
    {
        domainId: UnloadingPointDomainId,
        id: "UnloadingPoint",
        route: `${SubmodulesEditRouteName}/unloading-point`,
    },
    {
        domainId: ProductDomainId,
        id: "Product",
        route: `${SubmodulesEditRouteName}/product`,
    },
    {
        domainId: LoadingPointAssignedProductsDomainId,
        id: "AssignedProduct",
        route: `${SubmodulesEditRouteName}/assigned-product`,
    },
];
export const EntityWebhooksConfigurationMenuItems = [
    {
        domainId: WebhooksDomainId,
        listId: "all",
        route: `${WebhooksRouteName}/all`,
    },
];
