import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { BusinessPartnersDomainId } from "@/app/domains/business-partners/business-partners.constants";
import { Permissions } from "@/app/shared/constants/permissions.constants";
import { CompanyRole } from "@/app/shared/types/access.type";
import { CompaniesUrl } from "@/app/domains/public-data/public-data.constants";
import { CreateCompanySuccessModalId } from "@/app/domains/public-data/companies/companies.constants";
import { BusinessPartnersListId } from "@/app/domains/business-partners/business-partners.constants";
import CreateBusinessPartnerModalComponent from "@/app/domains/business-partners/components/create-business-partner-modal.component.vue";
import BusinessPartnerListComponent from "@/app/domains/business-partners/components/business-partners-list.component.vue";
import CreateCompanySuccessModalComponent from "@/app/domains/public-data/companies/components/create-company-success-modal.component.vue";
import { CompanyService } from "@/app/domains/public-data/companies/services/company.service";
import { ManagedByFilterService } from "@/app/domains/business-partners/services/manage-by.service";
export const BusinessPartnersModule = createModule({
    components: [CreateBusinessPartnerModalComponent, BusinessPartnerListComponent, CreateCompanySuccessModalComponent],
    imports: [
        DomainModule({
            id: BusinessPartnersDomainId,
            icon: "business",
            detailsIcon: "business",
            lists: [
                {
                    id: BusinessPartnersListId,
                    filter: ManagedByFilterService,
                    createModal: CreateBusinessPartnerModalComponent,
                    createRoles: [...Permissions.isAdmin, CompanyRole.DataManager, CompanyRole.RequirementManager],
                },
            ],
            domainTranslationPrefix: "business-partners",
            baseUrl: CompaniesUrl,
            components: {
                createModal: CreateBusinessPartnerModalComponent,
                table: BusinessPartnerListComponent,
            },
        }),
        ModalModule(CreateCompanySuccessModalId, CreateCompanySuccessModalComponent),
    ],
    providers: [
        CompanyService,
        ManagedByFilterService,
        {
            token: TranslationPrefixToken,
            useValue: "domains.business-partners",
        },
    ],
});
