import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    return (_openBlock(), _createBlock(_component_AppButton, {
        class: "p-button-secondary bookmark-button",
        onClick: _ctx.addBookmarks,
        disabled: !_ctx.selectedIds.length,
        loading: _ctx.isLoading
    }, {
        default: _withCtx(() => [
            _createVNode(_component_Icon, { name: "bookmark" }),
            _createTextVNode(" " + _toDisplayString(_ctx.translate("buttons.bookmark")), 1)
        ]),
        _: 1
    }, 8, ["onClick", "disabled", "loading"]));
}
