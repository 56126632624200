import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-29b827b8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-product-group-assignment" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_EditProductGroupAssignmentView = _resolveComponent("EditProductGroupAssignmentView");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createBlock(_component_EditProductGroupAssignmentView, {
                key: 1,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.model = $event)),
                initial: _ctx.initial,
                errors: _ctx.errors,
                clearErrors: _ctx.clearErrors
            }, null, 8, ["modelValue", "initial", "errors", "clearErrors"]))
    ]));
}
