import { defineStore } from "pinia";
const initialState = {
    referringListByDomainId: {},
};
export const useListIdStore = defineStore({
    id: "list-id",
    state: () => initialState,
    actions: {
        setListId(domainId, fromListId, fromDomainId) {
            this.referringListByDomainId[domainId] = {
                listId: fromListId,
                domainId: fromDomainId ?? domainId,
            };
        },
        deleteListId(domainId) {
            delete this.referringListByDomainId[domainId];
        },
    },
});
