var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { DomainService } from "@/app/modular-content/services/domain.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { TranslationService } from "@/app/translation/services/translation.service";
let CopyAssignedProductsModulesService = class CopyAssignedProductsModulesService {
    httpService;
    domainService;
    toastService;
    translationService;
    constructor(httpService, domainService, toastService, translationService) {
        this.httpService = httpService;
        this.domainService = domainService;
        this.toastService = toastService;
        this.translationService = translationService;
    }
    async copyModules(dto) {
        const loadingType = dto.sourceLoadingPointId ? "loadingpoints" : "unloadingpoints";
        const currentEntity = this.domainService.getCurrent();
        await this.httpService.put(`${loadingType}/${currentEntity?.unloadingLoadingPointId}/products/${currentEntity?.id}/copyData`, dto);
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate("modular-content.components.copy-module.toast-message.success"),
        });
    }
};
CopyAssignedProductsModulesService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof DomainService !== "undefined" && DomainService) === "function" ? _b : Object, typeof (_c = typeof ToastService !== "undefined" && ToastService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object])
], CopyAssignedProductsModulesService);
export { CopyAssignedProductsModulesService };
