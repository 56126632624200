export const getObserver = () => {
    const MutationObserver = window.MutationObserver;
    return function (node, callback) {
        if (!node || node.nodeType !== 1) {
            return;
        }
        if (MutationObserver) {
            const mutationObserver = new MutationObserver(callback);
            mutationObserver.observe(node, { childList: true, subtree: true });
            return mutationObserver;
        }
        else {
            node.addEventListener("DOMNodeInserted", callback, false);
            node.addEventListener("DOMNodeRemoved", callback, false);
        }
    };
};
