import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-792412dc"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "confirmation-description mb-4" };
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_Icon = _resolveComponent("Icon");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        header: _ctx.modalHeader,
        "show-header": !_ctx.isRequestSent,
        "modal-id": _ctx.modalId,
        "show-close-icon": false,
        class: _normalizeClass(["company-delete-modal", { 'result-modal': _ctx.isRequestSent }])
    }, {
        default: _withCtx(() => [
            (!_ctx.isRequestSent)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("p", {
                            innerHTML: _ctx.translate(`confirmation.${_ctx.entityType}.description`, { company: _ctx.companyName })
                        }, null, 8, _hoisted_2)
                    ]),
                    _createVNode(_component_ButtonGroup, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppButton, {
                                class: "p-button-tertiary",
                                onClick: _ctx.close
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_AppButton, {
                                class: "p-button-danger",
                                onClick: _ctx.deleteCompany
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.delete")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"])
                        ]),
                        _: 1
                    })
                ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                            key: 0,
                            center: ""
                        }))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createVNode(_component_Icon, {
                                class: _normalizeClass(["result-icon", _ctx.iconColor]),
                                name: _ctx.iconName
                            }, null, 8, ["class", "name"]),
                            _createElementVNode("h3", {
                                class: _normalizeClass(["header", { 'pb-4': _ctx.isSuccess }])
                            }, _toDisplayString(_ctx.resultHeaderTranslation), 3),
                            (!_ctx.isSuccess)
                                ? (_openBlock(), _createElementBlock("p", {
                                    key: 0,
                                    class: "result-description",
                                    innerHTML: _ctx.translate('result.failure-description')
                                }, null, 8, _hoisted_3))
                                : _createCommentVNode("", true),
                            _createVNode(_component_AppButton, {
                                class: "p-button-lg",
                                onClick: _ctx.close
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.resultButtonTranslation), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"])
                        ], 64))
                ], 64))
        ]),
        _: 1
    }, 8, ["header", "show-header", "modal-id", "class"]));
}
