export const TendersDomainId = "Tenders";
export const TenderListId = "all";
export const EditTenderModalId = "EditEntityTenders";
export const CloseTenderModalId = "CloseTenderModal";
export const AssignCarrierModalId = "AssignCarrierModal";
export const DownloadSpecificationsModalId = "DownloadSpecificationsModal";
export const tenderStatusStyles = {
    Preparing: "yellow",
    Published: "green",
    Closed: "red",
};
export const StatusClosed = "Closed";
