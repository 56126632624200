var _a, _b;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { DomainRouteService } from "@/app/modular-content/services/domain-route.service";
import { RouterService } from "@/app/shared/services/router.service";
import { SiteDomainId } from "@/app/domains/sites/sites.constants";
import { camelCase } from "change-case";
let SelectedSiteFilterService = class SelectedSiteFilterService {
    routeService;
    routerService;
    constructor(routeService, routerService) {
        this.routeService = routeService;
        this.routerService = routerService;
    }
    createEqualsFilter() {
        const id = this.routerService.getParam(`${camelCase(SiteDomainId)}Id`) || this.routeService.getCurrentEntityId();
        return [
            {
                propertyName: "SiteId",
                propertyValue: id,
            },
        ];
    }
};
SelectedSiteFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof DomainRouteService !== "undefined" && DomainRouteService) === "function" ? _a : Object, typeof (_b = typeof RouterService !== "undefined" && RouterService) === "function" ? _b : Object])
], SelectedSiteFilterService);
export { SelectedSiteFilterService };
