import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EditAssignedProductMasterBasicInformationView = _resolveComponent("EditAssignedProductMasterBasicInformationView");
    return (_openBlock(), _createBlock(_component_EditAssignedProductMasterBasicInformationView, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial,
        errors: _ctx.errors
    }, null, 8, ["modelValue", "initial", "errors"]));
}
