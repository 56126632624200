var _a;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { InjectionScope } from "@/di/types/scope";
import { inject } from "@/di/decorators/inject.decorator";
import { BackendValidationService } from "@/app/validation/services/backend-validation.service";
import _ from "lodash";
import { rootErrorPath } from "@/app/validation/constants";
import { Subject } from "rxjs";
let BackendValidationErrorsService = class BackendValidationErrorsService {
    backendValidationService;
    errors = {};
    errorsSubject = new Subject();
    constructor(backendValidationService) {
        this.backendValidationService = backendValidationService;
    }
    resetErrors() {
        this.errors = {};
    }
    onChange() {
        return this.errorsSubject;
    }
    addFormError(error) {
        const path = error.path ?? rootErrorPath;
        if (this.errors[path] === undefined) {
            this.errors[path] = [];
        }
        this.errors[path].push(error);
    }
    addAxiosError(error) {
        this.backendValidationService.createFormErrors(error).forEach((it) => this.addFormError(it));
        this.errorsSubject.next(this.errors);
    }
    consumeErrors(path = rootErrorPath) {
        const errors = this.errors[path];
        delete this.errors[path];
        this.scrollToView();
        return errors;
    }
    scrollToView() {
        setTimeout(() => {
            const element = document.getElementById("error-message");
            element && element.closest(".form-field")?.scrollIntoView({ behavior: "smooth" });
        }, 350);
    }
    get() {
        return _.values(this.errors).flat();
    }
};
BackendValidationErrorsService = __decorate([
    service({ scope: InjectionScope.Global }),
    __param(0, inject(BackendValidationService)),
    __metadata("design:paramtypes", [typeof (_a = typeof BackendValidationService !== "undefined" && BackendValidationService) === "function" ? _a : Object])
], BackendValidationErrorsService);
export { BackendValidationErrorsService };
