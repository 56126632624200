var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { reactive } from "@/di/decorators/computed.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
let NotificationsService = class NotificationsService {
    httpClient;
    notificationList = [];
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async loadNotifications() {
        this.notificationList = await this.fetchNotifications();
    }
    async fetchNotifications() {
        return this.httpClient.get("members/notifications/myNotifications");
    }
    async removeNotification(notificationId) {
        await this.httpClient.post(`members/notifications/removeNotification/${notificationId}`, undefined);
        await this.loadNotifications();
    }
    async createNotification(notification) {
        await this.httpClient.post("members/notifications/createGlobalNotification", notification);
    }
    getNotificationList() {
        return this.notificationList;
    }
};
__decorate([
    reactive(),
    __metadata("design:type", Array)
], NotificationsService.prototype, "notificationList", void 0);
NotificationsService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object])
], NotificationsService);
export { NotificationsService };
