import { defineStore } from "pinia";
const initialState = {
    byId: {},
    data: undefined,
    isLoading: false,
};
export const useEntityStore = defineStore({
    id: "entity",
    state: () => initialState,
    getters: {
        getEntityById: (state) => {
            return (id) => state.byId[id];
        },
    },
    actions: {
        async load(callback) {
            this.isLoading = true;
            const value = await callback().finally(() => {
                this.isLoading = false;
            });
            return value;
        },
    },
});
