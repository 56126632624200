import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5fe84a56"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-available-equipment" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row section" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 column-description" };
const _hoisted_6 = { class: "row section" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = { class: "col-6 column-description" };
const _hoisted_9 = {
    key: 0,
    class: "row section"
};
const _hoisted_10 = { class: "col-6 column-header" };
const _hoisted_11 = { class: "col-6 column-description column-description-row" };
const _hoisted_12 = { key: 0 };
const _hoisted_13 = { key: 0 };
const _hoisted_14 = { key: 1 };
const _hoisted_15 = { key: 2 };
const _hoisted_16 = { class: "row section" };
const _hoisted_17 = { class: "col-6 column-header" };
const _hoisted_18 = { class: "col-6 column-description" };
const _hoisted_19 = { class: "row section" };
const _hoisted_20 = { class: "col-6 column-header" };
const _hoisted_21 = { class: "col-6 column-description" };
const _hoisted_22 = { class: "row section" };
const _hoisted_23 = { class: "col-6 column-header" };
const _hoisted_24 = { class: "col-6 column-description" };
const _hoisted_25 = { class: "row section" };
const _hoisted_26 = { class: "col-6 column-header" };
const _hoisted_27 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalizedField = _resolveComponent("LocalizedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_LocalizedField, {
                modelValue: _ctx.model?.depressurisingFreeText,
                freeText: ""
            }, {
                header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("depressurising")), 1)
                ]),
                description: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.depressurisingInfo), 1)
                ]),
                _: 1
            }, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("vapour-return")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.vapourReturn, "available", "not-available")), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("hoses")), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.hoses, "available", "not-available")), 1)
            ]),
            (_ctx.model?.hoses)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("hose-specification.title")), 1),
                    _createElementVNode("div", _hoisted_11, [
                        (_ctx.noHoseSpecifications)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, "-"))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                (_ctx.hoseSpecificationLengthInfo)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.hoseSpecificationLengthInfo), 1))
                                    : _createCommentVNode("", true),
                                (_ctx.hoseSpecificationDiameterInfo)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.hoseSpecificationDiameterInfo), 1))
                                    : _createCommentVNode("", true),
                                (_ctx.hoseSpecificationElasticityInfo)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.hoseSpecificationElasticityInfo), 1))
                                    : _createCommentVNode("", true)
                            ], 64))
                    ])
                ]))
                : _createCommentVNode("", true),
            (_ctx.model?.hoses)
                ? (_openBlock(), _createBlock(_component_LocalizedField, {
                    key: 1,
                    modelValue: _ctx.model?.emptyingTheHoseEnsuredByFreeText,
                    freeText: ""
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("emptying-the-hose-ensured-by.title")), 1)
                    ]),
                    description: _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(_ctx.emptyingTheHoseEnsuredByInfo()), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"]))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translate("vacuum-protection")), 1),
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.vacuumProtection, "available", "not-available")), 1)
            ]),
            _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.translate("non-return-value-in-line")), 1),
                _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.nonReturnValueInLine, "available", "not-available")), 1)
            ]),
            _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.translate("emergency-stop-for-fixed-installation")), 1),
                _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.emergencyStopForFixedInstallationInfo()), 1)
            ]),
            _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.translate("ana-system")), 1),
                _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.anaSystem, "available", "not-available")), 1)
            ])
        ])
    ]));
}
