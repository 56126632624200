import { createRouter, createWebHistory } from "vue-router";
import { DashboardRouteName } from "@/app/dashboard/constants/dashboard.constants";
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: "/",
            redirect: {
                name: DashboardRouteName,
            },
        },
    ],
});
export default router;
