import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9a15f208"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-cleaning-operators" };
const _hoisted_2 = {
    key: 0,
    class: "column-description mb-0"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "column-header col-6" };
const _hoisted_6 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_7 = {
    key: 1,
    class: "col-6 column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("accepted-cleaning-operators")), 1),
                    (_ctx.model?.cleaningOperators?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.cleaningOperators, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "CleaningOperators")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
                ])
            ]))
    ]));
}
