import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "feedback-info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    const _component_CollapsiblePanel = _resolveComponent("CollapsiblePanel");
    return (_openBlock(), _createBlock(_component_CollapsiblePanel, {
        title: _ctx.title,
        onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('click'))),
        descriptionIcon: false,
        "initially-collapsed": ""
    }, {
        button: _withCtx(() => [
            (_ctx.hasFeedback)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.translate("feedback-added")), 1)
                ]))
                : _createCommentVNode("", true),
            (_ctx.verificationAllowed)
                ? (_openBlock(), _createBlock(_component_AppButton, {
                    key: 1,
                    class: "p-button p-button-secondary",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.$emit('feedback')), ["stop"]))
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_Icon, { name: "feedback" }),
                        _createTextVNode(" " + _toDisplayString(_ctx.translate("buttons.feedback")), 1)
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
            (!_ctx.hasData)
                ? (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 0 }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.verificationAllowed ? _ctx.translate("no-data") : _ctx.translate("no-module-data")), 1)
                    ]),
                    _: 1
                }))
                : _renderSlot(_ctx.$slots, "default", { key: 1 })
        ]),
        _: 3
    }, 8, ["title"]));
}
