import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ContextMenuHeader = _resolveComponent("ContextMenuHeader");
    return (_openBlock(), _createBlock(_component_ContextMenuHeader, {
        class: "companies-context-menu-header",
        icon: "assignment",
        isLoading: _ctx.isLoading,
        label: _ctx.company?.id
    }, null, 8, ["isLoading", "label"]));
}
