var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
let CopyProductService = class CopyProductService {
    httpService;
    constructor(httpService) {
        this.httpService = httpService;
    }
    async copyProduct(productId) {
        return await this.httpService.post(`products/copy`, {
            productId,
        });
    }
    async copyAssignedProductModule(modules, sourceLoadingUnloadingPointId, sourceProductId, productId, loadingUnloadingPointId, isInboundProduct) {
        const loadingType = isInboundProduct ? "unloadingpoints" : "loadingpoints";
        const sourceLoadingUnloadingPointKey = isInboundProduct ? "sourceUnloadingPointId" : "sourceloadingPointId";
        return await this.httpService.put(`${loadingType}/${loadingUnloadingPointId}/products/${productId}/copyData`, {
            [sourceLoadingUnloadingPointKey]: sourceLoadingUnloadingPointId,
            sourceProductId,
            modules,
        });
    }
};
CopyProductService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object])
], CopyProductService);
export { CopyProductService };
