import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppChips = _resolveComponent("AppChips");
    const _component_ErrorMessage = _resolveComponent("ErrorMessage");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Button = _resolveComponent("Button");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        showCloseIcon: true,
        "modal-id": _ctx.modalId,
        header: _ctx.translate(`${_ctx.headerPrefix}-header`)
    }, {
        default: _withCtx(() => [
            _createVNode(_component_FormField, null, {
                default: _withCtx(() => [
                    _createVNode(_component_InputLabel, { required: "" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("form-fields.emails.label")), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_AppChips, {
                        modelValue: _ctx.emails,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.emails) = $event)),
                        itemStyles: _ctx.itemStyles,
                        class: _normalizeClass({ 'p-invalid': _ctx.isError }),
                        placeholder: _ctx.translate('form-fields.emails.placeholder'),
                        onChange: _cache[1] || (_cache[1] = ($event) => (_ctx.isError = false)),
                        focusOnMounted: true,
                        separator: [' ', '\n', ',']
                    }, null, 8, ["modelValue", "itemStyles", "class", "placeholder"]),
                    (_ctx.errorEmails?.length > 0)
                        ? (_openBlock(), _createBlock(_component_ErrorMessage, { key: 0 }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", { innerHTML: _ctx.errorMessage }, null, 8, _hoisted_1)
                            ]),
                            _: 1
                        }))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }),
            _createVNode(_component_ButtonGroup, { orientation: "horizontal" }, {
                default: _withCtx(() => [
                    _createVNode(_component_Button, {
                        class: "p-button-lg p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_Button, {
                        disabled: _ctx.buttonDisabled,
                        loading: _ctx.isLoading,
                        class: "p-button-lg",
                        onClick: _ctx.inviteMethod
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.invite")), 1)
                        ]),
                        _: 1
                    }, 8, ["disabled", "loading", "onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
