import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpRequestInterceptorToken } from "@/app/http/tokens/http-request-interceptor.token";
import { CompanyIdHeader } from "@/app/http/constants/custom-headers";
import _ from "lodash";
import { useCompaniesStore } from "@/app/domains/public-data/companies/state/companies.state";
let CompanyIdHeaderInterceptorService = class CompanyIdHeaderInterceptorService {
    companiesStore;
    constructor() {
        this.companiesStore = useCompaniesStore();
    }
    interceptRequest(request, next) {
        if (_.keys(request.headers)?.includes(CompanyIdHeader)) {
            return next(request);
        }
        const companyId = this.companiesStore.selectedBusinessPartnerId || this.companiesStore.selectedId;
        if (!companyId) {
            return next(request);
        }
        if (request.headers === undefined) {
            request.headers = {};
        }
        request.headers = {
            ...request.headers,
            [CompanyIdHeader]: companyId,
        };
        return next(request);
    }
};
CompanyIdHeaderInterceptorService = __decorate([
    service({ token: HttpRequestInterceptorToken }),
    __metadata("design:paramtypes", [])
], CompanyIdHeaderInterceptorService);
export { CompanyIdHeaderInterceptorService };
