import { createModule } from "@/di/module";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { ModuleConfigurationService } from "@/app/modular-content/services/module-configuration.service";
import { ModuleConfigurationServiceToken } from "@/app/modular-content/token/modular-content.token";
import { ModuleConfigurationUrlFactoryService } from "@/app/modular-content/services/module-configuration-url-factory.service";
export const DomainConfigurationModule = (options) => {
    const specific = domainTokensFactory(options.id);
    const generic = domainTokensFactory();
    return createModule({
        providers: [
            { token: specific.configurationService, useClass: ModuleConfigurationService },
            { token: generic.configurationService, useToken: specific.configurationService },
            { token: ModuleConfigurationService, useToken: specific.configurationService },
            { token: ModuleConfigurationServiceToken, useToken: specific.configurationService },
            ModuleConfigurationUrlFactoryService,
        ],
    });
};
