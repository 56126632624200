import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _directive_tooltip = _resolveDirective("tooltip");
    return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([_ctx.statusClass, "connect-status"])
    }, null, 2)), [
        [
            _directive_tooltip,
            _ctx.tooltipMessage,
            void 0,
            { bottom: true }
        ]
    ]);
}
