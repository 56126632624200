import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f483d8ba"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "sub-module-title" };
const _hoisted_2 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_SubModuleInfo = _resolveComponent("SubModuleInfo");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    name: _ctx.icon,
                    variant: _ctx.iconVariant
                }, null, 8, ["name", "variant"]))
                : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.headerText), 1)
        ]),
        _createVNode(_component_SubModuleInfo, {
            publicInfo: _ctx.publicInfo,
            descriptionIcon: _ctx.descriptionIcon,
            headerText: _ctx.headerText,
            subModuleId: _ctx.subModuleId,
            subModuleKey: _ctx.subModuleKey,
            subModuleDescription: _ctx.subModuleDescription,
            showChangeHistory: _ctx.showChangeHistory
        }, null, 8, ["publicInfo", "descriptionIcon", "headerText", "subModuleId", "subModuleKey", "subModuleDescription", "showChangeHistory"])
    ]));
}
