import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-40640731"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "d-flex align-items-center flex-column mb-4" };
const _hoisted_2 = ["accept"];
const _hoisted_3 = {
    key: 0,
    class: "placeholder"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ErrorMessage = _resolveComponent("ErrorMessage");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        header: _ctx.translate('modal-header'),
        "modal-id": _ctx.modalId,
        class: "logo-upload-modal"
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ErrorMessage, {
                    style: _normalizeStyle(`visibility: ${_ctx.isTooBigFile ? 'visible' : 'hidden'}`),
                    class: "mt-0 mb-2"
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("file-size-error", { max: _ctx.fileSizeLimit })), 1)
                    ]),
                    _: 1
                }, 8, ["style"]),
                _createElementVNode("input", {
                    type: "file",
                    accept: _ctx.allowedFileTypes,
                    class: "d-none",
                    ref: "fileInput",
                    onChange: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.onFilePick && _ctx.onFilePick(...args)))
                }, null, 40, _hoisted_2),
                _createElementVNode("div", {
                    class: "image-container",
                    style: _normalizeStyle(`background-image: url(${_ctx.filePath})`),
                    onDragover: _cache[1] || (_cache[1] = _withModifiers(
                    //@ts-ignore
                    (...args) => (_ctx.onFileDrag && _ctx.onFileDrag(...args)), ["stop", "prevent"])),
                    onDrop: _cache[2] || (_cache[2] = _withModifiers(
                    //@ts-ignore
                    (...args) => (_ctx.onFileDrop && _ctx.onFileDrop(...args)), ["stop", "prevent"]))
                }, [
                    (!_ctx.filePath)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.translate("modal-placeholder")), 1))
                        : _createCommentVNode("", true)
                ], 36),
                _createVNode(_component_AppButton, {
                    onClick: _ctx.openFileBrowser,
                    class: "mt-4 w-50"
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("browse-btn")), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"])
            ]),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        loading: _ctx.isUploading,
                        onClick: _ctx.uploadMethod,
                        disabled: !_ctx.filePath
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("confirm-btn")), 1)
                        ]),
                        _: 1
                    }, 8, ["loading", "onClick", "disabled"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["header", "modal-id"]));
}
