import { createModule } from "@/di/module";
import { RestClientService } from "@/app/http/services/rest-client.service";
import { RestClientBaseUrlCallbackToken } from "@/app/http/tokens/rest-client-base-url-callback.token";
export const RestClientModule = (options) => {
    return createModule({
        providers: [
            { token: RestClientBaseUrlCallbackToken, useValue: options.baseUrlCallback },
            { token: options.token, useClass: RestClientService },
            { token: RestClientService, useToken: options.token },
        ],
    });
};
