var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let AlphanumericValidator = class AlphanumericValidator {
    translationService;
    name = "alphanumeric";
    regex = new RegExp(/^[0-9a-zA-Z]+$/);
    constructor(translationService) {
        this.translationService = translationService;
    }
    message() {
        return this.translationService.translate("validation.messages.alphanumeric");
    }
    validate(value) {
        return value === "" || !!value.match(this.regex);
    }
};
AlphanumericValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], AlphanumericValidator);
export { AlphanumericValidator };
