var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ValidatorToken } from "@/app/validation/tokens/validator.token";
import { TranslationService } from "@/app/translation/services/translation.service";
let MaxIntegerDigitsValidator = class MaxIntegerDigitsValidator {
    translationService;
    name = "max-integer-digits";
    arg = Number;
    constructor(translationService) {
        this.translationService = translationService;
    }
    message(max) {
        return this.translationService.translate("validation.messages.max-integer-digits", { max: max + 1 });
    }
    validate(value, max) {
        if (!value) {
            return true;
        }
        if (value < 0) {
            const digitStr = value.toString().slice(1).split(".")[0];
            return digitStr.length <= max;
        }
        const digitStr = value.toString().split(".")[0];
        return digitStr.length <= max;
    }
};
MaxIntegerDigitsValidator = __decorate([
    service({ token: ValidatorToken }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], MaxIntegerDigitsValidator);
export { MaxIntegerDigitsValidator };
