var _a;
import { __decorate, __metadata, __param } from "tslib";
import _ from "lodash";
import { service } from "@/di/decorators/service.decorator";
import { CurrentUserServiceToken } from "@/app/users/tokens/current-user-service.token";
import { inject } from "@/di/decorators/inject.decorator";
import { CurrentUserService } from "@/app/users/types/current-user-service.type";
const SESSION_STORAGE_KEY = "loady-session";
let SessionStorageService = class SessionStorageService {
    userService;
    constructor(userService) {
        this.userService = userService;
    }
    get(path) {
        const data = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) ?? "{}");
        return _.get(data, `${this.prefix()}.${path}`);
    }
    set(path, value) {
        const data = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) ?? "{}");
        _.set(data, `${this.prefix()}.${path}`, value);
        sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));
    }
    clear(path) {
        this.set(path, undefined);
    }
    prefix() {
        return this.userService.getCurrentUserId();
    }
};
SessionStorageService = __decorate([
    service(),
    __param(0, inject(CurrentUserServiceToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof CurrentUserService !== "undefined" && CurrentUserService) === "function" ? _a : Object])
], SessionStorageService);
export { SessionStorageService };
